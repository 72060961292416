import { Phase2AnswerAB } from "api/types/phase2/ab"
import phase2A from "assets/json/phase2/a.json"
import phase2AB from "assets/json/phase2/ab.json"
import phase2B from "assets/json/phase2/b.json"
import { Phase2ABFormData } from "pages/components/phase2/components/AB/schema"
import { Phase2Type } from "pages/components/phase2/utils"
import { DeepPartial } from "utils"
import {
  dateTranslator,
  selectAndDateTranslator,
  selectTranslator,
  stringTranslator,
  transferringStockTranslator,
  businessPlanTranslator,
  bankBorrowingAmountAndNetWorthTranslator,
  stringArrayTranslator,
  nameAndBirthdayTranslator,
} from "./utils"

type Diagnosis = Extract<Phase2Type, "UA" | "EA" | "UB" | "EB">

interface Args {
  diagnosis: Diagnosis
  formData: DeepPartial<Phase2ABFormData>
}

// TODO: phase2ResultAnswerがマージされたらそちらを使う
interface Return {
  diagnosis: Diagnosis
  answer: Phase2AnswerAB
}

/**
 * フォームのvalueをapiのスキーマに変換する
 */
export const phase2ABFormDataTranslator = ({
  diagnosis,
  formData,
}: Args): Return => ({
  diagnosis: diagnosis,
  answer: {
    question_group_1: {
      title: phase2AB.title1,
      answers: {
        answer_01: selectTranslator({
          json: phase2AB.q1,
          formData: formData.q1,
        }),
      },
    },
    question_group_2: {
      title: phase2AB.title2,
      answers: {
        answer_02: dateTranslator({
          json: phase2AB.q2,
          formData: formData.ab?.q2,
        }),
        answer_03: stringArrayTranslator({
          json: phase2AB.q3,
          formData: formData.ab?.q3,
        }),
        answer_04: selectTranslator({
          json: phase2AB.q4,
          formData: formData.ab?.q4,
        }),
        answer_05: dateTranslator({
          json: phase2AB.q5,
          formData: formData.ab?.q5,
        }),
        answer_06: selectTranslator({
          json: phase2AB.q6,
          formData: formData.ab?.q6,
        }),
        answer_07: dateTranslator({
          json: phase2AB.q7,
          formData: formData.ab?.q7,
        }),
        answer_08: dateTranslator({
          json: phase2AB.q8,
          formData: formData.ab?.q8,
        }),
        answer_09: stringTranslator({
          json: phase2AB.q9,
          formData: formData.ab?.q9,
        }),
      },
    },
    question_group_3: {
      title: phase2A.title1,
      answers: {
        answer_10: nameAndBirthdayTranslator({
          json: phase2A.q0,
          formData: formData.a?.q0,
        }),
        answer_11: selectAndDateTranslator({
          json: phase2A.q1,
          formData: formData.a?.q1,
        }),
        answer_12: selectAndDateTranslator({
          json: phase2A.q2,
          formData: formData.a?.q2,
        }),
        answer_13: selectAndDateTranslator({
          json: phase2A.q2,
          formData: formData.a?.q3,
        }),
        answer_14: selectAndDateTranslator({
          json: phase2A.q4,
          formData: formData.a?.q4,
        }),
      },
    },
    question_group_4: {
      title: phase2A.title2,
      answers: {
        answer_15: selectAndDateTranslator({
          json: phase2A.q5,
          formData: formData.a?.q5,
        }),
        answer_16: selectTranslator({
          json: phase2A.q6,
          formData: formData.a?.q6,
        }),
        answer_17: selectTranslator({
          json: phase2A.q7,
          formData: formData.a?.q7,
        }),
      },
    },
    question_group_5: {
      title: phase2A.title3,
      answers: {
        answer_18: selectAndDateTranslator({
          json: phase2A.q8,
          formData: formData.a?.q8,
        }),
        answer_19: selectAndDateTranslator({
          json: phase2A.q9,
          formData: formData.a?.q9,
        }),
        answer_20: selectAndDateTranslator({
          json: phase2A.q10,
          formData: formData.a?.q10,
        }),
        answer_21: selectAndDateTranslator({
          json: phase2A.q11,
          formData: formData.a?.q11,
        }),
        answer_22: selectTranslator({
          json: phase2A.q12,
          formData: formData.a?.q12,
        }),
        answer_23: selectAndDateTranslator({
          json: phase2A.q13,
          formData: formData.a?.q13,
        }),
        answer_24: selectAndDateTranslator({
          json: phase2A.q14,
          formData: formData.a?.q14,
        }),
        answer_25: selectTranslator({
          json: phase2A.q15,
          formData: formData.a?.q15,
        }),
        answer_26: selectAndDateTranslator({
          json: phase2A.q16,
          formData: formData.a?.q16,
        }),
        answer_27: selectTranslator({
          json: phase2A.q17,
          formData: formData.a?.q17,
        }),
        answer_28: selectAndDateTranslator({
          json: phase2A.q18,
          formData: formData.a?.q18,
        }),
        answer_29: selectAndDateTranslator({
          json: phase2A.q19,
          formData: formData.a?.q19,
        }),
        answer_30: selectAndDateTranslator({
          json: phase2A.q20,
          formData: formData.a?.q20,
        }),
        answer_31: selectAndDateTranslator({
          json: phase2A.q21,
          formData: formData.a?.q21,
        }),
      },
    },
    question_group_6: {
      title: phase2A.title4,
      answers: {
        answer_32: selectTranslator({
          json: phase2A.q22,
          formData: formData.a?.q22,
        }),
        answer_33: transferringStockTranslator({
          json: phase2A.q23,
          formData: formData.a?.q23,
        }),
        answer_34: selectAndDateTranslator({
          json: phase2A.q24,
          formData: formData.a?.q24,
        }),
        answer_35: selectAndDateTranslator({
          json: phase2A.q25,
          formData: formData.a?.q25,
        }),
        answer_36: businessPlanTranslator({
          json: phase2A.q26,
          formData: formData.a?.q26,
        }),
        answer_37: dateTranslator({
          json: phase2A.q27,
          formData: formData.a?.q27,
        }),
        answer_38: selectAndDateTranslator({
          json: phase2A.q28,
          formData: formData.a?.q28,
        }),
        answer_39: selectTranslator({
          json: phase2A.q29,
          formData: formData.a?.q29,
        }),
        answer_40: stringTranslator({
          json: phase2A.q30,
          formData: formData.a?.q30,
        }),
        answer_41: stringTranslator({
          json: phase2A.q31,
          formData: formData.a?.q31,
        }),
        answer_42: bankBorrowingAmountAndNetWorthTranslator({
          json: phase2A.q32,
          formData: formData.a?.q32,
        }),
      },
    },
    question_group_7: {
      title: phase2B.title1,
      answers: {
        answer_43: nameAndBirthdayTranslator({
          json: phase2B.q0,
          formData: formData.b?.q0,
        }),
        answer_44: selectAndDateTranslator({
          json: phase2B.q1,
          formData: formData.b?.q1,
        }),
        answer_45: selectAndDateTranslator({
          json: phase2B.q2,
          formData: formData.b?.q2,
        }),
        answer_46: selectAndDateTranslator({
          json: phase2B.q2,
          formData: formData.b?.q3,
        }),
        answer_47: selectAndDateTranslator({
          json: phase2B.q4,
          formData: formData.b?.q4,
        }),
      },
    },
    question_group_8: {
      title: phase2B.title2,
      answers: {
        answer_48: selectAndDateTranslator({
          json: phase2B.q5,
          formData: formData.b?.q5,
        }),
        answer_49: selectTranslator({
          json: phase2B.q6,
          formData: formData.b?.q6,
        }),
        answer_50: selectTranslator({
          json: phase2B.q7,
          formData: formData.b?.q7,
        }),
      },
    },
    question_group_9: {
      title: phase2B.title3,
      answers: {
        answer_51: selectAndDateTranslator({
          json: phase2B.q8,
          formData: formData.b?.q8,
        }),
        answer_52: selectAndDateTranslator({
          json: phase2B.q9,
          formData: formData.b?.q9,
        }),
        answer_53: selectAndDateTranslator({
          json: phase2B.q10,
          formData: formData.b?.q10,
        }),
        answer_54: selectAndDateTranslator({
          json: phase2B.q11,
          formData: formData.b?.q11,
        }),
        answer_55: selectTranslator({
          json: phase2B.q12,
          formData: formData.b?.q12,
        }),
        answer_56: selectAndDateTranslator({
          json: phase2B.q13,
          formData: formData.b?.q13,
        }),
        answer_57: selectAndDateTranslator({
          json: phase2B.q14,
          formData: formData.b?.q14,
        }),
        answer_58: selectTranslator({
          json: phase2B.q15,
          formData: formData.b?.q15,
        }),
        answer_59: selectAndDateTranslator({
          json: phase2B.q16,
          formData: formData.b?.q16,
        }),
        answer_60: selectTranslator({
          json: phase2B.q17,
          formData: formData.b?.q17,
        }),
        answer_61: selectAndDateTranslator({
          json: phase2B.q18,
          formData: formData.b?.q18,
        }),
        answer_62: selectAndDateTranslator({
          json: phase2B.q19,
          formData: formData.b?.q19,
        }),
        answer_63: selectAndDateTranslator({
          json: phase2B.q20,
          formData: formData.b?.q20,
        }),
        answer_64: selectAndDateTranslator({
          json: phase2B.q21,
          formData: formData.b?.q21,
        }),
      },
    },
    question_group_10: {
      title: phase2B.title4,
      answers: {
        answer_65: selectTranslator({
          json: phase2B.q22,
          formData: formData.b?.q22,
        }),
        answer_66: transferringStockTranslator({
          json: phase2B.q23,
          formData: formData.b?.q23,
        }),
        answer_67: selectAndDateTranslator({
          json: phase2B.q24,
          formData: formData.b?.q24,
        }),
        answer_68: selectAndDateTranslator({
          json: phase2B.q25,
          formData: formData.b?.q25,
        }),
        answer_69: businessPlanTranslator({
          json: phase2B.q26,
          formData: formData.b?.q26,
        }),
        answer_70: dateTranslator({
          json: phase2B.q27,
          formData: formData.b?.q27,
        }),
        answer_71: selectAndDateTranslator({
          json: phase2B.q28,
          formData: formData.b?.q28,
        }),
        answer_72: selectTranslator({
          json: phase2B.q29,
          formData: formData.b?.q29,
        }),
        answer_73: stringTranslator({
          json: phase2B.q30,
          formData: formData.b?.q30,
        }),
        answer_74: stringTranslator({
          json: phase2B.q31,
          formData: formData.b?.q31,
        }),
        answer_75: bankBorrowingAmountAndNetWorthTranslator({
          json: phase2B.q32,
          formData: formData.b?.q32,
        }),
      },
    },
  },
})
