import { css } from "styled-components"

const SCROLL_BAR_SIZE = 10

export const ScrollStyles = css`
  overflow: auto;
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.color.nobel};
    border-radius: ${SCROLL_BAR_SIZE / 2}px;
  }
  &::-webkit-scrollbar {
    background-color: ${(props) => props.theme.color.silver};
    height: ${SCROLL_BAR_SIZE}px;
    width: ${SCROLL_BAR_SIZE}px;
    border-radius: ${SCROLL_BAR_SIZE / 2}px;
  }
  &::-webkit-scrollbar-corner {
    background-color: ${(props) => props.theme.color.silver};
  }
`
