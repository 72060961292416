import { RefObject, useEffect } from "react"

interface Props {
  /**
   * 購読の有効化
   */
  enabled?: boolean
  /**
   * 内側の要素
   */
  excludes: RefObject<Element>[]
  /**
   * ハンドラー
   */
  handler: (e: Event) => void
}

/**
 * 指定の要素の外側のクリックイベントを拾うHooks
 */
export function useOutsideClick({ enabled = true, excludes, handler }: Props) {
  useEffect(() => {
    const listener = (event: Event) => {
      if (!enabled) {
        return
      }

      // クリックイベントがrefsにある要素に一つでも含まれていれば呼ばない
      if (
        excludes.some(
          (ref) =>
            ref.current && ref.current.contains(event.target as HTMLElement),
        )
      ) {
        return
      }
      handler(event)
    }

    document.addEventListener("mouseup", listener)
    document.addEventListener("touchend", listener)

    return () => {
      document.removeEventListener("mouseup", listener)
      document.removeEventListener("touchend", listener)
    }
  }, [enabled, excludes, handler])
}
