import { AxiosError } from "axios"
import { useMutation, UseMutationOptions } from "react-query"
import {
  AccountEmailUpdateRequest,
  AccountEmailVerifyRequest,
  AccountPasswordResetRequest,
  AccountPasswordUpdateRequest,
  AccountPasswordUpdateError,
} from "api/account/types"
import { OmitMutationConfig } from "lib/react-query"
import { AccountAPI } from "./api"

const PREFIX_ID = "account"

export const passwordResetMutation: UseMutationOptions<
  void,
  AxiosError,
  AccountPasswordResetRequest
> = {
  mutationKey: `${PREFIX_ID}/resetPassword`,
  mutationFn: AccountAPI.resetPassword,
}

/**
 * パスワードの再設定をするHooks
 */
export function usePasswordResetMutation(
  config?: OmitMutationConfig<typeof passwordResetMutation>,
) {
  return useMutation({ ...passwordResetMutation, ...config })
}

export const updateEmailMutation: UseMutationOptions<
  void,
  AxiosError,
  AccountEmailUpdateRequest
> = {
  mutationKey: `${PREFIX_ID}/updateEmail`,
  mutationFn: AccountAPI.updateEmail,
}

/**
 * メールアドレス更新をするHooks
 */
export function useUpdateEmailMutataion(
  config?: OmitMutationConfig<typeof updateEmailMutation>,
) {
  return useMutation({ ...updateEmailMutation, ...config })
}

export const verifyEmailMutation: UseMutationOptions<
  void,
  AxiosError,
  AccountEmailVerifyRequest
> = {
  mutationKey: `${PREFIX_ID}/verifyEmail`,
  mutationFn: AccountAPI.verifyEmail,
}

/**
 * メールアドレス更新認証をするHooks
 */
export function useVerifyEmailMutation(
  config?: OmitMutationConfig<typeof verifyEmailMutation>,
) {
  return useMutation({ ...verifyEmailMutation, ...config })
}

export const updatePasswordMutation: UseMutationOptions<
  void,
  AxiosError<AccountPasswordUpdateError>,
  AccountPasswordUpdateRequest
> = {
  mutationKey: `${PREFIX_ID}/updatePassword`,
  mutationFn: AccountAPI.updatePassword,
}

/**
 * パスワードを更新するHooks
 */
export function useUpdatePasswordMutation(
  config?: OmitMutationConfig<typeof updatePasswordMutation>,
) {
  return useMutation({ ...updatePasswordMutation, ...config })
}
