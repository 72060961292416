import { axiosClient } from "lib/axios-client"
import {
  MyProfileGetResponse,
  ProfileCreateRequest,
  ProfileCreateResponse,
  ProfileGetResponse,
  ProfileUpdateRequest,
} from "./types"

export const ProfileAPI = {
  /**
   * ログイン中のユーザーのプロフィール情報を取得
   * @returns MyProfileGetResponse
   */
  fetchMyProfile: async () => {
    const { data } = await axiosClient.request<MyProfileGetResponse>({
      url: "/user/profile",
      method: "GET",
    })
    return data
  },

  /**
   * ユーザーのプロフィール情報を取得
   * @param userId 取得するユーザーのUserID
   * @returns ProfileGetResponse
   */
  fetchProfile: async (userId: string) => {
    const { data } = await axiosClient.request<ProfileGetResponse>({
      url: `/users/${userId}/profile`,
      method: "GET",
    })
    return data
  },

  /**
   * ログインユーザーのプロフィール情報を更新
   * @param payload 更新情報
   */
  updateProfile: async (payload: ProfileUpdateRequest) => {
    await axiosClient.request({
      url: "/user/profile",
      method: "PATCH",
      data: payload,
    })
  },

  /**
   * プロフィール登録
   * @param payload プロフィール情報
   */
  registerProfile: async (payload: ProfileCreateRequest) => {
    const { data } = await axiosClient.request<ProfileCreateResponse>({
      url: "/register",
      method: "POST",
      data: payload,
    })
    return data
  },
} as const
