import { SelectAndDate } from "api/types/phase2/utils"
import { SelectAndDateFormData } from "pages/utils/types/phase2FormData"

interface Args {
  json: {
    question: string
    options: {
      label: string
      date_label?: string
    }[]
  }
  formData: SelectAndDateFormData
}

/**
 * 選択肢を選ぶと日付の入力欄が出てくる設問
 */
export const selectAndDateTranslator = ({
  json,
  formData,
}: Args): SelectAndDate | undefined => {
  if (formData?.select === undefined) return undefined

  return {
    question: json.question,
    answer: {
      type: "object",
      content: {
        select: formData.select
          ? {
              type: "string",
              content: json.options[formData.select - 1].label,
              choice_number: formData.select,
            }
          : undefined,
        execution_date:
          formData.select && formData.date
            ? {
                type: "string",
                content: formData.date,
                label: json.options[formData.select - 1].date_label,
              }
            : undefined,
      },
    },
  } as const
}
