import { BookmarkGetResponse, BookmarksGetResponse } from "api/bookmark/types"
import { axiosClient } from "lib/axios-client"

export const BookmarkAPI = {
  /**
   * ブックマークしたユーザの一覧を取得
   * @returns BookmarksGetResponse
   */
  fetchBookmarks: async () => {
    const { data } = await axiosClient.request<BookmarksGetResponse>({
      url: "/bookmarks",
      method: "GET",
    })
    return data
  },

  /**
   * ブックマーク作成
   * @param userId
   */
  createBookmark: async (userId: string) => {
    await axiosClient.request({
      url: "/bookmarks",
      method: "POST",
      data: { user_id: userId },
    })
  },

  /**
   * 特定のユーザのBookmarkIdを取得
   * @param userId
   */
  fetchBookmark: async (userId: string) => {
    const { data } = await axiosClient.request<BookmarkGetResponse>({
      url: `/users/${userId}/bookmark`,
      method: "GET",
    })
    return data
  },

  /**
   * ブックマーク削除
   * @param userId
   */
  deleteBookmark: async (userId: string) => {
    await axiosClient.request({
      url: `/users/${userId}/bookmark`,
      method: "DELETE",
    })
  },
} as const
