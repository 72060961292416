import { Fragment } from "react"
import {
  ActionPlan,
  ArrayCost,
  ArrayNumber,
  ArrayString,
  BankBorrowingAmountAndNetWorth,
  BusinessPlan,
  ConcernAndSolution,
  Cost,
  Liabilities,
  NameAndBirthday,
  ObjectCost,
  PeakSales,
  SelectAndDate,
  TransferringStock,
} from "api"
import { AnswerWrap, TitleAndQAs } from "pages/components/Phase2Answer"
import { dateFormatter, DeepPartial } from "utils"

export const nameAndBirthday = (answerNumber?: NameAndBirthday) => {
  if (answerNumber === undefined) {
    return
  }
  return (
    <>
      <AnswerWrap>
        {answerNumber.answer?.content?.name?.label}:
        {answerNumber.answer?.content?.name?.content}
      </AnswerWrap>
      <AnswerWrap>
        {answerNumber.answer?.content?.birthday?.label}:
        {dateFormatter(answerNumber.answer?.content?.birthday?.content)}
      </AnswerWrap>
    </>
  )
}

export const selectAndDate = (answerNumber?: SelectAndDate) => {
  if (answerNumber === undefined) {
    return
  }
  return (
    <>
      <AnswerWrap>{answerNumber.answer?.content?.select?.content}</AnswerWrap>
      {answerNumber.answer?.content?.execution_date && (
        <AnswerWrap>
          {answerNumber.answer.content.execution_date.label}:
          {dateFormatter(answerNumber.answer.content.execution_date.content)}
        </AnswerWrap>
      )}
    </>
  )
}

export const transferringStock = (answerNumber?: TransferringStock) => {
  if (answerNumber === undefined) {
    return
  }
  return (
    <>
      <AnswerWrap>{answerNumber.answer?.content?.select?.content}</AnswerWrap>
      {answerNumber.answer?.content?.transferring_stock?.content &&
        answerNumber.answer.content.transferring_stock.content.map(
          (data, index) => (
            <Fragment key={index}>
              <AnswerWrap>{data?.label}</AnswerWrap>
              <AnswerWrap>
                {data?.president?.label}:{data?.president?.content}
                {data?.president?.unit}
              </AnswerWrap>
              <AnswerWrap>
                {data?.successor?.label}:{data?.successor?.content}
                {data?.successor?.unit}
              </AnswerWrap>
            </Fragment>
          ),
        )}
      {answerNumber.answer?.content?.execution_date && (
        <AnswerWrap>
          {answerNumber.answer.content.execution_date.label}:
          {dateFormatter(answerNumber.answer.content.execution_date.content)}
        </AnswerWrap>
      )}
    </>
  )
}

export const businessPlan = (answerNumber?: BusinessPlan) => {
  if (answerNumber?.answer?.content === undefined) {
    return
  }
  return (
    <>
      {answerNumber.answer.content.map((data, index) => (
        <Fragment key={index}>
          <AnswerWrap>{data?.label}</AnswerWrap>
          <AnswerWrap>
            {data?.sale?.label}:{data?.sale?.content}
            {data?.sale?.unit}
          </AnswerWrap>
          <AnswerWrap>
            {data?.ordinary_profit?.label}:{data?.ordinary_profit?.content}
            {data?.ordinary_profit?.unit}
          </AnswerWrap>
        </Fragment>
      ))}
    </>
  )
}

export const bankBorrowingAmountAndNetWorth = (
  answerNumber?: BankBorrowingAmountAndNetWorth,
) => {
  if (answerNumber?.answer?.content === undefined) {
    return
  }
  return (
    <>
      {answerNumber.answer.content.map((data, index) => (
        <Fragment key={index}>
          <AnswerWrap>{data?.label}</AnswerWrap>
          <AnswerWrap>
            {data?.bank_borrowing_amount?.label}:
            {data?.bank_borrowing_amount?.content}
            {data?.bank_borrowing_amount?.unit}
          </AnswerWrap>
          <AnswerWrap>
            {data?.net_worth?.label}:{data?.net_worth?.content}
            {data?.net_worth?.unit}
          </AnswerWrap>
        </Fragment>
      ))}
    </>
  )
}

export type QAPresentation<T> = (answers: T) => TitleAndQAs

export const arrayNumber = (answerNumber?: ArrayNumber) => {
  if (answerNumber?.answer?.content === undefined) {
    return
  }
  return (
    <>
      {answerNumber.answer.content.map((data, index) => (
        <AnswerWrap key={index}>
          {data?.label}:{data?.content}
          {data?.unit}
        </AnswerWrap>
      ))}
    </>
  )
}

const cost = (content?: DeepPartial<Cost>) => {
  return (
    <>
      {/* 商品仕入高 */}
      <AnswerWrap>
        {content?.product_purchase_amount?.label}:
        {content?.product_purchase_amount?.content}
        {content?.product_purchase_amount?.unit}
      </AnswerWrap>
      {/* 原材料費 */}
      <AnswerWrap>
        {content?.raw_material_costs?.label}:
        {content?.raw_material_costs?.content}
        {content?.raw_material_costs?.unit}
      </AnswerWrap>
      {/* 外注加工費 */}
      <AnswerWrap>
        {content?.outsourced_processing_costs?.label}:
        {content?.outsourced_processing_costs?.content}
        {content?.outsourced_processing_costs?.unit}
      </AnswerWrap>
      {/* 荷造運搬費 */}
      <AnswerWrap>
        {content?.freight_costs?.label}:{content?.freight_costs?.content}
        {content?.freight_costs?.unit}
      </AnswerWrap>
      {/* 人件費 */}
      <AnswerWrap>
        {content?.labor_cost?.label}:{content?.labor_cost?.content}
        {content?.labor_cost?.unit}
      </AnswerWrap>
      {/* 自分の給与（役員報酬） */}
      <AnswerWrap>
        {content?.my_salary?.label}:{content?.my_salary?.content}
        {content?.my_salary?.unit}
      </AnswerWrap>
      {/* 水道光熱費 */}
      <AnswerWrap>
        {content?.utilities_charge?.label}:{content?.utilities_charge?.content}
        {content?.utilities_charge?.unit}
      </AnswerWrap>
      {/* 減価償却費 */}
      <AnswerWrap>
        {content?.depreciation_and_amortization?.label}:
        {content?.depreciation_and_amortization?.content}
        {content?.depreciation_and_amortization?.unit}
      </AnswerWrap>
      {/* 賃借料 */}
      <AnswerWrap>
        {content?.rent?.label}:{content?.rent?.content} {content?.rent?.unit}
      </AnswerWrap>
      {/* リース料 */}
      <AnswerWrap>
        {content?.lease_payment?.label}:{content?.lease_payment?.content}
        {content?.lease_payment?.unit}
      </AnswerWrap>
      {/* 保険料 */}
      <AnswerWrap>
        {content?.premium?.label}:{content?.premium?.content}
        {content?.premium?.unit}
      </AnswerWrap>
      {/* 支払利息・手数料 */}
      <AnswerWrap>
        {content?.interest_expense_and_fees?.label}:
        {content?.interest_expense_and_fees?.content}
        {content?.interest_expense_and_fees?.unit}
      </AnswerWrap>
      {/* その他経費1 */}
      <AnswerWrap>
        {content?.other_costs_1?.label}:{content?.other_costs_1?.content}
        {content?.other_costs_1?.unit}
      </AnswerWrap>
      {/* その他経費2 */}
      <AnswerWrap>
        {content?.other_costs_2?.label}:{content?.other_costs_2?.content}
        {content?.other_costs_2?.unit}
      </AnswerWrap>
    </>
  )
}

export const arrayCost = (answerNumber?: ArrayCost) => {
  if (answerNumber?.answer?.content === undefined) {
    return
  }
  return (
    <>
      {answerNumber.answer?.content.map((data, index) => (
        <Fragment key={index}>
          <AnswerWrap>{data?.label}</AnswerWrap>
          {cost(data)}
        </Fragment>
      ))}
    </>
  )
}

export const objectCost = (answerNumber?: ObjectCost) => {
  return cost(answerNumber?.answer?.content)
}

export const peakSales = (answerNumber?: PeakSales) => {
  return (
    <>
      <AnswerWrap>
        {answerNumber?.answer?.content?.term?.label}:
        {answerNumber?.answer?.content?.term?.content}
        {answerNumber?.answer?.content?.term?.unit}
      </AnswerWrap>
      <AnswerWrap>
        {answerNumber?.answer?.content?.sale?.label}:
        {answerNumber?.answer?.content?.sale?.content}
        {answerNumber?.answer?.content?.sale?.unit}
      </AnswerWrap>
    </>
  )
}

export const actionPlan = (answerNumber?: ActionPlan) => {
  if (answerNumber?.answer?.content === undefined) {
    return
  }
  return (
    <>
      {answerNumber.answer.content.map((data, index) => (
        <Fragment key={index}>
          <AnswerWrap>
            {data?.plan?.label}:{data?.plan?.content}
          </AnswerWrap>
          <AnswerWrap>
            {data?.charge?.label}:{data?.charge?.content}
          </AnswerWrap>
          <AnswerWrap>
            {data?.start_date?.label}:{dateFormatter(data?.start_date?.content)}
          </AnswerWrap>
          <AnswerWrap>
            {data?.completed_date?.label}:
            {dateFormatter(data?.completed_date?.content)}
          </AnswerWrap>
        </Fragment>
      ))}
    </>
  )
}

export const concernAndSolution = (answerNumber?: ConcernAndSolution) => {
  if (answerNumber?.answer?.content === undefined) {
    return
  }
  return (
    <>
      {answerNumber.answer.content.map((data, index) => (
        <Fragment key={index}>
          <AnswerWrap>
            {data?.concern?.label}:{data?.concern?.content}
          </AnswerWrap>
          <AnswerWrap>
            {data?.solution?.label}:{data?.solution?.content}
          </AnswerWrap>
        </Fragment>
      ))}
    </>
  )
}

export const arrayString = (answerNumber?: ArrayString) => {
  if (answerNumber?.answer?.content === undefined) {
    return
  }
  return (
    <>
      {answerNumber.answer.content.map((data, index) => (
        <AnswerWrap key={index}>{data?.content}</AnswerWrap>
      ))}
    </>
  )
}

export const liabilities = (answerNumber?: Liabilities) => {
  if (answerNumber?.answer?.content === undefined) {
    return
  }
  return (
    <>
      {answerNumber.answer.content.map((data, index) => (
        <Fragment key={index}>
          <AnswerWrap>
            {data?.lender?.label}:{data?.lender?.content}
          </AnswerWrap>
          {data?.debt &&
            data.debt.map((data, index) => (
              <AnswerWrap key={index}>
                {data?.label}:{data?.content}
                {data?.unit}
              </AnswerWrap>
            ))}
        </Fragment>
      ))}
    </>
  )
}
