import { Phase2AnswerA } from "api"
import { dateFormatter } from "utils"
import {
  nameAndBirthday,
  selectAndDate,
  transferringStock,
  businessPlan,
  bankBorrowingAmountAndNetWorth,
  QAPresentation,
} from "./utils"

export const phase2ConvertAnswersA: QAPresentation<Phase2AnswerA> = (
  answers: Phase2AnswerA,
) => {
  return [
    {
      title: answers.question_group_1?.title,
      QA: [
        //answer_00?: NameAndBirthday
        {
          question: answers.question_group_1?.answers?.answer_00?.question,
          answer: nameAndBirthday(answers.question_group_1?.answers?.answer_00),
        },
        //answer_01?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_01?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_01),
        },
        //answer_02?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_02?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_02),
        },
        //answer_03?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_03?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_03),
        },
        //answer_04?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_04?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_04),
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_2?.title,
      QA: [
        //answer_05?: SelectAndDate
        {
          question: answers.question_group_2?.answers?.answer_05?.question,
          answer: selectAndDate(answers.question_group_2?.answers?.answer_05),
        },
        // answer_06?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_2?.answers?.answer_06?.question,
          answer: answers.question_group_2?.answers?.answer_06?.answer?.content,
        },
        //answer_07?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_2?.answers?.answer_07?.question,
          answer: answers.question_group_2?.answers?.answer_07?.answer?.content,
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_3?.title,
      QA: [
        //answer_08?: SelectAndDate
        {
          question: answers.question_group_3?.answers?.answer_08?.question,
          answer: selectAndDate(answers.question_group_3?.answers?.answer_08),
        },
        //answer_09?: SelectAndDate
        {
          question: answers.question_group_3?.answers?.answer_09?.question,
          answer: selectAndDate(answers.question_group_3?.answers?.answer_09),
        },
        //answer_10?: SelectAndDate
        {
          question: answers.question_group_3?.answers?.answer_10?.question,
          answer: selectAndDate(answers.question_group_3?.answers?.answer_10),
        },
        //answer_11?: SelectAndDate
        {
          question: answers.question_group_3?.answers?.answer_11?.question,
          answer: selectAndDate(answers.question_group_3?.answers?.answer_11),
        },
        //answer_12?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_3?.answers?.answer_12?.question,
          answer: answers.question_group_3?.answers?.answer_12?.answer?.content,
        },
        //answer_13?: SelectAndDate
        {
          question: answers.question_group_3?.answers?.answer_13?.question,
          answer: selectAndDate(answers.question_group_3?.answers?.answer_13),
        },
        //answer_14?: SelectAndDate
        {
          question: answers.question_group_3?.answers?.answer_14?.question,
          answer: selectAndDate(answers.question_group_3?.answers?.answer_14),
        },
        //answer_15?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_3?.answers?.answer_15?.question,
          answer: answers.question_group_3?.answers?.answer_15?.answer?.content,
        },
        //answer_16?: SelectAndDate
        {
          question: answers.question_group_3?.answers?.answer_16?.question,
          answer: selectAndDate(answers.question_group_3?.answers?.answer_16),
        },
        //answer_17?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_3?.answers?.answer_17?.question,
          answer: answers.question_group_3?.answers?.answer_17?.answer?.content,
        },
        //answer_18?: SelectAndDate
        {
          question: answers.question_group_3?.answers?.answer_18?.question,
          answer: selectAndDate(answers.question_group_3?.answers?.answer_18),
        },
        //answer_19?: SelectAndDate
        {
          question: answers.question_group_3?.answers?.answer_19?.question,
          answer: selectAndDate(answers.question_group_3?.answers?.answer_19),
        },
        //answer_20?: SelectAndDate
        {
          question: answers.question_group_3?.answers?.answer_20?.question,
          answer: selectAndDate(answers.question_group_3?.answers?.answer_20),
        },
        //answer_21?: SelectAndDate
        {
          question: answers.question_group_3?.answers?.answer_21?.question,
          answer: selectAndDate(answers.question_group_3?.answers?.answer_21),
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_4?.title,
      QA: [
        //answer_22?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_4?.answers?.answer_22?.question,
          answer: answers.question_group_4?.answers?.answer_22?.answer?.content,
        },
        //answer_23?: TransferringStock
        {
          question: answers.question_group_4?.answers?.answer_23?.question,
          answer: transferringStock(
            answers.question_group_4?.answers?.answer_23,
          ),
        },
        //answer_24?: SelectAndDate
        {
          question: answers.question_group_4?.answers?.answer_24?.question,
          answer: selectAndDate(answers.question_group_4?.answers?.answer_24),
        },
        //answer_25?: SelectAndDate
        {
          question: answers.question_group_4?.answers?.answer_25?.question,
          answer: selectAndDate(answers.question_group_4?.answers?.answer_25),
        },
        //answer_26?: BusinessPlan
        {
          question: answers.question_group_4?.answers?.answer_26?.question,
          answer: businessPlan(answers.question_group_4?.answers?.answer_26),
        },
        //answer_27?: DeepPartial<QA<DateTypeAnswer>>
        {
          question: answers.question_group_4?.answers?.answer_27?.question,
          answer: dateFormatter(
            answers.question_group_4?.answers?.answer_27?.answer?.content,
          ),
        },
        //answer_28?: SelectAndDate
        {
          question: answers.question_group_4?.answers?.answer_28?.question,
          answer: selectAndDate(answers.question_group_4?.answers?.answer_28),
        },
        //answer_29?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_4?.answers?.answer_29?.question,
          answer: answers.question_group_4?.answers?.answer_29?.answer?.content,
        },
        //answer_30?: DeepPartial<QA<StringTypeAnswer>>
        {
          question: answers.question_group_4?.answers?.answer_30?.question,
          answer: answers.question_group_4?.answers?.answer_30?.answer?.content,
        },
        //answer_31?: DeepPartial<QA<StringTypeAnswer>>
        {
          question: answers.question_group_4?.answers?.answer_31?.question,
          answer: answers.question_group_4?.answers?.answer_31?.answer?.content,
        },
        //answer_32?: BankBorrowingAmountAndNetWorth
        {
          question: answers.question_group_4?.answers?.answer_32?.question,
          answer: bankBorrowingAmountAndNetWorth(
            answers.question_group_4?.answers?.answer_32,
          ),
        },
      ].filter((item) => item.question && item.answer),
    },
  ].filter((item) => Boolean(item.QA.length))
}
