import { memo, useCallback, useState } from "react"
import { dequal } from "dequal"
import styled from "styled-components"
import { Button } from "components/atoms/Button"
import { Modal } from "components/atoms/Modal"
import { Tag } from "components/atoms/Tag"
import { TextWrap } from "components/atoms/TextWrap"

const MODAL_PADDING = 50
const TEXT_TAG_BUTTON_GAP = 25
const TAG_GAP = 15
const BUTTON_GAP = 20

type TagId = string

export interface AssigneeTag {
  /**
   * tagの一意なid
   */
  id: TagId
  /**
   * tagのlabel
   */
  label: string
}

interface Props {
  /**
   * マウント時に選択されているTag
   */
  initialTag: TagId
  /**
   * tagの情報を持った配列
   */
  tags: AssigneeTag[]
  /**
   * 「保存する」ボタンを押した際のアクション
   */
  onComplete: (selectedTag: TagId) => void
  /**
   * 「キャンセル」ボタンを押した際のアクション
   */
  onCancel: () => void
  /**
   * modalを閉じるアクション
   */
  onClose?: () => void
}

/**
 * 担当者のTagを表示するModal
 */
export const AssigneeModal = memo(
  ({ initialTag, tags, onComplete, onCancel, onClose }: Props) => {
    const [selectedTag, setSelectedTag] = useState<TagId>(initialTag)

    const handleComplete = useCallback(() => {
      onComplete(selectedTag)
      if (onClose !== undefined) {
        onClose()
      }
    }, [selectedTag, onComplete, onClose])

    return (
      <StyledModal>
        <TextWrap size="m" weight="bold">
          担当者
        </TextWrap>
        <TagWrapper>
          {tags.map((tag) => (
            <Tag
              key={tag.id}
              size="m"
              color="red"
              as="button"
              onClick={() => setSelectedTag(tag.id)}
              isActive={selectedTag === tag.id}
            >
              {tag.label}
            </Tag>
          ))}
        </TagWrapper>
        <ButtonWrapper>
          <Button color="gray" onClick={onCancel}>
            キャンセル
          </Button>
          <Button onClick={handleComplete}>保存する</Button>
        </ButtonWrapper>
      </StyledModal>
    )
  },
  dequal,
)

AssigneeModal.displayName = "AssigneeModal"

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  padding: ${MODAL_PADDING}px;
  gap: ${TEXT_TAG_BUTTON_GAP}px;
`

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${TAG_GAP}px;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: ${BUTTON_GAP}px;
`
