import { HTMLAttributes } from "react"
import styled, { css } from "styled-components"
import { TextWrap } from "components/atoms/TextWrap"
import { px, unreachable } from "utils"

const BORDER_RADIUS = {
  ROUND: 9999,
  SQUARE: 5,
} as const

const BORDER_WIDTH = 2

/**
 * Buttonとしての役割を果たすときのホバー時のOpacity
 */
const OPACITY_WHILE_HOVER = 0.6

const TAG_PADDING_X = {
  ROUND: 13,
  SQUARE: 8,
} as const

const TAG_PADDING_Y = {
  ROUND: 2,
  SQUARE: 0,
} as const

const COLOR_SCHEME = {
  GREEN: css`
    background-color: ${({ theme }) => theme.color.pigmentGreen};
    border: ${px(BORDER_WIDTH)} solid ${({ theme }) => theme.color.pigmentGreen};
    color: ${({ theme }) => theme.color.white};
  `,
  RED: css`
    background-color: ${({ theme }) => theme.color.wildWatermelon};
    border: ${px(BORDER_WIDTH)} solid
      ${({ theme }) => theme.color.wildWatermelon};
    color: ${({ theme }) => theme.color.white};
  `,
  GRAY: css`
    background-color: ${({ theme }) => theme.color.nobel};
    border: ${px(BORDER_WIDTH)} solid ${({ theme }) => theme.color.nobel};
    color: ${({ theme }) => theme.color.white};
  `,
  DEACTIVED: css`
    background-color: ${({ theme }) => theme.color.white};
    border: ${px(BORDER_WIDTH)} solid ${({ theme }) => theme.color.nobel};
    color: ${({ theme }) => theme.color.nobel};
  `,
} as const

type TagSize = "s" | "m"
type TagShape = "round" | "square"
export type TagColor = "green" | "red" | "gray"
type TagType = "div" | "button"

interface Props extends HTMLAttributes<HTMLElement> {
  /**
   * タグの状態をbooleanで受け取る
   */
  isActive?: boolean
  /**
   * タグの大きさ "s" | "m"
   */
  size?: TagSize
  /**
   * タグの形 "round" | "square"
   */
  shape?: TagShape
  /**
   * タグの色 "green"|"red"|"gray"
   */
  color?: TagColor
  /**
   * タグのHTML要素 "div" | "button"
   */
  as?: TagType
}

/**
 * 汎用タグ
 */
export const Tag = ({
  children,
  isActive = true,
  size = "s",
  shape = "round",
  color = "green",
  as = "div",
  ...rest
}: Props) => {
  return (
    <Container
      isActive={isActive}
      size={size}
      shape={shape}
      color={color}
      as={as}
      button={as === "button"}
      {...rest}
    >
      <ThinTextWrap size={size} weight="bold" color="inherit" ellipsis={1}>
        {children}
      </ThinTextWrap>
    </Container>
  )
}

type ContainerProps = {
  isActive: boolean
  size: TagSize
  shape: TagShape
  color: TagColor
  button?: boolean
}

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  max-width: 100%;

  ${({ shape }) =>
    shape === "square"
      ? css`
          border-radius: ${BORDER_RADIUS.SQUARE}px;
          padding: ${TAG_PADDING_Y.SQUARE}px ${TAG_PADDING_X.SQUARE}px;
        `
      : shape === "round"
      ? css`
          border-radius: ${BORDER_RADIUS.ROUND}px;
          padding: ${TAG_PADDING_Y.ROUND}px ${TAG_PADDING_X.ROUND}px;
        `
      : unreachable()};

  ${({ isActive, color }) =>
    !isActive
      ? COLOR_SCHEME.DEACTIVED
      : color === "gray"
      ? COLOR_SCHEME.GRAY
      : color === "green"
      ? COLOR_SCHEME.GREEN
      : color === "red"
      ? COLOR_SCHEME.RED
      : unreachable()}

  ${({ button }) =>
    button &&
    css`
      outline: none;
      :hover {
        opacity: ${OPACITY_WHILE_HOVER};
      }
    `}
`

const ThinTextWrap = styled(TextWrap)`
  margin: -${BORDER_WIDTH}px 0;
`
