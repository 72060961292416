import { Phase2AnswerAB } from "api"
import { dateFormatter } from "utils"
import {
  arrayString,
  bankBorrowingAmountAndNetWorth,
  businessPlan,
  nameAndBirthday,
  QAPresentation,
  selectAndDate,
  transferringStock,
} from "./utils"

export const phase2ConvertAnswersAB: QAPresentation<Phase2AnswerAB> = (
  answers: Phase2AnswerAB,
) => {
  return [
    {
      title: answers.question_group_1?.title,
      QA: [
        // answer_01?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_1?.answers?.answer_01?.question,
          answer: answers.question_group_1?.answers?.answer_01?.answer?.content,
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_2?.title,
      QA: [
        // answer_02?: DeepPartial<QA<DateTypeAnswer>>
        {
          question: answers.question_group_2?.answers?.answer_02?.question,
          answer: dateFormatter(
            answers.question_group_2?.answers?.answer_02?.answer?.content,
          ),
        },
        // answer_03?: ArrayString
        {
          question: answers.question_group_2?.answers?.answer_03?.question,
          answer: arrayString(answers.question_group_2?.answers?.answer_03),
        },
        // answer_04?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_2?.answers?.answer_04?.question,
          answer: answers.question_group_2?.answers?.answer_04?.answer?.content,
        },
        // answer_05?: DeepPartial<QA<DateTypeAnswer>>
        {
          question: answers.question_group_2?.answers?.answer_05?.question,
          answer: dateFormatter(
            answers.question_group_2?.answers?.answer_05?.answer?.content,
          ),
        },
        // answer_06?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_2?.answers?.answer_06?.question,
          answer: answers.question_group_2?.answers?.answer_06?.answer?.content,
        },
        // answer_07?: DeepPartial<QA<DateTypeAnswer>>
        {
          question: answers.question_group_2?.answers?.answer_07?.question,
          answer: dateFormatter(
            answers.question_group_2?.answers?.answer_07?.answer?.content,
          ),
        },
        // answer_08?: DeepPartial<QA<DateTypeAnswer>>
        {
          question: answers.question_group_2?.answers?.answer_08?.question,
          answer: dateFormatter(
            answers.question_group_2?.answers?.answer_08?.answer?.content,
          ),
        },
        // answer_09?: DeepPartial<QA<StringTypeAnswer>>
        {
          question: answers.question_group_2?.answers?.answer_09?.question,
          answer: answers.question_group_2?.answers?.answer_09?.answer?.content,
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_3?.title,
      QA: [
        //answer_10?: NameAndBirthday
        {
          question: answers.question_group_3?.answers?.answer_10?.question,
          answer: nameAndBirthday(answers.question_group_3?.answers?.answer_10),
        },
        //answer_11?: SelectAndDate
        {
          question: answers.question_group_3?.answers?.answer_11?.question,
          answer: selectAndDate(answers.question_group_3?.answers?.answer_11),
        },
        //answer_12?: SelectAndDate
        {
          question: answers.question_group_3?.answers?.answer_12?.question,
          answer: selectAndDate(answers.question_group_3?.answers?.answer_12),
        },
        //answer_13?: SelectAndDate
        {
          question: answers.question_group_3?.answers?.answer_13?.question,
          answer: selectAndDate(answers.question_group_3?.answers?.answer_13),
        },
        //answer_14?: SelectAndDate
        {
          question: answers.question_group_3?.answers?.answer_14?.question,
          answer: selectAndDate(answers.question_group_3?.answers?.answer_14),
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_4?.title,
      QA: [
        //answer_15?: SelectAndDate
        {
          question: answers.question_group_4?.answers?.answer_15?.question,
          answer: selectAndDate(answers.question_group_4?.answers?.answer_15),
        },
        // answer_16?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_4?.answers?.answer_16?.question,
          answer: answers.question_group_4?.answers?.answer_16?.answer?.content,
        },
        //answer_17?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_4?.answers?.answer_17?.question,
          answer: answers.question_group_4?.answers?.answer_17?.answer?.content,
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_5?.title,
      QA: [
        //answer_18?: SelectAndDate
        {
          question: answers.question_group_5?.answers?.answer_18?.question,
          answer: selectAndDate(answers.question_group_5?.answers?.answer_18),
        },
        //answer_19?: SelectAndDate
        {
          question: answers.question_group_5?.answers?.answer_19?.question,
          answer: selectAndDate(answers.question_group_5?.answers?.answer_19),
        },
        //answer_20?: SelectAndDate
        {
          question: answers.question_group_5?.answers?.answer_20?.question,
          answer: selectAndDate(answers.question_group_5?.answers?.answer_20),
        },
        //answer_21?: SelectAndDate
        {
          question: answers.question_group_5?.answers?.answer_21?.question,
          answer: selectAndDate(answers.question_group_5?.answers?.answer_21),
        },
        //answer_22?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_5?.answers?.answer_22?.question,
          answer: answers.question_group_5?.answers?.answer_22?.answer?.content,
        },
        //answer_23?: SelectAndDate
        {
          question: answers.question_group_5?.answers?.answer_23?.question,
          answer: selectAndDate(answers.question_group_5?.answers?.answer_23),
        },
        //answer_24?: SelectAndDate
        {
          question: answers.question_group_5?.answers?.answer_24?.question,
          answer: selectAndDate(answers.question_group_5?.answers?.answer_24),
        },
        //answer_25?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_5?.answers?.answer_25?.question,
          answer: answers.question_group_5?.answers?.answer_25?.answer?.content,
        },
        //answer_26?: SelectAndDate
        {
          question: answers.question_group_5?.answers?.answer_26?.question,
          answer: selectAndDate(answers.question_group_5?.answers?.answer_26),
        },
        //answer_27?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_5?.answers?.answer_27?.question,
          answer: answers.question_group_5?.answers?.answer_27?.answer?.content,
        },
        //answer_28?: SelectAndDate
        {
          question: answers.question_group_5?.answers?.answer_28?.question,
          answer: selectAndDate(answers.question_group_5?.answers?.answer_28),
        },
        //answer_29?: SelectAndDate
        {
          question: answers.question_group_5?.answers?.answer_29?.question,
          answer: selectAndDate(answers.question_group_5?.answers?.answer_29),
        },
        //answer_30?: SelectAndDate
        {
          question: answers.question_group_5?.answers?.answer_30?.question,
          answer: selectAndDate(answers.question_group_5?.answers?.answer_30),
        },
        //answer_31?: SelectAndDate
        {
          question: answers.question_group_5?.answers?.answer_31?.question,
          answer: selectAndDate(answers.question_group_5?.answers?.answer_31),
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_6?.title,
      QA: [
        //answer_32?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_6?.answers?.answer_32?.question,
          answer: answers.question_group_6?.answers?.answer_32?.answer?.content,
        },
        //answer_33?: TransferringStock
        {
          question: answers.question_group_6?.answers?.answer_33?.question,
          answer: transferringStock(
            answers.question_group_6?.answers?.answer_33,
          ),
        },
        //answer_34?: SelectAndDate
        {
          question: answers.question_group_6?.answers?.answer_34?.question,
          answer: selectAndDate(answers.question_group_6?.answers?.answer_34),
        },
        //answer_35?: SelectAndDate
        {
          question: answers.question_group_6?.answers?.answer_35?.question,
          answer: selectAndDate(answers.question_group_6?.answers?.answer_35),
        },
        //answer_36?: BusinessPlan
        {
          question: answers.question_group_6?.answers?.answer_36?.question,
          answer: businessPlan(answers.question_group_6?.answers?.answer_36),
        },
        //answer_37?: DeepPartial<QA<DateTypeAnswer>>
        {
          question: answers.question_group_6?.answers?.answer_37?.question,
          answer: dateFormatter(
            answers.question_group_6?.answers?.answer_37?.answer?.content,
          ),
        },
        //answer_38?: SelectAndDate
        {
          question: answers.question_group_6?.answers?.answer_38?.question,
          answer: selectAndDate(answers.question_group_6?.answers?.answer_38),
        },
        //answer_39?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_6?.answers?.answer_39?.question,
          answer: answers.question_group_6?.answers?.answer_39?.answer?.content,
        },
        //answer_40?: DeepPartial<QA<StringTypeAnswer>>
        {
          question: answers.question_group_6?.answers?.answer_40?.question,
          answer: answers.question_group_6?.answers?.answer_40?.answer?.content,
        },
        //answer_41?: DeepPartial<QA<StringTypeAnswer>>
        {
          question: answers.question_group_6?.answers?.answer_41?.question,
          answer: answers.question_group_6?.answers?.answer_41?.answer?.content,
        },
        //answer_42?: BankBorrowingAmountAndNetWorth
        {
          question: answers.question_group_6?.answers?.answer_42?.question,
          answer: bankBorrowingAmountAndNetWorth(
            answers.question_group_6?.answers?.answer_42,
          ),
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_7?.title,
      QA: [
        //answer_43?: NameAndBirthday
        {
          question: answers.question_group_7?.answers?.answer_43?.question,
          answer: nameAndBirthday(answers.question_group_7?.answers?.answer_43),
        },
        //answer_44?: SelectAndDate
        {
          question: answers.question_group_7?.answers?.answer_44?.question,
          answer: selectAndDate(answers.question_group_7?.answers?.answer_44),
        },
        //answer_45?: SelectAndDate
        {
          question: answers.question_group_7?.answers?.answer_45?.question,
          answer: selectAndDate(answers.question_group_7?.answers?.answer_45),
        },
        //answer_46?: SelectAndDate
        {
          question: answers.question_group_7?.answers?.answer_46?.question,
          answer: selectAndDate(answers.question_group_7?.answers?.answer_46),
        },
        //answer_47?: SelectAndDate
        {
          question: answers.question_group_7?.answers?.answer_47?.question,
          answer: selectAndDate(answers.question_group_7?.answers?.answer_47),
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_8?.title,
      QA: [
        //answer_48?: SelectAndDate
        {
          question: answers.question_group_8?.answers?.answer_48?.question,
          answer: selectAndDate(answers.question_group_8?.answers?.answer_48),
        },
        // answer_49?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_8?.answers?.answer_49?.question,
          answer: answers.question_group_8?.answers?.answer_49?.answer?.content,
        },
        //answer_50?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_8?.answers?.answer_50?.question,
          answer: answers.question_group_8?.answers?.answer_50?.answer?.content,
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_9?.title,
      QA: [
        //answer_51?: SelectAndDate
        {
          question: answers.question_group_9?.answers?.answer_51?.question,
          answer: selectAndDate(answers.question_group_9?.answers?.answer_51),
        },
        //answer_52?: SelectAndDate
        {
          question: answers.question_group_9?.answers?.answer_52?.question,
          answer: selectAndDate(answers.question_group_9?.answers?.answer_52),
        },
        //answer_53?: SelectAndDate
        {
          question: answers.question_group_9?.answers?.answer_53?.question,
          answer: selectAndDate(answers.question_group_9?.answers?.answer_53),
        },
        //answer_54?: SelectAndDate
        {
          question: answers.question_group_9?.answers?.answer_54?.question,
          answer: selectAndDate(answers.question_group_9?.answers?.answer_54),
        },
        //answer_55?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_9?.answers?.answer_55?.question,
          answer: answers.question_group_9?.answers?.answer_55?.answer?.content,
        },
        //answer_56?: SelectAndDate
        {
          question: answers.question_group_9?.answers?.answer_56?.question,
          answer: selectAndDate(answers.question_group_9?.answers?.answer_56),
        },
        //answer_57?: SelectAndDate
        {
          question: answers.question_group_9?.answers?.answer_57?.question,
          answer: selectAndDate(answers.question_group_9?.answers?.answer_57),
        },
        //answer_58?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_9?.answers?.answer_58?.question,
          answer: answers.question_group_9?.answers?.answer_58?.answer?.content,
        },
        //answer_59?: SelectAndDate
        {
          question: answers.question_group_9?.answers?.answer_59?.question,
          answer: selectAndDate(answers.question_group_9?.answers?.answer_59),
        },
        //answer_60?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_9?.answers?.answer_60?.question,
          answer: answers.question_group_9?.answers?.answer_60?.answer?.content,
        },
        //answer_61?: SelectAndDate
        {
          question: answers.question_group_9?.answers?.answer_61?.question,
          answer: selectAndDate(answers.question_group_9?.answers?.answer_61),
        },
        //answer_62?: SelectAndDate
        {
          question: answers.question_group_9?.answers?.answer_62?.question,
          answer: selectAndDate(answers.question_group_9?.answers?.answer_62),
        },
        //answer_63?: SelectAndDate
        {
          question: answers.question_group_9?.answers?.answer_63?.question,
          answer: selectAndDate(answers.question_group_9?.answers?.answer_63),
        },
        //answer_64?: SelectAndDate
        {
          question: answers.question_group_9?.answers?.answer_64?.question,
          answer: selectAndDate(answers.question_group_9?.answers?.answer_64),
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_10?.title,
      QA: [
        //answer_65?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_10?.answers?.answer_65?.question,
          answer:
            answers.question_group_10?.answers?.answer_65?.answer?.content,
        },
        //answer_66?: TransferringStock
        {
          question: answers.question_group_10?.answers?.answer_66?.question,
          answer: transferringStock(
            answers.question_group_10?.answers?.answer_66,
          ),
        },
        //answer_67?: SelectAndDate
        {
          question: answers.question_group_10?.answers?.answer_67?.question,
          answer: selectAndDate(answers.question_group_10?.answers?.answer_67),
        },
        //answer_68?: SelectAndDate
        {
          question: answers.question_group_10?.answers?.answer_68?.question,
          answer: selectAndDate(answers.question_group_10?.answers?.answer_68),
        },
        //answer_69?: BusinessPlan
        {
          question: answers.question_group_10?.answers?.answer_69?.question,
          answer: businessPlan(answers.question_group_10?.answers?.answer_69),
        },
        //answer_70?: DeepPartial<QA<DateTypeAnswer>>
        {
          question: answers.question_group_10?.answers?.answer_70?.question,
          answer: dateFormatter(
            answers.question_group_10?.answers?.answer_70?.answer?.content,
          ),
        },
        //answer_71?: SelectAndDate
        {
          question: answers.question_group_10?.answers?.answer_71?.question,
          answer: selectAndDate(answers.question_group_10?.answers?.answer_71),
        },
        //answer_72?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_10?.answers?.answer_72?.question,
          answer:
            answers.question_group_10?.answers?.answer_72?.answer?.content,
        },
        //answer_73?: DeepPartial<QA<StringTypeAnswer>>
        {
          question: answers.question_group_10?.answers?.answer_73?.question,
          answer:
            answers.question_group_10?.answers?.answer_73?.answer?.content,
        },
        //answer_74?: DeepPartial<QA<StringTypeAnswer>>
        {
          question: answers.question_group_10?.answers?.answer_74?.question,
          answer:
            answers.question_group_10?.answers?.answer_74?.answer?.content,
        },
        //answer_75?: BankBorrowingAmountAndNetWorth
        {
          question: answers.question_group_10?.answers?.answer_75?.question,
          answer: bankBorrowingAmountAndNetWorth(
            answers.question_group_10?.answers?.answer_75,
          ),
        },
      ].filter((item) => item.question && item.answer),
    },
  ].filter((item) => Boolean(item.QA.length))
}
