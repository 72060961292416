import { Phase2AnswerH } from "api/types/phase2/h"
import phase2H from "assets/json/phase2/h.json"
import { Phase2HFormData } from "pages/components/phase2/components/H/schema"
import { Phase2Type } from "pages/components/phase2/utils"
import { DeepPartial } from "utils"
import { selectAndDateTranslator } from "./utils"

type Diagnosis = Extract<Phase2Type, "AH" | "IH" | "UH" | "EH">

interface Args {
  diagnosis: Diagnosis
  formData: DeepPartial<Phase2HFormData>
}

// TODO: phase2ResultAnswerがマージされたらそちらを使う
interface Return {
  diagnosis: Diagnosis
  answer: Phase2AnswerH
}

export const phase2HFormDataTranslator = ({
  diagnosis,
  formData,
}: Args): Return => ({
  diagnosis: diagnosis,
  answer: {
    question_group_1: {
      answers: {
        answer_01: selectAndDateTranslator({
          json: phase2H.q1,
          formData: formData.q1,
        }),
        answer_02: selectAndDateTranslator({
          json: phase2H.q2,
          formData: formData.q2,
        }),
        answer_03: selectAndDateTranslator({
          json: phase2H.q3,
          formData: formData.q3,
        }),
        answer_04: selectAndDateTranslator({
          json: phase2H.q4,
          formData: formData.q4,
        }),
        answer_05: selectAndDateTranslator({
          json: phase2H.q5,
          formData: formData.q5,
        }),
        answer_06: selectAndDateTranslator({
          json: phase2H.q6,
          formData: formData.q6,
        }),
        answer_07: selectAndDateTranslator({
          json: phase2H.q7,
          formData: formData.q7,
        }),
        answer_08: selectAndDateTranslator({
          json: phase2H.q8,
          formData: formData.q8,
        }),
        answer_09: selectAndDateTranslator({
          json: phase2H.q9,
          formData: formData.q9,
        }),
        answer_10: selectAndDateTranslator({
          json: phase2H.q10,
          formData: formData.q10,
        }),
      },
    },
  },
})
