import { ArrayString } from "api/types/phase2/utils"
import { PartiallyRequiredStringArrayFormData } from "pages/utils/types/phase2FormData"

type Params = {
  requiredLength: number
}

/**
 * 数個入力必須で他は任意のテキスト入力の配列の設問 D Q13 Q14 Q15
 */
export const partiallyRequiredStringArrayDraftTranslator = (
  answer: ArrayString | undefined,
  params: Params,
): PartiallyRequiredStringArrayFormData | undefined => {
  const requiredArray = answer?.answer?.content?.slice(0, params.requiredLength)
  const optionalArray = answer?.answer?.content?.slice(params.requiredLength)

  return {
    required: requiredArray?.map(({ content } = {}) => content),
    optional: optionalArray?.map(({ content } = {}) => content),
  }
}
