import { useTheme } from "styled-components"
import { SvgCreater } from "utils/SvgCreater"
import { IconProps } from "./interface"

const VIEWBOX_SIZE = 24

/**
 * 削除アイコン
 */
export const DeleteIcon = ({ size = 24, ...rest }: IconProps) => {
  const theme = useTheme()
  return (
    <SvgCreater
      size={size}
      fill={theme.color.nobel}
      viewboxSize={VIEWBOX_SIZE}
      {...rest}
    >
      <path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7L6 7L6 19ZM8 9L16 9V19H8L8 9ZM15.5 4L14.5 3L9.5 3L8.5 4L5 4L5 6L19 6V4L15.5 4Z" />
    </SvgCreater>
  )
}
