import {
  TodoCreateRequest,
  TodoCreateResponse,
  TodosGetResponse,
  TodoUpdateRequest,
  TodoUpdateResponse,
  UserTodosGetResponse,
} from "api/todos/types"
import { axiosClient } from "lib/axios-client"

export const TodosAPI = {
  /**
   * todo一覧を取得する
   */
  fetchTodos: async () => {
    const { data } = await axiosClient.request<TodosGetResponse>({
      url: "/todos",
      method: "GET",
    })
    return data
  },

  /**
   * todoを作成する
   */
  createTodo: async (payload: TodoCreateRequest) => {
    const { data } = await axiosClient.request<TodoCreateResponse>({
      url: "/todos",
      method: "POST",
      data: payload,
    })
    return data
  },

  /**
   * todoを更新する
   */
  updateTodo: async ({ todoId, ...rest }: TodoUpdateRequest) => {
    const { data } = await axiosClient.request<TodoUpdateResponse>({
      url: `/todos/${todoId}`,
      method: "PATCH",
      data: rest,
    })
    return data
  },

  /**
   * 特定のユーザのtodo一覧を取得する
   */
  fetchUserTodos: async (userId: string) => {
    const { data } = await axiosClient.request<UserTodosGetResponse>({
      url: `/users/${userId}/todos`,
      method: "GET",
    })
    return data
  },
} as const
