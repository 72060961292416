import { PeakSales } from "api/types/phase2/utils"
import { PeakSalesFormData } from "pages/utils/types/phase2FormData"

interface Args {
  json: {
    question: string
    textbox: {
      label: {
        term: string
        sale: string
      }
      unit: {
        term: string
        sale: string
      }
    }
  }
  formData: PeakSalesFormData
}

/**
 * ピーク時の売上 D Q11
 */
export const peakSalesTranslator = ({
  json,
  formData,
}: Args): PeakSales | undefined => {
  if (formData === undefined) return undefined

  return {
    question: json.question,
    answer: {
      type: "object",
      content: {
        term: {
          type: "number",
          content: formData.term,
          label: json.textbox.label.term,
          unit: json.textbox.unit.term,
        },
        sale: {
          type: "number",
          content: formData.sale,
          label: json.textbox.label.sale,
          unit: json.textbox.unit.sale,
        },
      },
    },
  } as const
}
