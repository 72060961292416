import { useCallback, useMemo, useState } from "react"
import constate from "constate"
import {
  Phase1Category,
  Phase1FormData,
} from "pages/components/phase1/utils/types"
import { Phase1Diagnosis } from "./../types"

export const [
  Phase1FormProvider,
  usePhase1FormData,
  usePhase1FormDataSetter,
  usePhase1Category,
  usePhase1CategorySetter,
  usePhase1Deficit,
  usePhase1DeficitSetter,
  usePhase1Diagnosis,
  usePhase1DiagnosisSetter,
  usePhase1CalculateReconstructionYear,
] = constate(
  () => {
    const [formData, setFormData] = useState<Phase1FormData>({})
    const [category, setCategory] = useState<Phase1Category>()
    const [deficit, setDeficit] = useState<number>()
    const [diagnosis, setDiagnosis] = useState<Phase1Diagnosis>()

    const calculateReconstructionYear = useCallback(
      ({
        deposit,
        ordinaryProfit,
        depreciation,
        debtPrincipal,
      }: Phase1FormData["q8_1"] = {}) => {
        if (
          deposit === undefined ||
          ordinaryProfit === undefined ||
          depreciation === undefined ||
          debtPrincipal === undefined
        ) {
          return
        }
        const year = Math.abs(
          Math.ceil(deposit / (ordinaryProfit + depreciation - debtPrincipal)),
        )
        // MEMO: 分子が0の様に無限大の数字になった際には、0を返す
        if (year === Infinity || isNaN(year)) {
          return 0
        }
        return year
      },
      [],
    )

    /**
     * 再建年数の計算(小数点は切り上げ)
     */
    const reconstructionYear = useMemo(
      () => calculateReconstructionYear(formData.q8_1),
      [formData.q8_1, calculateReconstructionYear],
    )

    return {
      formData,
      setFormData,
      category,
      setCategory,
      deficit,
      setDeficit,
      calculateReconstructionYear,
      reconstructionYear,
      diagnosis,
      setDiagnosis,
    }
  },
  ({ formData, reconstructionYear }) => ({ formData, reconstructionYear }),
  ({ setFormData }) => ({ setFormData }),
  ({ category }) => ({ category }),
  ({ setCategory }) => ({ setCategory }),
  ({ deficit }) => ({ deficit }),
  ({ setDeficit }) => ({ setDeficit }),
  ({ diagnosis }) => ({ diagnosis }),
  ({ setDiagnosis }) => ({ setDiagnosis }),
  ({ calculateReconstructionYear }) => ({ calculateReconstructionYear }),
)
