import { useCallback } from "react"
import { NavigateOptions, useNavigate } from "react-router-dom"
import { PUBLIC_ROUTE_PATH } from "routes"

/**
 * Public Route系の画面遷移集約Hooks
 */
export function usePublicRoute() {
  const navigate = useNavigate()

  const goToLogin = useCallback(
    (options?: NavigateOptions) => {
      navigate(PUBLIC_ROUTE_PATH.LOGIN, options)
    },
    [navigate],
  )

  const goToPasswordReset = useCallback(
    (options?: NavigateOptions) => {
      navigate(PUBLIC_ROUTE_PATH.PASSWORD_RESET, options)
    },
    [navigate],
  )

  const goToTermsOfService = useCallback(
    (options?: NavigateOptions) => {
      navigate(PUBLIC_ROUTE_PATH.TERMS_OF_SERVICE, options)
    },
    [navigate],
  )

  const goToPrivacyPolicy = useCallback(
    (options?: NavigateOptions) => {
      navigate(PUBLIC_ROUTE_PATH.PRIVACY_POLICY, options)
    },
    [navigate],
  )

  const goToLpUserRegister = useCallback(
    (options?: NavigateOptions) => {
      navigate(PUBLIC_ROUTE_PATH.LP_USER_REGISTER, options)
    },
    [navigate],
  )

  const goToNotFound = useCallback(
    (options?: NavigateOptions) => {
      navigate(PUBLIC_ROUTE_PATH.NOT_FOUND, options)
    },
    [navigate],
  )

  const goToNetworkError = useCallback(
    (options?: NavigateOptions) => {
      navigate(PUBLIC_ROUTE_PATH.NETWORK_ERROR, options)
    },
    [navigate],
  )

  const goToInsufficientIdError = useCallback(
    (options?: NavigateOptions) => {
      navigate(PUBLIC_ROUTE_PATH.INSUFFICIENT_ID, options)
    },
    [navigate],
  )

  const goToAbout = useCallback(
    (options?: NavigateOptions) => {
      navigate(PUBLIC_ROUTE_PATH.ABOUT, options)
    },
    [navigate],
  )

  const goToKeieiSyokei = useCallback(() => {
    window.location.assign(PUBLIC_ROUTE_PATH.KEIEI_SYOKEI_HOME_PAGE)
  }, [])

  return {
    goToLogin,
    goToPasswordReset,
    goToTermsOfService,
    goToPrivacyPolicy,
    goToLpUserRegister,
    goToNotFound,
    goToNetworkError,
    goToInsufficientIdError,
    goToAbout,
    goToKeieiSyokei,
  } as const
}
