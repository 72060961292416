import { Suspense, useCallback } from "react"
import { useAuthPermission } from "context/auth"
import { useClientDownloadPlanModals } from "context/downloadPlan"
import { useQuery } from "react-query"
import { Outlet } from "react-router-dom"
import styled from "styled-components"
import { fetchMyLPResultsQuery } from "api"
import { GeneralHeader } from "components/organisms/GeneralHeader"
import { useHeaderMenu } from "components/organisms/GeneralHeader/useHeaderMenu"
import { useAdminRoute } from "hooks/useAdminRoute"
import { useClientRoute } from "hooks/useClientRoute"
import { useSupporterRoute } from "hooks/useSupporterRoute"
import { selectLatestResults } from "pages/utils"

/**
 * App共通のレイアウト
 * （ユーザー権限ごとに表示するヘッダーが変更する）
 */
export const AppCommonLayout = () => {
  const menuItems = useHeaderMenu()
  const { permission } = useAuthPermission()
  const { goToHome: goToClientHome } = useClientRoute()
  const { goToHome: goToSupporterHome } = useSupporterRoute()
  const { goToHome: goToAdminHome } = useAdminRoute()
  const { data: latestResults } = useQuery({
    ...fetchMyLPResultsQuery,
    select: selectLatestResults,
    enabled: permission === "client",
  })

  const handleLogoClick = useCallback(() => {
    if (permission === "client") {
      return goToClientHome()
    }
    if (permission === "expert") {
      return goToSupporterHome()
    }
    if (permission === "admin") {
      return goToAdminHome()
    }
  }, [goToAdminHome, goToClientHome, goToSupporterHome, permission])

  const { ClientDownloadConfirmModal, ClientDownloadCompletedModal } =
    useClientDownloadPlanModals()

  return (
    <Container>
      <GeneralHeader menuItems={menuItems} onLogoClick={handleLogoClick} />
      <MainContainer>
        <Suspense fallback={null}>
          <Outlet />
        </Suspense>
      </MainContainer>
      <ClientDownloadConfirmModal
        phase2Id={latestResults?.phase2?.phase2Id}
        createdAt={latestResults?.phase2?.createdAt}
      />
      <ClientDownloadCompletedModal />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
`

const MainContainer = styled.main`
  flex: 1;
`
