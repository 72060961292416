import { axiosClient } from "lib/axios-client"
import {
  MyConsultStatusGetResponse,
  MyPhaseStatusGetResponse,
  PhaseStatusesGetResponse,
  SalesStatusesGetResponse,
  SalesStatusGetResponse,
  SalesStatusUpdateRequest,
  ManagerStatusGetResponse,
  ManagerStatusUpdateRequest,
  ConsultStatusGetResponse,
  PhaseStatusGetResponse,
  SalesStatusUpdateResponse,
  ManagerStatusUpdateResponse,
  MyConsultStatusTurnOnResponse,
  SalesStatusCreateRequest,
  SalesStatusCreateResponse,
  SalesStatusEditRequest,
  SalesStatusDeleteRequest,
} from "./types"

export const StatusAPI = {
  /**
   * ログインユーザの回答ステータス群を取得する
   */
  fetchMyPhaseStatus: async () => {
    const { data } = await axiosClient.request<MyPhaseStatusGetResponse>({
      url: "/user/phase",
      method: "GET",
    })
    return data
  },

  /**
   * ログインユーザの相談フラグを取得する
   */
  fetchMyConsultStatus: async () => {
    const { data } = await axiosClient.request<MyConsultStatusGetResponse>({
      url: "/user/status/consult",
      method: "GET",
    })
    return data
  },

  /**
   * ログインユーザの相談フラグをONにする
   */
  turnOnMyConsultStatus: async () => {
    const { data } = await axiosClient.request<MyConsultStatusTurnOnResponse>({
      url: "/user/status/consult",
      method: "PATCH",
    })
    return data
  },

  /**
   * 回答ステータス一覧を取得する
   */
  fetchPhaseStatuses: async () => {
    const { data } = await axiosClient.request<PhaseStatusesGetResponse>({
      url: "/status/phase",
      method: "GET",
    })
    return data
  },

  /**
   * 営業ステータス一覧を取得する
   */
  fetchSalesStatuses: async () => {
    const { data } = await axiosClient.request<SalesStatusesGetResponse>({
      url: "/status/sales",
      method: "GET",
    })
    return data
  },

  /**
   * 営業ステータスを新たに作成する
   */
  createSalesStatus: async ({ name }: SalesStatusCreateRequest) => {
    const { data } = await axiosClient.request<SalesStatusCreateResponse>({
      url: "/status/sales",
      method: "POST",
      data: { name },
    })
    return data
  },

  /**
   * 営業ステータス名を更新する
   */
  editSalesStatus: async ({
    sales_status_id,
    name,
  }: SalesStatusEditRequest) => {
    const { data } = await axiosClient.request<SalesStatusCreateResponse>({
      url: `/status/sales/${sales_status_id}`,
      method: "PATCH",
      data: { name },
    })
    return data
  },

  /**
   * 営業ステータスを削除する
   */
  deleteSalesStatus: async ({ sales_status_id }: SalesStatusDeleteRequest) => {
    await axiosClient.request<SalesStatusDeleteRequest>({
      url: `/status/sales/${sales_status_id}`,
      method: "DELETE",
    })
  },

  /**
   * 特定のユーザの営業ステータスを取得する
   */
  fetchSalesStatus: async (userId: string) => {
    const { data } = await axiosClient.request<SalesStatusGetResponse>({
      url: `/users/${userId}/status/sales`,
      method: "GET",
    })
    return data
  },

  /**
   * 特定のユーザの営業ステータスを更新する
   */
  updateSalesStatus: async ({
    userId,
    sales_status_id,
  }: SalesStatusUpdateRequest) => {
    const { data } = await axiosClient.request<SalesStatusUpdateResponse>({
      url: `/users/${userId}/status/sales`,
      method: "PATCH",
      data: { sales_status_id },
    })
    return data
  },

  /**
   * 特定のユーザの担当者を取得する
   */
  fetchManagerStatus: async (userId: string) => {
    const { data } = await axiosClient.request<ManagerStatusGetResponse>({
      url: `/users/${userId}/status/manager`,
      method: "GET",
    })
    return data
  },

  /**
   * 特定のユーザの担当者を更新する
   */
  updateManagerStatus: async ({
    userId,
    expert_id,
  }: ManagerStatusUpdateRequest) => {
    const { data } = await axiosClient.request<ManagerStatusUpdateResponse>({
      url: `/users/${userId}/status/manager`,
      method: "PATCH",
      data: { expert_id },
    })
    return data
  },

  /**
   * 特定ユーザの専門家フラグを取得
   */
  fetchConsultStatus: async (userId: string) => {
    const { data } = await axiosClient.request<ConsultStatusGetResponse>({
      url: `/users/${userId}/status/consult`,
      method: "GET",
    })
    return data
  },

  /**
   * 特定ユーザの回答ステータスを取得
   */
  fetchPhaseStatus: async (userId: string) => {
    const { data } = await axiosClient.request<PhaseStatusGetResponse>({
      url: `/users/${userId}/phase`,
      method: "GET",
    })
    return data
  },
} as const
