import { useParams } from "react-router-dom"
const USER_ID = "user_id"
const COMPANY_ID = "company_id"
const LP_ID = "lp_id"

export function useUserId() {
  const { [USER_ID]: userId } = useParams()

  return userId
}

export function useCompanyId() {
  const { [COMPANY_ID]: companyId } = useParams()

  return companyId
}

export function useLpId() {
  const { [LP_ID]: lpId } = useParams()
  if (lpId === undefined) {
    return
  }
  const parsedLpId = parseInt(lpId)
  if (Number.isNaN(parsedLpId)) {
    return
  }
  return parsedLpId
}
