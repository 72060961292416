import { PeakSales } from "api/types/phase2/utils"
import { PeakSalesFormData } from "pages/utils/types/phase2FormData"

/**
 * ピーク時の売上 D Q11
 */
export const peakSalesDraftTranslator = (
  answer: PeakSales | undefined,
): PeakSalesFormData | undefined => ({
  term: answer?.answer?.content?.term?.content,
  sale: answer?.answer?.content?.sale?.content,
})
