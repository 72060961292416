import { useQuery } from "react-query"
import { RelationsAPI } from "api/relations/api"
import { OmitQueryConfig } from "lib/react-query"

export const fetchManagersQuery = {
  queryKey: "/managers",
  queryFn: RelationsAPI.fetchManagers,
}

/**
 * 担当者の一覧を取得するHooks
 * @param config
 */
export function useManagersQuery(
  config?: OmitQueryConfig<typeof fetchManagersQuery>,
) {
  return useQuery({ ...fetchManagersQuery, ...config })
}
