import { AxiosError } from "axios"
import { useMutation, UseMutationOptions } from "react-query"
import {
  ProfileCreateErrorResponse,
  ProfileCreateRequest,
  ProfileCreateResponse,
  ProfileUpdateRequest,
} from "api/profile/types"
import { OmitMutationConfig } from "lib/react-query"
import { ProfileAPI } from "./api"

const PREFIX_ID = "profile"

export const fetchMyProfileQuery = {
  queryKey: `${PREFIX_ID}/fetchMyProfile`,
  queryFn: ProfileAPI.fetchMyProfile,
} as const

export const fetchProfileQuery = (userId: string) =>
  ({
    queryKey: `${PREFIX_ID}/fetchProfile/${userId}`,
    queryFn: () => ProfileAPI.fetchProfile(userId),
  } as const)

export const updateProfileMutation: UseMutationOptions<
  void,
  AxiosError,
  ProfileUpdateRequest
> = {
  mutationFn: ProfileAPI.updateProfile,
  mutationKey: `${PREFIX_ID}/updateProfile`,
}

/**
 * プロフィール情報の更新Hook
 * @param config MutationConfig
 */
export function useUpdateProfileMutation(
  config?: OmitMutationConfig<typeof updateProfileMutation>,
) {
  return useMutation({ ...updateProfileMutation, ...config })
}

export const registerProfileMutation: UseMutationOptions<
  ProfileCreateResponse,
  AxiosError<ProfileCreateErrorResponse>,
  ProfileCreateRequest
> = {
  mutationFn: ProfileAPI.registerProfile,
  mutationKey: `${PREFIX_ID}/registerProfile`,
}

/**
 * プロフィール登録Hooks
 * @param config MutationConfig
 */
export function useRegisterProfileMutation(
  config?: OmitMutationConfig<typeof registerProfileMutation>,
) {
  return useMutation({ ...registerProfileMutation, ...config })
}
