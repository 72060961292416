import { AxiosError } from "axios"
import { useMutation, UseMutationOptions } from "react-query"
import { DefaultErrorResponse } from "api/types"
import { OmitMutationConfig } from "lib/react-query"
import { SigninAPI, SigninRequest, SigninResponse } from "./api"

const PREFIX_ID = "signin"

export const signinMutation: UseMutationOptions<
  SigninResponse,
  AxiosError<DefaultErrorResponse>,
  SigninRequest
> = {
  mutationFn: SigninAPI.signin,
  mutationKey: `${PREFIX_ID}/signin`,
}

/**
 * Signin Hooks
 * @param config MutationConfig
 */
export function useSigninMutation(
  config?: OmitMutationConfig<typeof signinMutation>,
) {
  return useMutation({ ...signinMutation, ...config })
}
