import { Phase2AnswerG } from "api"
import { Phase2GFormData } from "pages/components/phase2/components/G/schema"
import { DeepPartial } from "utils"
import {
  selectAndDateDraftTranslator,
  numberDraftTranslator,
  selectDraftTranslator,
  numberArrayDraftTranslator,
  arrayCostDraftTranslator,
  objectCostDraftTranslator,
  actionPlanDraftTranslator,
  concernAndSolutionDraftTranslator,
  liabilitiesDraftTranslator,
} from "./utils"

/**
 * 下書きを受けとってformDataの形に変換する
 */
export const phase2DraftGTranslator = (
  answer: Phase2AnswerG,
): DeepPartial<Phase2GFormData> => ({
  q1: selectDraftTranslator(answer.question_group_1?.answers?.answer_01),
  q2: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_02),
  q3: numberDraftTranslator(answer.question_group_1?.answers?.answer_03),
  // q4: 表示のみ
  // q5: 表示のみ
  q6: selectAndDateDraftTranslator(answer.question_group_2?.answers?.answer_06),
  q7: selectAndDateDraftTranslator(answer.question_group_2?.answers?.answer_07),
  q8: numberArrayDraftTranslator(answer.question_group_3?.answers?.answer_08),
  q9: arrayCostDraftTranslator(answer.question_group_3?.answers?.answer_09),
  q10: liabilitiesDraftTranslator(answer.question_group_3?.answers?.answer_10),
  q11: numberArrayDraftTranslator(answer.question_group_3?.answers?.answer_11),
  q12: numberDraftTranslator(answer.question_group_3?.answers?.answer_12),
  q13: objectCostDraftTranslator(answer.question_group_3?.answers?.answer_13),
  q14: numberDraftTranslator(answer.question_group_3?.answers?.answer_14),
  q15: numberArrayDraftTranslator(answer.question_group_3?.answers?.answer_15),
  q16: arrayCostDraftTranslator(answer.question_group_3?.answers?.answer_16),
  q17: numberArrayDraftTranslator(answer.question_group_3?.answers?.answer_17),
  // q18: 表示のみ
  q19: numberDraftTranslator(answer.question_group_3?.answers?.answer_19),
  q20: numberArrayDraftTranslator(answer.question_group_3?.answers?.answer_20),
  q21: numberArrayDraftTranslator(answer.question_group_3?.answers?.answer_21),
  q22q24: actionPlanDraftTranslator(
    answer.question_group_3?.answers?.answer_22_24,
  ),
  q25: concernAndSolutionDraftTranslator(
    answer.question_group_3?.answers?.answer_25,
  ),
})
