import { Phase2AnswerB } from "api/types/phase2/b"
import phase2B from "assets/json/phase2/b.json"
import { Phase2BFormData } from "pages/components/phase2/components/B/schema"
import { Phase2Type } from "pages/components/phase2/utils"
import { DeepPartial } from "utils"
import {
  dateTranslator,
  selectAndDateTranslator,
  selectTranslator,
  stringTranslator,
  nameAndBirthdayTranslator,
  transferringStockTranslator,
  businessPlanTranslator,
  bankBorrowingAmountAndNetWorthTranslator,
} from "./utils"

type Diagnosis = Extract<Phase2Type, "AB" | "IB">

interface Args {
  diagnosis: Diagnosis
  formData: DeepPartial<Phase2BFormData>
}

// TODO: phase2ResultAnswerがマージされたらそちらを使う
interface Return {
  diagnosis: Diagnosis
  answer: Phase2AnswerB
}

/**
 * フォームのvalueをapiのスキーマに変換する
 */
export const phase2BFormDataTranslator = ({
  diagnosis,
  formData,
}: Args): Return => ({
  diagnosis: diagnosis,
  answer: {
    question_group_1: {
      title: phase2B.title1,
      answers: {
        answer_00: nameAndBirthdayTranslator({
          json: phase2B.q0,
          formData: formData.q0,
        }),
        answer_01: selectAndDateTranslator({
          json: phase2B.q1,
          formData: formData.q1,
        }),
        answer_02: selectAndDateTranslator({
          json: phase2B.q2,
          formData: formData.q2,
        }),
        answer_03: selectAndDateTranslator({
          json: phase2B.q3,
          formData: formData.q3,
        }),
        answer_04: selectAndDateTranslator({
          json: phase2B.q4,
          formData: formData.q4,
        }),
      },
    },
    question_group_2: {
      title: phase2B.title2,
      answers: {
        answer_05: selectAndDateTranslator({
          json: phase2B.q5,
          formData: formData.q5,
        }),
        answer_06: selectTranslator({
          json: phase2B.q6,
          formData: formData.q6,
        }),
        answer_07: selectTranslator({
          json: phase2B.q7,
          formData: formData.q7,
        }),
      },
    },
    question_group_3: {
      title: phase2B.title3,
      answers: {
        answer_08: selectAndDateTranslator({
          json: phase2B.q8,
          formData: formData.q8,
        }),
        answer_09: selectAndDateTranslator({
          json: phase2B.q9,
          formData: formData.q9,
        }),
        answer_10: selectAndDateTranslator({
          json: phase2B.q10,
          formData: formData.q10,
        }),
        answer_11: selectAndDateTranslator({
          json: phase2B.q11,
          formData: formData.q11,
        }),
        answer_12: selectTranslator({
          json: phase2B.q12,
          formData: formData.q12,
        }),
        answer_13: selectAndDateTranslator({
          json: phase2B.q13,
          formData: formData.q13,
        }),
        answer_14: selectAndDateTranslator({
          json: phase2B.q14,
          formData: formData.q14,
        }),
        answer_15: selectTranslator({
          json: phase2B.q15,
          formData: formData.q15,
        }),
        answer_16: selectAndDateTranslator({
          json: phase2B.q16,
          formData: formData.q16,
        }),
        answer_17: selectTranslator({
          json: phase2B.q17,
          formData: formData.q17,
        }),
        answer_18: selectAndDateTranslator({
          json: phase2B.q18,
          formData: formData.q18,
        }),
        answer_19: selectAndDateTranslator({
          json: phase2B.q19,
          formData: formData.q19,
        }),
        answer_20: selectAndDateTranslator({
          json: phase2B.q20,
          formData: formData.q20,
        }),
        answer_21: selectAndDateTranslator({
          json: phase2B.q21,
          formData: formData.q21,
        }),
      },
    },
    question_group_4: {
      title: phase2B.title4,
      answers: {
        answer_22: selectTranslator({
          json: phase2B.q22,
          formData: formData.q22,
        }),
        answer_23: transferringStockTranslator({
          json: phase2B.q23,
          formData: formData.q23,
        }),
        answer_24: selectAndDateTranslator({
          json: phase2B.q24,
          formData: formData.q24,
        }),
        answer_25: selectAndDateTranslator({
          json: phase2B.q25,
          formData: formData.q25,
        }),
        answer_26: businessPlanTranslator({
          json: phase2B.q26,
          formData: formData.q26,
        }),
        answer_27: dateTranslator({
          json: phase2B.q27,
          formData: formData.q27,
        }),
        answer_28: selectAndDateTranslator({
          json: phase2B.q28,
          formData: formData.q28,
        }),
        answer_29: selectTranslator({
          json: phase2B.q29,
          formData: formData.q29,
        }),
        answer_30: stringTranslator({
          json: phase2B.q30,
          formData: formData.q30,
        }),
        answer_31: stringTranslator({
          json: phase2B.q31,
          formData: formData.q31,
        }),
        answer_32: bankBorrowingAmountAndNetWorthTranslator({
          json: phase2B.q32,
          formData: formData.q32,
        }),
      },
    },
  },
})
