import { HTMLAttributes } from "react"
import styled from "styled-components"

const MENU_WIDTH = 33
const MENU_HEIGHT = 33
const LINE_WIDTH = 33
const LINE_HEIGHT = 4

/**
 * 閉じるボタン 形状: バツ
 */
export const CloseButton = (props: HTMLAttributes<HTMLButtonElement>) => {
  return (
    <Container {...props}>
      <Line />
      <Line />
    </Container>
  )
}

const Line = styled.div`
  height: ${LINE_HEIGHT}px;
  width: ${LINE_WIDTH}px;
  margin: 0 auto;
  border-radius: ${LINE_HEIGHT / 2}px;
  background-color: ${(props) => props.theme.color.nightRider};
`

const Container = styled.button`
  width: ${MENU_WIDTH}px;
  height: ${MENU_HEIGHT}px;

  ${Line} {
    :first-child {
      transform: translateY(${LINE_HEIGHT / 2}px) rotate(-45deg);
    }

    :last-child {
      transform: translateY(-${LINE_HEIGHT / 2}px) rotate(45deg);
    }
  }
`
