import { axiosClient } from "lib/axios-client"
import {
  LPResultGetResponse,
  ResultsGetResponse,
  LPAggregatedResultsGetResponse,
  UserLpResultGetResponse,
  UserLPAggregatedResultsGetResponse,
  Phase1DiagnosisResultGetResponse,
  UserPhase1DiagnosisResultGetResponse,
  LpResultCategoryGetResponse,
  Phase1ResultGetResponse,
  UserPhase1ResultGetResponse,
  Phase2ResultGetResponse,
  UserPhase2ResultGetResponse,
  Phase1LatestDiagnosisGetResponse,
} from "./types"

export const ResultsAPI = {
  /**
   * 回答一覧を取得
   * TODO: LPとついているがLP以外も含まれているので命名変更
   * @returns ResultsGetResponse
   */
  fetchMyLPResults: async () => {
    const { data } = await axiosClient.request<ResultsGetResponse>({
      url: "/results",
      method: "GET",
    })
    return data
  },

  /**
   * LP回答を取得
   * @param lpId
   * @returns LpResultGetResponse
   */
  fetchLPResult: async (lpId: number) => {
    const { data } = await axiosClient.request<LPResultGetResponse>({
      url: `/results/lp/${lpId}`,
      method: "GET",
    })
    return data
  },

  /**
   * LP結果を取得
   * @param lpId
   * @returns LpResultsAggregateGetResponse
   */
  fetchLPAggregatedResult: async (lpId: number) => {
    const { data } = await axiosClient.request<LPAggregatedResultsGetResponse>({
      url: `/results/lp/${lpId}/aggregate`,
      method: "GET",
    })
    return data
  },

  /**
   * 特定のuserの回答ログ一覧取得
   * @param userId
   */
  fetchLPResults: async (userId: string) => {
    const { data } = await axiosClient.request<ResultsGetResponse>({
      url: `/users/${userId}/results`,
      method: "GET",
    })
    return data
  },

  /**
   * 特定ユーザのLP回答を取得
   * @param userId
   * @param lpId
   */
  fetchUserLpResult: async (userId: string, lpId: number) => {
    const { data } = await axiosClient.request<UserLpResultGetResponse>({
      url: `/users/${userId}/results/lp/${lpId}`,
      method: "GET",
    })
    return data
  },

  /**
   * 特定ユーザのLP結果を取得
   * @param lpId
   * @param userId
   * @returns LpUserResultsAggregateGetResponse
   */
  fetchUserLPAggregatedResult: async (userId: string, lpId: number) => {
    const { data } =
      await axiosClient.request<UserLPAggregatedResultsGetResponse>({
        url: `/users/${userId}/results/lp/${lpId}/aggregate`,
        method: "GET",
      })
    return data
  },

  /**
   * phase1結果を取得
   * @param phase1Id
   * @returns Phase1DiagnosisResultGetResponse
   */
  fetchPhase1DiagnosisResult: async (phase1Id: number) => {
    const { data } =
      await axiosClient.request<Phase1DiagnosisResultGetResponse>({
        url: `results/phase1/${phase1Id}/diagnosis`,
        method: "GET",
      })
    return data
  },

  /**
   * 特定ユーザのphase1結果を取得
   * @param userId
   * @param phase1Id
   * @returns UserPhase1DiagnosisResultGetResponse
   */
  fetchUserPhase1DiagnosisResult: async (userId: string, phase1Id: number) => {
    const { data } =
      await axiosClient.request<UserPhase1DiagnosisResultGetResponse>({
        url: `users/${userId}/results/phase1/${phase1Id}/diagnosis`,
        method: "GET",
      })
    return data
  },

  /**
   * LP最新回答の分類取得
   */
  fetchLpCategory: async () => {
    const { data } = await axiosClient.request<LpResultCategoryGetResponse>({
      url: "/results/lp/category",
      method: "GET",
    })
    return data
  },

  /**
   * phase1回答を取得
   * @param phase1Id
   * @returns Phase1ResultGetResponse
   */
  fetchPhase1Result: async (phase1Id: number) => {
    const { data } = await axiosClient.request<Phase1ResultGetResponse>({
      url: `/results/phase1/${phase1Id}`,
      method: "GET",
    })
    return data
  },

  /**
   * 特定ユーザのphase1回答を取得
   * @param userId
   * @param phase1Id
   * @returns UserPhase1ResultGetResponse
   */
  fetchUserPhase1Result: async (userId: string, phase1Id: number) => {
    const { data } = await axiosClient.request<UserPhase1ResultGetResponse>({
      url: `/users/${userId}/results/phase1/${phase1Id}`,
      method: "GET",
    })
    return data
  },

  /**
   * Phase1回答最新診断結果取得
   * @returns Phase1LatestDiagnosis
   */
  fetchPhase1LatestDiagnosis: async () => {
    const { data } =
      await axiosClient.request<Phase1LatestDiagnosisGetResponse>({
        url: "/results/phase1/diagnosis",
        method: "GET",
      })
    return data
  },

  /**
   * Phase2回答を取得
   * @param phase2Id
   * @returns Phase2ResultGetResponse
   */
  fetchPhase2Result: async (phase2Id: number) => {
    const { data } = await axiosClient.request<Phase2ResultGetResponse>({
      url: `/results/phase2/${phase2Id}`,
      method: "GET",
    })
    return data
  },

  /**
   * 特定のユーザのPhase2回答を取得
   * @param userId
   * @param phase2Id
   * @returns UserPhase2ResultGetResponse
   */
  fetchUserPhase2Result: async (userId: string, phase2Id: number) => {
    const { data } = await axiosClient.request<UserPhase2ResultGetResponse>({
      url: `/users/${userId}/results/phase2/${phase2Id}`,
      method: "GET",
    })
    return data
  },
} as const
