import { generateMedia } from "styled-media-query"
import { HexColor, Px } from "utils"

interface AppColorSet {
  pigmentGreen: HexColor
  monteCarlo: HexColor
  nobel: HexColor
  pinkSwan: HexColor
  silver: HexColor
  nightRider: HexColor
  white: HexColor
  wildWatermelon: HexColor
  portica: HexColor
  caribbean: HexColor
  caribbeanLight: HexColor
  babyBlue: HexColor
  turquoise: HexColor
  deepSky: HexColor
  link: HexColor
}

interface AppFontFamily {
  default: string
}

export interface AppFontSizeSet {
  h1: Px
  h2: Px
  h3: Px
  xl: Px
  l: Px
  m: Px
  s: Px
  xs: Px
  xxs: Px
}

export interface AppLineHeightSet {
  h1: Px
  h2: Px
  h3: Px
  xl: Px
  l: Px
  m: Px
  s: Px
  xs: Px
  xxs: Px
}

export interface AppFontWeightSet {
  normal: number
  bold: number
}

export interface AppTheme {
  color: AppColorSet
  fontFamily: AppFontFamily
  fontSize: AppFontSizeSet
  fontWeight: AppFontWeightSet
  lineHeight: AppLineHeightSet
}

export const theme: AppTheme = {
  color: {
    pigmentGreen: "#00A559",
    monteCarlo: "#7FC2A6",
    nobel: "#949494",
    pinkSwan: "#B5B5B5",
    silver: "#C4C4C4",
    nightRider: "#333333",
    white: "#FFFFFF",
    wildWatermelon: "#EA586B",
    portica: "#EACA58",
    caribbean: "#00BC84",
    caribbeanLight: "#07D3AE",
    babyBlue: "#5EFFFF",
    turquoise: "#36E9D7",
    deepSky: "#15D1F2",
    //MEMO: figmaのカラーコードには存在しないが、aタグのcolorとして使用
    link: "#0000EE",
  },
  fontFamily: {
    default: "'Noto Sans JP', sans-serif",
  },
  fontSize: {
    h1: "32px",
    h2: "28px",
    h3: "24px",
    xl: "25px",
    l: "20px",
    m: "16px",
    s: "12px",
    xs: "10px",
    xxs: "10px",
  },
  // TODO: Font Weight未確定のため変更の可能性あり
  fontWeight: {
    normal: 400,
    bold: 700,
  },
  lineHeight: {
    h1: "41.6px",
    h2: "36.4px",
    h3: "31.2px",
    xl: "44px",
    l: "36px",
    m: "28.8px",
    s: "21.6px",
    xs: "18px",
    xxs: "14px",
  },
}

export const appBreakpoints = {
  desktop: "960px",
  tablet: "600px",
} as const

export const appMedia = generateMedia(appBreakpoints)
