import { Phase2AnswerD } from "api"
import phase2D from "assets/json/phase2/d.json"
import { Phase2DFormData } from "pages/components/phase2/components/D/schema"
import { DeepPartial } from "utils"
import {
  selectAndDateDraftTranslator,
  numberDraftTranslator,
  selectDraftTranslator,
  numberArrayDraftTranslator,
  arrayCostDraftTranslator,
  peakSalesDraftTranslator,
  partiallyRequiredStringArrayDraftTranslator,
  objectCostDraftTranslator,
  actionPlanDraftTranslator,
  concernAndSolutionDraftTranslator,
} from "./utils"

/**
 * 下書きを受けとってformDataの形に変換する
 */
export const phase2DraftDTranslator = (
  answer: Phase2AnswerD,
): DeepPartial<Phase2DFormData> => ({
  q1: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_01),
  q2: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_02),
  q3: selectAndDateDraftTranslator(answer.question_group_2?.answers?.answer_03),
  q4: selectAndDateDraftTranslator(answer.question_group_2?.answers?.answer_04),
  q5: selectAndDateDraftTranslator(answer.question_group_2?.answers?.answer_05),
  q6: numberDraftTranslator(answer.question_group_3?.answers?.answer_06),
  q7: selectDraftTranslator(answer.question_group_3?.answers?.answer_07),
  q8: numberArrayDraftTranslator(answer.question_group_3?.answers?.answer_08),
  q9: arrayCostDraftTranslator(answer.question_group_3?.answers?.answer_09),
  q10: numberArrayDraftTranslator(answer.question_group_3?.answers?.answer_10),
  q11: peakSalesDraftTranslator(answer.question_group_3?.answers?.answer_11),
  q12: numberDraftTranslator(answer.question_group_3?.answers?.answer_12),
  q13: partiallyRequiredStringArrayDraftTranslator(
    answer.question_group_3?.answers?.answer_13,
    { requiredLength: phase2D.q13.textbox.label.required.length },
  ),
  q14: partiallyRequiredStringArrayDraftTranslator(
    answer.question_group_3?.answers?.answer_14,
    { requiredLength: phase2D.q14.textbox.label.required.length },
  ),
  q15: partiallyRequiredStringArrayDraftTranslator(
    answer.question_group_3?.answers?.answer_15,
    { requiredLength: phase2D.q15.textbox.label.required.length },
  ),
  q16: numberDraftTranslator(answer.question_group_3?.answers?.answer_16),
  q17: objectCostDraftTranslator(answer.question_group_3?.answers?.answer_17),
  q18: numberDraftTranslator(answer.question_group_3?.answers?.answer_18),
  q19: numberArrayDraftTranslator(answer.question_group_3?.answers?.answer_19),
  q20: arrayCostDraftTranslator(answer.question_group_3?.answers?.answer_20),
  q21: numberArrayDraftTranslator(answer.question_group_3?.answers?.answer_21),
  q22q24: actionPlanDraftTranslator(
    answer.question_group_3?.answers?.answer_22_24,
  ),
  q25: concernAndSolutionDraftTranslator(
    answer.question_group_3?.answers?.answer_25,
  ),
})
