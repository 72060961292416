import { Phase2AnswerF } from "api/types/phase2/f"
import phase2F from "assets/json/phase2/f.json"
import { Phase2FFormData } from "pages/components/phase2/components/F/schema"
import { Phase2Type } from "pages/components/phase2/utils"
import { DeepPartial } from "utils"
import { selectAndDateTranslator, dateTranslator } from "./utils"

type Diagnosis = Extract<Phase2Type, "AF" | "IF" | "UF" | "EF">

interface Args {
  diagnosis: Diagnosis
  formData: DeepPartial<Phase2FFormData>
}

// TODO: phase2ResultAnswerがマージされたらそちらを使う
interface Return {
  diagnosis: Diagnosis
  answer: Phase2AnswerF
}

export const phase2FFormDataTranslator = ({
  diagnosis,
  formData,
}: Args): Return => ({
  diagnosis: diagnosis,
  answer: {
    question_group_1: {
      answers: {
        // answer_00 表示のみ
        answer_01: selectAndDateTranslator({
          json: phase2F.q1,
          formData: formData.q1,
        }),
        answer_02: selectAndDateTranslator({
          json: phase2F.q2,
          formData: formData.q2,
        }),
        answer_03: dateTranslator({
          json: phase2F.q3,
          formData: formData.q3,
        }),
        answer_04: dateTranslator({
          json: phase2F.q4,
          formData: formData.q4,
        }),
        answer_05: dateTranslator({
          json: phase2F.q5,
          formData: formData.q5,
        }),
        answer_06: dateTranslator({
          json: phase2F.q6,
          formData: formData.q6,
        }),
        answer_07: dateTranslator({
          json: phase2F.q7,
          formData: formData.q7,
        }),
      },
    },
  },
})
