import { NameAndBirthday } from "api/types/phase2/utils"
import { NameAndBirthdayFormData } from "pages/utils/types/phase2FormData"

interface Args {
  json: {
    question: string
    textbox: {
      label: {
        name: string
        birthdate: string
      }
    }
  }
  formData: NameAndBirthdayFormData
}

/**
 * 名前と誕生日 （A Q0, B Q0）
 */
export const nameAndBirthdayTranslator = ({
  json,
  formData,
}: Args): NameAndBirthday | undefined => {
  if (!formData?.date || !formData.name) return undefined

  return {
    question: json.question,
    answer: {
      type: "object",
      content: {
        name: {
          type: "string",
          content: formData.name,
          label: json.textbox.label.name,
        },
        birthday: {
          type: "string",
          content: formData.date,
          label: json.textbox.label.birthdate,
        },
      },
    },
  } as const
}
