import { UsersGetResponse } from "api/users/types"
import { axiosClient } from "lib/axios-client"

export const UsersAPI = {
  /**
   * ユーザの一覧を取得する
   */
  fetchUsers: async (params?: URLSearchParams) => {
    const { data } = await axiosClient.request<UsersGetResponse>({
      url: "/users",
      method: "GET",
      params: params,
    })
    return data
  },

  /**
   * ユーザを削除する
   * DBからユーザを削除するのではなく、ユーザを退会状態にするためPATCH
   * @param userId
   */
  deleteUser: async (userId: string) => {
    await axiosClient.request({
      url: `/users/${userId}/delete`,
      method: "PATCH",
    })
  },

  /**
   * 月額会員一覧取得
   */
  fetchPaymentUsers: async (params?: URLSearchParams) => {
    const { data } = await axiosClient.request<UsersGetResponse>({
      url: "/users/payment",
      method: "GET",
      params: params,
    })
    return data
  },

  /**
   * 月額会員ユーザー論理削除
   */
  deletePaymentUser: async (userId: string) => {
    await axiosClient.request({
      url: `/users/${userId}/payment/delete`,
      method: "PATCH",
    })
  },
} as const
