import { Suspense } from "react"
import { Outlet } from "react-router-dom"
import styled from "styled-components"
import { GeneralHeader } from "components/organisms/GeneralHeader"
import { DiscardAnswerModal } from "components/organisms/Modals/DiscardAnswerModal"
import { useClientRoute } from "hooks/useClientRoute"
import { useModal, ModalWrap } from "hooks/useModal"
import { Phase1FormProvider } from "pages/components/phase1/utils"

/**
 * /phase1のレイアウトコンポーネント
 */
export const Phase1Layout = () => {
  const { goToHome } = useClientRoute()

  const {
    ModalPortal: ConfirmModalPortal,
    open: openConfirmModal,
    close: closeConfirmModal,
  } = useModal({
    closeOnOverlayClick: true,
    preventScroll: true,
  })

  return (
    <Container>
      <GeneralHeader onLogoClick={openConfirmModal} />
      <MainContainer>
        <Phase1FormProvider>
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
        </Phase1FormProvider>
      </MainContainer>
      <ConfirmModalPortal>
        <ModalWrap>
          <DiscardAnswerModal
            onAgree={() => goToHome()}
            onCancel={closeConfirmModal}
          />
        </ModalWrap>
      </ConfirmModalPortal>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const MainContainer = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
`
