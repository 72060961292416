import {
  InvitationGetResponse,
  InvitationCreateResponse,
  InvitationId,
} from "api/invitation/types"
import { axiosClient } from "lib/axios-client"

export const InvitationAPI = {
  /**
   * 招待IDの有効期限検証
   * @param invitationId 招待ID
   */
  verifyInvitation: async (invitationId: InvitationId) => {
    const { data } = await axiosClient.request<InvitationGetResponse>({
      url: "/invitation-url",
      method: "GET",
      params: {
        invitation_id: invitationId,
      },
    })
    return data
  },

  /**
   * 専門家招待URLを発行
   * @returns 専門家招待URL
   */
  createInvitation: async () => {
    const { data } = await axiosClient.request<InvitationCreateResponse>({
      url: "/expert/invitation-url",
      method: "POST",
    })
    return data
  },
} as const
