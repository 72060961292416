import { ArrayCost } from "api/types/phase2/utils"
import { ArrayCostFormData } from "pages/utils/types/phase2FormData"

/**
 * 過去3年の経費 D Q9, G Q9 経費削減計画 D Q20, G Q16
 */
export const arrayCostDraftTranslator = (
  answer: ArrayCost | undefined,
): ArrayCostFormData | undefined =>
  answer?.answer?.content?.map(
    ({
      product_purchase_amount,
      raw_material_costs,
      outsourced_processing_costs,
      freight_costs,
      labor_cost,
      my_salary,
      utilities_charge,
      depreciation_and_amortization,
      rent,
      lease_payment,
      premium,
      interest_expense_and_fees,
      other_costs_1,
      other_costs_2,
    } = {}) => ({
      productPurchaseAmount: product_purchase_amount?.content,
      rawMaterialCosts: raw_material_costs?.content,
      outsourcedProcessingCosts: outsourced_processing_costs?.content,
      freightCosts: freight_costs?.content,
      laborCost: labor_cost?.content,
      mySalary: my_salary?.content,
      utilitiesCharge: utilities_charge?.content,
      depreciationAndAmortization: depreciation_and_amortization?.content,
      rent: rent?.content,
      leasePayment: lease_payment?.content,
      premium: premium?.content,
      interestExpenseAndFees: interest_expense_and_fees?.content,
      otherCosts1: other_costs_1?.content,
      otherCosts2: other_costs_2?.content,
    }),
  )
