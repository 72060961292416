import { Liabilities } from "api/types/phase2/utils"
import { LiabilitiesFormData } from "pages/utils/types/phase2FormData"

interface Args {
  json: {
    question: string
    textbox: {
      label: {
        lender: string
        debt: string[]
      }
      unit: string
    }
  }
  formData?: LiabilitiesFormData
}

/**
 * 負債総額 G Q10
 */
export const liabilitiesTranslator = ({
  json,
  formData,
}: Args): Liabilities | undefined => {
  if (formData === undefined) return undefined

  return {
    question: json.question,
    answer: {
      type: "array",
      content: formData.map(({ lender, debt: debts } = {}) => ({
        lender: lender
          ? {
              type: "string",
              content: lender,
              label: json.textbox.label.lender,
            }
          : undefined,
        debt: debts
          ? debts.map((debt, index) => ({
              type: "number",
              content: debt,
              label: json.textbox.label.debt[index],
              unit: json.textbox.unit,
            }))
          : undefined,
      })),
    },
  } as const
}
