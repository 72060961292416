import {
  ExpertGetResponse,
  ExpertNameUpdateRequest,
  ExpertStatisticsOfPhaseGetResponse,
  ExpertStatisticsOfSalesGetResponse,
} from "api/expert/types"
import { axiosClient } from "lib/axios-client"

export const ExpertAPI = {
  /**
   * 専門家情報取得
   */
  fetchExpert: async () => {
    const { data } = await axiosClient.request<ExpertGetResponse>({
      url: "/expert",
      method: "GET",
    })
    return data
  },

  /**
   * 専門家の名前を更新する
   */
  updateExpertName: async (payload: ExpertNameUpdateRequest) => {
    const { data } = await axiosClient.request({
      url: "/expert/name",
      method: "PATCH",
      data: payload,
    })
    return data
  },

  /**
   * 専門家フェーズステータス
   */
  fetchExpertStatisticsOfPhase: async () => {
    const { data } =
      await axiosClient.request<ExpertStatisticsOfPhaseGetResponse>({
        url: "/expert/statistics/phase",
        method: "GET",
      })
    return data
  },

  /**
   * 専門家営業ステータス
   */
  fetchExpertStatisticsOfSales: async () => {
    const { data } =
      await axiosClient.request<ExpertStatisticsOfSalesGetResponse>({
        url: "/expert/statistics/sales",
        method: "GET",
      })
    return data
  },
} as const
