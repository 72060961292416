import { useAuthPermission } from "context/auth"
import { useInvId } from "context/invitationId"
import { useRoutes } from "react-router-dom"
import { adminRoutes } from "routes/admin"
import { clientRoutes } from "./client"
import { invitedEntityRoute, invitedRoutes } from "./invited"
import { publicRoutes } from "./public"
import { supporterRoutes } from "./supporter"

/**
 * アプリ全体のルーティング定義コンポーネント
 */
export const AppRoutes = () => {
  const { status } = useInvId()
  const { permission } = useAuthPermission()
  // MEMO: 同じpathの場合routesにおいて上に書かれた方が優先される
  const routes = [
    invitedEntityRoute,
    ...(status === "success" ? invitedRoutes : []),
    ...(permission === "client"
      ? clientRoutes
      : permission === "expert"
      ? supporterRoutes
      : permission === "admin"
      ? adminRoutes
      : []),
    ...publicRoutes,
  ]

  const element = useRoutes(routes)
  return element
}
