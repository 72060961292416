import { Liabilities } from "api/types/phase2/utils"
import { LiabilitiesFormData } from "pages/utils/types/phase2FormData"

/**
 * 負債総額 G Q10
 */
export const liabilitiesDraftTranslator = (
  answer: Liabilities | undefined,
): LiabilitiesFormData | undefined =>
  answer?.answer?.content?.map(({ lender, debt } = {}) => ({
    lender: lender?.content,
    debt: debt?.map(({ content } = {}) => content),
  }))
