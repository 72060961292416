import { HTMLAttributes } from "react"
import styled, { useTheme } from "styled-components"
import { TextWrap } from "components/atoms/TextWrap"

/**
 * 質問のまとまりごとの見出し
 */
export const GroupTitle = ({
  children,
  ...rest
}: HTMLAttributes<HTMLDivElement>) => {
  const theme = useTheme()
  return (
    <Container {...rest}>
      <TextWrap size="m" color={theme.color.white} weight="bold">
        {children}
      </TextWrap>
    </Container>
  )
}

const Container = styled.div`
  padding: 0 28px;
  background-color: ${({ theme }) => theme.color.pigmentGreen};
`
