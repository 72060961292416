import { HTMLAttributes } from "react"
import styled from "styled-components"

interface Props extends HTMLAttributes<HTMLAnchorElement> {
  /**
   * リンク先
   */
  href: string
  /**
   * 新しいタブを開くかどうか
   */
  newTab?: boolean
}

/**
 * 外部サイトへのリンク
 */
export const Link = ({ children, href, newTab, ...rest }: Props) => {
  return (
    <StyledA
      href={href}
      target={newTab ? "_blank" : ""}
      rel={newTab ? "noopener noreferrer" : ""}
      {...rest}
    >
      {children}
    </StyledA>
  )
}

const StyledA = styled.a`
  color: ${({ theme }) => theme.color.link};
  text-decoration: underline;
  cursor: pointer;
`
