import { generatePath, RouteObject } from "react-router-dom"
import { AppCommonLayout } from "pages/components/AppCommonLayout"
import { lazyImport } from "utils"

const { AdminHomePage } = lazyImport(
  () => import("pages/admin/home"),
  "AdminHomePage",
)

const { AdminEditCompanyPage } = lazyImport(
  () => import("pages/admin/companies/company_id/edit"),
  "AdminEditCompanyPage",
)

const { AdminCreateCompanyCompletedPage } = lazyImport(
  () => import("pages/admin/companies/create/complete"),
  "AdminCreateCompanyCompletedPage",
)

const { AdminCompaniesPage } = lazyImport(
  () => import("pages/admin/companies"),
  "AdminCompaniesPage",
)

const { AdminCompanyUsersPage } = lazyImport(
  () => import("pages/admin/companies/company_id/users"),
  "AdminCompanyUsersPage",
)

const { AdminEmailChangeFormPage } = lazyImport(
  () => import("pages/admin/settings/email/index"),
  "AdminEmailChangeFormPage",
)

const { AdminCreateCompanyPage } = lazyImport(
  () => import("pages/admin/companies/create"),
  "AdminCreateCompanyPage",
)

const { AdminEmailChangePendingPage } = lazyImport(
  () => import("pages/admin/settings/email/pending"),
  "AdminEmailChangePendingPage",
)

const { AdminSettingsEmailVerifyPage } = lazyImport(
  () => import("pages/admin/settings/email/verify"),
  "AdminSettingsEmailVerifyPage",
)

const { AdminPasswordFormPage } = lazyImport(
  () => import("pages/admin/settings/password/index"),
  "AdminPasswordFormPage",
)

const { AdminPasswordCompletedPage } = lazyImport(
  () => import("pages/admin/settings/password/complete"),
  "AdminPasswordCompletedPage",
)

const { AdminCompanyUserDetailPage } = lazyImport(
  () => import("pages/admin/companies/company_id/users/user_id"),
  "AdminCompanyUserDetailPage",
)

const { AdminCompanyUserResultsPage } = lazyImport(
  () => import("pages/admin/companies/company_id/users/user_id/results"),
  "AdminCompanyUserResultsPage",
)

const { AdminCompanyUserResultPhase1Page } = lazyImport(
  () =>
    import(
      "pages/admin/companies/company_id/users/user_id/results/phase1/phase1_id/"
    ),
  "AdminCompanyUserResultPhase1Page",
)
const { AdminCompanyUserResultPhase1AnswerPage } = lazyImport(
  () =>
    import(
      "pages/admin/companies/company_id/users/user_id/results/phase1/phase1_id/answer"
    ),
  "AdminCompanyUserResultPhase1AnswerPage",
)

const { AdminCompanyUserLPResultPage } = lazyImport(
  () =>
    import("pages/admin/companies/company_id/users/user_id/results/lp/lp_id"),
  "AdminCompanyUserLPResultPage",
)

const { AdminCompanyUserLPAnswerPage } = lazyImport(
  () =>
    import(
      "pages/admin/companies/company_id/users/user_id/results/lp/lp_id/answer"
    ),
  "AdminCompanyUserLPAnswerPage",
)

const { AdminCompanyUserResultPhase2Page } = lazyImport(
  () =>
    import(
      "pages/admin/companies/company_id/users/user_id/results/phase2/phase2_id"
    ),
  "AdminCompanyUserResultPhase2Page",
)

const HOME = "home"
const EDIT = "edit"
const CREATE = "create"
const SETTINGS = "settings"
const EMAIL = "email"
const VERIFY = "verify"
const PENDING = "pending"
const PASSWORD = "password"
const COMPLETE = "complete"
const COMPANIES = "companies"
const COMPANY_ID = "company_id"
const RESULTS = "results"
const USERS = "users"
const USER_ID = "user_id"
const LP = "lp"
const LP_ID = "lp_id"
const PHASE1 = "phase1"
const PHASE1_ID = "phase1_id"
const PHASE2 = "phase2"
const PHASE2_ID = "phase2_id"
const ANSWER = "answer"

export const ADMIN_ROUTE_PATH = {
  HOME: `/${HOME}`,
  COMPANY_EDIT: `/${COMPANIES}/:${COMPANY_ID}/${EDIT}`,
  COMPANIES: `/${COMPANIES}`,
  COMPANY_USERS: `/${COMPANIES}/:${COMPANY_ID}/${USERS}`,
  COMPANY_CREATE: `/${COMPANIES}/${CREATE}`,
  COMPANY_CREATE_COMPLETE: `/${COMPANIES}/${CREATE}/${COMPLETE}`,
  SETTINGS_EMAIL: `/${SETTINGS}/${EMAIL}`,
  SETTINGS_EMAIL_PENDING: `/${SETTINGS}/${EMAIL}/${PENDING}`,
  SETTINGS_EMAIL_VERIFY: `/${SETTINGS}/${EMAIL}/${VERIFY}`,
  SETTINGS_PASSWORD: `/${SETTINGS}/${PASSWORD}`,
  SETTINGS_PASSWORD_COMPLETE: `/${SETTINGS}/${PASSWORD}/${COMPLETE}`,
  COMPANY_USER_DETAIL: `/${COMPANIES}/:${COMPANY_ID}/${USERS}/:${USER_ID}`,
  COMPANY_USER_RESULTS: `/${COMPANIES}/:${COMPANY_ID}/${USERS}/:${USER_ID}/${RESULTS}`,
  COMPANY_USER_RESULTS_LP: `/${COMPANIES}/:${COMPANY_ID}/${USERS}/:${USER_ID}/${RESULTS}/${LP}/:${LP_ID}`,
  COMPANY_USER_RESULTS_LP_ANSWER: `/${COMPANIES}/:${COMPANY_ID}/${USERS}/:${USER_ID}/${RESULTS}/${LP}/:${LP_ID}/${ANSWER}`,
  COMPANY_USER_RESULTS_PHASE1: `/${COMPANIES}/:${COMPANY_ID}/${USERS}/:${USER_ID}/${RESULTS}/${PHASE1}/:${PHASE1_ID}`,
  COMPANY_USER_RESULTS_PHASE1_ANSWER: `/${COMPANIES}/:${COMPANY_ID}/${USERS}/:${USER_ID}/${RESULTS}/${PHASE1}/:${PHASE1_ID}/${ANSWER}`,
  COMPANY_USER_RESULTS_PHASE2: `/${COMPANIES}/:${COMPANY_ID}/${USERS}/:${USER_ID}/${RESULTS}/${PHASE2}/:${PHASE2_ID}`,
}

export function getCompanyUsersPath(companyId: string) {
  return generatePath(ADMIN_ROUTE_PATH.COMPANY_USERS, {
    [COMPANY_ID]: companyId,
  })
}

export function getCompanyEditPath(companyId: string) {
  return generatePath(ADMIN_ROUTE_PATH.COMPANY_EDIT, {
    [COMPANY_ID]: companyId,
  })
}

export function getCompanyUserDetailPath(companyId: string, userId: string) {
  return generatePath(ADMIN_ROUTE_PATH.COMPANY_USER_DETAIL, {
    [COMPANY_ID]: companyId,
    [USER_ID]: userId,
  })
}

export function getCompanyUserResultsPath(companyId: string, userId: string) {
  return generatePath(ADMIN_ROUTE_PATH.COMPANY_USER_RESULTS, {
    [COMPANY_ID]: companyId,
    [USER_ID]: userId,
  })
}

export function getCompanyUserResultLPAnswerPath(
  companyId: string,
  userId: string,
  lpId: number,
) {
  return generatePath(ADMIN_ROUTE_PATH.COMPANY_USER_RESULTS_LP_ANSWER, {
    [COMPANY_ID]: companyId,
    [USER_ID]: userId,
    [LP_ID]: String(lpId),
  })
}

export function getCompanyUserResultLPPath(
  companyId: string,
  userId: string,
  lpId: number,
) {
  return generatePath(ADMIN_ROUTE_PATH.COMPANY_USER_RESULTS_LP, {
    [COMPANY_ID]: companyId,
    [USER_ID]: userId,
    [LP_ID]: String(lpId),
  })
}

export function getCompanyUserResultPhase1Path(
  companyId: string,
  userId: string,
  phase1Id: number,
) {
  return generatePath(ADMIN_ROUTE_PATH.COMPANY_USER_RESULTS_PHASE1, {
    [COMPANY_ID]: companyId,
    [USER_ID]: userId,
    [PHASE1_ID]: String(phase1Id),
  })
}

export function getCompanyUserResultPhase1AnswerPath(
  companyId: string,
  userId: string,
  phase1Id: number,
) {
  return generatePath(ADMIN_ROUTE_PATH.COMPANY_USER_RESULTS_PHASE1_ANSWER, {
    [COMPANY_ID]: companyId,
    [USER_ID]: userId,
    [PHASE1_ID]: String(phase1Id),
  })
}

export function getCompanyUserResultPhase2Path(
  companyId: string,
  userId: string,
  phase2Id: number,
) {
  return generatePath(ADMIN_ROUTE_PATH.COMPANY_USER_RESULTS_PHASE2, {
    [COMPANY_ID]: companyId,
    [USER_ID]: userId,
    [PHASE2_ID]: String(phase2Id),
  })
}

/**
 * 経営承継専用ルーティング
 */
export const adminRoutes: RouteObject[] = [
  {
    path: "/",
    element: <AppCommonLayout />,
    children: [
      {
        path: ADMIN_ROUTE_PATH.HOME,
        element: <AdminHomePage />,
      },
      {
        path: ADMIN_ROUTE_PATH.COMPANY_EDIT,
        element: <AdminEditCompanyPage />,
      },
      {
        path: ADMIN_ROUTE_PATH.COMPANY_CREATE_COMPLETE,
        element: <AdminCreateCompanyCompletedPage />,
      },
      {
        path: ADMIN_ROUTE_PATH.COMPANY_CREATE,
        element: <AdminCreateCompanyPage />,
      },
      {
        path: ADMIN_ROUTE_PATH.COMPANIES,
        element: <AdminCompaniesPage />,
      },
      {
        path: ADMIN_ROUTE_PATH.COMPANY_USERS,
        element: <AdminCompanyUsersPage />,
      },
      {
        path: ADMIN_ROUTE_PATH.COMPANY_USER_RESULTS_PHASE1,
        element: <AdminCompanyUserResultPhase1Page />,
      },
      {
        path: ADMIN_ROUTE_PATH.COMPANY_USER_RESULTS_PHASE1_ANSWER,
        element: <AdminCompanyUserResultPhase1AnswerPage />,
      },
      {
        path: ADMIN_ROUTE_PATH.SETTINGS_EMAIL,
        element: <AdminEmailChangeFormPage />,
      },
      {
        path: ADMIN_ROUTE_PATH.SETTINGS_EMAIL_PENDING,
        element: <AdminEmailChangePendingPage />,
      },
      {
        path: ADMIN_ROUTE_PATH.SETTINGS_EMAIL_VERIFY,
        element: <AdminSettingsEmailVerifyPage />,
      },
      {
        path: ADMIN_ROUTE_PATH.SETTINGS_PASSWORD,
        element: <AdminPasswordFormPage />,
      },
      {
        path: ADMIN_ROUTE_PATH.SETTINGS_PASSWORD_COMPLETE,
        element: <AdminPasswordCompletedPage />,
      },
      {
        path: ADMIN_ROUTE_PATH.COMPANY_USER_DETAIL,
        element: <AdminCompanyUserDetailPage />,
      },
      {
        path: ADMIN_ROUTE_PATH.COMPANY_USER_RESULTS,
        element: <AdminCompanyUserResultsPage />,
      },
      {
        path: ADMIN_ROUTE_PATH.COMPANY_USER_RESULTS_LP,
        element: <AdminCompanyUserLPResultPage />,
      },
      {
        path: ADMIN_ROUTE_PATH.COMPANY_USER_RESULTS_LP_ANSWER,
        element: <AdminCompanyUserLPAnswerPage />,
      },
      {
        path: ADMIN_ROUTE_PATH.COMPANY_USER_RESULTS_PHASE2,
        element: <AdminCompanyUserResultPhase2Page />,
      },
    ],
  },
]
