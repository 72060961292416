import { localStorageFactory } from "./factory"

const SESSION_TIMESTAMP_KEY = "sc.session-timestamp"

/**
 * 最後にAPIサーバーにアクセスした時刻情報をLocalStorageに保存するクライアント
 */
export const sessionTimestampStorage = localStorageFactory<Date>({
  key: SESSION_TIMESTAMP_KEY,
  serializer: (value) => value.toJSON(),
  parser: (value) => new Date(value),
})
