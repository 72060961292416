import { Navigate, RouteObject } from "react-router-dom"
import { AppCommonLayout } from "pages/components/AppCommonLayout"
import { TopLayout } from "pages/invited/components/TopLayout"
import { lazyImport } from "utils"

const { LoginPage } = lazyImport(
  () => import("pages/public/login"),
  "LoginPage",
)
const { LPUserRegisterPage } = lazyImport(
  () => import("pages/public/lp/user_register"),
  "LPUserRegisterPage",
)

const { NotFoundErrorPage } = lazyImport(
  () => import("pages/public/not_found"),
  "NotFoundErrorPage",
)

const { NetworkErrorPage } = lazyImport(
  () => import("pages/public/network_error"),
  "NetworkErrorPage",
)

const { InsufficientIdErrorPage } = lazyImport(
  () => import("pages/public/insufficient_id"),
  "InsufficientIdErrorPage",
)

const { PrivacyPolicyPage } = lazyImport(
  () => import("pages/public/policy"),
  "PrivacyPolicyPage",
)

const { TermsOfServicePage } = lazyImport(
  () => import("pages/public/terms"),
  "TermsOfServicePage",
)

const { PasswordResetPage } = lazyImport(
  () => import("pages/public/password_reset"),
  "PasswordResetPage",
)

const { AboutPage } = lazyImport(
  () => import("pages/public/about"),
  "AboutPage",
)

/**
 * URLパス定数
 * NOTE: Typoなどの回避等
 */
const LOGIN = "login"
const LP = "lp"
const TERMS = "terms"
const POLICY = "policy"
const PASSWORD_REISSUANCE = "password_reissuance"
const USER_REGISTER = "user_register"
const NOT_FOUND = "not_found"
const NETWORK_ERROR = "network_error"
const INSUFFICIENT_ID = "insufficient_id"
const ABOUT = "about"
const KEIEI_SYOKEI_HOME_PAGE = "https://m-succession.jp/"

/**
 * Auth不要のパス
 * NOTE: 絶対パスで記載
 */
export const PUBLIC_ROUTE_PATH = {
  LOGIN: `/${LOGIN}`,
  PASSWORD_RESET: `/${PASSWORD_REISSUANCE}`,
  TERMS_OF_SERVICE: `/${TERMS}`,
  PRIVACY_POLICY: `/${POLICY}`,
  LP_USER_REGISTER: `/${LP}/${USER_REGISTER}`,
  NOT_FOUND: `/${NOT_FOUND}`,
  NETWORK_ERROR: `/${NETWORK_ERROR}`,
  INSUFFICIENT_ID: `/${INSUFFICIENT_ID}`,
  ABOUT: `/${ABOUT}`,
  KEIEI_SYOKEI_HOME_PAGE: KEIEI_SYOKEI_HOME_PAGE,
} as const

/**
 * Auth不要のルーティング
 */
export const publicRoutes: RouteObject[] = [
  {
    path: PUBLIC_ROUTE_PATH.LOGIN,
    element: <TopLayout />,
    children: [
      {
        path: PUBLIC_ROUTE_PATH.LOGIN,
        element: <LoginPage />,
      },
    ],
  },
  {
    path: "/",
    element: <AppCommonLayout />,
    children: [
      {
        index: true,
        element: <Navigate to={PUBLIC_ROUTE_PATH.LOGIN} replace />,
      },
      {
        path: PUBLIC_ROUTE_PATH.PASSWORD_RESET,
        element: <PasswordResetPage />,
      },
      {
        path: PUBLIC_ROUTE_PATH.NOT_FOUND,
        element: <NotFoundErrorPage />,
      },
      {
        path: PUBLIC_ROUTE_PATH.NETWORK_ERROR,
        element: <NetworkErrorPage />,
      },
      {
        path: PUBLIC_ROUTE_PATH.TERMS_OF_SERVICE,
        element: <TermsOfServicePage />,
      },
      {
        path: PUBLIC_ROUTE_PATH.PRIVACY_POLICY,
        element: <PrivacyPolicyPage />,
      },
      {
        path: PUBLIC_ROUTE_PATH.LP_USER_REGISTER,
        element: <LPUserRegisterPage />,
      },
      {
        path: PUBLIC_ROUTE_PATH.INSUFFICIENT_ID,
        element: <InsufficientIdErrorPage />,
      },
      {
        path: PUBLIC_ROUTE_PATH.ABOUT,
        element: <AboutPage />,
      },
      {
        path: "*",
        element: <NotFoundErrorPage />,
      },
    ],
  },
]
