import { useTheme } from "styled-components"
import { SvgCreater } from "utils/SvgCreater"
import { IconProps } from "./interface"

const VIEWBOX_SIZE = 45

export const ScreenRotationIcon = ({ size = 45, ...rest }: IconProps) => {
  const theme = useTheme()
  return (
    <SvgCreater
      size={size}
      fill={theme.color.nobel}
      viewboxSize={VIEWBOX_SIZE}
      {...rest}
    >
      <path d="M30.9 4.725C37.0312 7.63125 41.4188 13.575 42.0938 20.625H44.9063C43.95 9.075 34.2937 0 22.5 0L21.2625 0.05625L28.4062 7.2L30.9 4.725ZM19.1812 3.28125C18.075 2.175 16.2937 2.175 15.2062 3.28125L3.28125 15.2062C2.175 16.3125 2.175 18.0937 3.28125 19.1812L25.8187 41.7188C26.925 42.825 28.7062 42.825 29.7937 41.7188L41.7187 29.7938C42.825 28.6875 42.825 26.9063 41.7187 25.8188L19.1812 3.28125ZM27.8062 39.7313L5.26875 17.1938L17.1937 5.26875L39.7312 27.8062L27.8062 39.7313ZM14.1 40.275C7.96875 37.3875 3.58125 31.425 2.90625 24.375H0.09375C1.05 35.925 10.7062 45 22.5 45L23.7375 44.9437L16.5937 37.8L14.1 40.275Z" />
    </SvgCreater>
  )
}
