import styled from "styled-components"
import { TextWrap } from "components/atoms/TextWrap"
import { YesNoModal } from "./YesNoModal"

interface Props {
  /**
   * はい を押したとき
   */
  onAgree: () => void
  /**
   * キャンセル を押したとき
   */
  onCancel: () => void
}

export const DiscardAnswerModal = ({ onAgree, onCancel }: Props) => {
  return (
    <YesNoModal onAgree={onAgree} onCancel={onCancel}>
      <StyledTextWrap>
        保存していないデータは消去されます。
        <br />
        宜しいでしょうか。
      </StyledTextWrap>
    </YesNoModal>
  )
}

const StyledTextWrap = styled(TextWrap).attrs({ size: "m", weight: "bold" })`
  text-align: center;
`
