import { ManagersGetResponse } from "api/relations/types"
import { axiosClient } from "lib/axios-client"

export const RelationsAPI = {
  /**
   * 担当者一覧を取得する
   */
  fetchManagers: async () => {
    const { data } = await axiosClient.request<ManagersGetResponse>({
      url: "/managers",
      method: "GET",
    })
    return data
  },
} as const
