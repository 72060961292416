import { ArrayNumber } from "api/types/phase2/utils"
import { NumberArrayFormData } from "pages/utils/types/phase2FormData"

/**
 * 数値入力の配列の設問
 */
export const numberArrayDraftTranslator = (
  answer: ArrayNumber | undefined,
): NumberArrayFormData | undefined =>
  answer?.answer?.content?.map(({ content } = {}) => content)
