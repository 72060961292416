import { ArrayString } from "api/types/phase2/utils"
import { StringArrayFormData } from "pages/utils/types/phase2FormData"

interface Args {
  json: {
    question: string
    textbox: {
      label: string[]
    }
  }
  formData: StringArrayFormData
}

/**
 * テキスト入力の配列の設問
 */
export const stringArrayTranslator = ({
  json,
  formData,
}: Args): ArrayString | undefined => {
  if (formData === undefined) return undefined

  return {
    question: json.question,
    answer: {
      type: "array",
      content: json.textbox.label.map((label, index) => ({
        type: "string",
        content: formData[index],
        label: label,
      })),
    },
  } as const
}
