import { useTheme } from "styled-components"
import { SvgCreater } from "utils/SvgCreater"
import { IconProps } from "./interface"

export const NavigateBeforeIcon = ({ size = 24, ...rest }: IconProps) => {
  const theme = useTheme()
  return (
    <SvgCreater size={size} fill={theme.color.nobel} {...rest}>
      <path d="M16.59 15.705L18 14.295L12 8.29504L6 14.295L7.41 15.705L12 11.125L16.59 15.705Z" />
    </SvgCreater>
  )
}
