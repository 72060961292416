import styled, { css } from "styled-components"
import { AppFontSizeSet, AppFontWeightSet } from "theme"
import { HexColor, unreachable } from "utils"

type FontSize = keyof AppFontSizeSet
type FontWeight = keyof AppFontWeightSet
type FontColor = HexColor

interface Props {
  /**
   * 表示するHTMLタグの種類、undefinedならdiv
   */
  type?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span"
  /**
   * fontの大きさ, xxs ~ xlを指定
   */
  size?: FontSize
  /**
   * font-weight, normalかboldを指定
   */
  weight?: FontWeight
  /**
   * 色の指定
   */
  color?: FontColor | "inherit"
  /**
   * 省略する行数
   */
  ellipsis?: 1 | 2 | 3
  /**
   * displayプロパティ デフォルトはblock
   */
  display?: "inline" | "block"
}

const ellipsisCss = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const clampCss = (ellipsis: number) => {
  return css`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${ellipsis};
    overflow: hidden;
  `
}

/**
 * テキストを使用する際の汎用的なコンポーネントです
 */
export const TextWrap = styled.div<Props>`
  word-break: break-all;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  font-size: ${({ size, theme }) => (size ? theme.fontSize[size] : "inherit")};
  line-height: ${({ size, theme }) =>
    size ? theme.lineHeight[size] : "inherit"};
  font-weight: ${({ weight, theme }) =>
    weight ? theme.fontWeight[weight] : "inherit"};
  color: ${({ color, theme }) => (color ? color : theme.color.nightRider)};
  ${({ ellipsis }) =>
    ellipsis &&
    (ellipsis === 1
      ? ellipsisCss
      : ellipsis > 1
      ? clampCss(ellipsis)
      : unreachable("存在しないellipsisの行数を使おうとしています"))}
  display: ${({ display = "block" }) => display};
`
