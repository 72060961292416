import { forwardRef } from "react"
import styled from "styled-components"
import { Tag } from "components/atoms/Tag"

// valueが空の時にクリックするスペースを確保するために全角スペースを表示している
const PLACEHOLDER = "　"

interface Props {
  /**
   * 表示するテキスト
   */
  currentValue: string
  /**
   * マウント時に入っている値
   */
  defaultValue?: string
  /**
   * フォーカスイベントハンドラ
   */
  onFocus: () => void
}

/**
 * 内容が編集可能なAtoms/Tag
 * 横幅を可変にしているので、現在の値を常にpropsに渡す必要がある
 */
export const EditableTag = forwardRef<HTMLInputElement, Props>(
  ({ currentValue, defaultValue, onFocus, ...rest }: Props, ref) => {
    return (
      <Tag size="m">
        <Inner>
          {currentValue ? currentValue : PLACEHOLDER}
          <InvisibleInput
            defaultValue={defaultValue}
            onFocus={onFocus}
            ref={ref}
            {...rest}
          />
        </Inner>
      </Tag>
    )
  },
)
EditableTag.displayName = "EditableTag"

const Inner = styled.div`
  position: relative;
`

const InvisibleInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1em;
  min-height: 1em;
  outline: none;
  color: transparent;
  caret-color: white;
`
