import {
  useAuthPermission,
  useAuthSignoutModal,
  useIsParent,
} from "context/auth"
import { useClientDownloadModal } from "context/downloadPlan"
import { useQuery } from "react-query"
import { useLocation } from "react-router-dom"
import { fetchMyLPResultsQuery } from "api"
import { useAdminRoute } from "hooks/useAdminRoute"
import { useClientRoute } from "hooks/useClientRoute"
import { useSupporterRoute } from "hooks/useSupporterRoute"
import { selectLatestResults } from "pages/utils"
import {
  ADMIN_ROUTE_PATH,
  CLIENT_ROUTE_PATH,
  SUPPORTER_ROUTE_PATH,
} from "routes"
import { noop } from "utils"
import { MenuItem } from "./types"

/**
 * 顧客向けメニュー
 */
export function useClientHeaderMenu(enableDownloadPlan?: boolean): MenuItem[] {
  const {
    goToHome,
    goToConsult,
    goToResults,
    goToSettingsProfile,
    goToSettingsEmail,
    goToSettingsPassword,
    goToContact,
  } = useClientRoute()

  const { pathname } = useLocation()

  const { openModal: openSignoutModal } = useAuthSignoutModal()

  const { openDownloadConfirmModal: openDownloadModal } =
    useClientDownloadModal()

  return [
    {
      type: "leaf",
      label: "マイページトップ",
      visibleFor: "mobile",
      isActive: pathname === CLIENT_ROUTE_PATH.HOME,
      action: goToHome,
    },
    {
      type: "leaf",
      label: "専門家相談",
      isActive: pathname === CLIENT_ROUTE_PATH.CONSULT,
      action: goToConsult,
    },
    {
      type: "leaf",
      label: "診断結果一覧",
      isActive: pathname === CLIENT_ROUTE_PATH.RESULTS,
      action: goToResults,
    },
    {
      // 最新のフェーズ2回答結果がダウンロードできる
      // 計画表が生成されない場合がある
      type: "leaf",
      label: "計画表",
      action: openDownloadModal,
      disabled: !enableDownloadPlan,
    },
    {
      type: "parent",
      label: "アカウント管理",
      children: [
        {
          type: "leaf",
          label: "プロフィール編集",
          isActive: pathname === CLIENT_ROUTE_PATH.SETTINGS_PROFILE,
          action: goToSettingsProfile,
        },
        {
          type: "leaf",
          label: "メールアドレス変更",
          isActive: pathname === CLIENT_ROUTE_PATH.SETTINGS_EMAIL,
          action: goToSettingsEmail,
        },
        {
          type: "leaf",
          label: "パスワード変更",
          isActive: pathname === CLIENT_ROUTE_PATH.SETTINGS_PASSWORD,
          action: goToSettingsPassword,
        },
      ],
    },
    {
      type: "leaf",
      label: "お問い合わせ",
      isActive: pathname === CLIENT_ROUTE_PATH.CONTACT,
      action: goToContact,
    },
    {
      type: "leaf",
      label: "ログアウト",
      action: openSignoutModal,
    },
  ]
}

/**
 * サポート企業向けメニュー
 */
export function useSupporterHeaderMenu(): MenuItem[] {
  const {
    goToHome,
    goToBookmarks,
    goToUserList,
    goToUserSearch,
    goToUserRegister,
    goToSettingsName,
    goToSettingsEmail,
    goToSettingsPassword,
    goToContact,
  } = useSupporterRoute()
  const { pathname } = useLocation()
  const { openModal: openSignoutModal } = useAuthSignoutModal()
  const { isParent } = useIsParent()

  return [
    {
      type: "leaf",
      label: "サポートトップ",
      visibleFor: "mobile",
      isActive: pathname === SUPPORTER_ROUTE_PATH.HOME,
      action: goToHome,
    },
    {
      type: "leaf",
      label: "ブックマーク",
      isActive: pathname === SUPPORTER_ROUTE_PATH.BOOKMARKS,
      action: goToBookmarks,
    },
    {
      type: "leaf",
      label: "顧客一覧",
      isActive: pathname === SUPPORTER_ROUTE_PATH.USER_SEARCH,
      action: goToUserSearch,
    },
    {
      type: "leaf",
      label: "顧客新規登録",
      isActive: pathname === SUPPORTER_ROUTE_PATH.USER_REGISTER,
      action: goToUserRegister,
    },
    {
      type: "parent",
      label: "アカウント管理",
      children: [
        {
          type: "leaf",
          label: "アカウント名変更",
          isActive: pathname === SUPPORTER_ROUTE_PATH.SETTINGS_NAME,
          action: goToSettingsName,
        },
        {
          type: "leaf",
          label: "メールアドレス変更",
          isActive: pathname === SUPPORTER_ROUTE_PATH.SETTINGS_EMAIL,
          action: goToSettingsEmail,
        },
        {
          type: "leaf",
          label: "パスワード変更",
          isActive: pathname === SUPPORTER_ROUTE_PATH.SETTINGS_PASSWORD,
          action: goToSettingsPassword,
        },
        {
          type: "leaf",
          label: "サポートユーザ一覧\n（マスターのみ）",
          action: goToUserList,
          isActive: pathname === SUPPORTER_ROUTE_PATH.USER_LIST,
          disabled: !isParent,
        },
      ],
    },
    {
      type: "leaf",
      label: "お問い合わせ",
      isActive: pathname === SUPPORTER_ROUTE_PATH.CONTACT,
      action: goToContact,
    },
    {
      type: "leaf",
      label: "ログアウト",
      action: openSignoutModal,
    },
  ]
}

/**
 * 管理者向けメニュー
 */
export function useAdminHeaderMenu(): MenuItem[] {
  const { goToHome, goToCompanies, goToSettingsEmail, goToSettingPassword } =
    useAdminRoute()

  const { pathname } = useLocation()
  const { openModal: openSignoutModal } = useAuthSignoutModal()

  return [
    {
      type: "leaf",
      label: "管理者トップ",
      visibleFor: "mobile",
      isActive: pathname === ADMIN_ROUTE_PATH.HOME,
      action: goToHome,
    },
    {
      type: "leaf",
      label: "ブックマーク",
      isActive: false,
      // TODO: 月額会員機能が実装され次第遷移追加
      action: noop,
      disabled: true,
    },
    {
      type: "leaf",
      label: "月額会員一覧",
      isActive: false,
      // TODO: 月額会員機能が実装され次第遷移追加
      action: noop,
      disabled: true,
    },
    {
      type: "leaf",
      label: "サポート会社一覧",
      isActive: pathname === ADMIN_ROUTE_PATH.COMPANIES,
      action: goToCompanies,
    },
    {
      type: "parent",
      label: "アカウント管理",
      children: [
        {
          type: "leaf",
          label: "メールアドレス変更",
          isActive: pathname === ADMIN_ROUTE_PATH.SETTINGS_EMAIL,
          action: goToSettingsEmail,
        },
        {
          type: "leaf",
          label: "パスワード変更",
          isActive: pathname === ADMIN_ROUTE_PATH.SETTINGS_PASSWORD,
          action: goToSettingPassword,
        },
      ],
    },
    {
      type: "leaf",
      label: "ログアウト",
      action: openSignoutModal,
    },
  ]
}

export function useHeaderMenu() {
  const { permission } = useAuthPermission()
  const { data: latestResults } = useQuery({
    ...fetchMyLPResultsQuery,
    select: selectLatestResults,
    enabled: permission === "client",
  })

  const clientMenu = useClientHeaderMenu(latestResults?.phase2?.hasPlan)
  const supporterMenu = useSupporterHeaderMenu()
  const adminMenu = useAdminHeaderMenu()

  return permission === "client"
    ? clientMenu
    : permission === "expert"
    ? supporterMenu
    : permission === "admin"
    ? adminMenu
    : undefined
}
