import { BusinessPlan } from "api/types/phase2/utils"
import { BusinessPlanFormData } from "pages/utils/types/phase2FormData"

/**
 * 事業計画（A Q26, B Q26）
 */
export const businessPlanDraftTranslator = (
  answer: BusinessPlan | undefined,
): BusinessPlanFormData | undefined =>
  answer?.answer?.content?.map(({ sale, ordinary_profit } = {}) => ({
    sales: sale?.content,
    profit: ordinary_profit?.content,
  }))
