import { HTMLAttributes } from "react"
import styled, { useTheme } from "styled-components"
import { TextWrap } from "components/atoms/TextWrap"

const FRAME_BORDER_RADIUS = 20
const FRAME_PADDING_Y = 30
const FRAME_PADDING_X = 21
// TAIL=吹き出しの三角部分
const TAIL_HEIGHT = 20
const TAIL_WIDTH = 22.57

/**
 * 専門家フラグの設定用の吹き出し
 */
export const SpeechBubble = ({
  children,
  ...rest
}: HTMLAttributes<HTMLDivElement>) => {
  const theme = useTheme()
  return (
    <Container {...rest}>
      <TextWrap size="m" weight="bold" color={theme.color.white}>
        {children}
      </TextWrap>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  padding: ${FRAME_PADDING_Y}px ${FRAME_PADDING_X}px;
  background: ${(props) => props.theme.color.pigmentGreen};
  border-radius: ${FRAME_BORDER_RADIUS}px;
  ::after {
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    top: 100%;
    left: 50%;
    border-width: ${TAIL_HEIGHT}px ${TAIL_WIDTH / 2}px 0;
    margin-left: ${-TAIL_WIDTH / 2}px;
    border-color: transparent;
    border-top-color: ${(props) => props.theme.color.pigmentGreen};
  }
`
