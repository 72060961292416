import { AxiosError } from "axios"
import { useMutation, UseMutationOptions } from "react-query"
import { Phase2API } from "api/phase2/api"
import {
  Phase2CreateRequest,
  Phase2CreateResponse,
  Phase2DraftUpdateRequest,
} from "api/phase2/types"
import { OmitQueryConfig } from "lib/react-query"

const PREFIX_ID = "phase2"

export const sendPhase2Mutation: UseMutationOptions<
  Phase2CreateResponse,
  AxiosError,
  Phase2CreateRequest
> = {
  mutationKey: `${PREFIX_ID}/sendResult`,
  mutationFn: Phase2API.sendResult,
}

/**
 * Phase2解答の作成Hooks
 */
export function useSendPhase2ResultMutation(
  config?: OmitQueryConfig<typeof sendPhase2Mutation>,
) {
  return useMutation({ ...sendPhase2Mutation, ...config })
}

/**
 * Phase2の下書きを取得する
 */
export const fetchPhase2DraftQuery = {
  queryKey: `${PREFIX_ID}/fetchDraft`,
  queryFn: Phase2API.fetchDraft,
}

export const updatePhase2DraftMutation: UseMutationOptions<
  void,
  AxiosError,
  Phase2DraftUpdateRequest
> = {
  mutationKey: `${PREFIX_ID}/updateDraft`,
  mutationFn: Phase2API.updateDraft,
}

/**
 * Phase2下書き回答更新
 */
export function useUpdatePhase2DraftMutation(
  config?: OmitQueryConfig<typeof updatePhase2DraftMutation>,
) {
  return useMutation({ ...updatePhase2DraftMutation, ...config })
}

export const deletePhase2DraftMutation: UseMutationOptions<
  void,
  AxiosError,
  void
> = {
  mutationKey: `${PREFIX_ID}/deleteDraft`,
  mutationFn: Phase2API.deleteDraft,
}

/**
 * Phase2下書き削除
 */
export function useDeletePhase2DraftMutation(
  config?: OmitQueryConfig<typeof deletePhase2DraftMutation>,
) {
  return useMutation({ ...deletePhase2DraftMutation, ...config })
}
