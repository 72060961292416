import { axiosClient } from "lib/axios-client"
import {
  CompaniesGetResponse,
  CompanyCreateRequest,
  CompanyDeleteRequest,
  CompanyEditRequest,
  CompanyEditResponse,
  CompanyGetRequest,
  CompanyGetResponse,
  CompanyUsersGetRequest,
  CompanyUsersGetResponse,
} from "./types"

export const CompanyAPI = {
  /**
   * サポート会社一覧を取得する
   */
  fetchCompanies: async () => {
    const { data } = await axiosClient.request<CompaniesGetResponse>({
      url: "/companies",
      method: "GET",
    })
    return data
  },

  /**
   * サポート会社を追加する
   */
  createCompany: async (payload: CompanyCreateRequest) => {
    await axiosClient.request({
      url: "/companies",
      method: "POST",
      data: payload,
    })
  },

  /**
   * サポート会社の情報を取得する
   */
  fetchCompany: async (company_id: CompanyGetRequest) => {
    const { data } = await axiosClient.request<CompanyGetResponse>({
      url: `/companies/${company_id}`,
      method: "GET",
    })
    return data
  },

  /**
   * サポート会社を編集する
   */
  editCompany: async ({ payload, company_id }: CompanyEditRequest) => {
    const { data } = await axiosClient.request<CompanyEditResponse>({
      url: `/companies/${company_id}`,
      method: "PATCH",
      data: payload,
    })
    return data
  },

  /**
   * サポート会社を論理削除する
   */
  deleteCompany: async (company_id: CompanyDeleteRequest) => {
    await axiosClient.request({
      url: `/companies/${company_id}/delete`,
      method: "PATCH",
    })
  },

  /**
   * サポート会社の顧客一覧
   */
  fetchCompanyUsers: async ({ company_id, params }: CompanyUsersGetRequest) => {
    const { data } = await axiosClient.request<CompanyUsersGetResponse>({
      url: `/companies/${company_id}/users`,
      method: "GET",
      params: params,
    })
    return data
  },
}
