import * as yup from "yup"
import {
  Phase1QuestionId,
  Phase1QuestionConfig,
} from "pages/components/phase1/utils/types"
import { lazyImport, unreachable } from "utils"

// Lazyimport
const { Phase1Question0 } = lazyImport(
  () => import("pages/components/phase1/components/Phase1Question0"),
  "Phase1Question0",
)

const { Phase1Question1 } = lazyImport(
  () => import("pages/components/phase1/components/Phase1Question1"),
  "Phase1Question1",
)

const { Phase1Question2 } = lazyImport(
  () => import("pages/components/phase1/components/Phase1Question2"),
  "Phase1Question2",
)

const { Phase1Question3 } = lazyImport(
  () => import("pages/components/phase1/components/Phase1Question3"),
  "Phase1Question3",
)

const { Phase1Question4 } = lazyImport(
  () => import("pages/components/phase1/components/Phase1Question4"),
  "Phase1Question4",
)

const { Phase1Question5 } = lazyImport(
  () => import("pages/components/phase1/components/Phase1Question5"),
  "Phase1Question5",
)

const { Phase1Question6 } = lazyImport(
  () => import("pages/components/phase1/components/Phase1Question6"),
  "Phase1Question6",
)

const { Phase1Question7 } = lazyImport(
  () => import("pages/components/phase1/components/Phase1Question7"),
  "Phase1Question7",
)

const { Phase1Question8_1 } = lazyImport(
  () => import("pages/components/phase1/components/Phase1Question8_1"),
  "Phase1Question8_1",
)

const { Phase1Question8_2 } = lazyImport(
  () => import("pages/components/phase1/components/Phase1Question8_2"),
  "Phase1Question8_2",
)

const { Phase1Question9 } = lazyImport(
  () => import("pages/components/phase1/components/Phase1Question9"),
  "Phase1Question9",
)

const { Phase1Question10 } = lazyImport(
  () => import("pages/components/phase1/components/Phase1Question10"),
  "Phase1Question10",
)

const { Phase1Question11 } = lazyImport(
  () => import("pages/components/phase1/components/Phase1Question11"),
  "Phase1Question11",
)

const { Phase1Question12 } = lazyImport(
  () => import("pages/components/phase1/components/Phase1Question12"),
  "Phase1Question12",
)

// MEMO: Phase1のページを作成次第、lazyimportしてelementに追加する
export const Phase1QuestionConfigs: Record<
  Phase1QuestionId,
  Phase1QuestionConfig
> = {
  q0: {
    schema: yup.number().required(),
    element: <Phase1Question0 />,
    next: Phase1QuestionId.q1,
    back: "home",
  },
  q1: {
    schema: yup.array(yup.number()).min(1).required(),
    element: <Phase1Question1 />,
    next: (formData) => {
      if (formData.q1 === undefined) {
        return undefined
      }

      return formData.q1.includes(5) ? Phase1QuestionId.q2 : Phase1QuestionId.q6
    },
    back: Phase1QuestionId.q0,
  },
  q2: {
    schema: yup.number().required(),
    element: <Phase1Question2 />,
    next: (formData, category) => {
      if (formData.q2 === undefined) {
        return undefined
      }
      if (category === "A" || category === "U") {
        return formData.q2 === 1
          ? Phase1QuestionId.q3
          : formData.q2 === 2
          ? "result"
          : formData.q2 === 3
          ? Phase1QuestionId.q9
          : undefined
      }
      if (category === "I" || category === "E") {
        return formData.q2 === 1
          ? Phase1QuestionId.q3
          : formData.q2 === 2
          ? Phase1QuestionId.q12
          : undefined
      }
    },
    back: Phase1QuestionId.q1,
    diagnosis: (formData, category) => {
      if (category === "A" || category === "U") {
        if (formData.q2 === 2) {
          return "C"
        }
      }
    },
  },
  q3: {
    schema: yup.number().required(),
    element: <Phase1Question3 />,
    next: (formData, category) => {
      if (formData.q3 === undefined) {
        return undefined
      }
      if (category === "A" || category === "U") {
        return formData.q3 === 1
          ? Phase1QuestionId.q4
          : formData.q3 === 2
          ? Phase1QuestionId.q5
          : formData.q3 === 3
          ? "result"
          : undefined
      }
      if (category === "I" || category === "E") {
        return formData.q3 === 1
          ? Phase1QuestionId.q4
          : formData.q3 === 2
          ? Phase1QuestionId.q5
          : formData.q3 === 3
          ? Phase1QuestionId.q12
          : undefined
      }
    },
    back: (formData) => {
      if (formData.q2 === 1) {
        return Phase1QuestionId.q2
      }
      return Phase1QuestionId.q8_1
    },
    diagnosis: (formData, category) => {
      if (category === "A" || category === "U") {
        if (formData.q3 === 3) {
          return "C"
        }
      }
    },
  },
  q4: {
    schema: yup.number().required(),
    element: <Phase1Question4 />,
    next: "result",
    back: (formData) => {
      if (formData.q3 === 1) {
        return Phase1QuestionId.q3
      }
      if (formData.q5 === undefined) {
        return undefined
      }
      return formData.q5 === 1
        ? Phase1QuestionId.q5
        : formData.q5 === 2
        ? Phase1QuestionId.q5
        : undefined
    },
    diagnosis: (formData) => {
      if (formData.q4 === 1) {
        return "A"
      }
      if (formData.q4 === 2) {
        return "B"
      }
    },
  },
  q5: {
    schema: yup.number().required(),
    element: <Phase1Question5 />,
    next: (formData, category) => {
      if (formData.q5 === undefined) {
        return undefined
      }
      if (category === "A" || category === "U") {
        return formData.q5 === 1
          ? Phase1QuestionId.q4
          : formData.q5 === 2
          ? Phase1QuestionId.q4
          : formData.q5 === 3
          ? "result"
          : undefined
      }
      if (category === "I" || category === "E") {
        return formData.q5 === 1
          ? Phase1QuestionId.q4
          : formData.q5 === 2
          ? Phase1QuestionId.q4
          : formData.q5 === 3
          ? Phase1QuestionId.q12
          : undefined
      }
    },
    back: (formData) => {
      if (formData.q3 === undefined) {
        return undefined
      }
      if (formData.q3 === 2) {
        return Phase1QuestionId.q3
      }
    },
    diagnosis: (formData, category) => {
      if (category === "A" || category === "U") {
        if (formData.q5 === 3) {
          return "C"
        }
      }
    },
  },
  q6: {
    schema: yup.number().required(),
    element: <Phase1Question6 />,
    next: (formData) => {
      if (formData.q6 === undefined) {
        return undefined
      }
      return formData.q6 === 1
        ? Phase1QuestionId.q7
        : formData.q6 === 2
        ? Phase1QuestionId.q9
        : undefined
    },
    back: Phase1QuestionId.q1,
  },
  q7: {
    schema: yup.number().required(),
    element: <Phase1Question7 />,
    next: (formData) => {
      if (formData.q7 === undefined) {
        return undefined
      }
      return formData.q7 === 1
        ? Phase1QuestionId.q8_1
        : formData.q7 === 2
        ? Phase1QuestionId.q9
        : undefined
    },
    back: Phase1QuestionId.q6,
  },
  q8_1: {
    schema: yup
      .object()
      .shape({
        deposit: yup.number().required(),
        ordinaryProfit: yup.number().required(),
        depreciation: yup.number().required(),
        debtPrincipal: yup.number().required(),
      })
      .required(),
    element: <Phase1Question8_1 />,
    next: (_, __, reconstructionYear) => {
      if (reconstructionYear === undefined) {
        return unreachable("reconstructionYearが未設定です")
      }

      if (reconstructionYear >= 10) {
        return Phase1QuestionId.q3
      }
      return Phase1QuestionId.q8_2
    },
    back: Phase1QuestionId.q7,
  },
  q8_2: {
    schema: yup.number().required(),
    element: <Phase1Question8_2 />,
    next: (formData) => {
      if (formData.q8_2 === undefined) {
        return undefined
      }
      return formData.q8_2 === 1
        ? "result"
        : formData.q8_2 === 2
        ? Phase1QuestionId.q9
        : undefined
    },
    back: Phase1QuestionId.q8_1,
    diagnosis: (formData) => {
      if (formData.q8_2 === 1) {
        return "D"
      }
    },
  },
  q9: {
    schema: yup.number().required(),
    element: <Phase1Question9 />,
    next: (formData, category) => {
      if (formData.q9 === undefined) {
        return undefined
      }
      if (category === "A" || category === "U") {
        return formData.q9 === 1
          ? Phase1QuestionId.q11
          : formData.q9 === 2
          ? "result"
          : formData.q9 === 3
          ? "result"
          : formData.q9 === 4
          ? "result"
          : formData.q9 === 5
          ? "result"
          : undefined
      }
      if (category === "I" || category === "E") {
        return formData.q9 === 1
          ? Phase1QuestionId.q10
          : formData.q9 === 2
          ? "result"
          : formData.q9 === 3
          ? "result"
          : formData.q9 === 4
          ? "result"
          : formData.q9 === 5
          ? "result"
          : undefined
      }
    },
    back: (formData, category) => {
      if (formData.q7 == 2) {
        return Phase1QuestionId.q7
      }
      if (formData.q8_2 === 2) {
        return Phase1QuestionId.q8_2
      }
      if (formData.q6 === 2) {
        return Phase1QuestionId.q6
      }
      if (category === "A" || category === "U") {
        if (formData.q2 === 3) {
          return Phase1QuestionId.q2
        }
      }
    },
    diagnosis: (formData) => {
      if (formData.q9 === 2) {
        return "G"
      }
      if (formData.q9 === 3) {
        return "F"
      }
      if (formData.q9 === 4) {
        return "H"
      }
      if (formData.q9 === 5) {
        return "C"
      }
    },
  },
  q10: {
    schema: yup.number().required(),
    element: <Phase1Question10 />,
    next: (formData) => {
      if (formData.q10 === undefined) {
        return undefined
      }
      return formData.q10 === 1
        ? Phase1QuestionId.q11
        : formData.q10 === 2
        ? "result"
        : undefined
    },
    back: (formData, category) => {
      if (category === "I" || category === "E") {
        if (formData.q9 === 1) {
          return Phase1QuestionId.q9
        }
      }
    },
    diagnosis: (formData) => {
      if (formData.q10 === 2) {
        return "G"
      }
    },
  },
  q11: {
    schema: yup.number().required(),
    element: <Phase1Question11 />,
    next: "result",
    back: (formData, category) => {
      if (formData.q10 === 1) {
        return Phase1QuestionId.q10
      }
      if (category === "A" || category === "U") {
        if (formData.q9 === 1) {
          return Phase1QuestionId.q9
        }
      }
    },
    diagnosis: (formData) => {
      if (formData.q11 === 1) {
        return "E"
      }
      if (formData.q11 === 2) {
        return "G"
      }
    },
  },
  q12: {
    schema: yup.number().required(),
    element: <Phase1Question12 />,
    next: "result",
    back: (formData, category) => {
      if (category === "I" || category === "E") {
        if (formData.q2 === 2) {
          return Phase1QuestionId.q2
        }
        if (formData.q3 === 3) {
          return Phase1QuestionId.q3
        }
        if (formData.q5 === 3) {
          return Phase1QuestionId.q5
        }
      }
    },
    diagnosis: (formData) => {
      if (formData.q12 === 1) {
        return "C"
      }
      if (formData.q12 === 2) {
        return "G"
      }
    },
  },
}
