import { AxiosError } from "axios"
import { useMutation, UseMutationOptions } from "react-query"
import {
  ExpertNameUpdateRequest,
  ExpertNameUpdateResponse,
} from "api/expert/types"
import { OmitMutationConfig } from "lib/react-query"
import { ExpertAPI } from "./api"

const PREFIX_ID = "expert"

export const fetchExpertQuery = {
  queryKey: `${PREFIX_ID}/fetchExpert`,
  queryFn: ExpertAPI.fetchExpert,
} as const

export const updateExpertNameMutation: UseMutationOptions<
  ExpertNameUpdateResponse,
  AxiosError,
  ExpertNameUpdateRequest
> = {
  mutationKey: `${PREFIX_ID}/updateExpertName`,
  mutationFn: ExpertAPI.updateExpertName,
}

/**
 * 専門家の名前を更新するHooks
 */
export function useUpdateExpertNameMutation(
  config?: OmitMutationConfig<typeof updateExpertNameMutation>,
) {
  return useMutation({ ...updateExpertNameMutation, ...config })
}

export const fetchExpertStatisticsOfPhaseQuery = {
  queryKey: `${PREFIX_ID}/fetchExpertStatisticsOfPhase`,
  queryFn: ExpertAPI.fetchExpertStatisticsOfPhase,
} as const

export const fetchExpertStatisticsOfSalesQuery = {
  queryKey: `${PREFIX_ID}/fetchExpertStatisticsOfSales`,
  queryFn: ExpertAPI.fetchExpertStatisticsOfSales,
} as const
