import { axiosClient } from "lib/axios-client"
import { ContactCreateRequest, ContactCreateResponse } from "./types"

export const ContactAPI = {
  /**
   * 問い合わせを送信する
   * @param payload
   * @returns ContactCreateResponse
   */
  createContact: async (payload: ContactCreateRequest) => {
    const { data } = await axiosClient.request<ContactCreateResponse>({
      url: "/contact",
      method: "POST",
      data: payload,
    })
    return data
  },
} as const
