import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { useClientRoute } from "hooks/useClientRoute"
import { Phase1QuestionConfigs } from "pages/components/phase1/utils/confings"
import { usePhase1DiagnosisSetter } from "pages/components/phase1/utils/hooks/usePhase1FormData"
import {
  Phase1Category,
  Phase1FormData,
  Phase1QuestionId,
} from "pages/components/phase1/utils/types"
import { unreachable } from "utils"

/**
 * 現在表示中の質問IDの取得、ReactNodeの取得を行うHooks
 */
export function usePhase1Route(questionId: Phase1QuestionId) {
  const questionConfig = Phase1QuestionConfigs[questionId]
  const navigate = useNavigate()
  const { goToPhase1, goToPhase1Confirm, goToHome } = useClientRoute()
  const { setDiagnosis } = usePhase1DiagnosisSetter()

  const goToNext = useCallback(
    (
      formData?: Phase1FormData,
      category?: Phase1Category,
      reconstructionYear?: number,
    ) => {
      if (questionConfig === undefined) {
        return
      }

      const { next, diagnosis } = questionConfig

      const nextQuesionId =
        typeof next === "string"
          ? next
          : typeof next === "function" && formData
          ? next(formData, category, reconstructionYear)
          : unreachable("goToNextに問題があります")

      if (nextQuesionId === "result") {
        if (diagnosis !== undefined && formData !== undefined) {
          setDiagnosis(diagnosis(formData, category))
        }
        goToPhase1Confirm()
        return
      }
      goToPhase1(nextQuesionId)
    },
    [goToPhase1, questionConfig, goToPhase1Confirm, setDiagnosis],
  )

  const goToPrev = useCallback(
    (formData?: Phase1FormData, category?: Phase1Category) => {
      const { back } = questionConfig

      const backQuestionId =
        typeof back === "string"
          ? back
          : typeof back === "function" && formData
          ? back(formData, category)
          : unreachable("goToPrevに問題があります")

      if (backQuestionId === undefined) {
        navigate(-1)
        return
      }

      if (backQuestionId === "home") {
        goToHome()
      } else {
        goToPhase1(backQuestionId)
      }
    },
    [navigate, questionConfig, goToPhase1, goToHome],
  )

  return { ...questionConfig, goToNext, goToPrev }
}
