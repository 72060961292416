import { useAuthPermission } from "context/auth"
import { LPQuestionId, questionConfigs } from ".."

// 2回目以降はパスされる問題数
const FIRST_TIME_ONLY_NUMBER = 3
const numberOfQuestions = Object.keys(questionConfigs).length

/**
 * LPのプログレスバーの情報を渡す
 */
export const useLPProgress = (questionId: LPQuestionId | undefined) => {
  const { permission } = useAuthPermission()

  if (questionId === undefined) {
    return
  }

  // permissionがclientの場合は2回目以降なので、省略される問題数を引くことでプログレスバーが途中から始まることを防いでいる
  const maxIndex =
    permission === "client"
      ? numberOfQuestions - FIRST_TIME_ONLY_NUMBER
      : numberOfQuestions
  const { index } = questionConfigs[questionId]
  const currentIndex =
    permission === "client" ? index - FIRST_TIME_ONLY_NUMBER : index

  return { maxIndex, currentIndex }
}
