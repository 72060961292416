import { HTMLAttributes } from "react"
import styled, { css } from "styled-components"
import { unreachable, HexColor } from "utils"

interface Props extends HTMLAttributes<HTMLElement> {
  size?: number
  viewboxSize?: number
  fillRule?: "nonzero" | "evenodd" | "inherit"
  clipRule?: "nonzero" | "evenodd"
  fill?: "none" | "currentColor" | HexColor
  stroke?: "none" | "currentColor" | HexColor
  strokeWidth?: number
}

/**
 * svg要素を作成するコンポーネント
 * SvgCreatorで生成されるSVGIconはブロック要素として扱われる
 * インラインで扱いたい場合は親要素にFlexBoxを用いる
 */
export const SvgCreater = ({
  children,
  size = 24,
  viewboxSize = 24,
  fillRule,
  clipRule,
  fill,
  stroke = "none",
  strokeWidth,
  ...rest
}: Props) => {
  if (size <= 0) {
    unreachable()
  }
  return (
    <Container size={size} {...rest}>
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${viewboxSize} ${viewboxSize}`}
        fillRule={fillRule}
        clipRule={clipRule}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        preserveAspectRatio="xMidYMid meet"
      >
        {children}
      </svg>
    </Container>
  )
}

const Container = styled.div<{ size: number }>`
  display: flex;
  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
`
