import { AxiosError } from "axios"
import { useMutation, UseMutationOptions } from "react-query"
import { DownloadAPI } from "api/download/api"
import { OmitMutationConfig } from "lib/react-query"
import {
  CsvDownloadGenerateResponse,
  CsvDownloadGenerateRequest,
  LpDownloadGetResponse,
  UserLpDownloadGetResponse,
  UserLpDownloadGetRequest,
  LpId,
  Phase2DownloadGetResponse,
  UserPhase2DownloadGetRequest,
  UserPhase2DownloadGetResponse,
} from "./types"

const PREFIX_ID = "download"

export const generateLpDownloadMutation: UseMutationOptions<
  LpDownloadGetResponse,
  AxiosError,
  LpId
> = {
  mutationKey: `${PREFIX_ID}/generateLpPdfLink`,
  mutationFn: DownloadAPI.generateLpPdfLink,
}

/**
 * ライフプランPDFのダウンロードリンクを発行するHooks
 */
export function useGenerateLpDownloadMutation(
  config?: OmitMutationConfig<typeof generateLpDownloadMutation>,
) {
  return useMutation({ ...generateLpDownloadMutation, ...config })
}

export const generateUserLpDownloadMutation: UseMutationOptions<
  UserLpDownloadGetResponse,
  AxiosError,
  UserLpDownloadGetRequest
> = {
  mutationKey: `${PREFIX_ID}/generateLpPdfLink`,
  mutationFn: DownloadAPI.generateUserLpPdfLink,
}

/**
 * 特定ユーザのライフプランPDFのダウンロードリンクを発行するHooks
 */
export function useGenerateUserLpDownloadMutation(
  config?: OmitMutationConfig<typeof generateUserLpDownloadMutation>,
) {
  return useMutation({ ...generateUserLpDownloadMutation, ...config })
}

export const generateCsvDownloadMutation: UseMutationOptions<
  CsvDownloadGenerateResponse,
  AxiosError,
  CsvDownloadGenerateRequest
> = {
  mutationKey: `${PREFIX_ID}/generateCsvDownload`,
  mutationFn: DownloadAPI.generateCsv,
}

/**
 * 顧客情報CSVを生成しダウンロードするHooks
 */
export function useGenerateCsvDownloadMutation(
  config?: OmitMutationConfig<typeof generateCsvDownloadMutation>,
) {
  return useMutation({ ...generateCsvDownloadMutation, ...config })
}

export const generatePhase2ExcelLinkMutation: UseMutationOptions<
  Phase2DownloadGetResponse,
  AxiosError,
  number
> = {
  mutationKey: `${PREFIX_ID}/generatePhase2ExcelLink`,
  mutationFn: DownloadAPI.generatePhase2ExcelLink,
}

/**
 * フェーズ2の計画書Excelをダウンロードするhooks
 */
export function useGeneratePhase2ExcelLinkMutation(
  config?: OmitMutationConfig<typeof generatePhase2ExcelLinkMutation>,
) {
  return useMutation({ ...generatePhase2ExcelLinkMutation, ...config })
}

export const generateUserPhase2ExcelLinkMutation: UseMutationOptions<
  UserPhase2DownloadGetResponse,
  AxiosError,
  UserPhase2DownloadGetRequest
> = {
  mutationKey: `${PREFIX_ID}/generateUserPhase2ExcelLink`,
  mutationFn: DownloadAPI.generateUserPhase2ExcelLink,
}

/**
 * 特定のフェーズ2の計画書Excelをダウンロードするhooks
 */
export function useGenerateUserPhase2ExcelLinkMutation(
  config?: OmitMutationConfig<typeof generateUserPhase2ExcelLinkMutation>,
) {
  return useMutation({ ...generateUserPhase2ExcelLinkMutation, ...config })
}
