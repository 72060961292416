import { QueryClient } from "react-query"

/**
 * React-query Client
 * MEMO: defaultOptionsはApp.tsx内で定義
 */
export const queryClient = new QueryClient()

export type OmitQueryConfig<T> = Omit<T, "queryKey" | "queryFn">

export type OmitMutationConfig<T> = Omit<T, "mutationFn" | "mutationKey">
