import { useMediaQuery } from "react-responsive"
import { appBreakpoints } from "theme"

export function useMediaLessThan(viewport: keyof typeof appBreakpoints) {
  return useMediaQuery({ maxWidth: appBreakpoints[viewport] })
}

export function useMediaGreaterThan(viewport: keyof typeof appBreakpoints) {
  return useMediaQuery({ minWidth: appBreakpoints[viewport] })
}
