import { QA, StringTypeAnswer } from "api"
import { StringFormData } from "pages/utils/types/phase2FormData"
import { DeepPartial } from "utils"

interface Args {
  json: {
    question: string
  }
  formData: StringFormData
}

/**
 * テキスト入力の設問
 */
export const stringTranslator = ({
  json,
  formData,
}: Args): DeepPartial<QA<StringTypeAnswer>> | undefined => {
  if (!formData) return undefined

  return {
    question: json.question,
    answer: {
      type: "string",
      content: formData,
    },
  } as const
}
