import { ConcernAndSolution } from "api/types/phase2/utils"
import { ConcernAndSolutionFormData } from "pages/utils/types/phase2FormData"

interface Args {
  json: {
    question: string
    textbox: {
      label: {
        concern: string
        solution: string
      }
    }
  }
  formData: ConcernAndSolutionFormData
}

/**
 * 不安要素と解決策 あD Q25, あG Q25 Q32
 */
export const concernAndSolutionTranslator = ({
  json,
  formData,
}: Args): ConcernAndSolution | undefined => {
  if (formData === undefined) return undefined

  const content = formData
    .map(({ concern, solution } = {}) => {
      if (!concern && !solution) return

      return {
        concern: {
          type: "string",
          content: concern,
          label: json.textbox.label.concern,
        },
        solution: {
          type: "string",
          content: solution,
          label: json.textbox.label.solution,
        },
      } as const
    })
    .filter((item) => item !== undefined)

  if (content.length === 0) {
    return
  }

  return {
    question: json.question,
    answer: {
      type: "array",
      content: content,
    },
  } as const
}
