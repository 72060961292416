import { Phase2AnswerAB } from "api"
import { Phase2ABFormData } from "pages/components/phase2/components/AB/schema"
import { DeepPartial } from "utils"
import {
  selectDraftTranslator,
  dateDraftTranslator,
  stringArrayDraftTranslator,
  stringDraftTranslator,
  nameAndBirthdayDraftTranslator,
  selectAndDateDraftTranslator,
  transferringStockDraftTranslator,
  businessPlanDraftTranslator,
  bankBorrowingAmountAndNetWorthDraftTranslator,
} from "./utils"

/**
 * 下書きを受けとってformDataの形に変換する
 */
export const phase2DraftABTranslator = (
  answer: Phase2AnswerAB,
): DeepPartial<Phase2ABFormData> => ({
  q1: selectDraftTranslator(answer.question_group_1?.answers?.answer_01),
  ab: {
    q2: dateDraftTranslator(answer.question_group_2?.answers?.answer_02),
    q3: stringArrayDraftTranslator(answer.question_group_2?.answers?.answer_03),
    q4: selectDraftTranslator(answer.question_group_2?.answers?.answer_04),
    q5: dateDraftTranslator(answer.question_group_2?.answers?.answer_05),
    q6: selectDraftTranslator(answer.question_group_2?.answers?.answer_06),
    q7: dateDraftTranslator(answer.question_group_2?.answers?.answer_07),
    q8: dateDraftTranslator(answer.question_group_2?.answers?.answer_08),
    q9: stringDraftTranslator(answer.question_group_2?.answers?.answer_09),
  },
  a: {
    q0: nameAndBirthdayDraftTranslator(
      answer.question_group_3?.answers?.answer_10,
    ),
    q1: selectAndDateDraftTranslator(
      answer.question_group_3?.answers?.answer_11,
    ),
    q2: selectAndDateDraftTranslator(
      answer.question_group_3?.answers?.answer_12,
    ),
    q3: selectAndDateDraftTranslator(
      answer.question_group_3?.answers?.answer_13,
    ),
    q4: selectAndDateDraftTranslator(
      answer.question_group_3?.answers?.answer_14,
    ),
    q5: selectAndDateDraftTranslator(
      answer.question_group_4?.answers?.answer_15,
    ),
    q6: selectDraftTranslator(answer.question_group_4?.answers?.answer_16),
    q7: selectDraftTranslator(answer.question_group_4?.answers?.answer_17),
    q8: selectAndDateDraftTranslator(
      answer.question_group_5?.answers?.answer_18,
    ),
    q9: selectAndDateDraftTranslator(
      answer.question_group_5?.answers?.answer_19,
    ),
    q10: selectAndDateDraftTranslator(
      answer.question_group_5?.answers?.answer_20,
    ),
    q11: selectAndDateDraftTranslator(
      answer.question_group_5?.answers?.answer_21,
    ),
    q12: selectDraftTranslator(answer.question_group_5?.answers?.answer_22),
    q13: selectAndDateDraftTranslator(
      answer.question_group_5?.answers?.answer_23,
    ),
    q14: selectAndDateDraftTranslator(
      answer.question_group_5?.answers?.answer_24,
    ),
    q15: selectDraftTranslator(answer.question_group_5?.answers?.answer_25),
    q16: selectAndDateDraftTranslator(
      answer.question_group_5?.answers?.answer_26,
    ),
    q17: selectDraftTranslator(answer.question_group_5?.answers?.answer_27),
    q18: selectAndDateDraftTranslator(
      answer.question_group_5?.answers?.answer_28,
    ),
    q19: selectAndDateDraftTranslator(
      answer.question_group_5?.answers?.answer_29,
    ),
    q20: selectAndDateDraftTranslator(
      answer.question_group_5?.answers?.answer_30,
    ),
    q21: selectAndDateDraftTranslator(
      answer.question_group_5?.answers?.answer_31,
    ),
    q22: selectDraftTranslator(answer.question_group_6?.answers?.answer_32),
    q23: transferringStockDraftTranslator(
      answer.question_group_6?.answers?.answer_33,
    ),
    q24: selectAndDateDraftTranslator(
      answer.question_group_6?.answers?.answer_34,
    ),
    q25: selectAndDateDraftTranslator(
      answer.question_group_6?.answers?.answer_35,
    ),
    q26: businessPlanDraftTranslator(
      answer.question_group_6?.answers?.answer_36,
    ),
    q27: dateDraftTranslator(answer.question_group_6?.answers?.answer_37),
    q28: selectAndDateDraftTranslator(
      answer.question_group_6?.answers?.answer_38,
    ),
    q29: selectDraftTranslator(answer.question_group_6?.answers?.answer_39),
    q30: stringDraftTranslator(answer.question_group_6?.answers?.answer_40),
    q31: stringDraftTranslator(answer.question_group_6?.answers?.answer_41),
    q32: bankBorrowingAmountAndNetWorthDraftTranslator(
      answer.question_group_6?.answers?.answer_42,
    ),
  },
  b: {
    q0: nameAndBirthdayDraftTranslator(
      answer.question_group_7?.answers?.answer_43,
    ),
    q1: selectAndDateDraftTranslator(
      answer.question_group_7?.answers?.answer_44,
    ),
    q2: selectAndDateDraftTranslator(
      answer.question_group_7?.answers?.answer_45,
    ),
    q3: selectAndDateDraftTranslator(
      answer.question_group_7?.answers?.answer_46,
    ),
    q4: selectAndDateDraftTranslator(
      answer.question_group_7?.answers?.answer_47,
    ),
    q5: selectAndDateDraftTranslator(
      answer.question_group_8?.answers?.answer_48,
    ),
    q6: selectDraftTranslator(answer.question_group_8?.answers?.answer_49),
    q7: selectDraftTranslator(answer.question_group_8?.answers?.answer_50),
    q8: selectAndDateDraftTranslator(
      answer.question_group_9?.answers?.answer_51,
    ),
    q9: selectAndDateDraftTranslator(
      answer.question_group_9?.answers?.answer_52,
    ),
    q10: selectAndDateDraftTranslator(
      answer.question_group_9?.answers?.answer_53,
    ),
    q11: selectAndDateDraftTranslator(
      answer.question_group_9?.answers?.answer_54,
    ),
    q12: selectDraftTranslator(answer.question_group_9?.answers?.answer_55),
    q13: selectAndDateDraftTranslator(
      answer.question_group_9?.answers?.answer_56,
    ),
    q14: selectAndDateDraftTranslator(
      answer.question_group_9?.answers?.answer_57,
    ),
    q15: selectDraftTranslator(answer.question_group_9?.answers?.answer_58),
    q16: selectAndDateDraftTranslator(
      answer.question_group_9?.answers?.answer_59,
    ),
    q17: selectDraftTranslator(answer.question_group_9?.answers?.answer_60),
    q18: selectAndDateDraftTranslator(
      answer.question_group_9?.answers?.answer_61,
    ),
    q19: selectAndDateDraftTranslator(
      answer.question_group_9?.answers?.answer_62,
    ),
    q20: selectAndDateDraftTranslator(
      answer.question_group_9?.answers?.answer_63,
    ),
    q21: selectAndDateDraftTranslator(
      answer.question_group_9?.answers?.answer_64,
    ),
    q22: selectDraftTranslator(answer.question_group_10?.answers?.answer_65),
    q23: transferringStockDraftTranslator(
      answer.question_group_10?.answers?.answer_66,
    ),
    q24: selectAndDateDraftTranslator(
      answer.question_group_10?.answers?.answer_67,
    ),
    q25: selectAndDateDraftTranslator(
      answer.question_group_10?.answers?.answer_68,
    ),
    q26: businessPlanDraftTranslator(
      answer.question_group_10?.answers?.answer_69,
    ),
    q27: dateDraftTranslator(answer.question_group_10?.answers?.answer_70),
    q28: selectAndDateDraftTranslator(
      answer.question_group_10?.answers?.answer_71,
    ),
    q29: selectDraftTranslator(answer.question_group_10?.answers?.answer_72),
    q30: stringDraftTranslator(answer.question_group_10?.answers?.answer_73),
    q31: stringDraftTranslator(answer.question_group_10?.answers?.answer_74),
    q32: bankBorrowingAmountAndNetWorthDraftTranslator(
      answer.question_group_10?.answers?.answer_75,
    ),
  },
})
