import { useCallback } from "react"
import { useInvId } from "context/invitationId"
import { useNavigate } from "react-router-dom"
import { useClientRoute } from "hooks/useClientRoute"
import { useInvitedRoute } from "hooks/useInvitedRoute"
import { questionConfigs } from "../configs"
import { LPFormData, LPQuestionId } from "../types"

/**
 * 特定の質問に飛んだり、現在表示中の質問IDの取得、ReactNodeの取得を行うHooks
 */
export function useLPRoute(questionId: LPQuestionId) {
  const navigate = useNavigate()
  const { status } = useInvId()
  const { goToLp: goToLpForClient, goToLpCompleted: goToLpCompleteForClient } =
    useClientRoute()
  const { goToLp: goToLpForInvited, goToLpComplete: goToLpCompleteForInvited } =
    useInvitedRoute()

  const questionConfig = questionConfigs[questionId]

  /**
   * 次の質問ページに遷移する
   */
  const goToNext = (formData?: LPFormData) => {
    if (questionConfig === undefined) {
      return
    }

    const { next } = questionConfig
    const nextQuestionId =
      typeof next === "string"
        ? next
        : typeof next === "function" && formData !== undefined
        ? next(formData)
        : undefined

    if (nextQuestionId === undefined) {
      return
    }

    if (nextQuestionId === "finish") {
      if (status === "success") {
        goToLpCompleteForInvited()
      } else {
        goToLpCompleteForClient()
      }
      return
    }

    if (status === "success") {
      goToLpForInvited(nextQuestionId)
    } else {
      goToLpForClient(nextQuestionId)
    }
  }

  const goToPrev = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return {
    ...questionConfig,
    goToNext,
    goToPrev,
  } as const
}
