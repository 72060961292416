import { memo, useCallback, useState } from "react"
import { dequal } from "dequal"
import styled from "styled-components"
import { Button } from "components/atoms/Button"
import { SettingIcon } from "components/atoms/Icons"
import { Modal } from "components/atoms/Modal"
import { Tag } from "components/atoms/Tag"
import { TextWrap } from "components/atoms/TextWrap"
import { useBoolean } from "hooks/useBoolean"
import {
  EditSalesStatus,
  EditSalesStatusOnSave,
  EditSalesStatusTag,
} from "../EditSalesStatus/EditSalesStatus"

const MODAL_PADDING = 50
const TEXT_TAG_BUTTON_GAP = 25
const TAG_GAP = 15
const BUTTON_GAP = 20
const EDIT_SALES_STATUS_GAP = 25

type TagId = string

interface Props {
  /**
   * マウント時に選択されているTag
   */
  initialTag: TagId
  /**
   * tagの情報を持った配列
   */
  tags: EditSalesStatusTag[]
  /**
   * 「保存する」ボタンを押した際のアクション
   */
  onComplete: (selectedTag: TagId) => void
  /**
   * 「キャンセル」ボタンを押した際のアクション
   */
  onCancel: () => void
  /**
   * 営業ステータス編集画面で「保存する」を押した際のアクション
   */
  onEditComplete?: EditSalesStatusOnSave
}

/**
 * 営業ステータスのTagを表示するModal
 */
export const SalesStatusModal = memo(
  ({ initialTag, tags, onComplete, onCancel, onEditComplete }: Props) => {
    const [selectedTag, setSelectedTag] = useState<TagId>(initialTag)
    const [isEditing, setIsEditing] = useBoolean(false)

    const handleComplete = useCallback(
      () => onComplete(selectedTag),
      [selectedTag, onComplete],
    )

    const handleEditComplete: EditSalesStatusOnSave = useCallback(
      (selectedTag) => {
        if (onEditComplete === undefined) {
          return
        }

        onEditComplete(selectedTag)
        setIsEditing.off()
      },
      [onEditComplete, setIsEditing],
    )

    return (
      <StyledModal>
        {isEditing && onEditComplete !== undefined ? (
          <EditSalesStatus
            initialItemList={tags}
            gap={EDIT_SALES_STATUS_GAP}
            onCancel={setIsEditing.off}
            onSave={handleEditComplete}
          />
        ) : (
          <>
            <HeadingAndIconWrap>
              <TextWrap size="m" weight="bold">
                営業ステータス
              </TextWrap>
              <StyledSettingIcon onClick={setIsEditing.on} />
            </HeadingAndIconWrap>
            <TagWrapper>
              {tags.map((tag) => (
                <Tag
                  key={tag.id}
                  size="m"
                  as="button"
                  isActive={selectedTag === tag.id}
                  onClick={() => setSelectedTag(tag.id)}
                >
                  {tag.displayName}
                </Tag>
              ))}
            </TagWrapper>
            <ButtonWrapper>
              <Button color="gray" onClick={onCancel}>
                キャンセル
              </Button>
              <Button onClick={handleComplete}>保存する</Button>
            </ButtonWrapper>
          </>
        )}
      </StyledModal>
    )
  },
  dequal,
)

SalesStatusModal.displayName = "SalesStatusModal"

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  padding: ${MODAL_PADDING}px;
  gap: ${TEXT_TAG_BUTTON_GAP}px;
`

const HeadingAndIconWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const StyledSettingIcon = styled(SettingIcon)`
  cursor: pointer;
`

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${TAG_GAP}px;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: ${BUTTON_GAP}px;
`
