import { BankBorrowingAmountAndNetWorth } from "api/types/phase2/utils"
import { BankBorrowingAmountAndNetWorthFormData } from "pages/utils/types/phase2FormData"
import { dateToFiscalYear, now } from "utils"

interface Args {
  json: {
    question: string
    label: string
    textbox: {
      label: {
        bank_borrowing: string
        assets: string
      }
      unit: string
    }
  }
  formData?: BankBorrowingAmountAndNetWorthFormData
}

/**
 * 銀行借入額、純資産額(A Q32, B Q32)
 */
export const bankBorrowingAmountAndNetWorthTranslator = ({
  json,
  formData,
}: Args): BankBorrowingAmountAndNetWorth | undefined => {
  if (formData === undefined) return

  return {
    question: json.question,
    answer: {
      type: "array",
      content: formData.map(({ bankBorrowing, assets } = {}, index) => ({
        label: json.label.replace(
          "${year}",
          String(dateToFiscalYear(now()) + index),
        ),
        bank_borrowing_amount:
          bankBorrowing !== undefined
            ? {
                type: "number",
                content: bankBorrowing,
                label: json.textbox.label.bank_borrowing,
                unit: json.textbox.unit,
              }
            : undefined,
        net_worth:
          assets !== undefined
            ? {
                type: "number",
                content: assets,
                label: json.textbox.label.assets,
                unit: json.textbox.unit,
              }
            : undefined,
      })),
    },
  }
}
