import { useTheme } from "styled-components"
import { SvgCreater } from "utils/SvgCreater"
import { IconProps } from "./interface"

export const HelpIcon = ({ size = 24, ...rest }: IconProps) => {
  const theme = useTheme()
  return (
    <SvgCreater size={size} fill={theme.color.nobel} {...rest}>
      <path d="M11 18H13V16H11V18ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12 6C9.79 6 8 7.79 8 10H10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 12 11 11.75 11 15H13C13 12.75 16 12.5 16 10C16 7.79 14.21 6 12 6Z" />
    </SvgCreater>
  )
}
