import {
  Phase2CreateRequest,
  Phase2CreateResponse,
  Phase2DraftGetResponse,
  Phase2DraftUpdateRequest,
} from "api/phase2/types"
import { axiosClient } from "lib/axios-client"

export const Phase2API = {
  /**
   * Phase2回答作成
   */
  async sendResult(payload: Phase2CreateRequest) {
    const { data } = await axiosClient.request<Phase2CreateResponse>({
      url: "/phase2",
      method: "POST",
      data: payload,
    })
    return data
  },

  /**
   * 下書き解答の取得
   */
  async fetchDraft() {
    const { data } = await axiosClient.request<Phase2DraftGetResponse>({
      url: "/phase2/draft",
      method: "GET",
    })
    return data
  },

  /**
   * 下書き解答の更新
   */
  async updateDraft(payload: Phase2DraftUpdateRequest) {
    await axiosClient.request({
      url: "/phase2/draft",
      method: "PATCH",
      data: payload,
    })
  },

  /**
   * 下書き削除
   */
  async deleteDraft() {
    await axiosClient.request({
      url: "/phase2/draft",
      method: "DELETE",
    })
  },
} as const
