import { RefObject, useEffect } from "react"
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock"

interface Props {
  isScrollable: boolean
  target: RefObject<HTMLElement>
}

/**
 * useModal内で使うスクロールを禁止するカスタムフック
 */
export const useBodyScrollLock = ({ isScrollable, target }: Props) => {
  useEffect(() => {
    if (target.current === null) {
      return
    }

    if (isScrollable) {
      enableBodyScroll(target.current)
    } else {
      disableBodyScroll(target.current)
    }

    return () => clearAllBodyScrollLocks()
  }, [isScrollable, target])
}
