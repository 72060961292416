import { Suspense } from "react"
import { Outlet } from "react-router-dom"
import styled from "styled-components"
import { Button } from "components/atoms/Button"
import { Character } from "components/atoms/Character"
import { Logo } from "components/atoms/Logo"
import { TextWrap } from "components/atoms/TextWrap"
import { GeneralContainer } from "utils/GeneralContainer"

/**
 * 登録画面/新規登録画面, 登録画面/ログイン画面で共通のレイアウトコンポーネント
 */
export const TopLayout = () => {
  return (
    <Container>
      <LogoContainer>
        <Logo variant="noCharacter" />
      </LogoContainer>
      <CharacterContainer>
        <CharacterWrapper>
          <Character name="syou-chan" status="right" />
          <CharacterName>しょうちゃん</CharacterName>
        </CharacterWrapper>
        <CharacterWrapper>
          <Character name="kei-chan" status="left" />
          <CharacterName>けいちゃん</CharacterName>
        </CharacterWrapper>
      </CharacterContainer>
      <Suspense fallback={null}>
        <Outlet />
      </Suspense>
    </Container>
  )
}

TopLayout.FooterLink = styled(Button).attrs({
  shape: "noFrame",
  color: "gray",
})<{
  fluid?: boolean
}>`
  /* MEMO: Buttonのwidth:100%を本当は消したい */
  width: auto;
  flex-basis: ${({ fluid = false }) => fluid && "100%"};
`

const Container = styled(GeneralContainer)`
  padding-top: 70px;
  padding-bottom: 70px;
`

const LogoContainer = styled.div`
  width: 192px;
  padding-bottom: 60px;
  margin: 0 auto;
`

const CharacterContainer = styled.div`
  display: flex;
  margin: 0 auto;
`

const CharacterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CharacterName = styled(TextWrap).attrs({ size: "s", weight: "bold" })``
