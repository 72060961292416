import { Suspense } from "react"
import { Outlet } from "react-router-dom"
import styled from "styled-components"
import { PROGRESS_BAR_HEIGHT } from "components/molecules/ProgressBar"
import {
  GeneralHeader,
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_TABLET,
} from "components/organisms/GeneralHeader"
import { DiscardAnswerModal } from "components/organisms/Modals/DiscardAnswerModal"
import { useClientRoute } from "hooks/useClientRoute"
import { useModal, ModalWrap } from "hooks/useModal"
import { appMedia } from "theme"
import { px } from "utils"
import { Phase2FormDataProvider } from "./utils"

/**
 * /phase2のレイアウトコンポーネント
 */
export const Phase2Layout = () => {
  const {
    ModalPortal: ConfirmModalPortal,
    open: openConfirmModal,
    close: closeConfirmModal,
  } = useModal({
    preventScroll: true,
    closeOnOverlayClick: true,
  })

  const { goToHome } = useClientRoute()

  return (
    <>
      <Container>
        <GeneralHeader onLogoClick={openConfirmModal} fixed />
        <Phase2FormDataProvider>
          <MainContainer>
            <Suspense fallback={null}>
              <Outlet />
            </Suspense>
          </MainContainer>
        </Phase2FormDataProvider>
      </Container>
      <ConfirmModalPortal>
        <ModalWrap>
          <DiscardAnswerModal
            onAgree={() => goToHome()}
            onCancel={closeConfirmModal}
          />
        </ModalWrap>
      </ConfirmModalPortal>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: ${px(HEADER_HEIGHT_DESKTOP + PROGRESS_BAR_HEIGHT)};

  ${appMedia.lessThan("desktop")`
    padding-top: ${px(HEADER_HEIGHT_TABLET + PROGRESS_BAR_HEIGHT)}
  `}
`

const MainContainer = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
`
