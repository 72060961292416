import * as React from "react"
import { format, getYear, subMonths } from "date-fns"
import reactStringReplace from "react-string-replace"
import { Link } from "components/atoms/Link"

export const px = (value: number) => `${value}px`

/**
 * 到達不可能なコードを記載した際にエラーを起こす
 */
export const unreachable = (errorText?: string) => {
  if (errorText) {
    throw new Error(errorText)
  }
  throw new Error("このコードに到達してしまっています")
}
/**
 * 度数からラジアンへ変換
 * @param degree 度数
 * @returns ラジアン
 */
export function radian(degree: number): number {
  return (Math.PI / 180) * degree
}

export type HexColor = `#${string}`

/**
 * countの分だけ空白の要素を作る配列
 */
export function range(count: number) {
  if (Number.isNaN(count)) {
    unreachable()
  }
  return [...Array(Math.abs(count))]
}

interface ClampProps {
  value: number
  min?: number
  max?: number
}

/**
 * 値を上限と下限の間に設定する関数
 */
export const clamp = ({
  value,
  max = Number.MAX_VALUE,
  min = Number.MIN_VALUE,
}: ClampProps): number => {
  if (max < min) {
    return clamp({ value, min: max, max: min })
  }
  return Math.min(max, Math.max(value, min))
}

export type Px = `${number}px`

/**
 * No operation
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {}

// named imports for React.lazy: https://github.com/facebook/react/issues/14603#issuecomment-726551598
export function lazyImport<
  T extends React.ComponentType<unknown>,
  I extends { [K2 in K]: T },
  K extends keyof I,
>(factory: () => Promise<I>, name: K): I {
  return Object.create({
    [name]: React.lazy(() =>
      factory().then((module) => ({ default: module[name] })),
    ),
  })
}

/**
 * 電話番号用の正規表現
 */
export const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3,4}[-s.]?[0-9]{4}$/

/**
 * 万円単位から円に直す関数
 */
export const normalizeMoney = (money: number) => {
  if (Number.isNaN(money)) {
    console.warn("moneyがNaNです")
    return money
  }
  return money * 10000
}

/**
 * 配列を指定の数ごとに等分して返す関数
 */
export function splitArrayPerChunk<T>(
  array: T[],
  chunk: number,
  index: number,
) {
  const returnArray = []
  for (let i = 0; i < array.length; i += chunk) {
    const slicedArray = array.slice(i, i + chunk)
    returnArray.push(slicedArray)
  }
  const clampedIndex = clamp({
    value: index,
    min: 0,
    max: returnArray.length - 1,
  })
  if (!returnArray[clampedIndex]) {
    return { splitedArray: undefined, arrayLength: undefined }
  }
  return {
    splitedArray: returnArray[clampedIndex],
    arrayLength: returnArray.length,
  }
}

/**
 * 現在時刻
 */
export const now = () => new Date()

/**
 * 再帰的にオプショナルにする型
 */
export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

/**
 * 空オブジェクトかどうか判定する関数
 */
export const isEmptyObject = (obj: object) => {
  if (Array.isArray(obj)) {
    throw new Error("isEmptyObjectに配列が渡されています")
  }
  return !Object.keys(obj).length
}

/*
 * 文字列中の${}で囲まれたurlをリンクに変換
 */
export const stringToLink = (text: string) => {
  const regex = /\${(https?:\/\/\S+)}/g
  return reactStringReplace(text, regex, (match) => (
    <Link href={match} newTab>
      {match}
    </Link>
  ))
}

type DateFormatter = (date?: string) => string | undefined
/**
 * ISO文字列をyyyy/MM/ddに変換
 * @param date ISO文字列
 * @returns yyyy/MM/dd
 */
export const dateFormatter: DateFormatter = (date?: string) => {
  if (date === undefined || date === "") {
    return
  }
  return format(new Date(date), "yyyy/MM/dd")
}

/**
 * Dateを引数にとり年度を返す
 */
export const dateToFiscalYear = (date: Date): number => {
  if (Number.isNaN(date.getTime())) {
    console.warn("dateToFiscalYearに不正な日付が渡されています")
  }
  return getYear(subMonths(date, 3))
}

/**
 * 任意の桁で四捨五入する関数
 * @param value 丸める対象の数値
 * @param digit 小数点以下何桁に丸めるか
 * @example
 * // returns 1.6
 * round(1.55, 1)
 */
export const round = (value: number, digit: number) => {
  if (digit <= 0 || !Number.isInteger(digit)) {
    return unreachable("digitの値は自然数にしてください")
  }
  const base = 10 ** digit

  return Math.round(value * base) / base
}

/**
 * オブジェクトからクエリパラメータを作成する
 */
type GenerateParamsProps = Record<string, string | string[]>
export const generateParams = (
  paramArrays: GenerateParamsProps,
): URLSearchParams => {
  const params = new URLSearchParams()

  Object.entries(paramArrays).forEach(([key, value]) => {
    if (typeof value === "string") {
      params.append(key, value)
    } else if (typeof value === "object") {
      value.forEach((item) => {
        params.append(key, item)
      })
    }
  })

  return params
}

/**
 * オブジェクトがNullishではなく、NaNでもなく、空文字列でもない値を持っているかどうかを再帰的に探索する
 */
export function hasValidValue(obj: unknown) {
  if (obj == null || Number.isNaN(obj) || obj === "") {
    return false
  }

  if (typeof obj === "object") {
    for (const key in obj) {
      if (hasValidValue(obj[key as keyof typeof obj])) {
        return true
      }
    }
    return false
  }

  return true
}
