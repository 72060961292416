import { HTMLAttributes } from "react"
import styled from "styled-components"

type Size = "s" | "m"

const MENU_SIZE: Record<Size, number> = {
  s: 40,
  m: 54,
}
const LINE_WIDTH: Record<Size, number> = {
  s: 33,
  m: 51,
}
const LINE_HEIGHT: Record<Size, number> = {
  s: 4,
  m: 5,
}
const LINE_GAP: Record<Size, number> = {
  s: 6,
  m: 12,
}

interface MenuProps extends HTMLAttributes<HTMLButtonElement> {
  /**
   * boolean型のstateを受け取る
   */
  isOpen: boolean
  /**
   * メニューのサイズ "s" | "m"
   */
  size: Size
}

/**
 * クリックすると変化するハンバーガーメニュー
 */
export const Menu = ({ isOpen, size, ...rest }: MenuProps) => {
  return (
    <Container isOpen={isOpen} size={size} {...rest}>
      <Line size={size} />
      <Line size={size} />
      <Line size={size} />
    </Container>
  )
}

interface ContainerProps {
  isOpen: boolean
  size: Size
}

const Line = styled.div<{ size: Size }>`
  height: ${({ size }) => LINE_HEIGHT[size]}px;
  width: ${({ size }) => LINE_WIDTH[size]}px;
  margin: 0 auto;
  border-radius: 9999px;
  background-color: ${(props) => props.theme.color.nightRider};
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
`

const Container = styled.button<ContainerProps>`
  width: ${({ size }) => MENU_SIZE[size]}px;
  height: ${({ size }) => MENU_SIZE[size]}px;

  ${Line} {
    :first-child {
      transform: ${({ isOpen, size }) =>
        isOpen
          ? `translateY(${LINE_GAP[size] + LINE_HEIGHT[size]}px) rotate(-45deg)`
          : "rotate(0)"};
    }

    :nth-child(2) {
      margin: ${({ size }) => LINE_GAP[size]}px auto;
      opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};
    }

    :last-child {
      transform: ${({ isOpen, size }) =>
        isOpen
          ? `translateY(-${LINE_GAP[size] + LINE_HEIGHT[size]}px) rotate(45deg)`
          : "rotate(0)"};
    }
  }
`
