import { useState, useCallback, useLayoutEffect } from "react"
import axios from "axios"
import constate from "constate"
import { differenceInMinutes } from "date-fns"
import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  fetchExpertQuery,
  useRegisterProfileMutation,
  useSigninMutation,
} from "api"
import { useModal, ModalWrap } from "hooks/useModal"
import { usePublicRoute } from "hooks/usePublicRoute"
import { axiosClient, isAxiosError } from "lib/axios-client"
import { LogoutModal } from "pages/components/LogoutModal"
import { now, unreachable } from "utils"
import {
  sessionTimestampStorage,
  tokenStorage,
  userPermissionStorage,
} from "utils/local-storage"

type AuthStatus =
  | "idle"
  | "signingIn"
  | "signedIn"
  | "signingOut"
  | "signedOut"
  | "error"

type UserPermission = "client" | "expert" | "admin"
/**
 * 操作なし時間: 120分
 */
const MAX_SESSION_INTERVAL_MIN = 120
export const [
  AuthProvider,
  useAuthStatus,
  useAuthPermission,
  useAuthAction,
  useAuthSignoutModal,
  useIsParent,
] = constate(
  () => {
    /**
     * State
     */
    // 初回マウント時にLocalStorageを読み込む
    // MEMO: setPermissionするたびにLocalStorageの更新も忘れずに
    // FIXME: CustomHook化
    const [userPermission, setUserPermission] = useState<
      UserPermission | undefined
    >(() => userPermissionStorage.load() ?? undefined)
    const [authStatus, setAuthStatus] = useState<AuthStatus>("idle")

    /**
     * misc.
     */
    const { goToNotFound, goToInsufficientIdError } = usePublicRoute()

    /**
     * Handler
     */
    const clearAuthData = useCallback(() => {
      // ログアウトしたらLocalStorageにあるトークン・セッション時刻・ユーザー権限情報は削除
      tokenStorage.clear()
      sessionTimestampStorage.clear()
      userPermissionStorage.clear()
      setUserPermission(undefined)
    }, [])

    const { mutateAsync: signin } = useSigninMutation({
      onMutate: () => setAuthStatus("signingIn"),
      onSuccess: ({ permission, token }) => {
        tokenStorage.save(token)
        setAuthStatus("signedIn")
        const userPermission: UserPermission =
          permission === "general"
            ? "client"
            : permission === "expert"
            ? "expert"
            : permission === "admin"
            ? "admin"
            : unreachable()
        setUserPermission(userPermission)
        userPermissionStorage.save(userPermission)
      },
      onError: () => setAuthStatus("error"),
    })

    const { mutateAsync: signup } = useRegisterProfileMutation({
      onSuccess: ({ permission, token }) => {
        tokenStorage.save(token)
        const userPermission: UserPermission =
          permission === "general"
            ? "client"
            : permission === "expert"
            ? "expert"
            : permission === "admin"
            ? "admin"
            : unreachable()
        setUserPermission(userPermission)
        userPermissionStorage.save(userPermission)
      },
      onError: (error) => {
        if (error.response?.data.error_type === "invalid_data") {
          goToNotFound()
        }
        if (error.response?.data.error_type === "over_id_limit_count") {
          goToInsufficientIdError()
        }
      },
    })

    const queryClient = useQueryClient()

    const { mutateAsync: signout } = useMutation({
      mutationFn: async () => {
        clearAuthData()
        queryClient.clear()
      },
      onMutate: () => setAuthStatus("signingOut"),
      onSuccess: () => setAuthStatus("signedOut"),
      onError: () => setAuthStatus("error"),
    })

    /**
     * useAuthSignoutModal
     */

    const { ModalPortal, open: openModal, close: closeModal } = useModal()

    const { goToLogin } = usePublicRoute()

    const handleLogout = useCallback(async () => {
      await signout()
      closeModal()
      goToLogin()
    }, [closeModal, goToLogin, signout])

    const Modal = useCallback(() => {
      return (
        <ModalPortal>
          <ModalWrap>
            <LogoutModal onLogout={handleLogout} onCancel={closeModal} />
          </ModalWrap>
        </ModalPortal>
      )
    }, [ModalPortal, closeModal, handleLogout])

    /**
     * useIsParent
     */
    const { data: isParent, status: isParentStatus } = useQuery({
      ...fetchExpertQuery,
      enabled: userPermission === "expert",
      staleTime: Infinity,
      select: ({ is_parent }) => is_parent,
    })

    /**
     * AxiosにInterceptorをsubscribeする
     */
    useLayoutEffect(() => {
      /**
       * LocalStorageにトークンがあればAuthorizationヘッダーに付与する
       */
      const requestInterceptorId = axiosClient.interceptors.request.use(
        async (config) => {
          /**
           * トークンがなければそのままリクエストを飛ばす。（認証不要なAPIを考慮, signinなど）
           */
          const token = tokenStorage.load()
          if (token === null) {
            return config
          }

          /**
           * トークンはあるものの、セッションタイムスタンプがない場合はそのままリクエスト飛ばす
           */
          const session = sessionTimestampStorage.load()
          if (session === null) {
            throw new axios.Cancel("Session timestamp was not found")
          }

          const lastUsedInterval = differenceInMinutes(now(), session)
          if (lastUsedInterval >= MAX_SESSION_INTERVAL_MIN) {
            await signout()
            goToLogin()
            throw new axios.Cancel("Session timeout")
          }

          config.headers = {
            ...config.headers,
            Authorization: `Token ${token}`,
          }

          return config
        },
      )

      const responseInterceptorId = axiosClient.interceptors.response.use(
        async (res) => {
          sessionTimestampStorage.save(now())
          return res
        },
        async (err) => {
          if (!isAxiosError(err)) {
            return Promise.reject(err)
          }

          const statusCode = err.response?.status
          if (statusCode === undefined) {
            return Promise.reject(err)
          }

          /**
           * 401 Unauthorized
           */
          if (statusCode === 401) {
            clearAuthData()
          }
          return Promise.reject(err)
        },
      )

      return () => {
        axiosClient.interceptors.request.eject(requestInterceptorId)
        axiosClient.interceptors.response.eject(responseInterceptorId)
      }
    }, [clearAuthData, goToLogin, signout])

    return {
      permission: userPermission,
      status: authStatus,
      signin,
      signout,
      openModal,
      Modal,
      isParent,
      isParentStatus,
      signup,
    }
  },
  ({ status }) => ({ status }),
  ({ permission }) => ({ permission }),
  ({ signin, signout, signup }) => ({
    signin,
    signout,
    signup,
  }),
  ({ Modal, openModal }) => ({ Modal, openModal }),
  ({ isParent, isParentStatus }) => ({ isParent, isParentStatus }),
)
