import { ReactNode } from "react"
import * as yup from "yup"

export type LPFinish = "finish"

/**
 * 金額
 * NOTE: 万円単位
 */
type Money = number
export enum LPQuestionId {
  Applicable = "applicable",
  Birthdate = "birthdate",
  Name = "name",
  Q1 = "q1",
  Q2 = "q2",
  Q3 = "q3",
  Q4 = "q4",
  Q5 = "q5",
  Q6 = "q6",
  Q7 = "q7",
  Q8Q10 = "q8q10",
  Q11 = "q11",
  Q12 = "q12",
  Q13 = "q13",
  Q14 = "q14",
  Q15Q16 = "q15q16",
  Q17 = "q17",
  Q18 = "q18",
  Q19 = "q19",
  Q20 = "q20",
  Q21 = "q21",
  Q22 = "q22",
  Q23 = "q23",
  Q24 = "q24",
  Q25Q26 = "q25q26",
  Q27 = "q27",
  Q28 = "q28",
  Q29 = "q29",
  Q30 = "q30",
  Q31 = "q31",
  Q32 = "q32",
  Q33 = "q33",
}

/**
 * LPで管理するフォーム情報の型
 */
export type LPFormData = {
  [LPQuestionId.Applicable]?: boolean
  [LPQuestionId.Birthdate]?: string
  [LPQuestionId.Name]?: string
  [LPQuestionId.Q1]?: number
  [LPQuestionId.Q2]?: number
  [LPQuestionId.Q3]?: number
  [LPQuestionId.Q4]?: number
  [LPQuestionId.Q5]?: number
  [LPQuestionId.Q6]?: number
  [LPQuestionId.Q7]?: number
  [LPQuestionId.Q8Q10]?: {
    name: string
    age: number
    relationship: number
  }[]
  [LPQuestionId.Q11]?: number
  [LPQuestionId.Q12]?: {
    self_pension: Money
    spouse_pension: Money
  }
  [LPQuestionId.Q13]?: {
    self_monthly_income: number
    self_severance_pay: number
    spouse_monthly_income: number
    spouse_severance_pay: number
  }
  [LPQuestionId.Q14]?: Money
  [LPQuestionId.Q15Q16]?: {
    premium: number
    premium_remaining_years: number
  }[]
  [LPQuestionId.Q17]?: number
  [LPQuestionId.Q18]?: Money
  [LPQuestionId.Q19]?: boolean
  [LPQuestionId.Q20]?: number
  [LPQuestionId.Q21]?: {
    displacement: number
    unit: number
  }[]
  [LPQuestionId.Q24]?: {
    children_num: number
  }
  [LPQuestionId.Q25Q26]?: {
    chose_id?: number
    remaining_years?: number
  }[]
  [LPQuestionId.Q27]?: number
  [LPQuestionId.Q28]?: number
  [LPQuestionId.Q32]?: {
    monthly: number
    repayment_years: number
  }
  [LPQuestionId.Q33]?: {
    saving_amount: number
    listed_stock: number
    investment_trust: number
    investment: number
  }
}
export interface QuestionConfig {
  schema?: yup.BaseSchema
  element: ReactNode
  next?:
    | ((formData: LPFormData) => LPQuestionId | LPFinish)
    | LPQuestionId
    | LPFinish
  index: number
}
