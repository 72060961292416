import { Phase2AnswerE } from "api"
import { Phase2EFormData } from "pages/components/phase2/components/E/schema"
import { DeepPartial } from "utils"
import {
  selectAndDateDraftTranslator,
  dateDraftTranslator,
  stringDraftTranslator,
} from "./utils"

/**
 * 下書きを受けとってformDataの形に変換する
 */
export const phase2DraftETranslator = (
  answer: Phase2AnswerE,
): DeepPartial<Phase2EFormData> => ({
  // q0: 表示のみ
  q1: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_01),
  q2: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_02),
  q3: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_03),
  q4: dateDraftTranslator(answer.question_group_1?.answers?.answer_04),
  q5: dateDraftTranslator(answer.question_group_1?.answers?.answer_05),
  q6: dateDraftTranslator(answer.question_group_1?.answers?.answer_06),
  q7: dateDraftTranslator(answer.question_group_1?.answers?.answer_07),
  q8: dateDraftTranslator(answer.question_group_1?.answers?.answer_08),
  q9: dateDraftTranslator(answer.question_group_1?.answers?.answer_09),
  q10: dateDraftTranslator(answer.question_group_1?.answers?.answer_10),
  q11: selectAndDateDraftTranslator(
    answer.question_group_1?.answers?.answer_11,
  ),
  q12: dateDraftTranslator(answer.question_group_1?.answers?.answer_12),
  q13: dateDraftTranslator(answer.question_group_1?.answers?.answer_13),
  q14: dateDraftTranslator(answer.question_group_2?.answers?.answer_14),
  q15: dateDraftTranslator(answer.question_group_2?.answers?.answer_15),
  q16: stringDraftTranslator(answer.question_group_2?.answers?.answer_16),
  q17: dateDraftTranslator(answer.question_group_3?.answers?.answer_17),
  q18: dateDraftTranslator(answer.question_group_3?.answers?.answer_18),
  q19: dateDraftTranslator(answer.question_group_3?.answers?.answer_19),
  q20: dateDraftTranslator(answer.question_group_3?.answers?.answer_20),
  q21: dateDraftTranslator(answer.question_group_3?.answers?.answer_21),
  q22: dateDraftTranslator(answer.question_group_3?.answers?.answer_22),
  q23: dateDraftTranslator(answer.question_group_3?.answers?.answer_23),
  q24: selectAndDateDraftTranslator(
    answer.question_group_3?.answers?.answer_24,
  ),
  q25: selectAndDateDraftTranslator(
    answer.question_group_3?.answers?.answer_25,
  ),
  q26: dateDraftTranslator(answer.question_group_4?.answers?.answer_26),
  q27: dateDraftTranslator(answer.question_group_4?.answers?.answer_27),
  q28: dateDraftTranslator(answer.question_group_4?.answers?.answer_28),
  q29: dateDraftTranslator(answer.question_group_4?.answers?.answer_29),
  q30: dateDraftTranslator(answer.question_group_4?.answers?.answer_30),
  q31: dateDraftTranslator(answer.question_group_4?.answers?.answer_31),
  q32: dateDraftTranslator(answer.question_group_4?.answers?.answer_32),
  q33: dateDraftTranslator(answer.question_group_4?.answers?.answer_33),
  q34: dateDraftTranslator(answer.question_group_4?.answers?.answer_34),
  q35: dateDraftTranslator(answer.question_group_4?.answers?.answer_35),
  q36: dateDraftTranslator(answer.question_group_4?.answers?.answer_36),
  q37: dateDraftTranslator(answer.question_group_4?.answers?.answer_37),
})
