import { Phase2AnswerG } from "api"
import { AnswerWrap } from "pages/components/Phase2Answer"
import {
  arrayNumber,
  objectCost,
  arrayCost,
  selectAndDate,
  QAPresentation,
  liabilities,
  actionPlan,
  concernAndSolution,
} from "./utils"

export const phase2ConvertAnswersG: QAPresentation<Phase2AnswerG> = (
  answers: Phase2AnswerG,
) => {
  return [
    {
      title: answers.question_group_1?.title,
      QA: [
        // answer_01?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_1?.answers?.answer_01?.question,
          answer: answers.question_group_1?.answers?.answer_01?.answer?.content,
        },
        // answer_02?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_02?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_02),
        },
        // answer_03?: DeepPartial<QA<NumberTypeAnswer & Unit>>
        {
          question: answers.question_group_1?.answers?.answer_03?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_1?.answers?.answer_03?.answer?.content}
              {answers.question_group_1?.answers?.answer_03?.answer?.unit}
            </AnswerWrap>
          ),
        },
        // answer_04?: { question?: string } 非表示
        // answer_05?: { question?: string } 非表示
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_2?.title,
      QA: [
        // answer_06?: SelectAndDate
        {
          question: answers.question_group_2?.answers?.answer_06?.question,
          answer: selectAndDate(answers.question_group_2?.answers?.answer_06),
        },
        // answer_07?: SelectAndDate
        {
          question: answers.question_group_2?.answers?.answer_07?.question,
          answer: selectAndDate(answers.question_group_2?.answers?.answer_07),
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_3?.title,
      QA: [
        // answer_08?: ArrayNumber
        {
          question: answers.question_group_3?.answers?.answer_08?.question,
          answer: arrayNumber(answers.question_group_3?.answers?.answer_08),
        },
        // answer_09?: ArrayCost
        {
          question: answers.question_group_3?.answers?.answer_09?.question,
          answer: arrayCost(answers.question_group_3?.answers?.answer_09),
        },
        // answer_10?: Liabilities
        {
          question: answers.question_group_3?.answers?.answer_10?.question,
          answer: liabilities(answers.question_group_3?.answers?.answer_10),
        },
        // answer_11?: ArrayNumber
        {
          question: answers.question_group_3?.answers?.answer_11?.question,
          answer: arrayNumber(answers.question_group_3?.answers?.answer_11),
        },
        // answer_12?: DeepPartial<QA<NumberTypeAnswer & Unit>>
        {
          question: answers.question_group_3?.answers?.answer_12?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_3?.answers?.answer_12?.answer?.content}
              {answers.question_group_3?.answers?.answer_12?.answer?.unit}
            </AnswerWrap>
          ),
        },
        // answer_13?: ObjectCost
        {
          question: answers.question_group_3?.answers?.answer_13?.question,
          answer: objectCost(answers.question_group_3?.answers?.answer_13),
        },
        // answer_14?: DeepPartial<QA<NumberTypeAnswer & Unit>>
        {
          question: answers.question_group_3?.answers?.answer_14?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_3?.answers?.answer_14?.answer?.content}
              {answers.question_group_3?.answers?.answer_14?.answer?.unit}
            </AnswerWrap>
          ),
        },
        // answer_15?: ArrayNumber
        {
          question: answers.question_group_3?.answers?.answer_15?.question,
          answer: arrayNumber(answers.question_group_3?.answers?.answer_15),
        },
        // answer_16?: ArrayCost
        {
          question: answers.question_group_3?.answers?.answer_16?.question,
          answer: arrayCost(answers.question_group_3?.answers?.answer_16),
        },
        // answer_17?: ArrayNumber
        {
          question: answers.question_group_3?.answers?.answer_17?.question,
          answer: arrayNumber(answers.question_group_3?.answers?.answer_17),
        },
        // answer_18?: { question?: string } 非表示
        // answer_19?: DeepPartial<QA<NumberTypeAnswer & Unit>>
        {
          question: answers.question_group_3?.answers?.answer_19?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_3?.answers?.answer_19?.answer?.content}
              {answers.question_group_3?.answers?.answer_19?.answer?.unit}
            </AnswerWrap>
          ),
        },
        // answer_20?: ArrayNumber
        {
          question: answers.question_group_3?.answers?.answer_20?.question,
          answer: arrayNumber(answers.question_group_3?.answers?.answer_20),
        },
        // answer_21?: ArrayNumber
        {
          question: answers.question_group_3?.answers?.answer_21?.question,
          answer: arrayNumber(answers.question_group_3?.answers?.answer_21),
        },
        // answer_22_24?: ActionPlan
        {
          question: answers.question_group_3?.answers?.answer_22_24?.question,
          answer: actionPlan(answers.question_group_3?.answers?.answer_22_24),
        },
        // answer_25?: ConcernAndSolution
        {
          question: answers.question_group_3?.answers?.answer_25?.question,
          answer: concernAndSolution(
            answers.question_group_3?.answers?.answer_25,
          ),
        },
      ].filter((item) => item.question && item.answer),
    },
  ].filter((item) => Boolean(item.QA.length))
}
