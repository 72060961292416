import React from "react"
import { Phase2AFormData } from "../components/A/schema"
import { Phase2ABFormData } from "../components/AB/schema"
import { Phase2BFormData } from "../components/B/schema"
import { Phase2CFormData } from "../components/C/schema"
import { Phase2DFormData } from "../components/D/schema"
import { Phase2EFormData } from "../components/E/schema"
import { Phase2FFormData } from "../components/F/schema"
import { Phase2GFormData } from "../components/G/schema"
import { Phase2HFormData } from "../components/H/schema"

export const PHASE2_TYPE = {
  AA: "AA",
  AB: "AB",
  AC: "AC",
  AD: "AD",
  AE: "AE",
  AF: "AF",
  AG: "AG",
  AH: "AH",
  IA: "IA",
  IB: "IB",
  IC: "IC",
  ID: "ID",
  IE: "IE",
  IF: "IF",
  IG: "IG",
  IH: "IH",
  UA: "UA",
  UB: "UB",
  UC: "UC",
  UD: "UD",
  UE: "UE",
  UF: "UF",
  UG: "UG",
  UH: "UH",
  EA: "EA",
  EB: "EB",
  EC: "EC",
  ED: "ED",
  EE: "EE",
  EF: "EF",
  EG: "EG",
  EH: "EH",
} as const

export type Phase2Type = typeof PHASE2_TYPE[keyof typeof PHASE2_TYPE]

export type Phase2FormDataList = {
  [PHASE2_TYPE.AA]: Phase2AFormData
  [PHASE2_TYPE.AB]: Phase2BFormData
  [PHASE2_TYPE.AC]: Phase2CFormData
  [PHASE2_TYPE.AD]: Phase2DFormData
  [PHASE2_TYPE.AE]: Phase2EFormData
  [PHASE2_TYPE.AF]: Phase2FFormData
  [PHASE2_TYPE.AG]: Phase2GFormData
  [PHASE2_TYPE.AH]: Phase2HFormData
  [PHASE2_TYPE.IA]: Phase2AFormData
  [PHASE2_TYPE.IB]: Phase2BFormData
  [PHASE2_TYPE.IC]: Phase2CFormData
  [PHASE2_TYPE.ID]: Phase2DFormData
  [PHASE2_TYPE.IE]: Phase2EFormData
  [PHASE2_TYPE.IF]: Phase2FFormData
  [PHASE2_TYPE.IG]: Phase2GFormData
  [PHASE2_TYPE.IH]: Phase2HFormData
  [PHASE2_TYPE.UA]: Phase2ABFormData
  [PHASE2_TYPE.UB]: Phase2ABFormData
  [PHASE2_TYPE.UC]: Phase2CFormData
  [PHASE2_TYPE.UD]: Phase2DFormData
  [PHASE2_TYPE.UE]: Phase2EFormData
  [PHASE2_TYPE.UF]: Phase2FFormData
  [PHASE2_TYPE.UG]: Phase2GFormData
  [PHASE2_TYPE.UH]: Phase2HFormData
  [PHASE2_TYPE.EA]: Phase2ABFormData
  [PHASE2_TYPE.EB]: Phase2ABFormData
  [PHASE2_TYPE.EC]: Phase2CFormData
  [PHASE2_TYPE.ED]: Phase2DFormData
  [PHASE2_TYPE.EE]: Phase2EFormData
  [PHASE2_TYPE.EF]: Phase2FFormData
  [PHASE2_TYPE.EG]: Phase2GFormData
  [PHASE2_TYPE.EH]: Phase2HFormData
}

export interface Phase2QuestionConfig {
  element: React.FC
}
