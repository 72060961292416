import styled from "styled-components"
import { TextWrap } from "components/atoms/TextWrap"
import { theme } from "theme"
import { px } from "utils"

export const PROGRESS_BAR_HEIGHT = 57

export interface ProgressBarProps {
  /**
   * テキスト
   */
  text: string
  /**
   * 全体の数
   */
  max: number
  /**
   * 進行状況
   */
  value: number
}

/**
 * 進行状況を表示するバー
 */
export const ProgressBar = ({ text, max, value }: ProgressBarProps) => {
  return (
    <Container>
      <Text>{text}</Text>
      <BackGround>
        <Progress width={(value / max) * 100} />
      </BackGround>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 5px;
  height: ${px(PROGRESS_BAR_HEIGHT)};
`

const Text = styled(TextWrap).attrs({
  size: "s",
  weight: "bold",
  color: theme.color.pigmentGreen,
})``

const BackGround = styled.div`
  height: 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.silver};
`
const Progress = styled.div<{ width: number }>`
  position: relative;
  height: 100%;
  max-width: 100%;
  border-radius: 5px;
  width: ${({ width }) => width}%;
  background-color: ${({ theme }) => theme.color.pigmentGreen};
  transition: width 0.5s ease;

  &::after {
    content: "";
    width: 18px;
    height: 18px;
    position: absolute;
    top: -4px;
    right: -9px;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 50%;
    border: 4px solid ${({ theme }) => theme.color.pigmentGreen};
  }
`
