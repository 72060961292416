import { useCallback } from "react"
import { useGeneratePhase2ExcelLinkMutation } from "api"
import { useFileDownload, getPhase2ExcelFilename } from "hooks/useFileDownload"
import { usePublicRoute } from "hooks/usePublicRoute"

export const useClientDownloadPlan = () => {
  const { goToNotFound } = usePublicRoute()

  // 計画書のURLを取得
  const { mutateAsync: fetchDownloadUrl } = useGeneratePhase2ExcelLinkMutation()

  // Excelをダウンロードする処理
  const { download } = useFileDownload()
  const downloadPlan = useCallback(
    async (phase2Id?: number, createdAt?: Date) => {
      if (phase2Id === undefined || createdAt === undefined) {
        return
      }
      try {
        const downloadUrl = await fetchDownloadUrl(phase2Id)
        download({
          url: downloadUrl?.url,
          filename: getPhase2ExcelFilename(createdAt),
        })
      } catch {
        goToNotFound()
      }
    },
    [download, fetchDownloadUrl, goToNotFound],
  )
  return { downloadPlan }
}
