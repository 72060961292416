import { ObjectCost } from "api/types/phase2/utils"
import { ObjectCostFormData } from "pages/utils/types/phase2FormData"

interface Args {
  json: {
    question: string
    textbox: {
      input_label: {
        product_purchase_amount: string
        raw_material_costs: string
        outsourced_processing_costs: string
        freight_costs: string
        labor_cost: string
        my_salary: string
        utilities_charge: string
        depreciation_and_amortization: string
        rent: string
        lease_payment: string
        premium: string
        interest_expense_and_fees: string
        other_costs_1: string
        other_costs_2: string
      }
      unit: string
    }
  }
  formData: ObjectCostFormData
}

/**
 * 今期の経費 D Q17, G Q13
 */
export const objectCostTranslator = ({
  json,
  formData,
}: Args): ObjectCost | undefined => {
  if (formData === undefined) return undefined

  return {
    question: json.question,
    answer: {
      type: "object",
      content: {
        product_purchase_amount: {
          type: "number",
          label: json.textbox.input_label.product_purchase_amount,
          content: formData.productPurchaseAmount,
          unit: json.textbox.unit,
        },
        raw_material_costs: {
          type: "number",
          label: json.textbox.input_label.raw_material_costs,
          content: formData.rawMaterialCosts,
          unit: json.textbox.unit,
        },
        outsourced_processing_costs: {
          type: "number",
          label: json.textbox.input_label.outsourced_processing_costs,
          content: formData.outsourcedProcessingCosts,
          unit: json.textbox.unit,
        },
        freight_costs: {
          type: "number",
          label: json.textbox.input_label.freight_costs,
          content: formData.freightCosts,
          unit: json.textbox.unit,
        },
        labor_cost: {
          type: "number",
          label: json.textbox.input_label.labor_cost,
          content: formData.laborCost,
          unit: json.textbox.unit,
        },
        my_salary: {
          type: "number",
          label: json.textbox.input_label.my_salary,
          content: formData.mySalary,
          unit: json.textbox.unit,
        },
        utilities_charge: {
          type: "number",
          label: json.textbox.input_label.utilities_charge,
          content: formData.utilitiesCharge,
          unit: json.textbox.unit,
        },
        depreciation_and_amortization: {
          type: "number",
          label: json.textbox.input_label.depreciation_and_amortization,
          content: formData.depreciationAndAmortization,
          unit: json.textbox.unit,
        },
        rent: {
          type: "number",
          label: json.textbox.input_label.rent,
          content: formData.rent,
          unit: json.textbox.unit,
        },
        premium: {
          type: "number",
          label: json.textbox.input_label.premium,
          content: formData.premium,
          unit: json.textbox.unit,
        },
        lease_payment: {
          type: "number",
          label: json.textbox.input_label.lease_payment,
          content: formData.leasePayment,
          unit: json.textbox.unit,
        },
        interest_expense_and_fees: {
          type: "number",
          label: json.textbox.input_label.interest_expense_and_fees,
          content: formData.interestExpenseAndFees,
          unit: json.textbox.unit,
        },
        other_costs_1: {
          type: "number",
          label: json.textbox.input_label.other_costs_1,
          content: formData.otherCosts1,
          unit: json.textbox.unit,
        },
        other_costs_2: {
          type: "number",
          label: json.textbox.input_label.other_costs_2,
          content: formData.otherCosts2,
          unit: json.textbox.unit,
        },
      },
    },
  } as const
}
