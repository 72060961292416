import { NameAndBirthday } from "api/types/phase2/utils"
import { NameAndBirthdayFormData } from "pages/utils/types/phase2FormData"

interface Args {
  json: {
    question: string
    textbox: {
      label: {
        name: string
        birthdate: string
      }
    }
  }
  formData: NameAndBirthdayFormData
}

/**
 * 名前と誕生日 （A Q0, B Q0）
 */
export const nameAndBirthdayDraftTranslator = (
  answer: NameAndBirthday | undefined,
): NameAndBirthdayFormData | undefined => ({
  name: answer?.answer?.content?.name?.content,
  date: answer?.answer?.content?.birthday?.content,
})
