import { ButtonHTMLAttributes } from "react"
import styled, { css } from "styled-components"
import { TextWrap } from "components/atoms/TextWrap"
import { px, unreachable } from "utils"

type ButtonShape = "default" | "big" | "small" | "square" | "noFrame"
export type ButtonColor = "white" | "green" | "gray"

/**
 * noFrameはスタイルが他と異なるため、BUTTON_HEIGHTにありません
 */
const BUTTON_HEIGHT = {
  default: 41,
  big: 75,
  small: 30,
  square: 53,
} as const

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * ボタンの形 "default" | "big" | "small"|"square"|"noFrame"
   */
  shape?: ButtonShape
  /**
   * ボタンの色 "white"|"green"|"gray"
   */
  color?: ButtonColor
}

/**
 * 汎用ボタン
 */
export const Button = ({
  children,
  shape = "default",
  color = "white",
  ...rest
}: Props) => {
  if (shape === "noFrame" && color === "white") {
    unreachable("このボタンは存在しません")
  }
  if (shape === "noFrame")
    return (
      <NoFrameButtonContainer color={color} {...rest}>
        <TextWrap size="m" weight="bold" color="inherit">
          {children}
        </TextWrap>
      </NoFrameButtonContainer>
    )
  return (
    <Container shape={shape} color={color} {...rest}>
      <TextWrap
        size={shape === "small" ? "s" : "m"}
        weight="bold"
        color="inherit"
      >
        {children}
      </TextWrap>
    </Container>
  )
}

type ContainerProps = {
  shape: ButtonShape
  color: ButtonColor
}

const Container = styled.button<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  :hover,
  :focus {
    box-shadow: none;
  }
  ${({ shape }) =>
    shape === "noFrame"
      ? unreachable()
      : css`
          height: ${px(BUTTON_HEIGHT[shape])};
          border-radius: ${shape === "square"
            ? px(10)
            : px(BUTTON_HEIGHT[shape] / 2)};
        `}
  ${({ color }) => colorSchemeCss[color]}
`

const colorSchemeCss = {
  white: css`
    color: ${({ theme }) => theme.color.pigmentGreen};
    background-color: ${({ theme }) => theme.color.white};
    border: 3px solid ${({ theme }) => theme.color.pigmentGreen};
    :hover,
    :focus {
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.pigmentGreen};
    }
  `,
  green: css`
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.pigmentGreen};
    :hover,
    :focus {
      background-color: ${({ theme }) => theme.color.monteCarlo};
    }
  `,
  gray: css`
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.silver};
    :hover,
    :focus {
      background-color: ${({ theme }) => theme.color.nobel};
    }
  `,
}

type NoFrameButtonContainerProps = {
  color: ButtonColor
}

const NoFrameButtonContainer = styled.button<NoFrameButtonContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${({ color }) =>
    color === "white" ? unreachable() : noFrameColorSchemeCss[color]}
`
const noFrameColorSchemeCss = {
  green: css`
    color: ${({ theme }) => theme.color.pigmentGreen};
    :hover,
    :focus {
      color: ${({ theme }) => theme.color.monteCarlo};
    }
  `,
  gray: css`
    color: ${({ theme }) => theme.color.nobel};
    :hover,
    :focus {
      color: ${({ theme }) => theme.color.pinkSwan};
    }
  `,
}
