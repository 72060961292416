import { DateTypeAnswer, Label, QA } from "api"
import { DateFormData } from "pages/utils/types/phase2FormData"
import { DeepPartial } from "utils"

interface Args {
  json: {
    question: string
    textbox?: {
      label?: string
    }
  }
  formData: DateFormData
}

/**
 * 日付入力の設問
 */
export const dateTranslator = ({
  json,
  formData,
}: Args): DeepPartial<QA<DateTypeAnswer & Label>> | undefined => {
  if (!formData) return undefined

  return {
    question: json.question,
    answer: {
      type: "string",
      content: formData,
      label: json.textbox?.label,
    },
  } as const
}
