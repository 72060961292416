import { ReactNode } from "react"
import styled, { useTheme } from "styled-components"
import { TextWrap } from "components/atoms/TextWrap"
import { AppFontSizeSet } from "theme"

interface Props {
  /**
   * mainのテキスト内容
   */
  children: ReactNode
  /**
   * labelの内容
   */
  label: string
  /**
   * mainTextの大きさ
   */
  mainSize?: keyof AppFontSizeSet
  /**
   * labelTextの大きさ
   */
  labelSize?: keyof AppFontSizeSet
}

/**
 * Textとlabelを組み合わせたコンポーネント
 */
export const TextWithLabel = ({
  children,
  label,
  mainSize = "m",
  labelSize = "xs",
}: Props) => {
  const theme = useTheme()
  return (
    <Container>
      <TextWrap size={labelSize} color={theme.color.nobel} weight="bold">
        {label}
      </TextWrap>
      <TextWrap size={mainSize} weight="bold">
        {children}
      </TextWrap>
    </Container>
  )
}

const Container = styled.div``
