import { useCallback, useState, useRef } from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"
import { useBodyScrollLock } from "hooks/useBodyScrollLock"

interface Props {
  /**
   * モーダルを開いている間の背景スクロールを禁止する場合 true
   */
  preventScroll?: boolean
  /**
   * モーダル以外の部分をクリックしたときに閉じる場合 true
   */
  closeOnOverlayClick?: boolean
}

/**
 * Modalの機能を提供するhooks
 * 常にbody直下にマウントする
 */
export const useModal = ({
  preventScroll,
  closeOnOverlayClick,
}: Props = {}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isScrollable, setIsScrollable] = useState<boolean>(true)
  const target = useRef<HTMLDivElement>(null)

  useBodyScrollLock({ isScrollable, target })

  const open = useCallback(() => {
    setIsOpen(true)
    if (preventScroll) {
      setIsScrollable(false)
    }
  }, [preventScroll])

  const close = useCallback(() => {
    setIsOpen(false)
    if (preventScroll) {
      setIsScrollable(true)
    }
  }, [preventScroll])

  const onOverlayClick = useCallback(() => {
    if (closeOnOverlayClick) {
      close()
    }
  }, [closeOnOverlayClick, close])

  const ModalPortal = useCallback(
    ({ children }) => {
      if (!isOpen) {
        return null
      }
      return createPortal(
        <Container>
          <Overlay onClick={onOverlayClick} />
          <Wrapper>
            <Content ref={target} role="dialog">
              {children}
            </Content>
          </Wrapper>
        </Container>,
        document.body,
      )
    },
    [isOpen, onOverlayClick],
  )

  return { ModalPortal, open, close, isOpen }
}

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  overflow-y: auto;
  // スクロールバーを非表示
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 101;
`
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 40px 0px;
`

const Content = styled.div`
  position: relative;
  max-width: 100%;
  z-index: 102;
`

export const ModalWrap = styled.div<{ alignCenter?: boolean }>`
  padding: 0 15px;
  // 520(width) + 15(padding) * 2
  width: 550px;
  max-width: 100%;
  text-align: ${({ alignCenter }) => alignCenter && "center"};
`
