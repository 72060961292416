import { InferType } from "yup"
import { AnyObject, RequiredObjectSchema, TypeOfShape } from "yup/lib/object"
import { RequiredStringSchema } from "yup/lib/string"
import yup, { ERROR_MESSAGE } from "lib/yup"
/**
 * 共通のスキーマを書いていく
 */

/**
 * はい・いいえ -> 日付入力の質問
 * デフォルトではoptionalなので注意
 */
export const yesNoWithDateSchema = ({ required }: { required: boolean }) => {
  return yup
    .object({
      select: required
        ? yup.number().oneOf([1, 2]).required()
        : yup.number().oneOf([1, 2]),
      date: yup.string().when("select", (select, schema) => {
        if (select === 1) return schema.isISOValid().isISOPast().required()
        if (select === 2) return schema.isISOValid().isISOFuture().required()
      }),
    })
    .required()
}

/**
 * 経費
 * すべてrequired
 */
export const costSchema = yup
  .object({
    productPurchaseAmount: yup.number().min(0).excludeNaN().required(),
    rawMaterialCosts: yup.number().min(0).excludeNaN().required(),
    outsourcedProcessingCosts: yup.number().min(0).excludeNaN().required(),
    freightCosts: yup.number().min(0).excludeNaN().required(),
    laborCost: yup.number().min(0).excludeNaN().required(),
    mySalary: yup.number().min(0).excludeNaN().required(),
    utilitiesCharge: yup.number().min(0).excludeNaN().required(),
    depreciationAndAmortization: yup.number().min(0).excludeNaN().required(),
    rent: yup.number().min(0).excludeNaN().required(),
    leasePayment: yup.number().min(0).excludeNaN().required(),
    premium: yup.number().min(0).excludeNaN().required(),
    interestExpenseAndFees: yup.number().min(0).excludeNaN().required(),
    otherCosts1: yup.number().min(0).excludeNaN().required(),
    otherCosts2: yup.number().min(0).excludeNaN().required(),
  })
  .required()

export type CostFormData = InferType<typeof costSchema>

/**
 * 行動計画
 * 全てrequired
 */
export const actionPlanSchema = yup.lazy((values) =>
  yup
    .object({
      plan: yup.string().required(),
      charge: yup.string().required(),
      startDate: yup.string().isISOValid().isISOFuture().required(),
      completedDate: values.startDate
        ? yup
            .string()
            .isISOValid()
            .isISOFuture()
            .isISOAfter(values.startDate, ERROR_MESSAGE.COMPLETED_DATE_AFTER)
            .required()
        : yup.string().isISOValid().isISOFuture().required(),
    })
    .required(),
)
/**
 * lazyを利用するとarray.of()に入れる際に型が合わないので、中身のオブジェクトの型を別で定義してasキャストしている
 */
export type ActionPlanSchema = RequiredObjectSchema<
  {
    plan: RequiredStringSchema<string | undefined, AnyObject>
    charge: RequiredStringSchema<string | undefined, AnyObject>
    startDate: RequiredStringSchema<string | undefined, AnyObject>
    completedDate: RequiredStringSchema<string | undefined, AnyObject>
  },
  AnyObject,
  TypeOfShape<{
    plan: RequiredStringSchema<string | undefined, AnyObject>
    charge: RequiredStringSchema<string | undefined, AnyObject>
    startDate: RequiredStringSchema<string | undefined, AnyObject>
    completedDate: RequiredStringSchema<string | undefined, AnyObject>
  }>
>

export type ActionPlanFormData = InferType<typeof actionPlanSchema>

/**
 * 不安要素と解決策
 * optional
 */
export const concernAndSolutionSchema = yup.object({
  concern: yup.string(),
  solution: yup.string(),
})

export type ConcernAndSolutionFormData = InferType<
  typeof concernAndSolutionSchema
>
