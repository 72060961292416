import { axiosClient } from "lib/axios-client"
import {
  LPInitialResultSendRequest,
  LPResultSendRequest,
  LPResultSendResponse,
} from "./types"

/**
 * API Path
 */
export const LP_API_PATH = {
  LP_INITIAL: "/lp/initial",
  LP: "/lp",
} as const

/**
 * LP情報処理API
 */
export const LPAPI = {
  /**
   * 初回LP回答を送信する
   */
  async sendInitialResult(payload: LPInitialResultSendRequest) {
    await axiosClient.request({
      url: "/lp/initial",
      method: "POST",
      data: payload,
    })
  },

  /**
   * 二回目以降のLP回答を送信する
   */
  async sendResult(payload: LPResultSendRequest) {
    const { data } = await axiosClient.request<LPResultSendResponse>({
      url: "/lp",
      method: "POST",
      data: payload,
    })
    return data
  },
} as const
