import { localStorageFactory } from "./factory"

const TOKEN_KEY = "sc.token"

/**
 * トークン管理を行うLocalStorageクライアント
 */
export const tokenStorage = localStorageFactory<string>({
  key: TOKEN_KEY,
  parser: (value) => value,
  serializer: (value) => value,
})
