import { useTheme } from "styled-components"
import { SvgCreater } from "utils/SvgCreater"
import { IconProps } from "./interface"

export const WarningIcon = ({ size = 24, ...rest }: IconProps) => {
  const theme = useTheme()
  return (
    <SvgCreater size={size} fill={theme.color.white} {...rest}>
      <path d="M12 5.9936L19.53 19.0036L4.47 19.0036L12 5.9936ZM2.74 18.0036C1.97 19.3336 2.93 21.0036 4.47 21.0036L19.53 21.0036C21.07 21.0036 22.03 19.3336 21.26 18.0036L13.73 4.9936C12.96 3.6636 11.04 3.6636 10.27 4.9936L2.74 18.0036ZM11 11.0036L11 13.0036C11 13.5536 11.45 14.0036 12 14.0036C12.55 14.0036 13 13.5536 13 13.0036L13 11.0036C13 10.4536 12.55 10.0036 12 10.0036C11.45 10.0036 11 10.4536 11 11.0036ZM11 16.0036L13 16.0036L13 18.0036L11 18.0036L11 16.0036Z" />
    </SvgCreater>
  )
}
