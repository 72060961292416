import * as yup from "yup"
import { lazyImport } from "utils"
import { LPQuestionId, QuestionConfig } from "./types"

// LazyImport
const { LPApplicablePage } = lazyImport(
  () => import("pages/components/lp/components/LPApplicablePage"),
  "LPApplicablePage",
)

const { LPBirthDatePage } = lazyImport(
  () => import("pages/components/lp/components/LPBirthDatePage"),
  "LPBirthDatePage",
)

const { LPNamePage } = lazyImport(
  () => import("pages/components/lp/components/LPNamePage"),
  "LPNamePage",
)

const { LPQuestion1 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion1"),
  "LPQuestion1",
)

const { LPQuestion2 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion2"),
  "LPQuestion2",
)

const { LPQuestion3 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion3"),
  "LPQuestion3",
)

const { LPQuestion4 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion4"),
  "LPQuestion4",
)

const { LPQuestion5 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion5"),
  "LPQuestion5",
)

const { LPQuestion6 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion6"),
  "LPQuestion6",
)

const { LPQuestion7 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion7"),
  "LPQuestion7",
)

const { LPQuestion8to10 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion8to10"),
  "LPQuestion8to10",
)

const { LPQuestion11 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion11"),
  "LPQuestion11",
)

const { LPQuestion12 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion12"),
  "LPQuestion12",
)

const { LPQuestion13 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion13"),
  "LPQuestion13",
)

const { LPQuestion14 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion14"),
  "LPQuestion14",
)

const { LPQuestion15to16 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion15to16"),
  "LPQuestion15to16",
)

const { LPQuestion17 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion17"),
  "LPQuestion17",
)

const { LPQuestion18 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion18"),
  "LPQuestion18",
)

const { LPQuestion19 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion19"),
  "LPQuestion19",
)

const { LPQuestion20 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion20"),
  "LPQuestion20",
)

const { LPQuestion21 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion21"),
  "LPQuestion21",
)

const { LPQuestion22 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion22"),
  "LPQuestion22",
)

const { LPQuestion23 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion23"),
  "LPQuestion23",
)

const { LPQuestion24 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion24"),
  "LPQuestion24",
)

const { LPQuestion25to26 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion25to26"),
  "LPQuestion25to26",
)

const { LPQuestion27 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion27"),
  "LPQuestion27",
)

const { LPQuestion28 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion28"),
  "LPQuestion28",
)

const { LPQuestion29 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion29"),
  "LPQuestion29",
)

const { LPQuestion30 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion30"),
  "LPQuestion30",
)

const { LPQuestion31 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion31"),
  "LPQuestion31",
)

const { LPQuestion32 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion32"),
  "LPQuestion32",
)

const { LPQuestion33 } = lazyImport(
  () => import("pages/components/lp/components/LPQuestion33"),
  "LPQuestion33",
)

export const BIRTH_MIN_DATE = new Date("1900-01-01T00:00:00")
export const BIRTH_MAX_DATE = new Date()

export const questionConfigs: Record<LPQuestionId, QuestionConfig> = {
  applicable: {
    schema: yup.boolean().required(),
    element: <LPApplicablePage />,
    next: LPQuestionId.Birthdate,
    index: 0,
  },
  birthdate: {
    schema: yup.date().min(BIRTH_MIN_DATE).max(BIRTH_MAX_DATE).required(),
    element: <LPBirthDatePage />,
    next: LPQuestionId.Name,
    index: 1,
  },
  name: {
    schema: yup.string().required(),
    element: <LPNamePage />,
    next: LPQuestionId.Q1,
    index: 2,
  },
  q1: {
    schema: yup.number().required(),
    element: <LPQuestion1 />,
    next: LPQuestionId.Q2,
    index: 3,
  },
  q2: {
    schema: yup.number().required(),
    element: <LPQuestion2 />,
    next: LPQuestionId.Q3,
    index: 4,
  },
  q3: {
    schema: yup.number().required(),
    element: <LPQuestion3 />,
    next: LPQuestionId.Q4,
    index: 5,
  },
  q4: {
    schema: yup.number().required(),
    element: <LPQuestion4 />,
    next: LPQuestionId.Q5,
    index: 6,
  },
  q5: {
    schema: yup.number().required(),
    element: <LPQuestion5 />,
    next: LPQuestionId.Q6,
    index: 7,
  },
  q6: {
    schema: yup.number().required(),
    element: <LPQuestion6 />,
    next: LPQuestionId.Q7,
    index: 8,
  },
  q7: {
    schema: yup.number().required(),
    element: <LPQuestion7 />,
    next: LPQuestionId.Q8Q10,
    index: 9,
  },
  q8q10: {
    schema: yup.array(
      yup.object({
        name: yup.string().required(),
        age: yup.number().integer().min(0).required(),
        relationship: yup.number().integer().required(),
      }),
    ),
    element: <LPQuestion8to10 />,
    next: LPQuestionId.Q11,
    index: 10,
  },
  q11: {
    schema: yup.number().required().min(0),
    element: <LPQuestion11 />,
    next: LPQuestionId.Q12,
    index: 11,
  },
  q12: {
    schema: yup.object({
      self_pension: yup.number().min(0).required(),
      spouse_pension: yup.number().min(0).required(),
    }),
    element: <LPQuestion12 />,
    next: LPQuestionId.Q13,
    index: 12,
  },
  q13: {
    schema: yup.object({
      self_monthly_income: yup.number().min(0).required(),
      self_severance_pay: yup.number().min(0).required(),
      spouse_monthly_income: yup.number().min(0).required(),
      spouse_severance_pay: yup.number().min(0).required(),
    }),
    element: <LPQuestion13 />,
    next: LPQuestionId.Q14,
    index: 13,
  },
  q14: {
    schema: yup.number().required().min(0),
    element: <LPQuestion14 />,
    next: LPQuestionId.Q15Q16,
    index: 14,
  },
  q15q16: {
    schema: yup.array(
      yup
        .object()
        .shape({
          premium: yup.number().min(1).required(),
          premium_remaining_years: yup.number().integer().min(1).required(),
        })
        .required(),
    ),
    element: <LPQuestion15to16 />,
    next: LPQuestionId.Q17,
    index: 15,
  },
  q17: {
    schema: yup.number().min(0).required(),
    element: <LPQuestion17 />,
    next: LPQuestionId.Q18,
    index: 16,
  },
  q18: {
    schema: yup.number().min(0).required(),
    element: <LPQuestion18 />,
    next: LPQuestionId.Q19,
    index: 17,
  },
  q19: {
    schema: yup.boolean().required(),
    element: <LPQuestion19 />,
    next: (formData) => (formData.q19 ? LPQuestionId.Q20 : LPQuestionId.Q24),
    index: 18,
  },
  q20: {
    schema: yup.number().integer().min(0).required(),
    element: <LPQuestion20 />,
    next: LPQuestionId.Q21,
    index: 19,
  },
  q21: {
    schema: yup
      .array(
        yup
          .object()
          .shape({
            displacement: yup.number().integer().min(1).max(6).required(),
            unit: yup.number().integer().min(0).required(),
          })
          .required(),
      )
      .required(),
    element: <LPQuestion21 />,
    next: LPQuestionId.Q22,
    index: 20,
  },
  q22: {
    element: <LPQuestion22 />,
    next: LPQuestionId.Q23,
    index: 21,
  },
  q23: {
    element: <LPQuestion23 />,
    next: LPQuestionId.Q24,
    index: 22,
  },
  q24: {
    element: <LPQuestion24 />,
    next: (formData) =>
      (formData.q24?.children_num ?? 0) >= 1
        ? LPQuestionId.Q25Q26
        : LPQuestionId.Q27,
    index: 23,
  },
  q25q26: {
    schema: yup
      .array(
        yup
          .object()
          .shape({
            chose_id: yup.number().required(),
            remaining_years: yup.number().integer().min(1).required(),
          })
          .required(),
      )
      .required(),
    element: <LPQuestion25to26 />,
    next: LPQuestionId.Q27,
    index: 24,
  },
  q27: {
    schema: yup.number().required(),
    element: <LPQuestion27 />,
    next: (formData) =>
      formData.q27 === 1 ? LPQuestionId.Q28 : LPQuestionId.Q29,
    index: 25,
  },
  q28: {
    schema: yup.number().min(0).required(),
    element: <LPQuestion28 />,
    next: LPQuestionId.Q29,
    index: 26,
  },
  q29: {
    element: <LPQuestion29 />,
    next: LPQuestionId.Q30,
    index: 27,
  },
  q30: {
    element: <LPQuestion30 />,
    next: LPQuestionId.Q31,
    index: 28,
  },
  q31: {
    element: <LPQuestion31 />,
    next: LPQuestionId.Q32,
    index: 29,
  },
  q32: {
    schema: yup
      .object()
      .shape({
        monthly: yup.number().min(0).required(),
        repayment_years: yup.number().min(0).integer().required(),
      })
      .required(),
    element: <LPQuestion32 />,
    next: LPQuestionId.Q33,
    index: 30,
  },
  q33: {
    schema: yup
      .object()
      .shape({
        saving_amount: yup.number().min(0).required(),
        listed_stock: yup.number().min(0).required(),
        investment_trust: yup.number().min(0).required(),
        investment: yup.number().min(0).required(),
      })
      .required(),
    element: <LPQuestion33 />,
    next: "finish",
    index: 31,
  },
}
