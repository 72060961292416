import { ReactNode } from "react"
import styled, { useTheme } from "styled-components"
import { Character, Name, Status } from "components/atoms/Character"
import { TextWrap } from "components/atoms/TextWrap"

type Direction = "leading" | "trailing"

const QUOTE_PADDING_X = 28
const QUOTE_PADDING_BOTTOM = 29

interface Props {
  /**
   * キャラクターの名前 "kei-chan"|"syou-chan"
   */
  name: Name
  /**
   * キャラクターの動き "happy"|"left"|"praise"|"right"|"scold"
   */
  status: Status
  /**
   * キャラクターを左にするか右にするか "leading" | "trailing"
   */
  direction: Direction
  /**
   * セリフ部分 string で渡す 一部色を変えたい場合は TextWrap を使う ReactNode
   */
  quote: ReactNode
}

/**
 * キャラクターの 画像&名前 と セリフ を横並びに表示するコンポーネント
 */
export const CharacterLines = ({ name, status, direction, quote }: Props) => {
  const theme = useTheme()
  return (
    <Container direction={direction}>
      <CharacterWrap>
        <Character name={name} status={status} />
        <CharacterName size="s" weight="bold" color={theme.color.nightRider}>
          {name === "kei-chan" ? "けいちゃん" : "しょうちゃん"}
        </CharacterName>
      </CharacterWrap>
      <QuoteWrap
        direction={direction}
        size="m"
        weight="bold"
        color={theme.color.nightRider}
      >
        {quote}
      </QuoteWrap>
    </Container>
  )
}

const Container = styled.div<{ direction: Direction }>`
  display: flex;
  align-items: center;
  gap: 1px;
  flex-direction: ${({ direction }) =>
    direction === "leading" ? "row" : "row-reverse"};
`

const QuoteWrap = styled(TextWrap)<{ direction: Direction }>`
  flex: 1;
  padding-bottom: ${QUOTE_PADDING_BOTTOM}px;
  ${({ direction }) =>
    direction === "leading"
      ? `padding-right: ${QUOTE_PADDING_X}px;`
      : `padding-left: ${QUOTE_PADDING_X}px;`}
`

const CharacterWrap = styled.div``

const CharacterName = styled(TextWrap)`
  text-align: center;
`
