import { ImgHTMLAttributes } from "react"
import KeichanHappy from "assets/images/kei_chan_happy.gif"
import KeichanLeft from "assets/images/kei_chan_left.gif"
import KeichanPraise from "assets/images/kei_chan_praise.gif"
import KeichanRight from "assets/images/kei_chan_right.gif"
import SyouchanHappy from "assets/images/syou_chan_happy.gif"
import SyouchanLeft from "assets/images/syou_chan_left.gif"
import SyouchanRight from "assets/images/syou_chan_right.gif"
import SyouchanScold from "assets/images/syou_chan_scold.gif"
import { unreachable } from "utils"

export type Name = "kei-chan" | "syou-chan"
export type Status = "happy" | "left" | "praise" | "right" | "scold"

const KEI_CHAN = {
  happy: KeichanHappy,
  left: KeichanLeft,
  praise: KeichanPraise,
  scold: undefined,
  right: KeichanRight,
} as const

const SYOU_CHAN = {
  happy: SyouchanHappy,
  left: SyouchanLeft,
  praise: undefined,
  scold: SyouchanScold,
  right: SyouchanRight,
} as const

const IMG_WIDTH = 183
const IMG_HEIGHT = 168

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  /**
   * キャラクターの名前 "kei-chan"|"syou-chan"
   */
  name: Name
  /**
   * キャラクターの動き "happy"|"left"|"praise"|"right"|"scold"
   */
  status: Status
}

/**
 * アニメーション付きキャラクター画像
 */
export const Character = ({
  name,
  status,
  width = IMG_WIDTH,
  height = IMG_HEIGHT,
  ...rest
}: Props) => {
  const src =
    name === "kei-chan"
      ? KEI_CHAN[status]
      : name === "syou-chan"
      ? SYOU_CHAN[status]
      : unreachable()

  if (src === undefined) {
    console.warn("Invalid character props")
  }

  return <img {...rest} src={src} width={width} height={height} />
}
