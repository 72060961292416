import styled from "styled-components"
import { TextWrap } from "components/atoms/TextWrap"
import { YesNoModal } from "components/organisms/Modals"

interface Props {
  /**
   * ログアウトのハンドラ
   */
  onLogout: () => void
  /**
   * キャンセルのハンドラ
   */
  onCancel: () => void
}

export const LogoutModal = ({ onLogout, onCancel }: Props) => {
  return (
    <YesNoModal onAgree={onLogout} onCancel={onCancel}>
      <Content>ログアウトしますか</Content>
    </YesNoModal>
  )
}

const Content = styled(TextWrap).attrs({ size: "m", weight: "bold" })`
  text-align: center;
`
