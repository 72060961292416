import { AxiosError } from "axios"
import { UseMutationOptions, useMutation } from "react-query"
import {
  MyConsultStatusTurnOnResponse,
  ManagerStatusUpdateRequest,
  ManagerStatusUpdateResponse,
  SalesStatusUpdateResponse,
  SalesStatusUpdateRequest,
  SalesStatusCreateResponse,
  SalesStatusCreateRequest,
  SalesStatusEditResponse,
  SalesStatusEditRequest,
  SalesStatusDeleteRequest,
} from "api/status/types"
import { OmitMutationConfig } from "lib/react-query"
import { StatusAPI } from "./api"

const PREFIX_ID = "status"

export const fetchMyPhaseStatusQuery = {
  queryKey: `${PREFIX_ID}/fetchMyPhaseStatus`,
  queryFn: StatusAPI.fetchMyPhaseStatus,
} as const

export const fetchMyConsultStatusQuery = {
  queryKey: `${PREFIX_ID}/fetchMyConsultStatus`,
  queryFn: StatusAPI.fetchMyConsultStatus,
} as const

export const turnOnMyConsultStatusMutation: UseMutationOptions<
  MyConsultStatusTurnOnResponse,
  AxiosError
> = {
  mutationKey: `${PREFIX_ID}/turnOnMyConsultStatus`,
  mutationFn: StatusAPI.turnOnMyConsultStatus,
}

/**
 * ログインユーザの専門家相談フラグをONにするHooks
 * @param config
 */
export function useTurnOnMyConsultStatusMutation(
  config?: OmitMutationConfig<typeof turnOnMyConsultStatusMutation>,
) {
  return useMutation({ ...turnOnMyConsultStatusMutation, ...config })
}

export const fetchPhaseStatusesQuery = {
  queryKey: `${PREFIX_ID}/fetchPhaseStatuses`,
  queryFn: StatusAPI.fetchPhaseStatuses,
} as const

export const fetchSalesStatusesQuery = {
  queryKey: `${PREFIX_ID}/fetchSalesStatuses`,
  queryFn: StatusAPI.fetchSalesStatuses,
} as const

const createSalesStatusMutation: UseMutationOptions<
  SalesStatusCreateResponse,
  AxiosError,
  SalesStatusCreateRequest
> = {
  mutationKey: `${PREFIX_ID}/createSalesStatusMutation`,
  mutationFn: StatusAPI.createSalesStatus,
}

export const useCreateSalesStatusMutation = (
  config?: OmitMutationConfig<typeof createSalesStatusMutation>,
) => useMutation({ ...createSalesStatusMutation, ...config })

const editSalesStatusNameMutation: UseMutationOptions<
  SalesStatusEditResponse,
  AxiosError,
  SalesStatusEditRequest
> = {
  mutationKey: `${PREFIX_ID}/editSalesStatusName`,
  mutationFn: StatusAPI.editSalesStatus,
}

export const useEditSalesStatusNameMutation = (
  config?: OmitMutationConfig<typeof editSalesStatusNameMutation>,
) => useMutation({ ...editSalesStatusNameMutation, ...config })

const deleteSalesStatusMutation: UseMutationOptions<
  void,
  AxiosError,
  SalesStatusDeleteRequest
> = {
  mutationKey: `${PREFIX_ID}/deleteSalesStatus`,
  mutationFn: StatusAPI.deleteSalesStatus,
}

export const useDeleteSalesStatusMutation = (
  config?: OmitMutationConfig<typeof deleteSalesStatusMutation>,
) => useMutation({ ...deleteSalesStatusMutation, ...config })

export const fetchSalesStatusQuery = (userId: string) =>
  ({
    queryKey: [FETCH_SALES_STATUS_QUERY_KEY, userId],
    queryFn: () => StatusAPI.fetchSalesStatus(userId),
  } as const)
export const FETCH_SALES_STATUS_QUERY_KEY = `${PREFIX_ID}/fetchSalesStatus`

export const updateSalesStatusMutation: UseMutationOptions<
  SalesStatusUpdateResponse,
  AxiosError,
  SalesStatusUpdateRequest
> = {
  mutationKey: `${PREFIX_ID}/updateSalesStatus`,
  mutationFn: StatusAPI.updateSalesStatus,
}

/**
 * 特定のユーザの営業ステータスを更新するHooks
 * @param config
 */
export function useUpdateSalesStatusMutation(
  config?: OmitMutationConfig<typeof updateSalesStatusMutation>,
) {
  return useMutation({ ...updateSalesStatusMutation, ...config })
}

/**
 * 担当者が存在する場合は担当者が返ってくる
 * 担当者が存在しない場合はNot Found(404)が返る
 */
export const fetchManagerStatusQuery = (userId: string) =>
  ({
    queryKey: [FETCH_MANAGER_STATUS_QUERY_KEY, userId],
    queryFn: () => StatusAPI.fetchManagerStatus(userId),
  } as const)
// userIdを指定せずにinvalidationを行うためにkeyの前半を定数化している
export const FETCH_MANAGER_STATUS_QUERY_KEY = `${PREFIX_ID}/fetchManagerStatus`

export const updateManagerStatusMutation: UseMutationOptions<
  ManagerStatusUpdateResponse,
  AxiosError,
  ManagerStatusUpdateRequest
> = {
  mutationKey: `${PREFIX_ID}/updateManagerStatus`,
  mutationFn: StatusAPI.updateManagerStatus,
}

/**
 * 特定のユーザの担当者ステータスを更新するHooks
 * @param config
 */
export function useUpdateManagerStatusMutation(
  config?: OmitMutationConfig<typeof updateManagerStatusMutation>,
) {
  return useMutation({ ...updateManagerStatusMutation, ...config })
}

export const fetchConsultStatusQuery = (userId: string) =>
  ({
    queryKey: `${PREFIX_ID}/fetchConsultStatus/${userId}`,
    queryFn: () => StatusAPI.fetchConsultStatus(userId),
  } as const)

export const fetchPhaseStatusQuery = (userId: string) =>
  ({
    queryKey: `${PREFIX_ID}/fetchPhaseStatus/${userId}`,
    queryFn: () => StatusAPI.fetchPhaseStatus(userId),
  } as const)
