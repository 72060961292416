import { useEffect } from "react"
import * as React from "react"
import { useFloating } from "@floating-ui/react-dom"
import { AnimatePresence, motion, Variants } from "framer-motion"
import { createPortal } from "react-dom"
import styled, { css } from "styled-components"
import { Button } from "components/atoms/Button"
import { Line } from "components/atoms/Line"
import { Logo } from "components/atoms/Logo"
import { Menu } from "components/atoms/Menu"
import { ScrollStyles } from "components/atoms/Scroll"
import { TextWrap } from "components/atoms/TextWrap"
import { HeaderPullDown } from "components/molecules/HeaderPullDown"
import { useBoolean } from "hooks/useBoolean"
import { useMediaGreaterThan } from "hooks/useMedia"
import { appMedia } from "theme"
import { noop, px, unreachable } from "utils"
import { MenuItem, MenuLeafNode, MenuNode } from "./types"

/**
 * デスクトップ向けメニュー項目の中央揃えに対するOffset
 */
const MENU_ITEM_OFFSET = 10

export const HEADER_HEIGHT_TABLET = 80
export const HEADER_HEIGHT_DESKTOP = 85

const variants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}

/**
 * MenuItemからMenuLeafNodeのみを抜き出す
 * @param node MenuItem
 * @returns MenuLeafNode
 */
function retriveLeafNode(node: MenuItem): MenuLeafNode[] {
  if (node.type === "parent") {
    return node.children
  }
  return [node]
}

const isVisibleForDesktop = (item: MenuNode) =>
  item.visibleFor === "all" ||
  item.visibleFor === "desktop" ||
  item.visibleFor === undefined

const isVisibleForMobile = (item: MenuNode) =>
  item.visibleFor === "all" ||
  item.visibleFor === "mobile" ||
  item.visibleFor === undefined

interface GeneralHeaderProps {
  menuItems?: MenuItem[]
  fixed?: boolean
  onLogoClick?: () => void
}

/**
 * 一般マイページ用Header
 */
export const GeneralHeader: React.VFC<GeneralHeaderProps> = ({
  menuItems,
  fixed = false,
  onLogoClick = noop,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useBoolean(false)
  const isDesktop = useMediaGreaterThan("desktop")
  const { x, y, strategy, reference, floating } = useFloating({
    placement: "bottom",
  })

  useEffect(() => {
    if (isDesktop) {
      setIsMenuOpen.off()
    }
  }, [isDesktop, setIsMenuOpen])

  return (
    <Container fixed={fixed}>
      <MenuContainer ref={reference}>
        <LogoContainer onClick={onLogoClick}>
          <Logo variant={isDesktop ? "desktop" : "mobile"} />
        </LogoContainer>
        {/* MEMO: ここがデスクトップ向けのメニューアイテム */}
        {isDesktop &&
          menuItems &&
          menuItems.filter(isVisibleForDesktop).map((item) =>
            item.type === "leaf" ? (
              <MenuItemButton
                key={item.label}
                shape="noFrame"
                color="gray"
                onClick={item.action}
                disabled={item.disabled ?? false}
              >
                <MenuItemLabel
                  isSelected={item.isActive ?? false}
                  ellipsis={1}
                  disabled={item.disabled ?? false}
                >
                  {item.label}
                </MenuItemLabel>
              </MenuItemButton>
            ) : item.type === "parent" ? (
              <MenuItemPullDown
                key={item.label}
                menus={item.children.map((item) => ({
                  label: item.label,
                  onClick: item.action,
                  disabled: item.disabled,
                }))}
                label={item.label}
              />
            ) : (
              unreachable()
            ),
          )}
        {/* MEMO: 以下がモバイル端末向けのメニューアイテム */}
        {!isDesktop && menuItems && (
          <>
            <Menu size="s" isOpen={isMenuOpen} onClick={setIsMenuOpen.toggle} />
            {createPortal(
              <AnimatePresence>
                {isMenuOpen && (
                  <MobileMenuContainer
                    ref={floating}
                    variants={variants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    style={{
                      position: strategy,
                      left: x ?? "",
                      top: y ?? "",
                    }}
                  >
                    {menuItems
                      .flatMap(retriveLeafNode)
                      .filter(isVisibleForMobile)
                      .map((item) => (
                        <MobileMenuButton
                          key={item.label}
                          shape="noFrame"
                          color="gray"
                          disabled={item.disabled ?? false}
                          onClick={() => {
                            item.action()
                            setIsMenuOpen.off()
                          }}
                        >
                          {item.label}
                        </MobileMenuButton>
                      ))}
                  </MobileMenuContainer>
                )}
              </AnimatePresence>,
              document.body,
            )}
          </>
        )}
      </MenuContainer>
      <AnimatePresence initial={false}>
        {!isMenuOpen && (
          <LineWrapper
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <Line type="light" />
          </LineWrapper>
        )}
      </AnimatePresence>
    </Container>
  )
}

const Container = styled.header<{ fixed: boolean }>`
  flex: none;
  background-color: ${({ theme }) => theme.color.white};
  overflow: hidden;
  width: 100%;
  z-index: 1;

  ${({ fixed }) =>
    fixed &&
    css`
      position: fixed;
      top: 0;
      left: 0;
    `}
`

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${px(HEADER_HEIGHT_DESKTOP)};
  margin: 0 28px 0 14px;

  ${appMedia.lessThan("desktop")`
    height: ${px(HEADER_HEIGHT_TABLET)};
    margin: 0 17px 0 10px;
  `}
`

const LogoContainer = styled.button`
  /* MEMO: LogoのY軸方向の調整 */
  align-self: flex-start;
  padding-top: 2px;
  cursor: pointer;

  ${appMedia.lessThan("desktop")`
    padding-top: 9px;
  `}
`

const MenuItemButton = styled(Button)`
  flex-shrink: 1;
  width: initial;
  padding: ${MENU_ITEM_OFFSET}px 20px 0;
`

const MenuItemPullDown = styled(HeaderPullDown)`
  padding-top: ${MENU_ITEM_OFFSET}px;
`

const MenuItemLabel = styled(TextWrap)<{
  isSelected: boolean
  disabled: boolean
}>`
  color: ${({ theme, isSelected, disabled }) =>
    disabled
      ? theme.color.silver
      : isSelected
      ? theme.color.nightRider
      : theme.color.nobel};
  transition: color 0.2s;
  cursor: ${({ disabled }) => disabled && "not-allowed"};

  :hover {
    color: ${({ theme, disabled }) => !disabled && theme.color.nightRider};
  }
`

const MobileMenuContainer = styled(motion.div)`
  ${ScrollStyles}
  overflow-y: overlay;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  padding: 10px;
  background-color: ${({ theme }) => theme.color.white};
`

const MobileMenuButton = styled(Button)<{ disabled: boolean }>`
  color: ${({ theme, disabled }) =>
    disabled ? theme.color.silver : theme.color.nightRider};
  transition: color 0.2s;
`

const LineWrapper = styled(motion.div)``
