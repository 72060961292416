import { useCallback } from "react"
import axios from "axios"
import { format } from "date-fns/esm"
import fileDownload from "js-file-download"
import { Phase1Diagnosis } from "api"
import { now } from "utils"

interface DownloadProps {
  /**
   * ダウンロードファイルの絶対url
   */
  url: string
  /**
   * ファイル名
   */
  filename: string
}

/**
 * blobオブジェクトをダウンロードするカスタムフック
 * 返り値のdownload関数にurlとファイル名を渡す
 */
export const useFileDownload = () => {
  const download = useCallback(async ({ url, filename }: DownloadProps) => {
    const { data } = await axios({
      url: url,
      method: "GET",
      responseType: "blob",
    })
    fileDownload(data, filename)
  }, [])
  return { download } as const
}

/**
 * 顧客データ用ファイル名を取得する
 */
export function getClientDataFilename() {
  return `client-data_${format(now(), "yyyyMMdd_t")}.csv`
}

/**
 * ライフプランPDFのファイル名を取得する
 * @param date 実施日
 */
export function getLPPDFFilename(date: Date) {
  return `life-plan_${format(date, "yyyyMMdd")}.pdf`
}

/**
 * 計画表Excelのファイル名を取得する
 * @param date 実施日
 */
export function getPhase2ExcelFilename(date: Date) {
  return `phase2_plan_${format(date, "yyyyMMdd")}.xlsx`
}

export function isValidPhase2ExcelDownload(diagnosis: Phase1Diagnosis) {
  return (
    diagnosis === "AA" ||
    diagnosis === "IA" ||
    diagnosis === "UA" ||
    diagnosis === "EA" ||
    diagnosis === "AB" ||
    diagnosis === "IB" ||
    diagnosis === "UB" ||
    diagnosis === "EB" ||
    diagnosis === "AD" ||
    diagnosis === "ID" ||
    diagnosis === "UD" ||
    diagnosis === "ED" ||
    diagnosis === "AG" ||
    diagnosis === "IG" ||
    diagnosis === "UG" ||
    diagnosis === "EG"
  )
}
