import { yupResolver } from "@hookform/resolvers/yup"
import { DefaultValues, useForm, UseFormProps } from "react-hook-form"
import * as yup from "yup"
import { LPFormData } from "../types"
import { useLPFormData, useLPRoute } from "."

/**
 * 各質問項目に対するuseForm
 * @param questionId 質問ID
 * @param options useFormにわたすオプション
 */
export function useLPForm<T extends keyof LPFormData>(
  questionId: T,
  options?: UseFormProps<Pick<LPFormData, T>>,
) {
  const { formData } = useLPFormData()
  const { schema } = useLPRoute(questionId)

  const localSchema =
    schema !== undefined
      ? yup
          .object()
          .shape({ [questionId]: schema })
          .required()
      : undefined

  // MEMO: 型がうまくあわないためasキャストしている. (NestedValue関係の型不適合が発生するが動作に支障はなし)
  const defaultValues = {
    [questionId]: formData[questionId],
  } as DefaultValues<Pick<LPFormData, T>>

  return useForm<Pick<LPFormData, typeof questionId>>({
    mode: "onChange",
    resolver: yupResolver(localSchema),
    defaultValues,
    ...options,
  })
}
