import { NumberTypeAnswer, QA, Unit } from "api"
import { NumberFormData } from "pages/utils/types/phase2FormData"
import { DeepPartial } from "utils"

/**
 * 数値入力の設問
 */
export const numberDraftTranslator = (
  answer: DeepPartial<QA<NumberTypeAnswer & Unit>> | undefined,
): NumberFormData | undefined => answer?.answer?.content
