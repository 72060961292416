import { ReactNode } from "react"
import styled from "styled-components"
import { TextWrap } from "components/atoms/TextWrap"
import { YesNoModal } from "./YesNoModal"

type Phase = "lp" | "phase1" | "phase2"

interface Props {
  phase: Phase
  onAgree: () => void
  onCancel: () => void
}

const getText = (phase: Phase): ReactNode => {
  if (phase === "lp") {
    return (
      <>
        ライフプランを再診断しますか？
        <br />
        フェーズ１とフェーズ２の一時保存されたデータがある場合、それらは消去されます。
      </>
    )
  } else if (phase === "phase1") {
    return (
      <>
        フェーズ１を再診断しますか？
        <br />
        フェーズ１とフェーズ２の一時保存されたデータがある場合、それらは消去されます。
      </>
    )
  } else if (phase === "phase2") {
    return (
      <>
        フェーズ２を再診断しますか？
        <br />
        フェーズ２の一時保存されたデータとやることリストの進行状況は消去されます。
      </>
    )
  }
}

/**
 * 再診断を行う際の確認モーダル
 */
export const ReconsultConfirmModal = ({ phase, onAgree, onCancel }: Props) => {
  const text = getText(phase)

  return (
    <YesNoModal onAgree={onAgree} onCancel={onCancel}>
      <StyledTextWrap>{text}</StyledTextWrap>
    </YesNoModal>
  )
}

const StyledTextWrap = styled(TextWrap)`
  text-align: center;
`
