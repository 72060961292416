import { AxiosError } from "axios"
import { useMutation, UseMutationOptions } from "react-query"
import { OmitMutationConfig } from "lib/react-query"
import { MemoAPI } from "./api"
import {
  MemoCreateRequest,
  MemoUpdateRequest,
  MemoDeleteRequest,
  MemoUpdateResponse,
} from "./types"

const PREFIX_ID = "memo"

export const fetchMemosQuery = (userId: string) =>
  ({
    queryKey: `${PREFIX_ID}/fetchMemos/${userId}`,
    queryFn: () => MemoAPI.fetchMemos(userId),
  } as const)

export const fetchMemoQuery = (userId: string, memoId: number) =>
  ({
    queryKey: `${PREFIX_ID}/fetchMemo/${userId}/${memoId}`,
    queryFn: () => MemoAPI.fetchMemo({ userId, memoId }),
  } as const)

export const createMemoMutation: UseMutationOptions<
  void,
  AxiosError,
  MemoCreateRequest
> = {
  mutationKey: `${PREFIX_ID}/createMemo`,
  mutationFn: MemoAPI.createMemo,
}

/**
 * 指定したユーザーに関するメモを作成するHook
 * @param config
 */
export function useCreateMemoMutation(
  config?: OmitMutationConfig<typeof createMemoMutation>,
) {
  return useMutation({ ...createMemoMutation, ...config })
}

export const updateMemoMutation: UseMutationOptions<
  MemoUpdateResponse,
  AxiosError,
  MemoUpdateRequest
> = {
  mutationKey: `${PREFIX_ID}/updateMemo`,
  mutationFn: MemoAPI.updateMemo,
}

/**
 * 指定したメモを更新するHook
 * @param config
 */
export function useUpdateMemoMutation(
  config?: OmitMutationConfig<typeof updateMemoMutation>,
) {
  return useMutation({ ...updateMemoMutation, ...config })
}

export const deleteMemoMutation: UseMutationOptions<
  void,
  AxiosError,
  MemoDeleteRequest
> = {
  mutationKey: `${PREFIX_ID}/deleteMemo`,
  mutationFn: MemoAPI.deleteMemo,
}

/**
 * 指定したメモを削除するHook
 * @param config
 */
export function useDeleteMemoMutation(
  config?: OmitMutationConfig<typeof deleteMemoMutation>,
) {
  return useMutation({ ...deleteMemoMutation, ...config })
}
