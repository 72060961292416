import { axiosClient } from "lib/axios-client"
import {
  AccountEmailUpdateRequest,
  AccountEmailVerifyRequest,
  AccountPasswordResetRequest,
  AccountPasswordUpdateRequest,
} from "./types"

export const AccountAPI = {
  /**
   * パスワード再設定
   */
  resetPassword: async (payload: AccountPasswordResetRequest) => {
    await axiosClient.request({
      url: "/account/password/reset",
      method: "PATCH",
      data: payload,
    })
  },

  /**
   * メールアドレス更新
   */
  updateEmail: async (payload: AccountEmailUpdateRequest) => {
    await axiosClient.request({
      url: "/account/email",
      method: "POST",
      data: payload,
    })
  },

  /**
   * メールアドレス更新認証
   */
  verifyEmail: async (payload: AccountEmailVerifyRequest) => {
    await axiosClient.request({
      url: "/account/email/verify",
      method: "POST",
      data: payload,
    })
  },

  /**
   * パスワード更新
   */
  updatePassword: async (payload: AccountPasswordUpdateRequest) => {
    await axiosClient.request({
      url: "/account/password",
      method: "PATCH",
      data: payload,
    })
  },
} as const
