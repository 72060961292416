import { Phase2AnswerG } from "api/types/phase2/g"
import phase2G from "assets/json/phase2/g.json"
import { Phase2GFormData } from "pages/components/phase2/components/G/schema"
import { Phase2Type } from "pages/components/phase2/utils"
import { DeepPartial } from "utils"
import {
  actionPlanTranslator,
  arrayCostTranslator,
  averageSalaryTranslator,
  concernAndSolutionTranslator,
  liabilitiesTranslator,
  numberArrayTranslator,
  numberTranslator,
  objectCostTranslator,
  selectAndDateTranslator,
  selectTranslator,
} from "./utils"

type Diagnosis = Extract<Phase2Type, "AG" | "IG" | "UG" | "EG">

interface Args {
  diagnosis: Diagnosis
  formData: DeepPartial<Phase2GFormData>
}

// TODO: phase2ResultAnswerがマージされたらそちらを使う
interface Return {
  diagnosis: Diagnosis
  answer: Phase2AnswerG
}

export const phase2GFormDataTranslator = ({
  diagnosis,
  formData,
}: Args): Return => {
  return {
    diagnosis: diagnosis,
    answer: {
      question_group_1: {
        title: phase2G.title1,
        answers: {
          answer_01: selectTranslator({
            json: phase2G.q1,
            formData: formData.q1,
          }),
          answer_02: selectAndDateTranslator({
            json: phase2G.q2,
            formData: formData.q2,
          }),
          answer_03: numberTranslator({
            json: phase2G.q3,
            formData: formData.q3,
          }),
        },
      },
      question_group_2: {
        title: phase2G.title2,
        answers: {
          // answer_04: 表示のみ
          // answer_05: 表示のみ
          answer_06: selectAndDateTranslator({
            json: phase2G.q6,
            formData: formData.q6,
          }),
          answer_07: selectAndDateTranslator({
            json: phase2G.q7,
            formData: formData.q7,
          }),
        },
      },
      question_group_3: {
        title: phase2G.title3,
        answers: {
          answer_08: numberArrayTranslator({
            json: phase2G.q8,
            formData: formData.q8,
          }),
          answer_09: arrayCostTranslator({
            json: phase2G.q9,
            formData: formData.q9,
          }),
          answer_10: liabilitiesTranslator({
            json: phase2G.q10,
            formData: formData.q10,
          }),
          answer_11: numberArrayTranslator({
            json: phase2G.q11,
            formData: formData.q11,
          }),
          answer_12: numberTranslator({
            json: phase2G.q12,
            formData: formData.q12,
          }),
          answer_13: objectCostTranslator({
            json: phase2G.q13,
            formData: formData.q13,
          }),
          answer_14: numberTranslator({
            json: phase2G.q14,
            formData: formData.q14,
          }),
          answer_15: numberArrayTranslator({
            json: phase2G.q15,
            formData: formData.q15,
          }),
          answer_16: arrayCostTranslator({
            json: phase2G.q16,
            formData: formData.q16,
          }),
          answer_17: numberArrayTranslator({
            json: phase2G.q17,
            formData: formData.q17,
          }),
          // answer_18: 表示のみ
          answer_19: numberTranslator({
            json: phase2G.q19,
            formData: formData.q19,
          }),
          answer_20: numberArrayTranslator({
            json: phase2G.q20,
            formData: formData.q20,
          }),
          answer_21: averageSalaryTranslator({
            json: phase2G.q21,
            formData: formData.q21,
          }),
          answer_22_24: actionPlanTranslator({
            json: phase2G.q22q24,
            formData: formData.q22q24,
          }),
          answer_25: concernAndSolutionTranslator({
            json: phase2G.q25,
            formData: formData.q25,
          }),
        },
      },
    },
  }
}
