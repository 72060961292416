import { ResultsAPI } from "./api"

const PREFIX_ID = "results"

export const fetchMyLPResultsQuery = {
  queryKey: `${PREFIX_ID}/fetchMyLPResults`,
  queryFn: ResultsAPI.fetchMyLPResults,
} as const

export const fetchLPResultsQuery = (userId?: string) => {
  if (userId) {
    return {
      queryKey: `${PREFIX_ID}/fetchLPResults/${userId}`,
      queryFn: () => ResultsAPI.fetchLPResults(userId),
    } as const
  }
}

export const fetchLPResultQuery = (lpId?: number) => {
  if (lpId) {
    return {
      queryKey: `${PREFIX_ID}/fetchLpResult/${lpId}`,
      queryFn: () => ResultsAPI.fetchLPResult(lpId),
    }
  }
}

export const fetchLPAggregatedResultsQuery = (lpId?: number) => {
  if (lpId) {
    return {
      queryKey: `${PREFIX_ID}/fetchLPAggregatedResults/${lpId}`,
      queryFn: () => ResultsAPI.fetchLPAggregatedResult(lpId),
    }
  }
}

export const fetchUserLPAggregatedResultsQuery = (
  userId?: string,
  lpId?: number,
) => {
  if (userId !== undefined && lpId !== undefined) {
    return {
      queryKey: `${PREFIX_ID}/fetchUserLPAggregatedResults/${userId}/${lpId}`,
      queryFn: () => ResultsAPI.fetchUserLPAggregatedResult(userId, lpId),
    }
  }
}

export const fetchUserLPResultQuery = (userId?: string, lpId?: number) => {
  if (userId !== undefined && lpId !== undefined) {
    return {
      queryKey: `${PREFIX_ID}/fetchUserLpResult/${userId}/${lpId}`,
      queryFn: () => ResultsAPI.fetchUserLpResult(userId, lpId),
    }
  }
}

export const fetchPhase1DiagnosisResultQuery = (phase1Id?: number) => {
  if (phase1Id) {
    return {
      queryKey: `${PREFIX_ID}/fetchPhase1DiagnosisResult/${phase1Id}`,
      queryFn: () => ResultsAPI.fetchPhase1DiagnosisResult(phase1Id),
    }
  }
}

export const fetchUserPhase1DiagnosisResultQuery = (
  userId?: string,
  phase1Id?: number,
) => {
  if (userId !== undefined && phase1Id !== undefined) {
    return {
      queryKey: `${PREFIX_ID}/fetchUserPhase1DiagnosisResult/${userId}/${phase1Id}`,
      queryFn: () =>
        ResultsAPI.fetchUserPhase1DiagnosisResult(userId, phase1Id),
    }
  }
}

// MEMO: deficitは 2000万円 - lp100歳時点の残金
export const fetchLpResultCategoryQuery = {
  queryKey: `${PREFIX_ID}/fetchLpResultCategoryQuery`,
  queryFn: ResultsAPI.fetchLpCategory,
}

export const fetchPhase1ResultQuery = (phase1Id?: number) => {
  if (phase1Id) {
    return {
      queryKey: `${PREFIX_ID}/fetchPhase1Result/${phase1Id}`,
      queryFn: () => ResultsAPI.fetchPhase1Result(phase1Id),
    }
  }
}

export const fetchUserPhase1ResultQuery = (
  userId?: string,
  phase1Id?: number,
) => {
  if (userId !== undefined && phase1Id !== undefined) {
    return {
      queryKey: `${PREFIX_ID}/fetchUserPhase1Result/${userId}/${phase1Id}`,
      queryFn: () => ResultsAPI.fetchUserPhase1Result(userId, phase1Id),
    }
  }
}

export const fetchPhase1LatestDiagnosisQuery = {
  queryKey: `${PREFIX_ID}/fetchPhase1LatestDiagnosis`,
  queryFn: () => ResultsAPI.fetchPhase1LatestDiagnosis(),
}

export const fetchPhase2ResultQuery = (phase2Id?: number) => {
  if (phase2Id !== undefined) {
    return {
      queryKey: `${PREFIX_ID}/fetchPhase2Result/${phase2Id}`,
      queryFn: () => ResultsAPI.fetchPhase2Result(phase2Id),
    }
  }
}

export const fetchUserPhase2ResultQuery = (
  userId?: string,
  phase2Id?: number,
) => {
  if (userId !== undefined && phase2Id !== undefined) {
    return {
      queryKey: `${PREFIX_ID}/fetchUserPhase2Result/${userId}/${phase2Id}`,
      queryFn: () => ResultsAPI.fetchUserPhase2Result(userId, phase2Id),
    }
  }
}
