import { isAfter, isFuture, isPast, isToday, isValid, parseISO } from "date-fns"
import * as yup from "yup"

// MEMO: フェーズ１以前はこれを使っておらず、ライブラリを直接インポートしている

/**
 * それぞれのスキーマで個別のエラーメッセージが必要になったらこれに追加して使う
 */
export const ERROR_MESSAGE = {
  INVALID_DATE: "有効な日付を入力してください",
  PAST: "現在より後の日付を入力してください",
  FUTURE: "現在より前の日付を入力して下さい",
  AFTER: "${date}より後の日付を入力してください",
  COMPLETED_DATE_AFTER: "着手時期よりも後の日付を入力してください",
  DELETE_LAST_SALES_STATUS_TAG: "営業ステータスの個数を0にすることはできません",
}

/**
 * デフォルトのエラー文
 */
yup.setLocale({
  mixed: {
    default: "入力エラーです",
    required: "入力してください",
  },
  number: {
    min: ({ min }) => `${min}以上の値を入力してください`,
    max: ({ max }) => `${max}以下の値を入力してください`,
    positive: "正の数を入力してください",
    negative: "負の数を入力してください",
    integer: "整数を入力してください",
  },
  string: {
    min: ({ min }) => `${min}文字以上入力してください`,
    max: ({ max }) => `${max}文字以下を入力してください`,
  },
  array: {
    min: ({ min }) => `${min}以上入力してください`,
    max: ({ max }) => `${max}以下入力してください`,
  },
})

/**
 * ISO文字列が適切な日付を指しているかどうか
 */
yup.addMethod(yup.string, "isISOValid", function () {
  return this.test(
    "isISOValid",
    ERROR_MESSAGE.INVALID_DATE,
    (value): boolean => {
      if (value === undefined) {
        return false
      }
      return isValid(parseISO(value))
    },
  )
})

/**
 * ISO文字列が現在より後の日付を指しているかどうか(入力値がtodayでもOK)
 */
yup.addMethod(yup.string, "isISOFuture", function () {
  return this.test("isISOFuture", ERROR_MESSAGE.PAST, (value): boolean => {
    if (value === undefined) {
      return false
    }
    const date = new Date(value)

    return isToday(date) || isFuture(date)
  })
})

/**
 * ISO文字列が現在より前の日付を指しているかどうか(入力値がtodayでもOK)
 */
yup.addMethod(yup.string, "isISOPast", function () {
  return this.test("isISOPast", ERROR_MESSAGE.FUTURE, (value): boolean => {
    if (value === undefined) {
      return false
    }

    const date = new Date(value)

    return isToday(date) || isPast(date)
  })
})

/**
 * valueがISO文字列が引数として渡されたISO文字列よりも後かどうか判定
 */
yup.addMethod(
  yup.string,
  "isISOAfter",
  function (ISOStringToCompare: string, message?: string) {
    return this.test(
      "isISOAfter",
      message ?? ERROR_MESSAGE.AFTER.replace("${date}", ISOStringToCompare),
      (value) => {
        if (value === undefined) {
          return false
        }

        const date = new Date(value)
        const dateToCompare = new Date(ISOStringToCompare)

        return isAfter(date, dateToCompare)
      },
    )
  },
)

/**
 * NaNが入ってきた場合undefinedに変換する
 * type="number"かつvalueAsNumberのInputは未入力の場合NaNを返してしまうことへの対策
 */
yup.addMethod(yup.number, "excludeNaN", function () {
  return this.transform((value) => (isNaN(value) ? undefined : value))
})

/**
 * メソッドを追加したらここで型を拡張する
 */
declare module "yup" {
  interface StringSchema {
    isISOValid(): StringSchema
    isISOFuture(): StringSchema
    isISOPast(): StringSchema
    isISOAfter(ISOStringToCompare: string, message?: string): StringSchema
  }
  interface NumberSchema {
    excludeNaN(): NumberSchema
  }
}

export default yup
