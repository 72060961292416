import { useCallback, useState } from "react"
import constate from "constate"
import { useSearchParams } from "react-router-dom"
import { useVerifyInvitationMutation } from "api/invitation"

type VerificationStatus =
  | "idle"
  | "verifing"
  | "success"
  | "invalid-error"
  | "insufficient-error"

/**
 * 招待リンク系処理
 */
export const [InvIdProvider, useInvId] = constate(() => {
  const [searchParam] = useSearchParams()
  const [invitationId] = useState(() => searchParam.get("invite") ?? undefined)
  const [status, setStatus] = useState<VerificationStatus>(() => {
    if (invitationId === undefined || invitationId === "") {
      return "invalid-error"
    }
    return "idle"
  })

  const { mutateAsync: verifyMutation } = useVerifyInvitationMutation()

  /**
   * 招待IDの検証
   */
  const verify = useCallback(async () => {
    if (invitationId === undefined) {
      setStatus("invalid-error")
      return false
    }

    setStatus("verifing")
    const { is_valid, error_type } = await verifyMutation(invitationId)
    if (is_valid) {
      setStatus("success")
    } else if (!is_valid && error_type) {
      setStatus(
        error_type === "over_id_limit_count"
          ? "insufficient-error"
          : "invalid-error",
      )
    }

    return is_valid
  }, [invitationId, verifyMutation])

  return {
    invitationId,
    verify,
    status,
  }
})
