import { ButtonHTMLAttributes } from "react"
import * as React from "react"
import styled from "styled-components"
import { TextWrap } from "../TextWrap"

const ITEM_WIDTH = 167
const ITEM_HEIGHT = 58

export const HeaderPullDown: React.FC<
  ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, ...rest }) => {
  return (
    <Container {...rest}>
      <Label size="m" weight="bold" color="inherit" ellipsis={2}>
        {children}
      </Label>
    </Container>
  )
}

const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${ITEM_WIDTH}px;
  height: ${ITEM_HEIGHT}px;
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.nightRider};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  outline: none;
  transition: background-color 0.1s, color 0.1s;

  :hover {
    background-color: ${({ theme }) => theme.color.pigmentGreen};
    color: ${({ theme }) => theme.color.white};
  }
  :disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.color.silver};
    background-color: ${({ theme }) => theme.color.white};
  }
`

const Label = styled(TextWrap)`
  text-align: center;
`
