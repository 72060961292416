import { useCallback } from "react"
import { useCreateMyConsultMutation } from "api"

/**
 * phase2の相談を作成する関数を返すカスタムフック
 */
export const usePhase2CreateConsultation = () => {
  const { mutateAsync: sendConsultation } = useCreateMyConsultMutation()

  const createConsultation = useCallback(
    (question: string) => {
      sendConsultation({ type: "phase2", text: question })
    },
    [sendConsultation],
  )

  return { createConsultation }
}
