import { TransferringStock } from "api/types/phase2/utils"
import { TransferringStockFormData } from "pages/utils/types/phase2FormData"

/**
 * 株式の移転計画（A Q23）
 */
export const transferringStockDraftTranslator = (
  answer: TransferringStock | undefined,
): TransferringStockFormData | undefined => ({
  select: answer?.answer?.content?.select?.choice_number,
  stocks: answer?.answer?.content?.transferring_stock?.content?.map(
    ({ president, successor } = {}) => ({
      president: president?.content,
      successor: successor?.content,
    }),
  ),
  date: answer?.answer?.content?.execution_date?.content,
})
