import { AxiosError } from "axios"
import { useMutation, UseMutationOptions } from "react-query"
import { Phase1API } from "api/phase1/api"
import {
  Phase1CreateRequest,
  Phase1CreateResponse,
  Phase1DraftUpdateRequest,
} from "api/phase1/types"
import { OmitQueryConfig } from "lib/react-query"

const PREFIX_ID = "phase1"

export const sendPhase1Mutation: UseMutationOptions<
  Phase1CreateResponse,
  AxiosError,
  Phase1CreateRequest
> = {
  mutationKey: `${PREFIX_ID}/sendResult`,
  mutationFn: Phase1API.sendResult,
}

/**
 * Phase1解答の作成Hooks
 */
export function useSendPhase1ResultMutation(
  config?: OmitQueryConfig<typeof sendPhase1Mutation>,
) {
  return useMutation({ ...sendPhase1Mutation, ...config })
}

/**
 * Phase1の下書きを取得する
 */
export const fetchPhase1DraftQuery = {
  queryKey: `${PREFIX_ID}/fetchDraft`,
  queryFn: Phase1API.fetchDraft,
}

export const updatePhase1DraftMutation: UseMutationOptions<
  void,
  AxiosError,
  Phase1DraftUpdateRequest
> = {
  mutationKey: `${PREFIX_ID}/updateDraft`,
  mutationFn: Phase1API.updateDraft,
}

/**
 * Phase1下書き回答更新
 */
export function useUpdatePhase1DraftMutation(
  config?: OmitQueryConfig<typeof updatePhase1DraftMutation>,
) {
  return useMutation({ ...updatePhase1DraftMutation, ...config })
}

export const deletePhase1DraftMutation: UseMutationOptions<
  void,
  AxiosError,
  void
> = {
  mutationKey: `${PREFIX_ID}/deleteDraft`,
  mutationFn: Phase1API.deleteDraft,
}

/**
 * Phase1下書き削除
 */
export function useDeletePhase1DraftMutation(
  config?: OmitQueryConfig<typeof deletePhase1DraftMutation>,
) {
  return useMutation({ ...deletePhase1DraftMutation, ...config })
}
