import { Phase2AnswerD } from "api/types/phase2/d"
import phase2D from "assets/json/phase2/d.json"
import { Phase2DFormData } from "pages/components/phase2/components/D/schema"
import { Phase2Type } from "pages/components/phase2/utils"
import { DeepPartial } from "utils"
import {
  actionPlanTranslator,
  arrayCostTranslator,
  concernAndSolutionTranslator,
  numberArrayTranslator,
  numberTranslator,
  objectCostTranslator,
  partiallyRequiredStringArrayTranslator,
  peakSalesTranslator,
  selectAndDateTranslator,
  selectTranslator,
} from "./utils"

type Diagnosis = Extract<Phase2Type, "AD" | "ID" | "UD" | "ED">

interface Args {
  diagnosis: Diagnosis
  formData: DeepPartial<Phase2DFormData>
}

// TODO: phase2ResultAnswerがマージされたらそちらを使う
interface Return {
  diagnosis: Diagnosis
  answer: Phase2AnswerD
}

export const phase2DFormDataTranslator = ({
  diagnosis,
  formData,
}: Args): Return => {
  return {
    diagnosis: diagnosis,
    answer: {
      question_group_1: {
        title: phase2D.title1,
        answers: {
          answer_01: selectAndDateTranslator({
            json: phase2D.q1,
            formData: formData.q1,
          }),
          answer_02: selectAndDateTranslator({
            json: phase2D.q2,
            formData: formData.q2,
          }),
        },
      },
      question_group_2: {
        title: phase2D.title2,
        answers: {
          answer_03: selectAndDateTranslator({
            json: phase2D.q3,
            formData: formData.q3,
          }),
          answer_04: selectAndDateTranslator({
            json: phase2D.q4,
            formData: formData.q4,
          }),
          answer_05: selectAndDateTranslator({
            json: phase2D.q5,
            formData: formData.q5,
          }),
        },
      },
      question_group_3: {
        title: phase2D.title3,
        answers: {
          answer_06: numberTranslator({
            json: phase2D.q6,
            formData: formData.q6,
          }),
          answer_07: selectTranslator({
            json: phase2D.q7,
            formData: formData.q7,
          }),
          answer_08: numberArrayTranslator({
            json: phase2D.q8,
            formData: formData.q8,
          }),
          answer_09: arrayCostTranslator({
            json: phase2D.q9,
            formData: formData.q9,
          }),
          answer_10: numberArrayTranslator({
            json: phase2D.q10,
            formData: formData.q10,
          }),
          answer_11: peakSalesTranslator({
            json: phase2D.q11,
            formData: formData.q11,
          }),
          answer_12: numberTranslator({
            json: phase2D.q12,
            formData: formData.q12,
          }),
          answer_13: partiallyRequiredStringArrayTranslator({
            json: phase2D.q13,
            formData: formData.q13,
          }),
          answer_14: partiallyRequiredStringArrayTranslator({
            json: phase2D.q14,
            formData: formData.q14,
          }),
          answer_15: partiallyRequiredStringArrayTranslator({
            json: phase2D.q15,
            formData: formData.q15,
          }),
          answer_16: numberTranslator({
            json: phase2D.q16,
            formData: formData.q16,
          }),
          answer_17: objectCostTranslator({
            json: phase2D.q17,
            formData: formData.q17,
          }),
          answer_18: numberTranslator({
            json: phase2D.q18,
            formData: formData.q18,
          }),
          answer_19: numberArrayTranslator({
            json: phase2D.q19,
            formData: formData.q19,
          }),
          answer_20: arrayCostTranslator({
            json: phase2D.q20,
            formData: formData.q20,
          }),
          answer_21: numberArrayTranslator({
            json: phase2D.q21,
            formData: formData.q21,
          }),
          answer_22_24: actionPlanTranslator({
            json: phase2D.q22q24,
            formData: formData.q22q24,
          }),
          answer_25: concernAndSolutionTranslator({
            json: phase2D.q25,
            formData: formData.q25,
          }),
        },
      },
    },
  }
}
