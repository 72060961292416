import { Phase1CreateRequest } from "api/phase1"
import phase1 from "assets/json/phase1.json"
import phase1_result from "assets/json/phase1_result.json"
import {
  Phase1Category,
  Phase1DiagnosisResult,
  Phase1FormData,
} from "pages/components/phase1/utils"

export const phase1SendFormDataTranslator = (
  formData: Phase1FormData,
  category: Phase1Category,
  result: Phase1DiagnosisResult,
  name: string,
  deficit: number,
  reconstructionYear?: number,
): Phase1CreateRequest => ({
  diagnosis: {
    type: result,
    title: phase1_result[result].title,
    content: reconstructionYear
      ? phase1_result[result].content
          .replaceAll("${name}", name)
          .replaceAll("${year}", reconstructionYear.toString())
      : phase1_result[result].content.replaceAll("${name}", name),
    option: formData.q0 === 1 ? phase1_result.option : undefined,
  },
  answer: {
    answer_00: formData.q0
      ? {
          question: phase1.q0.question,
          answer: {
            type: "string",
            content: phase1.q0.options.find(({ id }) => formData.q0 === id)
              ?.label,
            choice_number: formData.q0,
          },
        }
      : undefined,
    answer_01: formData.q1
      ? {
          question: phase1.q1.question,
          answer: {
            type: "array",
            content: formData.q1?.map((data) => ({
              type: "string",
              content: phase1.q1.checkbox.find(({ id }) => data === id)?.label,
              choice_number: data,
            })),
          },
        }
      : undefined,
    answer_02: formData.q2
      ? {
          question: phase1.q2.question[category],
          answer: {
            type: "string",
            content: phase1.q2.options[category].find(
              ({ id }) => formData.q2 === id,
            )?.label,
            choice_number: formData.q2,
          },
        }
      : undefined,
    answer_03: formData.q3
      ? {
          question: phase1.q3.question,
          answer: {
            type: "string",
            content: phase1.q3.options.find(({ id }) => formData.q3 === id)
              ?.label,
            choice_number: formData.q3,
          },
        }
      : undefined,
    answer_04: formData.q4
      ? {
          question: phase1.q4.question[category],
          answer: {
            type: "string",
            content: phase1.q4.options.find(({ id }) => formData.q4 === id)
              ?.label,
            choice_number: formData.q4,
          },
        }
      : undefined,
    answer_05: formData.q5
      ? {
          question: phase1.q5.question,
          answer: {
            type: "string",
            content: phase1.q5.options.find(({ id }) => formData.q5 === id)
              ?.label,
            choice_number: formData.q5,
          },
        }
      : undefined,
    answer_06: formData.q6
      ? {
          question: phase1.q6.question,
          answer: {
            type: "string",
            content: phase1.q6.options.find(({ id }) => formData.q6 === id)
              ?.label,
            choice_number: formData.q6,
          },
        }
      : undefined,
    answer_07: formData.q7
      ? {
          question: phase1.q7.question,
          answer: {
            type: "string",
            content: phase1.q7.options.find(({ id }) => formData.q7 === id)
              ?.label,
            choice_number: formData.q7,
          },
        }
      : undefined,
    answer_08:
      formData.q8_1 &&
      Object.values(formData.q8_1).every((value) => value !== undefined) &&
      formData.q8_2 &&
      reconstructionYear !== undefined
        ? {
            reconstruction_calculation: {
              question: phase1["q8-1"].question,
              answer: {
                deposit: formData.q8_1?.deposit,
                ordinary_profit: formData.q8_1?.ordinaryProfit,
                depreciation: formData.q8_1?.depreciation,
                debt_principal: formData.q8_1?.debtPrincipal,
                year: reconstructionYear,
              },
            },
            reconstruction_plan: {
              question: phase1["q8-2"].question.replace(
                "${year}",
                reconstructionYear?.toString(),
              ),
              answer: {
                type: "string",
                content: phase1["q8-2"].options.find(
                  ({ id }) => formData.q8_2 === id,
                )?.label,
                choice_number: formData.q8_2,
              },
            },
          }
        : undefined,
    answer_09: formData.q9
      ? {
          question: phase1.q9.question,
          answer: {
            type: "string",
            content: phase1.q9.options.find(({ id }) => formData.q9 === id)
              ?.label,
            choice_number: formData.q9,
          },
        }
      : undefined,
    answer_10: formData.q10
      ? {
          question: phase1.q10.question.replace("${money}", deficit.toString()),
          answer: {
            type: "string",
            content: phase1.q10.options.find(({ id }) => formData.q10 === id)
              ?.label,
            choice_number: formData.q10,
          },
        }
      : undefined,
    answer_11: formData.q11
      ? {
          question: phase1.q11.question,
          answer: {
            type: "string",
            content: phase1.q11.options.find(({ id }) => formData.q11 === id)
              ?.label,
            choice_number: formData.q11,
          },
        }
      : undefined,
    answer_12: formData.q12
      ? {
          question: phase1.q12.question.replace("${money}", deficit.toString()),
          answer: {
            type: "string",
            content: phase1.q12.options.find(({ id }) => formData.q12 === id)
              ?.label,
            choice_number: formData.q12,
          },
        }
      : undefined,
  },
})
