import { useState, useCallback, useMemo } from "react"
import constate from "constate"
import { differenceInYears, getYear, isValid } from "date-fns/esm"
import { LPRelationship, LPResult } from "api"
import lp from "assets/json/lp.json"
import { unreachable } from "utils"
import { LPFormData } from "../types"

/**
 * 干支
 */
const ZODIAC_DATA = [
  "申",
  "酉",
  "戌",
  "亥",
  "子",
  "丑",
  "寅",
  "卯",
  "辰",
  "巳",
  "午",
  "未",
] as const

export const [LPFormProvider, useLPFormData, useLPFormDataSetter, useLPResult] =
  constate(
    () => {
      const [formData, setFormData] = useState<LPFormData>(() => ({
        q13: {
          self_monthly_income: 0,
          self_severance_pay: 0,
          spouse_monthly_income: 0,
          spouse_severance_pay: 0,
        },
        q32: {
          monthly: 0,
          repayment_years: 0,
        },
        q33: {
          saving_amount: 0,
          listed_stock: 0,
          investment_trust: 0,
          investment: 0,
        },
      }))

      /**
       * 回答者氏名
       */
      const name = useMemo(() => {
        return formData.name
      }, [formData.name])

      /**
       * 誕生日
       */
      const birthdate = useMemo(() => {
        if (formData.birthdate === undefined) {
          return
        }
        const birthdate = new Date(formData.birthdate)

        if (!isValid(birthdate)) {
          return
        }

        return birthdate
      }, [formData.birthdate])

      /**
       * 回答者の年齢
       */
      const age = useMemo(() => {
        if (!birthdate) {
          return
        }
        return differenceInYears(new Date(), birthdate)
      }, [birthdate])

      /**
       * 所有自動車数
       */
      const totalCarCount = useMemo(() => {
        return (formData.q21 ?? [])
          .map((item) => item.unit)
          .reduce((intermediate, unit) => intermediate + unit, 0)
      }, [formData.q21])

      /**
       * 自動車保険料
       */
      const carInsuranceFee = useMemo(() => {
        return totalCarCount * 20
      }, [totalCarCount])

      /**
       * 車検料
       */
      const carInspectionFee = useMemo(() => {
        return totalCarCount * 30
      }, [totalCarCount])

      /**
       * 子供の人数
       */
      const childrenCount = useMemo(() => {
        return formData.q24?.children_num ?? 0
      }, [formData.q24?.children_num])

      /**
       * RelationshipID (Q8Q10のoption.id)からAPIに返すLPRelationshipに変換する
       */
      const getRelationship = useCallback(
        (relationshipId: number): LPRelationship | undefined =>
          relationshipId === 1
            ? {
                type: "spouse",
                content: "配偶者",
              }
            : relationshipId === 2
            ? {
                type: "others",
                content: "親",
              }
            : relationshipId === 3
            ? {
                type: "others",
                content: "子",
              }
            : relationshipId === 4
            ? {
                type: "others",
                content: "その他",
              }
            : relationshipId === 5
            ? {
                type: "self",
                content: "本人",
              }
            : undefined,
        [],
      )

      /**
       * 誕生日を受け取って干支を返す関数
       * @param {Date} birthdate
       * @returns {string} 該当する干支
       */
      const getZodiac = useCallback((birthdate: Date) => {
        if (!isValid(birthdate)) {
          return
        }

        const year = getYear(birthdate)

        if (Number.isNaN(year)) {
          return
        }

        return ZODIAC_DATA[year % 12]
      }, [])
      /**
       * 回答者が結婚しているか
       */
      const isMarried = useMemo(
        () =>
          (formData.q8q10 ?? [])
            .map((item) => item.relationship)
            .map((item) => getRelationship(item)?.type)
            .includes("spouse"),
        [formData.q8q10, getRelationship],
      )

      /**
       * 先進医療費用
       */
      const advancedMedicalCost = useMemo(() => {
        return isMarried ? 600 : 300
      }, [isMarried])

      /**
       * 介護費用（固定）
       */
      const careCost = {
        age: 85,
        cost: 180,
      } as const

      /**
       * 健康保険料
       */
      const totalInsuranceFee = useMemo(() => {
        if (formData.q11 === undefined || formData.q8q10 === undefined) {
          return
        }
        const personalInsuranceFee = formData.q11 * 12 >= 10_000_000 ? 100 : 50

        const totalInsuranceFee = isMarried
          ? personalInsuranceFee * 2
          : personalInsuranceFee

        return totalInsuranceFee
      }, [formData.q11, formData.q8q10, isMarried])

      /**
       * APIに送信するLPResult
       */
      const lpResult = useMemo<Partial<LPResult>>(() => {
        const q1 = lp.q1.options.find((option) => option.id === formData.q1)
        const q2 = lp.q2.options.find((option) => option.id === formData.q2)
        const q3 = lp.q3.options.find((option) => option.id === formData.q3)
        const q4 = lp.q4.options.find((option) => option.id === formData.q4)
        const q5 = lp.q5.options.find((option) => option.id === formData.q5)
        const q6 = lp.q6.options.find((option) => option.id === formData.q6)
        const q7 = lp.q7.options.find((option) => option.id === formData.q7)
        const q14 = lp.q14.options.find(
          (option) => option.value === formData.q14,
        )
        const q18 = lp.q18.options.find(
          (option) => option.value === formData.q18,
        )
        const q19 = lp.q19.options.find(
          (option) => option.value === formData.q19,
        )
        const q21 = formData.q21?.map((item) => ({
          ...item,
          ...lp.q21.options.find((option) => item.displacement === option.id),
        }))
        const q24 = lp.q24.options.find(
          (option) => (formData.q24?.children_num ?? 0) >= 1 === option.value,
        )
        const q25q26 = formData.q25q26?.map((item) => ({
          ...item,
          ...lp.q25q26.options.find((option) => option.id === item.chose_id),
        }))
        const q27 = lp.q27.options.find((option) => option.id === formData.q27)

        return {
          answer_01: q1 && {
            question: lp.q1.question,
            answer: {
              type: "string",
              content: q1.label,
              choice_number: q1.id,
            },
          },
          answer_02: q2 && {
            question: lp.q2.question,
            answer: {
              type: "string",
              content: q2.label,
              choice_number: q2.id,
            },
          },
          answer_03: q3 && {
            question: lp.q3.question,
            answer: {
              type: "string",
              content: q3.label,
              choice_number: q3.id,
            },
          },
          answer_04: q4 && {
            question: lp.q4.question,
            answer: {
              type: "string",
              content: q4.label,
              choice_number: q4.id,
            },
          },
          answer_05: q5 && {
            question: lp.q5.question,
            answer: {
              type: "string",
              content: q5.label,
              choice_number: q5.id,
            },
          },
          answer_06: q6 && {
            question: lp.q6.question,
            answer: {
              type: "string",
              content: q6.label,
              choice_number: q6.id,
            },
          },
          answer_07: q7 && {
            question: lp.q7.question,
            answer: {
              type: "string",
              content: q7.label,
              choice_number: q7.id,
            },
          },
          answer_08_10:
            formData.q8q10 && formData.name !== undefined && age !== undefined
              ? {
                  question: lp.q8q10.question,
                  answer: {
                    type: "array",
                    content: [
                      {
                        name: formData.name,
                        age: age,
                        relationship: {
                          type: "self",
                          content: "本人",
                        },
                      },
                      ...formData.q8q10.map((item) => ({
                        name: item.name,
                        age: item.age,
                        relationship:
                          getRelationship(item.relationship) ?? unreachable(),
                      })),
                    ],
                  },
                }
              : undefined,
          answer_11:
            formData.q11 !== undefined
              ? {
                  question: lp.q11.question,
                  answer: {
                    type: "number",
                    content: formData.q11,
                  },
                }
              : undefined,
          answer_12:
            formData.q12 !== undefined
              ? {
                  question: isMarried
                    ? lp.q12.question.married
                    : lp.q12.question.single,
                  answer: {
                    type: "array",
                    content: isMarried
                      ? [
                          {
                            pension: formData.q12.self_pension,
                            relationship: {
                              type: "self",
                              content: "本人",
                            },
                          },
                          {
                            pension: formData.q12.spouse_pension,
                            relationship: {
                              type: "spouse",
                              content: "配偶者",
                            },
                          },
                        ]
                      : [
                          {
                            pension: formData.q12.self_pension,
                            relationship: {
                              type: "self",
                              content: "本人",
                            },
                          },
                        ],
                  },
                }
              : undefined,
          answer_13:
            formData.q13 !== undefined
              ? {
                  question: lp.q13.question,
                  answer: {
                    type: "array",
                    content: isMarried
                      ? [
                          {
                            monthly_income: formData.q13.self_monthly_income,
                            severance_pay: formData.q13.self_severance_pay,
                            relationship: {
                              type: "self",
                              content: "本人",
                            },
                          },
                          {
                            monthly_income: formData.q13.spouse_monthly_income,
                            severance_pay: formData.q13.spouse_severance_pay,
                            relationship: {
                              type: "spouse",
                              content: "配偶者",
                            },
                          },
                        ]
                      : [
                          {
                            monthly_income: formData.q13.self_monthly_income,
                            severance_pay: formData.q13.self_severance_pay,
                            relationship: {
                              type: "self",
                              content: "本人",
                            },
                          },
                        ],
                  },
                }
              : undefined,
          answer_14:
            formData.q14 !== undefined
              ? {
                  question: lp.q14.question,
                  answer: {
                    type: "number",
                    content: formData.q14,
                    // MEMO:「その他」をchoice_id: 5とする（負債）
                    choice_number: q14?.id ?? 5,
                  },
                }
              : undefined,
          answer_15_16:
            formData.q15q16 !== undefined
              ? {
                  question: lp.q15q16.question,
                  answer: {
                    type: "array",
                    content: formData.q15q16.map((item) => ({
                      premium: item.premium,
                      remaining_years: item.premium_remaining_years,
                    })),
                  },
                }
              : undefined,
          answer_17:
            formData.q17 !== undefined
              ? {
                  question: lp.q17.question,
                  answer: {
                    type: "number",
                    content: formData.q17,
                  },
                }
              : undefined,
          answer_18:
            formData.q18 !== undefined
              ? {
                  question: lp.q18.question,
                  answer: {
                    type: "number",
                    content: formData.q18,
                    // MEMO:「その他」をchoice_id: 5とする（負債）
                    choice_number: q18?.id ?? 5,
                  },
                }
              : undefined,
          answer_19:
            q19 !== undefined
              ? {
                  question: lp.q19.question,
                  answer: {
                    type: "string",
                    content: q19.label,
                    choice_number: q19.id,
                  },
                }
              : undefined,
          answer_20:
            formData.q20 !== undefined
              ? {
                  question: lp.q20.question,
                  answer: {
                    type: "integer",
                    content: formData.q20,
                  },
                }
              : undefined,
          answer_21:
            q21 !== undefined
              ? {
                  question: lp.q21.question,
                  answer: {
                    type: "array",
                    content: q21.map((item) => ({
                      displacement: item?.label ?? unreachable(),
                      number_of_cars: item.unit,
                      choice_number: item.id ?? unreachable(),
                    })),
                  },
                }
              : undefined,
          answer_22: {
            question: lp.q22.question,
            answer: {
              type: "integer",
              content: carInsuranceFee,
            },
          },
          answer_23: {
            question: lp.q23.question,
            answer: {
              type: "integer",
              content: carInspectionFee,
            },
          },
          answer_24:
            q24 !== undefined
              ? {
                  question: lp.q24.question,
                  answer: {
                    type: "string",
                    content: q24.label,
                    choice_number: q24.id,
                  },
                }
              : undefined,
          answer_25_26: q25q26
            ? {
                question: lp.q25q26.question,
                answer: {
                  type: "array",
                  content: q25q26.map((item) => ({
                    premium: item.label ?? unreachable(),
                    remaining_years: item.remaining_years ?? unreachable(),
                    choice_number: item.id ?? unreachable(),
                  })),
                },
              }
            : undefined,
          answer_27:
            q27 !== undefined
              ? {
                  question: lp.q27.question,
                  answer: {
                    type: "string",
                    content: q27.label,
                    choice_number: q27.id,
                  },
                }
              : undefined,
          answer_28:
            formData.q28 !== undefined
              ? {
                  question: lp.q28.question,
                  answer: {
                    type: "number",
                    content: formData.q28,
                  },
                }
              : undefined,
          answer_29: {
            question: lp.q29.question,
            answer: {
              type: "integer",
              content: advancedMedicalCost,
            },
          },
          answer_30: {
            question: lp.q30.question,
            answer: {
              type: "object",
              content: {
                age: careCost.age,
                care_cost: careCost.cost,
              },
            },
          },
          answer_31:
            totalInsuranceFee !== undefined
              ? {
                  question: lp.q31.question,
                  answer: {
                    type: "integer",
                    content: totalInsuranceFee,
                  },
                }
              : undefined,
          answer_32:
            formData.q32 !== undefined
              ? {
                  question: lp.q32.question,
                  answer: {
                    type: "object",
                    content: {
                      monthly_repayment: formData.q32.monthly,
                      repayment_years: formData.q32.repayment_years,
                    },
                  },
                }
              : undefined,
          answer_33:
            formData.q33 !== undefined
              ? {
                  question: lp.q33.question,
                  answer: {
                    type: "object",
                    content: {
                      bond: formData.q33.investment,
                      investment_trust: formData.q33.investment_trust,
                      listed_stock: formData.q33.listed_stock,
                      saving_deposit: formData.q33.saving_amount,
                    },
                  },
                }
              : undefined,
        }
      }, [
        advancedMedicalCost,
        age,
        carInspectionFee,
        carInsuranceFee,
        careCost.age,
        careCost.cost,
        formData.name,
        formData.q1,
        formData.q11,
        formData.q12,
        formData.q13,
        formData.q14,
        formData.q15q16,
        formData.q17,
        formData.q18,
        formData.q19,
        formData.q2,
        formData.q20,
        formData.q21,
        formData.q24?.children_num,
        formData.q25q26,
        formData.q27,
        formData.q28,
        formData.q3,
        formData.q32,
        formData.q33,
        formData.q4,
        formData.q5,
        formData.q6,
        formData.q7,
        formData.q8q10,
        getRelationship,
        isMarried,
        totalInsuranceFee,
      ])

      return {
        formData,
        setFormData,
        age,
        name,
        birthdate,
        childrenCount,
        isMarried,
        careCost,
        lpResult,
        advancedMedicalCost,
        carInsuranceFee,
        carInspectionFee,
        totalInsuranceFee,
        getZodiac,
      }
    },
    ({
      formData,
      age,
      name,
      birthdate,
      childrenCount,
      isMarried,
      careCost,
      advancedMedicalCost,
      carInsuranceFee,
      carInspectionFee,
      totalInsuranceFee,
      getZodiac,
    }) => ({
      formData,
      age,
      name,
      birthdate,
      childrenCount,
      isMarried,
      careCost,
      advancedMedicalCost,
      carInsuranceFee,
      carInspectionFee,
      totalInsuranceFee,
      getZodiac,
    }),
    ({ setFormData }) => ({ setFormData }),
    ({ lpResult }) => ({ lpResult }),
  )
