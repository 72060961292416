import { Phase2AnswerD } from "api"
import { AnswerWrap } from "pages/components/Phase2Answer"
import {
  selectAndDate,
  QAPresentation,
  arrayNumber,
  arrayCost,
  peakSales,
  arrayString,
  objectCost,
  actionPlan,
  concernAndSolution,
} from "./utils"

export const phase2ConvertAnswersD: QAPresentation<Phase2AnswerD> = (
  answers: Phase2AnswerD,
) => {
  return [
    {
      title: answers.question_group_1?.title,
      QA: [
        // answer_01?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_01?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_01),
        },
        // answer_02?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_02?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_02),
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_2?.title,
      QA: [
        // answer_03?: SelectAndDate
        {
          question: answers.question_group_2?.answers?.answer_03?.question,
          answer: selectAndDate(answers.question_group_2?.answers?.answer_03),
        },
        // answer_04?: SelectAndDate
        {
          question: answers.question_group_2?.answers?.answer_04?.question,
          answer: selectAndDate(answers.question_group_2?.answers?.answer_04),
        },
        // answer_05?: SelectAndDate
        {
          question: answers.question_group_2?.answers?.answer_05?.question,
          answer: selectAndDate(answers.question_group_2?.answers?.answer_05),
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_3?.title,
      QA: [
        // answer_06?: DeepPartial<QA<NumberTypeAnswer & Unit>>
        {
          question: answers.question_group_3?.answers?.answer_06?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_3?.answers?.answer_06?.answer?.content}
              {answers.question_group_3?.answers?.answer_06?.answer?.unit}
            </AnswerWrap>
          ),
        },
        // answer_07?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_3?.answers?.answer_07?.question,
          answer: answers.question_group_3?.answers?.answer_07?.answer?.content,
        },
        // answer_08?: ArrayNumber
        {
          question: answers.question_group_3?.answers?.answer_08?.question,
          answer: arrayNumber(answers.question_group_3?.answers?.answer_08),
        },
        // answer_09?: ArrayCost
        {
          question: answers.question_group_3?.answers?.answer_09?.question,
          answer: arrayCost(answers.question_group_3?.answers?.answer_09),
        },
        // answer_10?: ArrayNumber
        {
          question: answers.question_group_3?.answers?.answer_10?.question,
          answer: arrayNumber(answers.question_group_3?.answers?.answer_10),
        },
        // answer_11?: PeakSales
        {
          question: answers.question_group_3?.answers?.answer_11?.question,
          answer: peakSales(answers.question_group_3?.answers?.answer_11),
        },
        // answer_12?: DeepPartial<QA<NumberTypeAnswer & Unit>>
        {
          question: answers.question_group_3?.answers?.answer_12?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_3?.answers?.answer_12?.answer?.content}
              {answers.question_group_3?.answers?.answer_12?.answer?.unit}
            </AnswerWrap>
          ),
        },
        // answer_13?: ArrayString
        {
          question: answers.question_group_3?.answers?.answer_13?.question,
          answer: arrayString(answers.question_group_3?.answers?.answer_13),
        },
        // answer_14?: ArrayString
        {
          question: answers.question_group_3?.answers?.answer_14?.question,
          answer: arrayString(answers.question_group_3?.answers?.answer_14),
        },
        // answer_15?: ArrayString
        {
          question: answers.question_group_3?.answers?.answer_15?.question,
          answer: arrayString(answers.question_group_3?.answers?.answer_15),
        },
        // answer_16?: DeepPartial<QA<NumberTypeAnswer & Unit>>
        {
          question: answers.question_group_3?.answers?.answer_16?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_3?.answers?.answer_16?.answer?.content}
              {answers.question_group_3?.answers?.answer_16?.answer?.unit}
            </AnswerWrap>
          ),
        },
        // answer_17?: ObjectCost
        {
          question: answers.question_group_3?.answers?.answer_17?.question,
          answer: objectCost(answers.question_group_3?.answers?.answer_17),
        },
        // answer_18?: DeepPartial<QA<NumberTypeAnswer & Unit>>
        {
          question: answers.question_group_3?.answers?.answer_18?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_3?.answers?.answer_18?.answer?.content}
              {answers.question_group_3?.answers?.answer_18?.answer?.unit}
            </AnswerWrap>
          ),
        },
        // answer_19?: ArrayNumber
        {
          question: answers.question_group_3?.answers?.answer_19?.question,
          answer: arrayNumber(answers.question_group_3?.answers?.answer_19),
        },
        // answer_20?: ArrayCost
        {
          question: answers.question_group_3?.answers?.answer_20?.question,
          answer: arrayCost(answers.question_group_3?.answers?.answer_20),
        },
        // answer_21?: ArrayNumber
        {
          question: answers.question_group_3?.answers?.answer_21?.question,
          answer: arrayNumber(answers.question_group_3?.answers?.answer_21),
        },
        // answer_22_24?: ActionPlan
        {
          question: answers.question_group_3?.answers?.answer_22_24?.question,
          answer: actionPlan(answers.question_group_3?.answers?.answer_22_24),
        },
        // answer_25?: ConcernAndSolution
        {
          question: answers.question_group_3?.answers?.answer_25?.question,
          answer: concernAndSolution(
            answers.question_group_3?.answers?.answer_25,
          ),
        },
      ].filter((item) => item.question && item.answer),
    },
  ].filter((item) => Boolean(item.QA.length))
}
