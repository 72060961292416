import { RouteObject } from "react-router-dom"
import { InvitationRequired } from "pages/invited/components/InvitationRequired"
import { TopLayout } from "pages/invited/components/TopLayout"
import { lazyImport } from "utils"

const { LPLayout } = lazyImport(
  () => import("pages/components/lp/components/LPLayout"),
  "LPLayout",
)

const { TopPage } = lazyImport(() => import("pages/invited/index"), "TopPage")

const { InvitedLPPage } = lazyImport(
  () => import("pages/invited/lp"),
  "InvitedLPPage",
)

const { LPNotApplicablePage } = lazyImport(
  () => import("pages/invited/lp/not_applicable"),
  "LPNotApplicablePage",
)

const { LPTutorialPage } = lazyImport(
  () => import("pages/invited/lp/tutorial"),
  "LPTutorialPage",
)

const { LPTutorialStoryPage } = lazyImport(
  () => import("pages/invited/lp/tutorial/story"),
  "LPTutorialStoryPage",
)

const { LPCompletePage } = lazyImport(
  () => import("pages/invited/lp/complete"),
  "LPCompletePage",
)

/**
 * NOTE: 通常のimportだとエラーが出るので暫定の処置としてlazyImportにしている
 */
const { AppCommonLayout } = lazyImport(
  () => import("pages/components/AppCommonLayout"),
  "AppCommonLayout",
)

/**
 * URLパス定数
 * NOTE: Typoなどの回避等
 */
const LP = "lp"
const NOT_APPLICABLE = "not_applicable"
const TUTORIAL = "tutorial"
const STORY = "story"
const COMPLETE = "complete"

/**
 * 招待ユーザーのみ閲覧可能ルートパス
 * NOTE: 絶対パスで記載
 */
export const INVITED_ROUTE_PATH = {
  TOP_PAGE: "/",
  LP: `/${LP}`,
  LP_NOT_APPLICABLE: `/${LP}/${NOT_APPLICABLE}`,
  LP_TUTORIAL: `/${LP}/${TUTORIAL}`,
  LP_TUTORIAL_STORY: `/${LP}/${TUTORIAL}/${STORY}`,
  LP_COMPLETE: `/${LP}/${COMPLETE}`,
} as const

/**
 * Auth不要のルーティング
 */
export const invitedRoutes: RouteObject[] = [
  {
    path: "/",
    element: <InvitationRequired />,
    children: [
      {
        path: "/",
        element: <TopLayout />,
        children: [
          {
            index: true,
            element: <TopPage />,
          },
        ],
      },
      {
        path: "/",
        element: <AppCommonLayout />,
        children: [
          {
            path: INVITED_ROUTE_PATH.LP_NOT_APPLICABLE,
            element: <LPNotApplicablePage />,
          },
        ],
      },
      {
        path: INVITED_ROUTE_PATH.LP,
        element: <LPLayout />,
        children: [
          {
            index: true,
            element: <InvitedLPPage />,
          },
          {
            path: INVITED_ROUTE_PATH.LP_COMPLETE,
            element: <LPCompletePage />,
          },
        ],
      },
    ],
  },
]

export const invitedEntityRoute: RouteObject = {
  path: "/",
  element: <InvitationRequired shouldVerify />,
  children: [
    {
      path: "/",
      element: <AppCommonLayout />,
      children: [
        {
          path: INVITED_ROUTE_PATH.LP_TUTORIAL,
          element: <LPTutorialPage />,
        },
        {
          path: INVITED_ROUTE_PATH.LP_TUTORIAL_STORY,
          element: <LPTutorialStoryPage />,
        },
      ],
    },
  ],
}
