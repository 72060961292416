import { ArrayNumber } from "api/types/phase2/utils"
import { NumberArrayFormData } from "pages/utils/types/phase2FormData"

interface Args {
  json: {
    question: string
    textbox: {
      label: string[]
      unit?: string
    }
  }
  formData: NumberArrayFormData
}

/**
 * 数値入力の配列の設問
 */
export const numberArrayTranslator = ({
  json,
  formData,
}: Args): ArrayNumber | undefined => {
  if (formData === undefined) return undefined

  const content = json.textbox.label
    .map((label, index) => {
      if (formData[index] === undefined) {
        return
      }

      return {
        type: "number",
        content: formData[index],
        label: label,
        unit: json.textbox?.unit,
      } as const
    })
    .filter((item) => item !== undefined)

  if (content.length === 0) {
    return
  }

  return {
    question: json.question,
    answer: {
      type: "array",
      content: content,
    },
  } as const
}
