import { ConsultGetResponse, MyConsultGetResponse } from "api/consult/types"
import { axiosClient } from "lib/axios-client"
import { MyConsultCreateRequest } from "./types"

export const ConsultAPI = {
  /**
   * ログインユーザの専門家相談一覧を取得する
   */
  fetchMyConsult: async () => {
    const { data } = await axiosClient.request<MyConsultGetResponse>({
      url: "/user/consult",
      method: "GET",
    })
    return data
  },

  /**
   * ログインユーザの専門家相談を作成する
   * @param payload
   */
  createMyConsult: async (payload: MyConsultCreateRequest) => {
    await axiosClient.request({
      url: "/user/consult",
      method: "POST",
      data: payload,
    })
  },

  /**
   * 指定したユーザの専門家相談一覧を取得する
   * @param userId
   */
  fetchConsult: async (userId: string) => {
    const { data } = await axiosClient.request<ConsultGetResponse>({
      url: `/users/${userId}/consult`,
      method: "GET",
    })
    return data
  },
} as const
