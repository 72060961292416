import { useCallback } from "react"
import { NavigateOptions, useNavigate } from "react-router-dom"
import { INVITED_ROUTE_PATH } from "routes/invited"
import { LPQuestionId } from "pages/components/lp/utils"

export function useInvitedRoute() {
  const navigate = useNavigate()

  const goToTop = useCallback(
    (options?: NavigateOptions) => {
      navigate(INVITED_ROUTE_PATH.TOP_PAGE, options)
    },
    [navigate],
  )

  const goToLp = useCallback(
    (id?: LPQuestionId, options?: NavigateOptions) => {
      if (id === undefined) {
        navigate(INVITED_ROUTE_PATH.LP, options)
        return
      }
      navigate(
        {
          pathname: INVITED_ROUTE_PATH.LP,
          search: `?question=${id}`,
        },
        options,
      )
    },
    [navigate],
  )

  const goToLpNotApplicable = useCallback(
    (options?: NavigateOptions) => {
      navigate(INVITED_ROUTE_PATH.LP_NOT_APPLICABLE, options)
    },
    [navigate],
  )

  const goToLpTutorial = useCallback(
    (options?: NavigateOptions) => {
      navigate(INVITED_ROUTE_PATH.LP_TUTORIAL, options)
    },
    [navigate],
  )

  const goToLpTutorialStory = useCallback(
    (options?: NavigateOptions) => {
      navigate(INVITED_ROUTE_PATH.LP_TUTORIAL_STORY, options)
    },
    [navigate],
  )

  const goToLpComplete = useCallback(
    (options?: NavigateOptions) => {
      navigate(INVITED_ROUTE_PATH.LP_COMPLETE, options)
    },
    [navigate],
  )

  const goToLpReconsult = useCallback(
    () => goToLp(LPQuestionId.Birthdate),
    [goToLp],
  )

  return {
    goToTop,
    goToLp,
    goToLpNotApplicable,
    goToLpTutorial,
    goToLpTutorialStory,
    goToLpComplete,
    goToLpReconsult,
  } as const
}
