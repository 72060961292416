import { ObjectCost } from "api/types/phase2/utils"
import { ObjectCostFormData } from "pages/utils/types/phase2FormData"

/**
 * 今期の経費 D Q17, G Q13
 */
export const objectCostDraftTranslator = (
  answer: ObjectCost | undefined,
): ObjectCostFormData | undefined => ({
  productPurchaseAmount:
    answer?.answer?.content?.product_purchase_amount?.content,
  rawMaterialCosts: answer?.answer?.content?.raw_material_costs?.content,
  outsourcedProcessingCosts:
    answer?.answer?.content?.outsourced_processing_costs?.content,
  freightCosts: answer?.answer?.content?.freight_costs?.content,
  laborCost: answer?.answer?.content?.labor_cost?.content,
  mySalary: answer?.answer?.content?.my_salary?.content,
  utilitiesCharge: answer?.answer?.content?.utilities_charge?.content,
  depreciationAndAmortization:
    answer?.answer?.content?.depreciation_and_amortization?.content,
  rent: answer?.answer?.content?.rent?.content,
  leasePayment: answer?.answer?.content?.lease_payment?.content,
  premium: answer?.answer?.content?.premium?.content,
  interestExpenseAndFees:
    answer?.answer?.content?.interest_expense_and_fees?.content,
  otherCosts1: answer?.answer?.content?.other_costs_1?.content,
  otherCosts2: answer?.answer?.content?.other_costs_2?.content,
})
