import { ReactNode } from "react"
import styled from "styled-components"

interface Props {
  /**
   * paddingが不要な場合 true
   */
  disableGutters?: boolean
  /**
   * Containerの内容
   */
  children: ReactNode
  /**
   * styled-componentsの拡張のため
   */
  className?: string
}

const PADDING_X = 28
const MAX_WIDTH = 500

/**
 * 汎用的なコンテナ要素
 * @param disableGutters paddingが不要な場合 true
 * @param className styled-componentsの拡張のため
 */
export const GeneralContainer = ({
  disableGutters,
  children,
  className,
}: Props) => (
  <Container disableGutters={disableGutters} className={className}>
    {children}
  </Container>
)

const Container = styled.div<{ disableGutters?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  margin: 0 auto;
  padding-left: ${({ disableGutters }) =>
    disableGutters ? "0" : `${PADDING_X}px`};
  padding-right: ${({ disableGutters }) =>
    disableGutters ? "0" : `${PADDING_X}px`};
`
