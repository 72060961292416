import { ReactNode } from "react"
import styled from "styled-components"
import { Button, ButtonColor } from "components/atoms/Button"
import { Modal } from "components/atoms/Modal"
import { TextWrap } from "components/atoms/TextWrap"

const MODAL_PADDING_Y = 25
const MODAL_PADDING_X = 38
const TEXT_BUTTON_GAP = 25

interface Props {
  /**
   * mainテキストの内容
   */
  children: ReactNode
  /**
   * ボタンの内容
   */
  buttonText: string
  /**
   * atoms/buttonのcolor
   */
  buttonColor?: ButtonColor
  /**
   * buttonを押した際のアクション
   */
  onClickButton: () => void
}

/**
 * 一つのボタンをもつ汎用的なModal
 */
export const SingleButtonModal = ({
  children,
  buttonText,
  buttonColor = "white",
  onClickButton,
}: Props) => {
  return (
    <StyledModal>
      <TextWrap weight="bold" size="m">
        {children}
      </TextWrap>
      <Button onClick={onClickButton} color={buttonColor}>
        {buttonText}
      </Button>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${MODAL_PADDING_Y}px ${MODAL_PADDING_X}px;
  gap: ${TEXT_BUTTON_GAP}px;
`
