import { useCallback } from "react"
import { NavigateOptions, useNavigate } from "react-router-dom"
import {
  getUserDeletePath,
  getUserDetailPath,
  getUserMemoCreatePath,
  getUserMemoEditPath,
  getUserResultsPath,
  getUserResultLPAnswerPath,
  getUserResultLPPath,
  SUPPORTER_ROUTE_PATH,
  getUserResultPhase1Path,
  getUserResultPhase1AnswerPath,
  getUserResultPhase2Path,
} from "routes"

/**
 * サポーター企業専用の画面遷移集約Hooks
 */
export function useSupporterRoute() {
  const navigate = useNavigate()

  const goToHome = useCallback(
    (options?: NavigateOptions) => {
      navigate(SUPPORTER_ROUTE_PATH.HOME, options)
    },
    [navigate],
  )

  const goToSettingsName = useCallback(
    (options?: NavigateOptions) => {
      navigate(SUPPORTER_ROUTE_PATH.SETTINGS_NAME, options)
    },
    [navigate],
  )

  const goToSettingsPassword = useCallback(
    (options?: NavigateOptions) => {
      navigate(SUPPORTER_ROUTE_PATH.SETTINGS_PASSWORD, options)
    },
    [navigate],
  )

  const goToSettingsEmail = useCallback(
    (options?: NavigateOptions) => {
      navigate(SUPPORTER_ROUTE_PATH.SETTINGS_EMAIL, options)
    },
    [navigate],
  )

  const goToBookmarks = useCallback(
    (options?: NavigateOptions) => {
      navigate(SUPPORTER_ROUTE_PATH.BOOKMARKS, options)
    },
    [navigate],
  )

  const goToContact = useCallback(
    (options?: NavigateOptions) => {
      navigate(SUPPORTER_ROUTE_PATH.CONTACT, options)
    },
    [navigate],
  )

  const goToUserList = useCallback(
    (options?: NavigateOptions) => {
      navigate(SUPPORTER_ROUTE_PATH.USER_LIST, options)
    },
    [navigate],
  )

  const goToUserSearch = useCallback(
    (options?: NavigateOptions) => {
      navigate(SUPPORTER_ROUTE_PATH.USER_SEARCH, options)
    },
    [navigate],
  )

  const goToUserRegister = useCallback(
    (options?: NavigateOptions) => {
      navigate(SUPPORTER_ROUTE_PATH.USER_REGISTER, options)
    },
    [navigate],
  )

  const goToUserCreate = useCallback(
    (options?: NavigateOptions) => {
      navigate(SUPPORTER_ROUTE_PATH.USER_CREATE, options)
    },
    [navigate],
  )

  const goToUserDetail = useCallback(
    (userId: string, options?: NavigateOptions) => {
      navigate(getUserDetailPath(userId), options)
    },
    [navigate],
  )

  const goToUserDelete = useCallback(
    (userId: string, options?: NavigateOptions) => {
      navigate(getUserDeletePath(userId), options)
    },
    [navigate],
  )

  const goToUserMemoCreate = useCallback(
    (userId: string, options?: NavigateOptions) => {
      navigate(getUserMemoCreatePath(userId), options)
    },
    [navigate],
  )

  const goToUserMemoEdit = useCallback(
    (userId: string, memoId: string, options?: NavigateOptions) => {
      navigate(getUserMemoEditPath(userId, memoId), options)
    },
    [navigate],
  )

  const goToUserResults = useCallback(
    (userId: string, options?: NavigateOptions) => {
      navigate(getUserResultsPath(userId), options)
    },
    [navigate],
  )

  const goToUserResultLPAnswer = useCallback(
    (userId: string, lpId: number, options?: NavigateOptions) => {
      navigate(getUserResultLPAnswerPath(userId, lpId), options)
    },
    [navigate],
  )

  const goToUserResultLP = useCallback(
    (userId: string, lpId: number, options?: NavigateOptions) => {
      navigate(getUserResultLPPath(userId, lpId), options)
    },
    [navigate],
  )

  const goToUserResultPhase1 = useCallback(
    (userId: string, phase1Id: number, options?: NavigateOptions) => {
      navigate(getUserResultPhase1Path(userId, phase1Id), options)
    },
    [navigate],
  )

  const goToUserResultPhase1Answer = useCallback(
    (userId: string, phase1Id: number, options?: NavigateOptions) => {
      navigate(getUserResultPhase1AnswerPath(userId, phase1Id), options)
    },
    [navigate],
  )

  const goToUserResultPhase2 = useCallback(
    (userId: string, phase2Id: number, options?: NavigateOptions) => {
      navigate(getUserResultPhase2Path(userId, phase2Id), options)
    },
    [navigate],
  )

  return {
    goToHome,
    goToSettingsName,
    goToSettingsPassword,
    goToSettingsEmail,
    goToBookmarks,
    goToContact,
    goToUserList,
    goToUserSearch,
    goToUserRegister,
    goToUserCreate,
    goToUserDetail,
    goToUserDelete,
    goToUserMemoCreate,
    goToUserMemoEdit,
    goToUserResults,
    goToUserResultLPAnswer,
    goToUserResultLP,
    goToUserResultPhase1,
    goToUserResultPhase1Answer,
    goToUserResultPhase2,
  } as const
}
