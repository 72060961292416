import { AxiosError } from "axios"
import { useMutation, UseMutationOptions } from "react-query"
import { TodosAPI } from "api/todos/api"
import {
  TodoCreateRequest,
  TodoCreateResponse,
  TodoUpdateRequest,
  TodoUpdateResponse,
} from "api/todos/types"
import { OmitMutationConfig } from "lib/react-query"

const PREFIX_ID = "todos"

export const fetchTodosQuery = {
  queryKey: `${PREFIX_ID}/fetchTodos`,
  queryFn: TodosAPI.fetchTodos,
} as const

export const todoUpdateMutation: UseMutationOptions<
  TodoUpdateResponse,
  AxiosError,
  TodoUpdateRequest
> = {
  mutationKey: `${PREFIX_ID}/updateTodo`,
  mutationFn: TodosAPI.updateTodo,
}

/**
 * todoのupdate mutation
 * @param config
 */
export function useTodoUpdateMutation(
  config?: OmitMutationConfig<typeof todoUpdateMutation>,
) {
  return useMutation({ ...todoUpdateMutation, ...config })
}

export const todoCreateMutation: UseMutationOptions<
  TodoCreateResponse,
  AxiosError,
  TodoCreateRequest
> = {
  mutationKey: `${PREFIX_ID}/createTodo`,
  mutationFn: TodosAPI.createTodo,
}

/**
 * todoのcreate mutation
 * @param config
 */
export function useTodoCreateMutation(
  config?: OmitMutationConfig<typeof todoCreateMutation>,
) {
  return useMutation({ ...todoCreateMutation, ...config })
}

export const fetchUserTodosQuery = (userId?: string) => {
  if (userId) {
    return {
      queryKey: `${PREFIX_ID}/fetchUserTodos/${userId}`,
      queryFn: () => TodosAPI.fetchUserTodos(userId),
    }
  }
}
