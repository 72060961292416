import { isAfter } from "date-fns"
import { Results } from "types"
import { Phase2ResultPattern, ResultsGetResponse } from "api"
import {
  phase2ConvertAnswersA,
  phase2ConvertAnswersB,
  phase2ConvertAnswersC,
  phase2ConvertAnswersD,
  phase2ConvertAnswersE,
  phase2ConvertAnswersF,
  phase2ConvertAnswersG,
  phase2ConvertAnswersH,
  phase2ConvertAnswersAB,
} from "pages/utils/phase2AnswerConverter"

/**
 * 回答一覧データを新しい順にソートする
 * 未回答で空配列の場合はundefinedに変換する
 */
export const sortResults = (results: ResultsGetResponse): Results => {
  const sortedLp = results.lp
    .map(({ lp_id, created_at }) => ({
      lpId: lp_id,
      createdAt: new Date(created_at),
    }))
    .sort(($0, $1) => (isAfter($0.createdAt, $1.createdAt) ? -1 : 1))

  const sortedPhase1 = results.phase1
    .map(({ phase1_id, created_at }) => ({
      phase1Id: phase1_id,
      createdAt: new Date(created_at),
    }))
    .sort(($0, $1) => (isAfter($0.createdAt, $1.createdAt) ? -1 : 1))

  const sortedPhase2 = results.phase2
    .map(({ phase2_id, created_at, has_plan }) => ({
      phase2Id: phase2_id,
      createdAt: new Date(created_at),
      hasPlan: has_plan,
    }))
    .sort(($0, $1) => (isAfter($0.createdAt, $1.createdAt) ? -1 : 1))

  return {
    lp: results.lp.length === 0 ? undefined : sortedLp,
    phase1: results.phase1.length === 0 ? undefined : sortedPhase1,
    phase2: results.phase2.length === 0 ? undefined : sortedPhase2,
  }
}

/**
 * 回答一覧データから最新のものをしぼりこむ
 * 未回答で空配列の場合はundefinedとなる
 */
export const selectLatestResults = (results: ResultsGetResponse) => {
  const sortedResults = sortResults(results)

  return {
    lp: sortedResults.lp && sortedResults.lp[0],
    phase1: sortedResults.phase1 && sortedResults.phase1[0],
    phase2: sortedResults.phase2 && sortedResults.phase2[0],
  }
}

export const Phase2ResultTranslator = (
  // MEMO: 送信前の確認画面ではcreated_atが含まれない
  data: Phase2ResultPattern & { created_at?: string },
) => {
  if (data.diagnosis === "AA" || data.diagnosis === "IA") {
    return {
      answer: phase2ConvertAnswersA(data.answer),
      createdAt: data.created_at ? new Date(data.created_at) : undefined,
    }
  }
  if (data.diagnosis === "AB" || data.diagnosis === "IB") {
    return {
      answer: phase2ConvertAnswersB(data.answer),
      createdAt: data.created_at ? new Date(data.created_at) : undefined,
    }
  }
  if (
    data.diagnosis === "AC" ||
    data.diagnosis === "IC" ||
    data.diagnosis === "UC" ||
    data.diagnosis === "EC"
  ) {
    return {
      answer: phase2ConvertAnswersC(data.answer),
      createdAt: data.created_at ? new Date(data.created_at) : undefined,
    }
  }
  if (
    data.diagnosis === "AD" ||
    data.diagnosis === "ID" ||
    data.diagnosis === "UD" ||
    data.diagnosis === "ED"
  ) {
    return {
      answer: phase2ConvertAnswersD(data.answer),
      createdAt: data.created_at ? new Date(data.created_at) : undefined,
    }
  }
  if (
    data.diagnosis === "AE" ||
    data.diagnosis === "IE" ||
    data.diagnosis === "UE" ||
    data.diagnosis === "EE"
  ) {
    return {
      answer: phase2ConvertAnswersE(data.answer),
      createdAt: data.created_at ? new Date(data.created_at) : undefined,
    }
  }
  if (
    data.diagnosis === "AF" ||
    data.diagnosis === "IF" ||
    data.diagnosis === "UF" ||
    data.diagnosis === "EF"
  ) {
    return {
      answer: phase2ConvertAnswersF(data.answer),
      createdAt: data.created_at ? new Date(data.created_at) : undefined,
    }
  }
  if (
    data.diagnosis === "AG" ||
    data.diagnosis === "IG" ||
    data.diagnosis === "UG" ||
    data.diagnosis === "EG"
  ) {
    return {
      answer: phase2ConvertAnswersG(data.answer),
      createdAt: data.created_at ? new Date(data.created_at) : undefined,
    }
  }
  if (
    data.diagnosis === "AH" ||
    data.diagnosis === "IH" ||
    data.diagnosis === "UH" ||
    data.diagnosis === "EH"
  ) {
    return {
      answer: phase2ConvertAnswersH(data.answer),
      createdAt: data.created_at ? new Date(data.created_at) : undefined,
    }
  }
  if (
    data.diagnosis === "UA" ||
    data.diagnosis === "UB" ||
    data.diagnosis === "EA" ||
    data.diagnosis === "EB"
  ) {
    return {
      answer: phase2ConvertAnswersAB(data.answer),
      createdAt: data.created_at ? new Date(data.created_at) : undefined,
    }
  }
}
