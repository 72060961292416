import { useCallback } from "react"
import { useQueryClient } from "react-query"
import { fetchPhase2DraftQuery, useUpdatePhase2DraftMutation } from "api/phase2"
import { phase2SendFormDataTranslator } from "pages/utils/phase2"
import { DeepPartial } from "utils"
import { Phase2FormDataList, Phase2Type } from "../types"

interface Props {
  /**
   * フェーズ１診断結果
   */
  diagnosis: Phase2Type
  /**
   * 現在の入力値
   */
  formData: DeepPartial<Phase2FormDataList[Phase2Type]>
}

/**
 * フェーズ２の下書きを作成する関数を返すカスタムフック
 */
export const usePhase2CreateDraft = () => {
  const { mutateAsync: sendDraft } = useUpdatePhase2DraftMutation()
  const queryClient = useQueryClient()

  const createDraft = useCallback(
    ({ diagnosis, formData }: Props) => {
      if (diagnosis === undefined) {
        return
      }

      const convertedFormData = phase2SendFormDataTranslator({
        diagnosis: diagnosis,
        formData: formData,
      })

      if (convertedFormData === undefined) {
        return
      }

      sendDraft(convertedFormData)
      queryClient.invalidateQueries(fetchPhase2DraftQuery.queryKey)
    },
    [queryClient, sendDraft],
  )

  return { createDraft }
}
