import { Suspense, useCallback } from "react"
import { useAuthPermission } from "context/auth"
import { Outlet } from "react-router-dom"
import styled from "styled-components"
import { ProgressBar } from "components/molecules/ProgressBar"
import { GeneralHeader } from "components/organisms/GeneralHeader"
import { YesNoModal } from "components/organisms/Modals"
import { useClientRoute } from "hooks/useClientRoute"
import { ModalWrap, useModal } from "hooks/useModal"
import { GeneralContainer } from "utils/GeneralContainer"
import { LPFormProvider } from "../utils"
import { useLPProgress } from "../utils/hooks/useLPProgress"
import { useLPQuestionId } from "../utils/hooks/useLPQuestionId"

/**
 * /lpのレイアウトコンポーネント
 */
export const LPLayout = () => {
  const { goToHome } = useClientRoute()
  const { permission } = useAuthPermission()

  /**
   * progress
   */
  const questionId = useLPQuestionId()
  const progress = useLPProgress(questionId)

  /**
   * modal
   */
  const {
    ModalPortal,
    open: openModal,
    close: closeModal,
  } = useModal({
    closeOnOverlayClick: true,
    preventScroll: true,
  })

  /**
   * event-handler
   */
  const handleLogoClick = useCallback(() => {
    if (permission === "client") {
      openModal()
    }
  }, [permission, openModal])

  const handleNavigate = useCallback(() => {
    if (permission === "client") {
      goToHome()
    }
  }, [permission, goToHome])

  return (
    <Container>
      <GeneralHeader onLogoClick={handleLogoClick} />
      {progress && (
        <ProgressBarWrap>
          <ProgressBar
            text="分析完了まで"
            max={progress.maxIndex}
            value={progress.currentIndex}
          />
        </ProgressBarWrap>
      )}
      <MainContainer>
        <LPFormProvider>
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
        </LPFormProvider>
      </MainContainer>
      <ModalPortal>
        <StyledModalWrap>
          <YesNoModal onCancel={closeModal} onAgree={handleNavigate}>
            現在回答中のデータが消去されます。
            <br />
            宜しいでしょうか。
          </YesNoModal>
        </StyledModalWrap>
      </ModalPortal>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const ProgressBarWrap = styled(GeneralContainer)`
  padding-top: 5px;
  padding-bottom: 15px;
`

const MainContainer = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const StyledModalWrap = styled(ModalWrap)`
  text-align: center;
`
