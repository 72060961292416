import { useCallback } from "react"
import { NavigateOptions, useNavigate } from "react-router-dom"
import { LPQuestionId } from "pages/components/lp/utils"
import { Phase1QuestionId } from "pages/components/phase1/utils"
import {
  CLIENT_ROUTE_PATH,
  getResultLPAnswerPath,
  getResultLpPath,
  getResultPhase1AnswerPath,
  getResultPhase1Path,
  getResultPhase2Path,
} from "routes"

/**
 * 一般ユーザー系の画面遷移集約Hooks
 */
export function useClientRoute() {
  const navigate = useNavigate()

  const goToHome = useCallback(
    (options?: NavigateOptions) => {
      navigate(CLIENT_ROUTE_PATH.HOME, options)
    },
    [navigate],
  )

  const goToConsult = useCallback(
    (options?: NavigateOptions) => {
      navigate(CLIENT_ROUTE_PATH.CONSULT, options)
    },
    [navigate],
  )

  const goToContact = useCallback(
    (options?: NavigateOptions) => {
      navigate(CLIENT_ROUTE_PATH.CONTACT, options)
    },
    [navigate],
  )

  const goToSettingsEmail = useCallback(
    (options?: NavigateOptions) => {
      navigate(CLIENT_ROUTE_PATH.SETTINGS_EMAIL, options)
    },
    [navigate],
  )

  const goToSettingsPassword = useCallback(
    (options?: NavigateOptions) => {
      navigate(CLIENT_ROUTE_PATH.SETTINGS_PASSWORD, options)
    },
    [navigate],
  )

  const goToSettingsProfile = useCallback(
    (options?: NavigateOptions) => {
      navigate(CLIENT_ROUTE_PATH.SETTINGS_PROFILE, options)
    },
    [navigate],
  )

  const goToResults = useCallback(
    (options?: NavigateOptions) => {
      navigate(CLIENT_ROUTE_PATH.RESULTS, options)
    },
    [navigate],
  )

  const goToResultLP = useCallback(
    (lpId: number, options?: NavigateOptions) => {
      navigate(getResultLpPath(lpId), options)
    },
    [navigate],
  )

  const goToLp = useCallback(
    (id?: LPQuestionId, options?: NavigateOptions) => {
      if (id === undefined) {
        navigate(CLIENT_ROUTE_PATH.LP, options)
        return
      }
      navigate(
        {
          pathname: CLIENT_ROUTE_PATH.LP,
          search: `?question=${id}`,
        },
        options,
      )
    },
    [navigate],
  )

  const goToPhase1 = useCallback(
    (id?: Phase1QuestionId, options?: NavigateOptions) => {
      //MEMO: onClickに渡されたときにイベントオブジェクトが第一引数にわたってきてしまうので、その場合早期リターンしている
      if (id === undefined || typeof id !== "string") {
        navigate(CLIENT_ROUTE_PATH.PHASE1, options)
        return
      }
      navigate(
        {
          pathname: CLIENT_ROUTE_PATH.PHASE1,
          search: `?question=${id}`,
        },
        options,
      )
    },
    [navigate],
  )

  const goToLpReconsult = useCallback(
    (options?: NavigateOptions) => goToLp(LPQuestionId.Q1, options),
    [goToLp],
  )

  const goToLpCompleted = useCallback(
    (options?: NavigateOptions) => {
      navigate(CLIENT_ROUTE_PATH.LP_COMPLETE, options)
    },
    [navigate],
  )

  const goToResultLPAnswer = useCallback(
    (lpId: number, options?: NavigateOptions) => {
      navigate(getResultLPAnswerPath(lpId), options)
    },
    [navigate],
  )

  const goToResultPhase1 = useCallback(
    (phase1Id: number, options?: NavigateOptions) => {
      navigate(getResultPhase1Path(phase1Id), options)
    },
    [navigate],
  )

  const goToPhase1Confirm = useCallback(
    (options?: NavigateOptions) => {
      navigate(CLIENT_ROUTE_PATH.PHASE1_CONFIRM, options)
    },
    [navigate],
  )

  const goToResultPhase1Answer = useCallback(
    (phase1Id: number, options?: NavigateOptions) => {
      navigate(getResultPhase1AnswerPath(phase1Id), options)
    },
    [navigate],
  )

  const goToPhase2 = useCallback(
    (options?: NavigateOptions) => {
      navigate(CLIENT_ROUTE_PATH.PHASE2, options)
    },
    [navigate],
  )

  const goToResultPhase2 = useCallback(
    (phase2Id: number, options?: NavigateOptions) => {
      navigate(getResultPhase2Path(phase2Id), options)
    },
    [navigate],
  )

  const goToPhase2Confirm = useCallback(
    (options?: NavigateOptions) => {
      navigate(CLIENT_ROUTE_PATH.PHASE2_CONFIRM, options)
    },
    [navigate],
  )

  return {
    goToHome,
    goToConsult,
    goToContact,
    goToSettingsEmail,
    goToSettingsPassword,
    goToSettingsProfile,
    goToResults,
    goToResultLPAnswer,
    goToResultLP,
    goToLp,
    goToPhase1,
    goToLpReconsult,
    goToLpCompleted,
    goToResultPhase1,
    goToPhase1Confirm,
    goToResultPhase1Answer,
    goToPhase2,
    goToResultPhase2,
    goToPhase2Confirm,
  } as const
}
