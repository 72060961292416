import styled, { useTheme } from "styled-components"
import { TextWrap } from "components/atoms/TextWrap"

const SVG_WIDTH = 42
const SVG_HEIGHT = 40

interface Props {
  /**
   * 相談状態をbooleanで受け取る
   */
  isActive?: boolean
}

/**
 * 相談状態を示すFlag 「相談希望」または「相談しない」
 */
export const Flag = ({ isActive = false }: Props) => {
  const theme = useTheme()
  const svgColor = isActive ? theme.color.wildWatermelon : theme.color.pinkSwan
  const textColor = isActive ? theme.color.nightRider : theme.color.pinkSwan
  return (
    <Container>
      <svg
        width={SVG_WIDTH}
        height={SVG_HEIGHT}
        viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
      >
        <path
          d="M27.8477 9.7371C23.4957 -0.972905 11.4295 0.0245952 11.4295 0.0245952L0.0357115 38.2746C-0.100289 38.7321 0.164156 39.2196 0.625045 39.3546L1.81882 39.7071C2.27971 39.8421 2.77082 39.5796 2.90682 39.1221L7.57616 23.4396C7.67438 23.1021 7.96149 22.8621 8.30905 22.8171C10.7722 22.5171 14.9353 22.8771 21.8259 25.3896C32.6984 29.3571 39.2566 22.8096 41.697 19.5921C42.1428 18.9996 41.7046 18.1596 40.9642 18.1971C37.7455 18.3696 31.0815 17.6871 27.8477 9.7371Z"
          fill={svgColor}
        />
      </svg>
      <TextWrap size="s" weight="bold" color={textColor}>
        {isActive ? "相談中" : "相談前"}
      </TextWrap>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`
