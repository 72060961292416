import { useTheme } from "styled-components"
import { SvgCreater } from "utils/SvgCreater"
import { IconProps } from "./interface"

/**
 * Copyアイコン
 */
export const CopyIcon = ({ size, ...rest }: IconProps) => {
  const theme = useTheme()
  return (
    <SvgCreater
      size={size}
      fill={theme.color.pinkSwan}
      viewboxSize={size}
      {...rest}
    >
      <path d="M19.9375 1.20837H5.43748C4.10831 1.20837 3.02081 2.29587 3.02081 3.62504V20.5417H5.43748V3.62504H19.9375V1.20837ZM23.5625 6.04171H10.2708C8.94165 6.04171 7.85415 7.12921 7.85415 8.45837V25.375C7.85415 26.7042 8.94165 27.7917 10.2708 27.7917H23.5625C24.8916 27.7917 25.9791 26.7042 25.9791 25.375V8.45837C25.9791 7.12921 24.8916 6.04171 23.5625 6.04171ZM23.5625 25.375H10.2708V8.45837H23.5625V25.375Z" />
    </SvgCreater>
  )
}
