import { Phase2AnswerE } from "api/types/phase2/e"
import phase2E from "assets/json/phase2/e.json"
import { Phase2EFormData } from "pages/components/phase2/components/E/schema"
import { Phase2Type } from "pages/components/phase2/utils"
import { DeepPartial } from "utils"
import {
  selectAndDateTranslator,
  dateTranslator,
  stringTranslator,
} from "./utils"

type Diagnosis = Extract<Phase2Type, "AE" | "IE" | "UE" | "EE">

interface Args {
  diagnosis: Diagnosis
  formData: DeepPartial<Phase2EFormData>
}

// TODO: phase2ResultAnswerがマージされたらそちらを使う
interface Return {
  diagnosis: Diagnosis
  answer: Phase2AnswerE
}

export const phase2EFormDataTranslator = ({
  diagnosis,
  formData,
}: Args): Return => ({
  diagnosis: diagnosis,
  answer: {
    question_group_1: {
      answers: {
        // answer_00 表示のみ
        answer_01: selectAndDateTranslator({
          json: phase2E.q1,
          formData: formData.q1,
        }),
        answer_02: selectAndDateTranslator({
          json: phase2E.q2,
          formData: formData.q2,
        }),
        answer_03: selectAndDateTranslator({
          json: phase2E.q3,
          formData: formData.q3,
        }),
        answer_04: dateTranslator({
          json: phase2E.q4,
          formData: formData.q4,
        }),
        answer_05: dateTranslator({
          json: phase2E.q5,
          formData: formData.q5,
        }),
        answer_06: dateTranslator({
          json: phase2E.q6,
          formData: formData.q6,
        }),
        answer_07: dateTranslator({
          json: phase2E.q7,
          formData: formData.q7,
        }),
        answer_08: dateTranslator({
          json: phase2E.q8,
          formData: formData.q8,
        }),
        answer_09: dateTranslator({
          json: phase2E.q9,
          formData: formData.q9,
        }),
        answer_10: dateTranslator({
          json: phase2E.q10,
          formData: formData.q10,
        }),
        answer_11: selectAndDateTranslator({
          json: phase2E.q11,
          formData: formData.q11,
        }),
        answer_12: dateTranslator({
          json: phase2E.q12,
          formData: formData.q12,
        }),
        answer_13: dateTranslator({
          json: phase2E.q13,
          formData: formData.q13,
        }),
      },
    },
    question_group_2: {
      title: phase2E.title2,
      answers: {
        answer_14: dateTranslator({
          json: phase2E.q14,
          formData: formData.q14,
        }),
        answer_15: dateTranslator({
          json: phase2E.q15,
          formData: formData.q15,
        }),
        answer_16: stringTranslator({
          json: phase2E.q16,
          formData: formData.q16,
        }),
      },
    },
    question_group_3: {
      title: phase2E.title3,
      answers: {
        answer_17: dateTranslator({
          json: phase2E.q17,
          formData: formData.q17,
        }),
        answer_18: dateTranslator({
          json: phase2E.q18,
          formData: formData.q18,
        }),
        answer_19: dateTranslator({
          json: phase2E.q19,
          formData: formData.q19,
        }),
        answer_20: dateTranslator({
          json: phase2E.q20,
          formData: formData.q20,
        }),
        answer_21: dateTranslator({
          json: phase2E.q21,
          formData: formData.q21,
        }),
        answer_22: dateTranslator({
          json: phase2E.q22,
          formData: formData.q22,
        }),
        answer_23: dateTranslator({
          json: phase2E.q23,
          formData: formData.q23,
        }),
        answer_24: selectAndDateTranslator({
          json: phase2E.q24,
          formData: formData.q24,
        }),
        answer_25: selectAndDateTranslator({
          json: phase2E.q25,
          formData: formData.q25,
        }),
      },
    },
    question_group_4: {
      title: phase2E.title4,
      answers: {
        answer_26: dateTranslator({
          json: phase2E.q26,
          formData: formData.q26,
        }),
        answer_27: dateTranslator({
          json: phase2E.q27,
          formData: formData.q27,
        }),
        answer_28: dateTranslator({
          json: phase2E.q28,
          formData: formData.q28,
        }),
        answer_29: dateTranslator({
          json: phase2E.q29,
          formData: formData.q29,
        }),
        answer_30: dateTranslator({
          json: phase2E.q30,
          formData: formData.q30,
        }),
        answer_31: dateTranslator({
          json: phase2E.q31,
          formData: formData.q31,
        }),
        answer_32: dateTranslator({
          json: phase2E.q32,
          formData: formData.q32,
        }),
        answer_33: dateTranslator({
          json: phase2E.q33,
          formData: formData.q33,
        }),
        answer_34: dateTranslator({
          json: phase2E.q34,
          formData: formData.q34,
        }),
        answer_35: dateTranslator({
          json: phase2E.q35,
          formData: formData.q35,
        }),
        answer_36: dateTranslator({
          json: phase2E.q36,
          formData: formData.q36,
        }),
        answer_37: dateTranslator({
          json: phase2E.q37,
          formData: formData.q37,
        }),
      },
    },
  },
})
