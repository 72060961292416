import { ActionPlan } from "api/types/phase2/utils"
import { ActionPlanFormData } from "pages/utils/types/phase2FormData"

/**
 * 行動計画 あD Q22-24, あG Q22-24Q29-31
 */
export const actionPlanDraftTranslator = (
  answer: ActionPlan | undefined,
): ActionPlanFormData =>
  answer?.answer?.content?.map(
    ({ plan, charge, start_date, completed_date } = {}) => ({
      plan: plan?.content,
      charge: charge?.content,
      startDate: start_date?.content,
      completedDate: completed_date?.content,
    }),
  )
