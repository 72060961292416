import { HTMLAttributes } from "react"
import styled from "styled-components"

const MODAL_BOX_SHADOW = "0px 4px 4px rgba(0, 0, 0, 0.25);"
const MODAL_BORDER_RADIUS = 25

/**
 * ModalのViewを提供するコンポーネント
 */
export const Modal = ({ children, ...rest }: HTMLAttributes<HTMLElement>) => {
  return <Container {...rest}>{children}</Container>
}

const Container = styled.div`
  background-color: ${(props) => props.theme.color.white};
  box-shadow: ${MODAL_BOX_SHADOW};
  border-radius: ${MODAL_BORDER_RADIUS}px;
`
