import { AxiosError } from "axios"
import { useMutation, UseMutationOptions } from "react-query"
import { ContactCreateRequest, ContactCreateResponse } from "api/contact/types"
import { OmitMutationConfig } from "lib/react-query"
import { ContactAPI } from "./api"

const PREFIX_ID = "contact"

export const createContactMutation: UseMutationOptions<
  ContactCreateResponse,
  AxiosError,
  ContactCreateRequest
> = {
  mutationKey: `${PREFIX_ID}/createContact`,
  mutationFn: ContactAPI.createContact,
}

/**
 * 問い合わせを送信するHooks
 * @param config
 */
export function useCreateContactMutation(
  config?: OmitMutationConfig<typeof createContactMutation>,
) {
  return useMutation({ ...createContactMutation, ...config })
}
