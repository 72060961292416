import { ArrayCost } from "api/types/phase2/utils"
import { ArrayCostFormData } from "pages/utils/types/phase2FormData"

interface Args {
  json: {
    question: string
    textbox: {
      label: string[]
      input_label: {
        product_purchase_amount: string
        raw_material_costs: string
        outsourced_processing_costs: string
        freight_costs: string
        labor_cost: string
        my_salary: string
        utilities_charge: string
        depreciation_and_amortization: string
        rent: string
        lease_payment: string
        premium: string
        interest_expense_and_fees: string
        other_costs_1: string
        other_costs_2: string
      }
      unit: string
    }
  }
  formData: ArrayCostFormData
}

/**
 * 過去3年の経費 D Q9, G Q9 経費削減計画 D Q20, G Q16
 */
export const arrayCostTranslator = ({
  json,
  formData,
}: Args): ArrayCost | undefined => {
  if (formData === undefined) return undefined

  return {
    question: json.question,
    answer: {
      type: "array",
      content: json.textbox.label.map((label, index) => ({
        label: label,
        product_purchase_amount: {
          type: "number",
          label: json.textbox.input_label.product_purchase_amount,
          unit: json.textbox.unit,
          content: formData[index]?.productPurchaseAmount,
        },
        raw_material_costs: {
          type: "number",
          label: json.textbox.input_label.raw_material_costs,
          unit: json.textbox.unit,
          content: formData[index]?.rawMaterialCosts,
        },
        outsourced_processing_costs: {
          type: "number",
          label: json.textbox.input_label.outsourced_processing_costs,
          unit: json.textbox.unit,
          content: formData[index]?.outsourcedProcessingCosts,
        },
        freight_costs: {
          type: "number",
          label: json.textbox.input_label.freight_costs,
          unit: json.textbox.unit,
          content: formData[index]?.freightCosts,
        },
        labor_cost: {
          type: "number",
          label: json.textbox.input_label.labor_cost,
          unit: json.textbox.unit,
          content: formData[index]?.laborCost,
        },
        my_salary: {
          type: "number",
          label: json.textbox.input_label.my_salary,
          unit: json.textbox.unit,
          content: formData[index]?.mySalary,
        },
        utilities_charge: {
          type: "number",
          label: json.textbox.input_label.utilities_charge,
          unit: json.textbox.unit,
          content: formData[index]?.utilitiesCharge,
        },
        depreciation_and_amortization: {
          type: "number",
          label: json.textbox.input_label.depreciation_and_amortization,
          unit: json.textbox.unit,
          content: formData[index]?.depreciationAndAmortization,
        },
        rent: {
          type: "number",
          label: json.textbox.input_label.rent,
          unit: json.textbox.unit,
          content: formData[index]?.rent,
        },
        lease_payment: {
          type: "number",
          label: json.textbox.input_label.lease_payment,
          unit: json.textbox.unit,
          content: formData[index]?.leasePayment,
        },
        premium: {
          type: "number",
          label: json.textbox.input_label.premium,
          unit: json.textbox.unit,
          content: formData[index]?.premium,
        },
        interest_expense_and_fees: {
          type: "number",
          label: json.textbox.input_label.interest_expense_and_fees,
          unit: json.textbox.unit,
          content: formData[index]?.interestExpenseAndFees,
        },
        other_costs_1: {
          type: "number",
          label: json.textbox.input_label.other_costs_1,
          unit: json.textbox.unit,
          content: formData[index]?.otherCosts1,
        },
        other_costs_2: {
          type: "number",
          label: json.textbox.input_label.other_costs_2,
          unit: json.textbox.unit,
          content: formData[index]?.otherCosts2,
        },
      })),
    },
  } as const
}
