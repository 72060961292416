import { useTheme } from "styled-components"
import { SvgCreater } from "utils/SvgCreater"
import { IconProps } from "./interface"

const VIEWBOX_SIZE = 40

export const InquiryIcon = ({ size = 40, ...rest }: IconProps) => {
  const theme = useTheme()
  return (
    <SvgCreater
      size={size}
      fill={theme.color.pigmentGreen}
      viewboxSize={VIEWBOX_SIZE}
      {...rest}
    >
      <path d="M35.0002 20.8667C35.0002 11.7167 27.9002 5.5 20.0002 5.5C12.1835 5.5 5.00016 11.5833 5.00016 20.9667C4.00016 21.5333 3.33349 22.6 3.33349 23.8333L3.33349 27.1667C3.33349 29 4.83349 30.5 6.66682 30.5C7.58349 30.5 8.33349 29.75 8.33349 28.8333L8.33349 20.8167C8.33349 14.4333 13.2502 8.85 19.6335 8.66667C26.2335 8.46667 31.6668 13.7667 31.6668 20.3333L31.6668 32.1667L20.0002 32.1667C19.0835 32.1667 18.3335 32.9167 18.3335 33.8333C18.3335 34.75 19.0835 35.5 20.0002 35.5L31.6668 35.5C33.5002 35.5 35.0002 34 35.0002 32.1667L35.0002 30.1333C35.9835 29.6167 36.6668 28.6 36.6668 27.4L36.6668 23.5667C36.6668 22.4 35.9835 21.3833 35.0002 20.8667Z" />
      <path d="M15.0002 23.8333C15.9206 23.8333 16.6668 23.0871 16.6668 22.1667C16.6668 21.2462 15.9206 20.5 15.0002 20.5C14.0797 20.5 13.3335 21.2462 13.3335 22.1667C13.3335 23.0871 14.0797 23.8333 15.0002 23.8333Z" />
      <path d="M25.0002 23.8333C25.9206 23.8333 26.6668 23.0871 26.6668 22.1667C26.6668 21.2462 25.9206 20.5 25.0002 20.5C24.0797 20.5 23.3335 21.2462 23.3335 22.1667C23.3335 23.0871 24.0797 23.8333 25.0002 23.8333Z" />
      <path d="M30.0002 18.8833C29.2002 14.1333 25.0668 10.5 20.0835 10.5C15.0335 10.5 9.60016 14.6833 10.0335 21.25C14.1502 19.5667 17.2502 15.9 18.1335 11.4333C20.3168 15.8167 24.8002 18.8333 30.0002 18.8833Z" />
    </SvgCreater>
  )
}
