import { NumberTypeAnswer, QA, Unit } from "api"
import { NumberFormData } from "pages/utils/types/phase2FormData"
import { DeepPartial } from "utils"

interface Args {
  json: {
    question: string
    textbox?: {
      unit?: string
    }
  }
  formData: NumberFormData
}

/**
 * 数値入力の設問
 */
export const numberTranslator = ({
  json,
  formData,
}: Args): DeepPartial<QA<NumberTypeAnswer & Unit>> | undefined => {
  if (formData === undefined) return undefined

  return {
    question: json.question,
    answer: {
      type: "number",
      content: formData,
      unit: json.textbox?.unit,
    },
  } as const
}
