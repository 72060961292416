import { ReactNode } from "react"
import styled from "styled-components"
import { Button } from "components/atoms/Button"
import { CloseButton } from "components/atoms/CloseButton"
import { Modal } from "components/atoms/Modal"
import { TextWrap } from "components/atoms/TextWrap"

interface Props {
  /**
   * Modalのタイトル
   */
  title?: ReactNode
  /**
   * 解説内容
   */
  explanation?: ReactNode
  /**
   * 閉じるボタンのhandler
   */
  onClose: () => void
  /**
   * 専門家に相談するハンドラ
   */
  onConsult?: () => void
}

/**
 * 解説内容を表示するModal
 */
export const ExplanationModal = ({
  title,
  explanation,
  onClose,
  onConsult,
}: Props) => {
  return (
    <StyledModal>
      <TitleCloseWrap>
        <Title size="m" weight="bold">
          {title}
        </Title>
        <StyledCloseButton onClick={onClose} />
      </TitleCloseWrap>
      <Explanation size="m">{explanation}</Explanation>
      {onConsult && (
        <ConsultButtonWrap>
          <Button shape="square" color="green" onClick={onConsult}>
            {"専門家に相談する>>"}
          </Button>
        </ConsultButtonWrap>
      )}
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 25px;
  padding: 25px 10px;
  width: 100%;
`

const TitleCloseWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const Title = styled(TextWrap)`
  padding: 0 28px;
`

const StyledCloseButton = styled(CloseButton)`
  margin-right: 15px;
`

const Explanation = styled(TextWrap)`
  padding: 0 28px;
  align-self: center;
`

const ConsultButtonWrap = styled.div`
  margin: 0 28px;
  width: 100%;
`
