import { Permission } from "api/types/auth"
import { axiosClient } from "lib/axios-client"

/**
 * API Path
 */
export const SIGNIN_API_PATH = {
  SIGNIN: "/signin",
} as const

export interface SigninRequest {
  /**
   * User(会員/専門家/管理者)のメールアドレス
   */
  email: string
  /**
   * User(会員/専門家/管理者)のパスワード
   */
  password: string
}

export interface SigninResponse {
  /**
   * トークン
   */
  token: string
  /**
   * User権限
   */
  permission: Permission
}

/**
 * ログイン処理系API
 */
export const SigninAPI = {
  /**
   * ログイン処理
   * @param payload SigninRequest
   * @returns SigninResponse
   */
  async signin(payload: SigninRequest) {
    const { data } = await axiosClient.post<SigninResponse>(
      SIGNIN_API_PATH.SIGNIN,
      payload,
    )
    return data
  },
} as const
