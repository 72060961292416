import {
  CsvDownloadGenerateRequest,
  CsvDownloadGenerateResponse,
  LpDownloadGetResponse,
  LpId,
  Phase2DownloadGetResponse,
  UserLpDownloadGetRequest,
  UserPhase2DownloadGetRequest,
  UserPhase2DownloadGetResponse,
} from "api/download/types"
import { axiosClient } from "lib/axios-client"

export const DownloadAPI = {
  /**
   * ライフプランの詳細結果PDFをダウンロードする
   * @param lpId
   */
  generateLpPdfLink: async (lpId: LpId) => {
    const { data } = await axiosClient.request<LpDownloadGetResponse>({
      url: `/download/lp/${lpId}`,
      method: "GET",
    })
    return data
  },

  /**
   * 顧客情報CSVを生成しダウンロードする
   */
  generateCsv: async (payload: CsvDownloadGenerateRequest) => {
    const { data } = await axiosClient.request<CsvDownloadGenerateResponse>({
      url: "/users/download/csv",
      method: "POST",
      data: payload,
    })
    return data
  },

  /**
   * 特定ユーザのライフプランの詳細結果PDFをダウンロードする
   * @param lpId
   * @param userId
   */
  generateUserLpPdfLink: async ({ userId, lpId }: UserLpDownloadGetRequest) => {
    const { data } = await axiosClient.request<LpDownloadGetResponse>({
      url: `/users/${userId}/download/lp/${lpId}`,
      method: "GET",
    })
    return data
  },

  /**
   * フェーズ2の計画書Excelをダウンロード
   * @param phase2Id
   * @returns Phase2DownloadGetResponse
   */
  generatePhase2ExcelLink: async (phase2Id: number) => {
    const { data } = await axiosClient.request<Phase2DownloadGetResponse>({
      url: `/download/phase2/${phase2Id}`,
      method: "GET",
    })
    return data
  },

  /**
   * 特定ユーザのフェーズ2の計画書Excelをダウンロード
   * @param userId
   * @param phase2Id
   * @returns UserPhase2DownloadGetResponse
   */
  generateUserPhase2ExcelLink: async ({
    userId,
    phase2Id,
  }: UserPhase2DownloadGetRequest) => {
    const { data } = await axiosClient.request<UserPhase2DownloadGetResponse>({
      url: `/users/${userId}/download/phase2/${phase2Id}`,
      method: "GET",
    })
    return data
  },
} as const
