import { ReactNode } from "react"
import * as yup from "yup"

export enum Phase1QuestionId {
  q0 = "q0",
  q1 = "q1",
  q2 = "q2",
  q3 = "q3",
  q4 = "q4",
  q5 = "q5",
  q6 = "q6",
  q7 = "q7",
  q8_1 = "q8_1",
  q8_2 = "q8_2",
  q9 = "q9",
  q10 = "q10",
  q11 = "q11",
  q12 = "q12",
}

export type Phase1Category = "A" | "I" | "U" | "E"
export type Phase1Result = "result"
export type Home = "home"

export type Phase1FormData = {
  [Phase1QuestionId.q0]?: number
  [Phase1QuestionId.q1]?: (number | undefined)[]
  [Phase1QuestionId.q2]?: number
  [Phase1QuestionId.q3]?: number
  [Phase1QuestionId.q4]?: number
  [Phase1QuestionId.q5]?: number
  [Phase1QuestionId.q6]?: number
  [Phase1QuestionId.q7]?: number
  [Phase1QuestionId.q8_1]?: {
    deposit?: number
    ordinaryProfit?: number
    depreciation?: number
    debtPrincipal?: number
  }
  [Phase1QuestionId.q8_2]?: number
  [Phase1QuestionId.q9]?: number
  [Phase1QuestionId.q10]?: number
  [Phase1QuestionId.q11]?: number
  [Phase1QuestionId.q12]?: number
}

export interface Phase1QuestionConfig {
  schema?: yup.BaseSchema
  element: ReactNode
  next:
    | ((
        formData: Phase1FormData,
        category?: Phase1Category,
        reconstructionYear?: number,
      ) => Phase1QuestionId | Phase1Result | undefined)
    | Phase1QuestionId
    | Phase1Result
  back?:
    | ((
        formData: Phase1FormData,
        category?: Phase1Category,
      ) => Phase1QuestionId | undefined)
    | Phase1QuestionId
    | Home
  diagnosis?: (
    formData: Phase1FormData,
    category?: Phase1Category,
  ) => Phase1Diagnosis | undefined
}

export type NextQuestion =
  | "q0"
  | "q1"
  | "q2"
  | "q3"
  | "q4"
  | "q5"
  | "q6"
  | "q7"
  | "q8_1"
  | "q8_2"
  | "q9"
  | "q10"
  | "q11"
  | "q12"

export type Phase1Diagnosis = "A" | "B" | "C" | "D" | "E" | "F" | "G" | "H"

export type Phase1DiagnosisResult =
  | "AA"
  | "AB"
  | "AC"
  | "AD"
  | "AE"
  | "AF"
  | "AG"
  | "AH"
  | "IA"
  | "IB"
  | "IC"
  | "ID"
  | "IE"
  | "IF"
  | "IG"
  | "IH"
  | "UA"
  | "UB"
  | "UC"
  | "UD"
  | "UE"
  | "UF"
  | "UG"
  | "UH"
  | "EA"
  | "EB"
  | "EC"
  | "ED"
  | "EE"
  | "EF"
  | "EG"
  | "EH"
