import { generatePath, RouteObject, useParams } from "react-router-dom"
import { AppCommonLayout } from "pages/components/AppCommonLayout"
import { lazyImport } from "utils"

const { SupporterCreateMemoPage } = lazyImport(
  () => import("pages/supporter/users/user_id/memos/create"),
  "SupporterCreateMemoPage",
)

const { SupporterEditMemoPage } = lazyImport(
  () => import("pages/supporter/users/user_id/memos/memo_id/edit"),
  "SupporterEditMemoPage",
)

const { SupporterEditNamePage } = lazyImport(
  () => import("pages/supporter/settings/name"),
  "SupporterEditNamePage",
)

const { SupporterEditEmailPage } = lazyImport(
  () => import("pages/supporter/settings/email"),
  "SupporterEditEmailPage",
)

const { SupporterEditEmailCompletePage } = lazyImport(
  () => import("pages/supporter/settings/email/complete"),
  "SupporterEditEmailCompletePage",
)

const { SupporterEditPasswordPage } = lazyImport(
  () => import("pages/supporter/settings/password"),
  "SupporterEditPasswordPage",
)

const { SupporterUserDetailPage } = lazyImport(
  () => import("pages/supporter/users/user_id"),
  "SupporterUserDetailPage",
)

const { SupporterBookmarksPage } = lazyImport(
  () => import("pages/supporter/bookmarks"),
  "SupporterBookmarksPage",
)

const { SupporterLPAnswerPage } = lazyImport(
  () => import("pages/supporter/users/user_id/results/lp/lp_id/answer"),
  "SupporterLPAnswerPage",
)

const { SupportUserListPage } = lazyImport(
  () => import("pages/supporter/users"),
  "SupportUserListPage",
)

const { SupportUserSearchPage } = lazyImport(
  () => import("pages/supporter/users/search"),
  "SupportUserSearchPage",
)

const { SupporterUserRegisterPage } = lazyImport(
  () => import("pages/supporter/users/register"),
  "SupporterUserRegisterPage",
)

const { SupporterCreateUserPage } = lazyImport(
  () => import("pages/supporter/users/create"),
  "SupporterCreateUserPage",
)

const { SupporterUserDeletePage } = lazyImport(
  () => import("pages/supporter/users/user_id/delete"),
  "SupporterUserDeletePage",
)

const { SupporterHomePage } = lazyImport(
  () => import("pages/supporter/home"),
  "SupporterHomePage",
)

const { SupporterResultsPage } = lazyImport(
  () => import("pages/supporter/users/user_id/results/index"),
  "SupporterResultsPage",
)
const { SupporterLPResultPage } = lazyImport(
  () => import("pages/supporter/users/user_id/results/lp/lp_Id"),
  "SupporterLPResultPage",
)

const { SupporterContactPage } = lazyImport(
  () => import("pages/supporter/contact"),
  "SupporterContactPage",
)

const { SupporterParentAccountOnly } = lazyImport(
  () => import("pages/supporter/components/ParentAccountOnly"),
  "SupporterParentAccountOnly",
)

const { SupporterPhase1ResultPage } = lazyImport(
  () => import("pages/supporter/users/user_id/results/phase1/phase1_id"),
  "SupporterPhase1ResultPage",
)

const { SupporterPhase1AnswerPage } = lazyImport(
  () => import("pages/supporter/users/user_id/results/phase1/phase1_id/answer"),
  "SupporterPhase1AnswerPage",
)

const { SupporterPhase2AnswerPage } = lazyImport(
  () => import("pages/supporter/users/user_id/results/phase2/phase2_id"),
  "SupporterPhase2AnswerPage",
)

const HOME = "home"
const SETTINGS = "settings"
const CONTACT = "contact"
const BOOKMARKS = "bookmarks"
const USERS = "users"
const USER_ID = "user_id"
const CREATE = "create"
const DELETE = "delete"
const EDIT = "edit"
const NAME = "name"
const PASSWORD = "password"
const EMAIL = "email"
const MEMOS = "memos"
const MEMO_ID = "memo_id"
const VERIFY = "verify"
const RESULTS = "results"
const LP = "lp"
const LP_ID = "lp_id"
const ANSWER = "answer"
const SEARCH = "search"
const REGISTER = "register"
const PHASE1 = "phase1"
const PHASE1_ID = "phase1_id"
const PHASE2 = "phase2"
const PHASE2_ID = "phase2_id"

export const SUPPORTER_ROUTE_PATH = {
  HOME: `/${HOME}`,
  SETTINGS_NAME: `/${SETTINGS}/${NAME}`,
  SETTINGS_PASSWORD: `/${SETTINGS}/${PASSWORD}`,
  SETTINGS_EMAIL: `/${SETTINGS}/${EMAIL}`,
  SETTINGS_EMAIL_VERIFY: `/${SETTINGS}/${EMAIL}/${VERIFY}`,
  BOOKMARKS: `/${BOOKMARKS}`,
  CONTACT: `/${CONTACT}`,
  USER_LIST: `/${USERS}`,
  USER_CREATE: `/${USERS}/${CREATE}`,
  USER_DETAIL: `/${USERS}/:${USER_ID}`,
  USER_DELETE: `/${USERS}/:${USER_ID}/${DELETE}`,
  USER_MEMO_CREATE: `/${USERS}/:${USER_ID}/${MEMOS}/${CREATE}`,
  USER_MEMO_EDIT: `/${USERS}/:${USER_ID}/${MEMOS}/:${MEMO_ID}/${EDIT}`,
  USER_SEARCH: `/${USERS}/${SEARCH}`,
  USER_REGISTER: `/${USERS}/${REGISTER}`,
  USER_RESULTS: `/${USERS}/:${USER_ID}/${RESULTS}`,
  USER_RESULT_LP: `/${USERS}/:${USER_ID}/${RESULTS}/${LP}/:${LP_ID}`,
  USER_RESULT_LP_ANSWER: `/${USERS}/:${USER_ID}/${RESULTS}/${LP}/:${LP_ID}/${ANSWER}`,
  USER_RESULT_PHASE1: `/${USERS}/:${USER_ID}/${RESULTS}/${PHASE1}/:${PHASE1_ID}`,
  USER_RESULT_PHASE1_ANSWER: `/${USERS}/:${USER_ID}/${RESULTS}/${PHASE1}/:${PHASE1_ID}/${ANSWER}`,
  USER_RESULT_PHASE2: `/${USERS}/:${USER_ID}/${RESULTS}/${PHASE2}/:${PHASE2_ID}`,
} as const

export function getUserDetailPath(userId: string) {
  return generatePath(SUPPORTER_ROUTE_PATH.USER_DETAIL, { [USER_ID]: userId })
}

export function getUserDeletePath(userId: string) {
  return generatePath(SUPPORTER_ROUTE_PATH.USER_DELETE, { [USER_ID]: userId })
}

export function getUserMemoCreatePath(userId: string) {
  return generatePath(SUPPORTER_ROUTE_PATH.USER_MEMO_CREATE, {
    [USER_ID]: userId,
  })
}

export function getUserMemoEditPath(userId: string, memoId: string) {
  return generatePath(SUPPORTER_ROUTE_PATH.USER_MEMO_EDIT, {
    [USER_ID]: userId,
    [MEMO_ID]: memoId,
  })
}

export function getUserResultsPath(userId: string) {
  return generatePath(SUPPORTER_ROUTE_PATH.USER_RESULTS, { [USER_ID]: userId })
}

export function getUserResultLPAnswerPath(userId: string, lpId: number) {
  return generatePath(SUPPORTER_ROUTE_PATH.USER_RESULT_LP_ANSWER, {
    [USER_ID]: userId,
    [LP_ID]: String(lpId),
  })
}
export function getUserResultLPPath(userId: string, lpId: number) {
  return generatePath(SUPPORTER_ROUTE_PATH.USER_RESULT_LP, {
    [USER_ID]: userId,
    [LP_ID]: String(lpId),
  })
}

export function getUserResultPhase1Path(userId: string, phase1Id: number) {
  return generatePath(SUPPORTER_ROUTE_PATH.USER_RESULT_PHASE1, {
    [USER_ID]: userId,
    [PHASE1_ID]: String(phase1Id),
  })
}

export function getUserResultPhase1AnswerPath(
  userId: string,
  phase1Id: number,
) {
  return generatePath(SUPPORTER_ROUTE_PATH.USER_RESULT_PHASE1_ANSWER, {
    [USER_ID]: userId,
    [PHASE1_ID]: String(phase1Id),
  })
}

export function getUserResultPhase2Path(userId: string, phase2Id: number) {
  return generatePath(SUPPORTER_ROUTE_PATH.USER_RESULT_PHASE2, {
    [USER_ID]: userId,
    [PHASE2_ID]: String(phase2Id),
  })
}

export function useMemoId() {
  const { [MEMO_ID]: memoId } = useParams()
  if (memoId === undefined) {
    return
  }
  const parsedMemoId = parseInt(memoId)
  if (Number.isNaN(parsedMemoId)) {
    return
  }
  return parsedMemoId
}

export function usePhase1Id() {
  const { [PHASE1_ID]: phase1Id } = useParams()
  if (phase1Id === undefined) {
    return
  }
  const parsedPhase1Id = parseInt(phase1Id)
  if (Number.isNaN(parsedPhase1Id)) {
    return
  }
  return parsedPhase1Id
}

export function usePhase2Id() {
  const { [PHASE2_ID]: phase2Id } = useParams()
  if (phase2Id === undefined) {
    return
  }
  const parsedPhase2Id = parseInt(phase2Id)
  if (Number.isNaN(parsedPhase2Id)) {
    return
  }
  return parsedPhase2Id
}

/**
 * サポーター企業専用ルーティング
 */
export const supporterRoutes: RouteObject[] = [
  {
    path: "/",
    element: <AppCommonLayout />,
    children: [
      {
        path: SUPPORTER_ROUTE_PATH.HOME,
        element: <SupporterHomePage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.SETTINGS_NAME,
        element: <SupporterEditNamePage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.SETTINGS_EMAIL,
        element: <SupporterEditEmailPage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.SETTINGS_EMAIL_VERIFY,
        element: <SupporterEditEmailCompletePage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.SETTINGS_PASSWORD,
        element: <SupporterEditPasswordPage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.BOOKMARKS,
        element: <SupporterBookmarksPage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.CONTACT,
        element: <SupporterContactPage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.USER_LIST,
        element: <SupporterParentAccountOnly />,
        children: [{ index: true, element: <SupportUserListPage /> }],
      },
      {
        path: SUPPORTER_ROUTE_PATH.USER_SEARCH,
        element: <SupportUserSearchPage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.USER_REGISTER,
        element: <SupporterUserRegisterPage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.USER_CREATE,
        element: <SupporterCreateUserPage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.USER_DELETE,
        element: <SupporterUserDeletePage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.USER_DETAIL,
        element: <SupporterUserDetailPage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.USER_MEMO_CREATE,
        element: <SupporterCreateMemoPage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.USER_MEMO_EDIT,
        element: <SupporterEditMemoPage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.USER_RESULTS,
        element: <SupporterResultsPage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.USER_RESULT_LP_ANSWER,
        element: <SupporterLPAnswerPage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.USER_RESULT_LP,
        element: <SupporterLPResultPage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.USER_RESULT_PHASE1,
        element: <SupporterPhase1ResultPage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.USER_RESULT_PHASE1_ANSWER,
        element: <SupporterPhase1AnswerPage />,
      },
      {
        path: SUPPORTER_ROUTE_PATH.USER_RESULT_PHASE2,
        element: <SupporterPhase2AnswerPage />,
      },
    ],
  },
]
