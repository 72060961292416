import { useTheme } from "styled-components"
import { SvgCreater } from "utils/SvgCreater"
import { IconProps } from "./interface"

export const SettingIcon = ({ size = 24, ...rest }: IconProps) => {
  const theme = useTheme()
  return (
    <SvgCreater size={size} fill={theme.color.nobel} {...rest}>
      <path d="M19.5 12C19.5 11.77 19.49 11.55 19.47 11.32L21.33 9.91C21.73 9.61 21.84 9.05 21.59 8.61L19.72 5.38C19.47 4.94 18.93 4.76 18.47 4.96L16.32 5.87C15.95 5.61 15.56 5.38 15.15 5.19L14.86 2.88C14.8 2.38 14.37 2 13.87 2L10.14 2C9.62997 2 9.19997 2.38 9.13997 2.88L8.84997 5.19C8.43997 5.38 8.04997 5.61 7.67997 5.87L5.52997 4.96C5.06997 4.76 4.52997 4.94 4.27997 5.38L2.40997 8.62C2.15997 9.06 2.26997 9.61 2.66997 9.92L4.52997 11.33C4.50997 11.55 4.49997 11.77 4.49997 12C4.49997 12.23 4.50997 12.45 4.52997 12.68L2.66997 14.09C2.26997 14.39 2.15997 14.95 2.40997 15.39L4.27997 18.62C4.52997 19.06 5.06997 19.24 5.52997 19.04L7.67997 18.13C8.04997 18.39 8.43997 18.62 8.84997 18.81L9.13997 21.12C9.19997 21.62 9.62997 22 10.13 22L13.86 22C14.36 22 14.79 21.62 14.85 21.12L15.14 18.81C15.55 18.62 15.94 18.39 16.31 18.13L18.46 19.04C18.92 19.24 19.46 19.06 19.71 18.62L21.58 15.39C21.83 14.95 21.72 14.4 21.32 14.09L19.46 12.68C19.49 12.45 19.5 12.23 19.5 12ZM12.04 15.5C10.11 15.5 8.53997 13.93 8.53997 12C8.53997 10.07 10.11 8.5 12.04 8.5C13.97 8.5 15.54 10.07 15.54 12C15.54 13.93 13.97 15.5 12.04 15.5Z" />
    </SvgCreater>
  )
}
