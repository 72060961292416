import { ReactChild } from "react"
import { format } from "date-fns"
import styled from "styled-components"
import { Button } from "components/atoms/Button"
import { Flag } from "components/atoms/Flag"
import { SpeechBubble } from "components/atoms/SpeechBubble"
import { TextWrap } from "components/atoms/TextWrap"
import { CharacterLines } from "components/molecules/CharacterLines"
import { GroupTitle } from "components/molecules/GroupeTitle"
import { DoubleButtonModal } from "components/molecules/Modals"
import { TextWithLabel } from "components/molecules/TextWithLabel"
import { YesNoModal } from "components/organisms/Modals"
import { ReconsultConfirmModal } from "components/organisms/Modals/ReconsultConfirmModal"
import { useModal, ModalWrap } from "hooks/useModal"
import { appMedia } from "theme"
import { noop } from "utils"
import { GeneralContainer } from "utils/GeneralContainer"

export type TitleAndQAs =
  | {
      title?: string
      QA?: {
        question?: string
        answer?: ReactChild
      }[]
    }[]
  | undefined

interface Props {
  /**
   * title:質問グループ question: 質問文,answer: 回答内容のReactNode
   */
  titleAndQAs?: TitleAndQAs
  /**
   * 回答日
   */
  createdAt?: Date
  /**
   * 専門家相談フラグが立っているか否か boolean
   */
  isFlagActive?: boolean
  /**
   * 相談するボタン削除
   */
  disableExpert?: boolean
  /**
   * 再診断するボタン削除
   */
  disableReconsult?: boolean
  /**
   * 戻るボタンのハンドラ
   */
  onClickBack: () => void
  /**
   * 回答一覧へボタンのハンドラ
   */
  onClickResultList?: () => void
  /**
   * 専門家相談モーダル内 記入するボタンのハンドラ
   */
  onClickWrite?: () => void
  /**
   * 専門家相談モーダル内 このまま連絡を待つボタンのハンドラ
   */
  onClickWait?: () => void
  /**
   * 再診断時の処理
   */
  onReconsulting?: () => void
}

/**
 * フェーズ2/回答確認画面
 */
export const Phase2AnswerCommonLayout = ({
  titleAndQAs,
  createdAt,
  isFlagActive,
  disableExpert = false,
  disableReconsult = false,
  onClickBack,
  onClickWrite = noop,
  onClickWait = noop,
  onReconsulting = noop,
}: Props) => {
  const {
    ModalPortal: ExpertConfirmModalPortal,
    open: openExpertConfirmModal,
    close: closeExpertConfirmModal,
  } = useModal({
    closeOnOverlayClick: false,
    preventScroll: true,
  })

  const {
    ModalPortal: ExpertInConsultationModalPortal,
    open: openExpertInConsultationModal,
    close: closeExpertInConsultationModal,
  } = useModal({
    closeOnOverlayClick: false,
    preventScroll: true,
  })

  const {
    ModalPortal: ConfirmReconsultationModalPortal,
    open: openConfirmReconsultationModal,
    close: closeConfirmReconsultationModal,
  } = useModal({
    closeOnOverlayClick: true,
    preventScroll: true,
  })

  return (
    <Container>
      <HeadingWrap>
        <Heading>事業承継診断結果</Heading>
        {createdAt && (
          <SubHeading>{`${format(createdAt, "yyyy/MM/dd")}実施`}</SubHeading>
        )}
      </HeadingWrap>
      {titleAndQAs && (
        <>
          {titleAndQAs.map((titleAndQA, index) => (
            <AnswersContainer key={index}>
              {titleAndQA.title && <GroupTitle>{titleAndQA.title}</GroupTitle>}
              {titleAndQA.QA &&
                titleAndQA.QA.map((questionAndAnswer, index) => (
                  <QAContainer key={index}>
                    {questionAndAnswer.question && (
                      <TextWithLabel label="設問" labelSize="s">
                        {questionAndAnswer.question}
                      </TextWithLabel>
                    )}
                    {questionAndAnswer.answer && (
                      <TextWithLabel label="回答" labelSize="s">
                        {questionAndAnswer.answer}
                      </TextWithLabel>
                    )}
                  </QAContainer>
                ))}
            </AnswersContainer>
          ))}
        </>
      )}
      <CharacterLinesWrap>
        <CharacterLines
          name="kei-chan"
          status="right"
          direction="leading"
          quote="大丈夫よ、
自分一人じゃ
難しそうだと思ったら
専門家に相談してね。"
        />
      </CharacterLinesWrap>
      <SpeechBubbleWrap>
        <SpeechBubble>
          けいちゃんおすすめ。
          <br />
          専門家フラグを立てると、
          <br />
          結果を専門が見て助言をくれるよ！
        </SpeechBubble>
      </SpeechBubbleWrap>
      {disableExpert === false && (
        <ExpertWrap>
          <Button
            shape="square"
            color="green"
            onClick={() => {
              isFlagActive
                ? openExpertInConsultationModal()
                : openExpertConfirmModal()
            }}
          >
            {"専門家に相談する >>"}
          </Button>
          <Flag isActive={isFlagActive ? true : false} />
        </ExpertWrap>
      )}
      <ButtonsWrap>
        {disableReconsult === false && (
          <Button
            shape="noFrame"
            color="green"
            onClick={openConfirmReconsultationModal}
          >
            {"再診断する>>"}
          </Button>
        )}
        <Button shape="default" color="white" onClick={onClickBack}>
          回答一覧へ
        </Button>
      </ButtonsWrap>
      {disableExpert === false && (
        <>
          <ExpertConfirmModalPortal>
            <ModalWrap alignCenter={true}>
              <YesNoModal
                onAgree={() => {
                  closeExpertConfirmModal()
                  openExpertInConsultationModal()
                }}
                onCancel={closeExpertConfirmModal}
              >
                専門家があなたの診断結果を見て
                <br />
                アドバイスを行います。
                <br />
                専門家に相談しますか。
              </YesNoModal>
            </ModalWrap>
          </ExpertConfirmModalPortal>
          <ExpertInConsultationModalPortal>
            <ModalWrap alignCenter={true}>
              <DoubleButtonModal
                topButtonText="記入する"
                bottomButtonText="このまま連絡を待つ"
                onClickTopButton={onClickWrite}
                onClickBottomButton={() => {
                  onClickWait()
                  closeExpertInConsultationModal()
                }}
              >
                専門家に相談中です。
                <br />
                相談内容を記入しますか。
              </DoubleButtonModal>
            </ModalWrap>
          </ExpertInConsultationModalPortal>
          <ConfirmReconsultationModalPortal>
            <ModalWrap>
              <ReconsultConfirmModal
                phase="phase2"
                onAgree={onReconsulting}
                onCancel={closeConfirmReconsultationModal}
              />
            </ModalWrap>
          </ConfirmReconsultationModalPortal>
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  padding-top: 15px;
  padding-bottom: 35px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`

const HeadingWrap = styled(GeneralContainer)``

const Heading = styled(TextWrap).attrs({ weight: "bold", size: "l" })`
  text-align: center;
`

const SubHeading = styled(TextWrap).attrs({ weight: "bold", size: "m" })`
  text-align: center;
  color: ${({ theme }) => theme.color.nobel};
`

const AnswersContainer = styled(GeneralContainer).attrs({
  disableGutters: true,
})`
  gap: 35px;
`
const QAContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${appMedia.lessThan("tablet")`
  padding: 0 28px
  `}
`

export const AnswerWrap = styled(TextWrap).attrs({
  size: "m",
  weight: "bold",
})``

const ButtonsWrap = styled(GeneralContainer)`
  gap: 25px;
`

const CharacterLinesWrap = styled(GeneralContainer).attrs({
  disableGutters: true,
})``

const SpeechBubbleWrap = styled(GeneralContainer)`
  padding-bottom: 20px;
`
const ExpertWrap = styled(GeneralContainer)`
  flex-direction: row;
  gap: 14px;
`
