import { Phase2AnswerC } from "api"
import { AnswerWrap } from "pages/components/Phase2Answer"
import { dateFormatter } from "utils"
import { selectAndDate, QAPresentation } from "./utils"

export const phase2ConvertAnswersC: QAPresentation<Phase2AnswerC> = (
  answers: Phase2AnswerC,
) => {
  return [
    {
      title: answers.question_group_1?.title,
      QA: [
        // answer_01?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_1?.answers?.answer_01?.question,
          answer: answers.question_group_1?.answers?.answer_01?.answer?.content,
        },
        // answer_02?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_1?.answers?.answer_02?.question,
          answer: answers.question_group_1?.answers?.answer_02?.answer?.content,
        },
        // answer_03?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_1?.answers?.answer_03?.question,
          answer: answers.question_group_1?.answers?.answer_03?.answer?.content,
        },
        // answer_04?: DeepPartial<QA<DateTypeAnswer>>
        {
          question: answers.question_group_1?.answers?.answer_04?.question,
          answer: dateFormatter(
            answers.question_group_1?.answers?.answer_04?.answer?.content,
          ),
        },
        // answer_05?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_05?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_05),
        },
        // answer_06?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_06?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_06),
        },
        // answer_07?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_07?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_07),
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_2?.title,
      QA: [
        // answer_08?: SelectAndDate
        {
          question: answers.question_group_2?.answers?.answer_08?.question,
          answer: selectAndDate(answers.question_group_2?.answers?.answer_08),
        },
        // answer_09?: SelectAndDate
        {
          question: answers.question_group_2?.answers?.answer_09?.question,
          answer: selectAndDate(answers.question_group_2?.answers?.answer_09),
        },
        // answer_10?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_2?.answers?.answer_10?.question,
          answer: answers.question_group_2?.answers?.answer_10?.answer?.content,
        },
        // answer_11?: SelectAndDate
        {
          question: answers.question_group_2?.answers?.answer_11?.question,
          answer: selectAndDate(answers.question_group_2?.answers?.answer_11),
        },
        // answer_12?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_2?.answers?.answer_12?.question,
          answer: answers.question_group_2?.answers?.answer_12?.answer?.content,
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_3?.title,
      QA: [
        // answer_13?: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>>
        {
          question: answers.question_group_3?.answers?.answer_13?.question,
          answer: answers.question_group_3?.answers?.answer_13?.answer?.content,
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      QA: [
        // answer_14?: { question?: string } 表示なし
        // answer_15?: SelectAndDate
        {
          question: answers.question_group_4?.answers?.answer_15?.question,
          answer: selectAndDate(answers.question_group_4?.answers?.answer_15),
        },
        // answer_16?: SelectAndDate
        {
          question: answers.question_group_4?.answers?.answer_16?.question,
          answer: selectAndDate(answers.question_group_4?.answers?.answer_16),
        },
        // answer_17?: SelectAndDate
        {
          question: answers.question_group_4?.answers?.answer_17?.question,
          answer: selectAndDate(answers.question_group_4?.answers?.answer_17),
        },
        // answer_18?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_18?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_18?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_18?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_19?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_19?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_19?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_19?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_20?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_20?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_20?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_20?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_21?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_21?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_21?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_21?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_22?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_22?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_22?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_22?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_23?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_23?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_23?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_23?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_24?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_24?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_24?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_24?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_25?: SelectAndDate
        {
          question: answers.question_group_4?.answers?.answer_25?.question,
          answer: selectAndDate(answers.question_group_4?.answers?.answer_25),
        },
        // answer_26?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_26?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_26?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_26?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_27?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_27?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_27?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_27?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_5?.title,
      QA: [
        // answer_28?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_5?.answers?.answer_28?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_5?.answers?.answer_28?.answer?.label}:
              {dateFormatter(
                answers.question_group_5?.answers?.answer_28?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_29?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_5?.answers?.answer_29?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_5?.answers?.answer_29?.answer?.label}:
              {dateFormatter(
                answers.question_group_5?.answers?.answer_29?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_30?: DeepPartial<QA<StringTypeAnswer>>
        {
          question: answers.question_group_5?.answers?.answer_30?.question,
          answer: answers.question_group_5?.answers?.answer_30?.answer?.content,
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_6?.title,
      QA: [
        // answer_31?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_6?.answers?.answer_31?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_6?.answers?.answer_31?.answer?.label}:
              {dateFormatter(
                answers.question_group_6?.answers?.answer_31?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_32?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_6?.answers?.answer_32?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_6?.answers?.answer_32?.answer?.label}:
              {dateFormatter(
                answers.question_group_6?.answers?.answer_32?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_33?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_6?.answers?.answer_33?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_6?.answers?.answer_33?.answer?.label}:
              {dateFormatter(
                answers.question_group_6?.answers?.answer_33?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_34?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_6?.answers?.answer_34?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_6?.answers?.answer_34?.answer?.label}:
              {dateFormatter(
                answers.question_group_6?.answers?.answer_34?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_35?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_6?.answers?.answer_35?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_6?.answers?.answer_35?.answer?.label}:
              {dateFormatter(
                answers.question_group_6?.answers?.answer_35?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_36?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_6?.answers?.answer_36?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_6?.answers?.answer_36?.answer?.label}:
              {dateFormatter(
                answers.question_group_6?.answers?.answer_36?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_37?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_6?.answers?.answer_37?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_6?.answers?.answer_37?.answer?.label}:
              {dateFormatter(
                answers.question_group_6?.answers?.answer_37?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        //answer_38?: SelectAndDate
        {
          question: answers.question_group_6?.answers?.answer_38?.question,
          answer: selectAndDate(answers.question_group_6?.answers?.answer_38),
        },
        //answer_39?: SelectAndDate
        {
          question: answers.question_group_6?.answers?.answer_39?.question,
          answer: selectAndDate(answers.question_group_6?.answers?.answer_39),
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_7?.title,
      QA: [
        // answer_40?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_7?.answers?.answer_40?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_7?.answers?.answer_40?.answer?.label}:
              {dateFormatter(
                answers.question_group_7?.answers?.answer_40?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_41?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_7?.answers?.answer_41?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_7?.answers?.answer_41?.answer?.label}:
              {dateFormatter(
                answers.question_group_7?.answers?.answer_41?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_42?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_7?.answers?.answer_42?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_7?.answers?.answer_42?.answer?.label}:
              {dateFormatter(
                answers.question_group_7?.answers?.answer_42?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_42?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_7?.answers?.answer_43?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_7?.answers?.answer_43?.answer?.label}:
              {dateFormatter(
                answers.question_group_7?.answers?.answer_43?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_44?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_7?.answers?.answer_44?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_7?.answers?.answer_44?.answer?.label}:
              {dateFormatter(
                answers.question_group_7?.answers?.answer_44?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_45?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_7?.answers?.answer_45?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_7?.answers?.answer_45?.answer?.label}:
              {dateFormatter(
                answers.question_group_7?.answers?.answer_45?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_46?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_7?.answers?.answer_46?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_7?.answers?.answer_46?.answer?.label}:
              {dateFormatter(
                answers.question_group_7?.answers?.answer_46?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_47?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_7?.answers?.answer_47?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_7?.answers?.answer_47?.answer?.label}:
              {dateFormatter(
                answers.question_group_7?.answers?.answer_47?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_48?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_7?.answers?.answer_48?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_7?.answers?.answer_48?.answer?.label}:
              {dateFormatter(
                answers.question_group_7?.answers?.answer_48?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_49?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_7?.answers?.answer_49?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_7?.answers?.answer_49?.answer?.label}:
              {dateFormatter(
                answers.question_group_7?.answers?.answer_49?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_50?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_7?.answers?.answer_50?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_7?.answers?.answer_50?.answer?.label}:
              {dateFormatter(
                answers.question_group_7?.answers?.answer_50?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_51?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_7?.answers?.answer_51?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_7?.answers?.answer_51?.answer?.label}:
              {dateFormatter(
                answers.question_group_7?.answers?.answer_51?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
      ].filter((item) => item.question && item.answer),
    },
  ].filter((item) => Boolean(item.QA.length))
}
