import { BankBorrowingAmountAndNetWorth } from "api/types/phase2/utils"
import { BankBorrowingAmountAndNetWorthFormData } from "pages/utils/types/phase2FormData"

/**
 * 銀行借入額、純資産額(A Q32, B Q32)
 */
export const bankBorrowingAmountAndNetWorthDraftTranslator = (
  answer: BankBorrowingAmountAndNetWorth | undefined,
): BankBorrowingAmountAndNetWorthFormData | undefined =>
  answer?.answer?.content?.map(({ bank_borrowing_amount, net_worth } = {}) => ({
    bankBorrowing: bank_borrowing_amount?.content,
    assets: net_worth?.content,
  }))
