import { useCallback, useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useFieldArray, useForm } from "react-hook-form"
import styled, { useTheme } from "styled-components"
import { InferType } from "yup"
import { Button } from "components/atoms/Button"
import { AddIcon, DeleteIcon } from "components/atoms/Icons"
import { TextWrap } from "components/atoms/TextWrap"
import yup, { ERROR_MESSAGE } from "lib/yup"
import { px } from "utils"
import { EditableTag } from "../NarrowDownAndReorder/components/EditableTag"

const DEFAULT_ITEM = {
  displayName: "",
  deleted: false,
}

/**
 * schema
 */
const itemSchema = yup
  .object({
    displayName: yup.string().when("deleted", {
      is: false,
      then: (schema) => schema.min(1).max(29).required(),
      otherwise: (schema) => schema.optional(),
    }),
    id: yup.string(),
    deleted: yup.boolean().required(),
  })
  .required()

const schema = yup
  .object({
    items: yup.array().of(itemSchema),
  })
  .required()

/**
 * types
 */
type Item = InferType<typeof itemSchema>
type Schema = InferType<typeof schema>

export type EditSalesStatusTag = {
  /**
   * タグに表示する文字
   */
  displayName: string
  /**
   * 営業ステータスごとのid
   */
  id: string
}

type EditSalesStatusOnSaveArg = {
  createdItems: Item[]
  changedItems: Item[]
  deletedItems: Item[]
}
export type EditSalesStatusOnSave = (
  editedSalesStatusLists: EditSalesStatusOnSaveArg,
) => void

interface Props {
  /**
   * 選択肢の初期状態の配列
   */
  initialItemList: EditSalesStatusTag[]
  /**
   * 項目間の余白をpx単位で指定
   */
  gap: number
  /**
   * 営業ステータス一覧とボタンの間に入る余白の高さ 未指定だと要素自体描画されない
   */
  spacing?: number
  /**
   * キャンセルボタンを押したときのハンドラ
   */
  onCancel: () => void
  /**
   * 保存するボタンを押したときのハンドラ
   */
  onSave: EditSalesStatusOnSave
}

/**
 * 営業ステータス編集画面
 * onSaveに
 * - 新規作成されたタグ
 * - 編集されたタグ
 * - 消去されたタグ
 * の配列を渡す
 */
export const EditSalesStatus = ({
  initialItemList,
  gap,
  spacing,
  onCancel,
  onSave,
}: Props) => {
  const theme = useTheme()

  const [focusedTagIndex, setFocusedTagIndex] = useState<number>()

  const {
    register,
    control,
    watch,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Schema>({
    resolver: yupResolver(schema),
    defaultValues: {
      items: initialItemList.map((item) => ({ ...item, deleted: false })),
    },
  })
  const currentValue = watch("items")
  const { fields, append, update } = useFieldArray({
    control,
    name: "items",
    keyName: "key",
  })

  /**
   * スペース上エラーメッセージは同時に一件しか出力しないようにしている
   */
  const errorMessage = errors?.items?.find(
    (item) => item?.displayName?.message !== undefined,
  )?.displayName?.message

  const onSubmit = useCallback(
    ({ items }: Schema) => {
      if (items === undefined) {
        return
      }

      const createdItems = items?.filter(
        (item) => item.id === undefined && !item.deleted,
      )

      const changedItems = items?.filter(
        (item, index) =>
          !item.deleted &&
          item.id !== undefined &&
          item.displayName !== initialItemList[index].displayName,
      )

      const deletedItems = items?.filter(
        (item) => item.deleted && item.id !== undefined,
      )

      return onSave({
        createdItems,
        changedItems,
        deletedItems,
      })
    },
    [initialItemList, onSave],
  )

  const handleDelete = useCallback(() => {
    if (focusedTagIndex === undefined || currentValue === undefined) {
      return
    }

    if (currentValue.filter((item) => !item.deleted).length === 1) {
      setError("items.0.displayName", {
        type: "min",
        message: ERROR_MESSAGE.DELETE_LAST_SALES_STATUS_TAG,
      })
      return
    }

    const updatedItem = {
      ...currentValue[focusedTagIndex],
      deleted: true,
    }
    update(focusedTagIndex, updatedItem)
  }, [currentValue, focusedTagIndex, setError, update])

  return (
    <Container
      gap={gap}
      onSubmit={(e) => {
        handleSubmit(onSubmit)(e)
      }}
    >
      <StatusName>営業ステータス</StatusName>
      <MessageAndIconWrap>
        <TextWrap size="m" weight="bold" color={theme.color.wildWatermelon}>
          営業ステータス編集中。
        </TextWrap>
        <ClickableAddIcon onClick={() => append(DEFAULT_ITEM)} />
        <ClickableDeleteIcon onClick={handleDelete} />
      </MessageAndIconWrap>
      <TagContainer>
        {fields.map((item, index) => {
          const currentDisplayName = currentValue?.[index].displayName

          if (item.deleted || currentDisplayName === undefined) {
            return
          }

          return (
            <EditableTag
              key={item.key}
              currentValue={currentDisplayName}
              defaultValue={item.displayName}
              onFocus={() => setFocusedTagIndex(index)}
              {...register(`items.${index}.displayName` as const)}
            />
          )
        })}
      </TagContainer>
      {errorMessage ? (
        <ErrorMessage weight="bold" size="m" color={theme.color.wildWatermelon}>
          {errorMessage}
        </ErrorMessage>
      ) : (
        spacing !== undefined && <Spacing size={spacing} />
      )}
      <ButtonsWrap>
        <CancelButton type="button" onClick={onCancel}>
          キャンセル
        </CancelButton>
        <SaveButton type="submit">保存する</SaveButton>
      </ButtonsWrap>
    </Container>
  )
}

const Container = styled.form<{ gap: number }>`
  display: flex;
  flex-direction: column;
  row-gap: ${({ gap }) => px(gap)};
`

const StatusName = styled(TextWrap).attrs({
  size: "m",
  weight: "bold",
})``

const MessageAndIconWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const ClickableAddIcon = styled(AddIcon).attrs({
  size: 30,
})`
  cursor: pointer;
`

const ClickableDeleteIcon = styled(DeleteIcon).attrs({ size: 30 })`
  cursor: pointer;
`

const TagContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`

const ButtonsWrap = styled.div`
  display: flex;
  gap: 20px;
`

const CancelButton = styled(Button).attrs({
  color: "gray",
})`
  max-width: 200px;
  flex: 1;
`

const SaveButton = styled(Button).attrs({
  color: "white",
})`
  max-width: 200px;
  flex: 1;
`

const Spacing = styled.div<{ size: number }>`
  height: ${({ size }) => px(size)};
`

const ErrorMessage = styled(TextWrap)``
