import { Phase2AnswerF } from "api"
import { AnswerWrap } from "pages/components/Phase2Answer"
import { dateFormatter } from "utils"
import { QAPresentation, selectAndDate } from "./utils"

export const phase2ConvertAnswersF: QAPresentation<Phase2AnswerF> = (
  answers: Phase2AnswerF,
) => {
  return [
    {
      QA: [
        // answer_00?: { question?: string } 非表示
        // answer_01?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_01?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_01),
        },
        // answer_02?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_02?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_02),
        },
        // answer_03?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_1?.answers?.answer_03?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_1?.answers?.answer_03?.answer?.label}:
              {dateFormatter(
                answers.question_group_1?.answers?.answer_03?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_04?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_1?.answers?.answer_04?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_1?.answers?.answer_04?.answer?.label}:
              {dateFormatter(
                answers.question_group_1?.answers?.answer_04?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_05?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_1?.answers?.answer_05?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_1?.answers?.answer_05?.answer?.label}:
              {dateFormatter(
                answers.question_group_1?.answers?.answer_05?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_06?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_1?.answers?.answer_06?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_1?.answers?.answer_06?.answer?.label}:
              {dateFormatter(
                answers.question_group_1?.answers?.answer_06?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_07?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_1?.answers?.answer_07?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_1?.answers?.answer_07?.answer?.label}:
              {dateFormatter(
                answers.question_group_1?.answers?.answer_07?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
      ].filter((item) => item.question && item.answer),
    },
  ].filter((item) => Boolean(item.QA.length))
}
