import { ConcernAndSolution } from "api/types/phase2/utils"
import { ConcernAndSolutionFormData } from "pages/utils/types/phase2FormData"

/**
 * 不安要素と解決策 あD Q25, あG Q25 Q32
 */
export const concernAndSolutionDraftTranslator = (
  answer: ConcernAndSolution | undefined,
): ConcernAndSolutionFormData | undefined =>
  answer?.answer?.content?.map(({ concern, solution } = {}) => ({
    concern: concern?.content,
    solution: solution?.content,
  }))
