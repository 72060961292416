import { AxiosError } from "axios"
import { useMutation, UseMutationOptions } from "react-query"
import { BookmarkAPI } from "api/bookmark/api"
import {
  BookmarkCreateRequest,
  BookmarkDeleteRequest,
} from "api/bookmark/types"
import { OmitMutationConfig } from "lib/react-query"

const PREFIX_ID = "bookmark"

export const fetchBookmarksQuery = {
  queryKey: `${PREFIX_ID}/fetchBookmarks`,
  queryFn: BookmarkAPI.fetchBookmarks,
} as const

export const createBookmarkMutation: UseMutationOptions<
  void,
  AxiosError,
  BookmarkCreateRequest
> = {
  mutationKey: `${PREFIX_ID}/createBookmark`,
  mutationFn: BookmarkAPI.createBookmark,
}

/**
 * ブックマーク作成Hooks
 * @param config
 */
export function useCreateBookmarkMutation(
  config?: OmitMutationConfig<typeof createBookmarkMutation>,
) {
  return useMutation({ ...createBookmarkMutation, ...config })
}

/**
 * ユーザがbookmarkされている場合bookmark_idが返ってくる
 * bookmarkされていない場合Not Found(404)が返る
 */
export const fetchBookmarkQuery = (userId: string) => ({
  queryKey: `${PREFIX_ID}/fetchBookmark/${userId}`,
  queryFn: () => BookmarkAPI.fetchBookmark(userId),
})

export const deleteBookmarkMutation: UseMutationOptions<
  void,
  AxiosError,
  BookmarkDeleteRequest
> = {
  mutationKey: `${PREFIX_ID}/deleteBookmark`,
  mutationFn: BookmarkAPI.deleteBookmark,
}

/**
 * ブックマーク削除のHooks
 * @param config
 */
export function useDeleteBookmarkMutation(
  config?: OmitMutationConfig<typeof deleteBookmarkMutation>,
) {
  return useMutation({ ...deleteBookmarkMutation, ...config })
}
