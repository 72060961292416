import { ReactNode } from "react"
import styled from "styled-components"
import { Button } from "components/atoms/Button"
import { Modal } from "components/atoms/Modal"
import { Tag } from "components/atoms/Tag"
import { TextWrap } from "components/atoms/TextWrap"

const MODAL_PADDING_X = 38
const MODAL_PADDING_Y = 25
const TAG_TEXT_BUTTON_GAP = 25

interface Props {
  /**
   * テキストの内容
   */
  children: ReactNode
  /**
   * タグの内容
   */
  tagText: string
  /**
   * 「戻る」ボタンを押した際のアクション
   *  useModalのcloseが渡される想定
   */
  onBack: () => void
}

/**
 * 詳細情報を表示するModal
 */
export const DetailModal = ({ children, tagText, onBack }: Props) => {
  return (
    <StyledModal>
      <StyledTag shape="square" size="m">
        {tagText}
      </StyledTag>
      <TextWrap weight="bold" size="m">
        {children}
      </TextWrap>
      <Button color="gray" onClick={onBack}>
        戻る
      </Button>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  padding: ${MODAL_PADDING_Y}px ${MODAL_PADDING_X}px;
  gap: ${TAG_TEXT_BUTTON_GAP}px;
`

const StyledTag = styled(Tag)`
  align-self: center;
`
