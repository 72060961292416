import { ArrayString } from "api/types/phase2/utils"
import { StringArrayFormData } from "pages/utils/types/phase2FormData"

/**
 * テキスト入力の配列の設問
 */
export const stringArrayDraftTranslator = (
  answer: ArrayString | undefined,
): StringArrayFormData | undefined =>
  answer?.answer?.content?.map(({ content } = {}) => content)
