import { useEffect, ReactNode, Suspense } from "react"
import { AuthProvider, useAuthSignoutModal } from "context/auth"
import { ClientDownloadModalProvider } from "context/downloadPlan"
import { InvIdProvider } from "context/invitationId"
import { QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { BrowserRouter } from "react-router-dom"
import { usePublicRoute } from "hooks/usePublicRoute"
import { isAxiosError } from "lib/axios-client"
import { queryClient } from "lib/react-query"
import { AppRoutes } from "routes"

const AppQueryProvider: React.FC = ({ children }) => {
  const { goToNotFound, goToLogin } = usePublicRoute()

  useEffect(() => {
    const handleError = (error: unknown) => {
      if (!isAxiosError(error)) {
        return
      }
      const statusCode = error.response?.status
      if (statusCode === undefined) {
        return
      }

      // 401 Unauthorizedであればログインに遷移
      if (statusCode === 401) {
        goToLogin()
        return
      }

      // 401 Unauthorized以外であれば404に遷移
      if (statusCode >= 400 && statusCode <= 599) {
        goToNotFound()
        return
      }
    }

    queryClient.setDefaultOptions({
      queries: {
        onError: handleError,
        retry: false,
        staleTime: 1000 * 10,
        refetchOnWindowFocus: false,
        notifyOnChangeProps: "tracked",
      },
      mutations: {
        onError: handleError,
      },
    })
  }, [goToLogin, goToNotFound])

  return <>{children}</>
}

const AuthSignoutModalProvider = ({ children }: { children: ReactNode }) => {
  const { Modal } = useAuthSignoutModal()
  return (
    <>
      <Modal />
      {children}
    </>
  )
}

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AppQueryProvider>
            <AuthSignoutModalProvider>
              <InvIdProvider>
                <ClientDownloadModalProvider>
                  <Suspense fallback={null}>
                    <ReactQueryDevtools />
                    <AppRoutes />
                  </Suspense>
                </ClientDownloadModalProvider>
              </InvIdProvider>
            </AuthSignoutModalProvider>
          </AppQueryProvider>
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

export default App
