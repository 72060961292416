import { axiosClient } from "lib/axios-client"
import {
  MemoCreateRequest,
  MemoUpdateRequest,
  MemoUpdateResponse,
  MemoDeleteRequest,
  MemosGetResponse,
  MemoGetResponse,
  MemoGetRequest,
} from "./types"

export const MemoAPI = {
  /**
   * 指定したユーザーに関するメモを取得
   * @param userId 取得対象のUserID
   * @returns MemoGetResponse
   */
  fetchMemos: async (userId: string) => {
    const { data } = await axiosClient.request<MemosGetResponse>({
      url: `/users/${userId}/memo`,
      method: "GET",
    })
    return data
  },

  /**
   * 指定したユーザの特定のメモを取得
   */
  fetchMemo: async ({ userId, memoId }: MemoGetRequest) => {
    const { data } = await axiosClient.request<MemoGetResponse>({
      url: `/users/${userId}/memo/${memoId}`,
      method: "GET",
    })
    return data
  },

  /**
   * 指定したユーザーに関するメモを作成
   * @param userId 作成対象のUserID
   * @param text メモ情報 MemoCreateRequest
   */
  createMemo: async ({ userId, text }: MemoCreateRequest) => {
    await axiosClient.request({
      url: `/users/${userId}/memo`,
      method: "POST",
      data: { text },
    })
  },

  /**
   * 指定したメモを更新
   * @returns MemoUpdateResponse
   */
  updateMemo: async ({ userId, memoId, text }: MemoUpdateRequest) => {
    const { data } = await axiosClient.request<MemoUpdateResponse>({
      url: `/users/${userId}/memo/${memoId}`,
      method: "PATCH",
      data: { text },
    })
    return data
  },

  /**
   * 指定したメモを削除
   * @param userId 削除対象のUserID
   * @param memoId 削除対象のMemoID
   */
  deleteMemo: async ({ userId, memoId }: MemoDeleteRequest) => {
    await axiosClient.request({
      url: `/users/${userId}/memo/${memoId}`,
      method: "DELETE",
    })
  },
} as const
