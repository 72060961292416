import { ChoiceAnswer, QA, StringTypeAnswer } from "api"
import { SelectFormData } from "pages/utils/types/phase2FormData"
import { DeepPartial } from "utils"

interface Args {
  json: {
    question: string
    options: {
      label: string
    }[]
  }
  formData: SelectFormData
}

/**
 * 選択肢の設問
 */
export const selectTranslator = ({
  json,
  formData,
}: Args): DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>> | undefined => {
  if (formData === undefined) return undefined

  return {
    question: json.question,
    answer: {
      type: "string",
      content: json.options[formData - 1].label,
      choice_number: formData,
    },
  } as const
}
