import { RouteObject, generatePath } from "react-router-dom"
import { AppCommonLayout } from "pages/components/AppCommonLayout"
import { LPLayout } from "pages/components/lp/components/LPLayout"
import { Phase1Layout } from "pages/components/phase1/components/Phase1Layout"
import { Phase2Layout } from "pages/components/phase2/Phase2Layout"
import { lazyImport } from "utils"

const { ClientHomePage } = lazyImport(
  () => import("pages/client/home"),
  "ClientHomePage",
)

const { ClientSettingsProfilePage } = lazyImport(
  () => import("pages/client/settings/profile"),
  "ClientSettingsProfilePage",
)

const { ClientSettingsPasswordPage } = lazyImport(
  () => import("pages/client/settings/password"),
  "ClientSettingsPasswordPage",
)

const { ClientSettingsEmailPage } = lazyImport(
  () => import("pages/client/settings/email"),
  "ClientSettingsEmailPage",
)

const { ClientSettingsEmailCompletedPage } = lazyImport(
  () => import("pages/client/settings/email/completed"),
  "ClientSettingsEmailCompletedPage",
)

const { ClientContactPage } = lazyImport(
  () => import("pages/client/contact"),
  "ClientContactPage",
)

const { ClientConsultPage } = lazyImport(
  () => import("pages/client/consult"),
  "ClientConsultPage",
)

const { ClientResultsPage } = lazyImport(
  () => import("pages/client/results"),
  "ClientResultsPage",
)

const { ClientLPAnswerPage } = lazyImport(
  () => import("pages/client/results/lp/lp_id/answer"),
  "ClientLPAnswerPage",
)

const { ClientLPResultPage } = lazyImport(
  () => import("pages/client/results/lp/lp_id"),
  "ClientLPResultPage",
)

const { ClientLPPage } = lazyImport(
  () => import("pages/client/lp"),
  "ClientLPPage",
)

const { ClientLPCompletedPage } = lazyImport(
  () => import("pages/client/lp/complete"),
  "ClientLPCompletedPage",
)

const { ClientPhase1ResultPage } = lazyImport(
  () => import("pages/client/results/phase1/phase1_id"),
  "ClientPhase1ResultPage",
)

const { ClientPhase1ResultConfirmPage } = lazyImport(
  () => import("pages/client/phase1/confirm"),
  "ClientPhase1ResultConfirmPage",
)

const { ClientPhase1AnswerPage } = lazyImport(
  () => import("pages/client/results/phase1/phase1_id/answer"),
  "ClientPhase1AnswerPage",
)

const { ClientPhase2AnswerConfirmPage } = lazyImport(
  () => import("pages/client/phase2/confirm"),
  "ClientPhase2AnswerConfirmPage",
)

const { ClientPhase2AnswerPage } = lazyImport(
  () => import("pages/client/results/phase2/phase2_id"),
  "ClientPhase2AnswerPage",
)

const { ClientPhase1Page } = lazyImport(
  () => import("pages/client/phase1"),
  "ClientPhase1Page",
)

const { ClientPhase2Page } = lazyImport(
  () => import("pages/client/phase2"),
  "ClientPhase2Page",
)

const HOME = "home"
const SETTINGS = "settings"
const CONTACT = "contact"
const CONSULT = "consult"
const PASSWORD = "password"
const EMAIL = "email"
const VERIFY = "verify"
const PROFILE = "profile"
const RESULTS = "results"
const LP = "lp"
const COMPLETE = "complete"
const LP_ID = "lp_id"
const ANSWER = "answer"
const PHASE1 = "phase1"
const PHASE1_ID = "phase1_id"
const CONFIRM = "confirm"
const PHASE2 = "phase2"
const PHASE2_ID = "phase2_id"

export const CLIENT_ROUTE_PATH = {
  HOME: `/${HOME}`,
  SETTINGS_PROFILE: `/${SETTINGS}/${PROFILE}`,
  SETTINGS_PASSWORD: `/${SETTINGS}/${PASSWORD}`,
  SETTINGS_EMAIL: `/${SETTINGS}/${EMAIL}`,
  SETTINGS_EMAIL_VERIFY: `/${SETTINGS}/${EMAIL}/${VERIFY}`,
  CONTACT: `/${CONTACT}`,
  CONSULT: `/${CONSULT}`,
  LP: `/${LP}`,
  LP_COMPLETE: `/${LP}/${COMPLETE}`,
  RESULTS: `/${RESULTS}`,
  RESULT_LP: `/${RESULTS}/${LP}/:${LP_ID}`,
  RESULT_LP_ANSWER: `/${RESULTS}/${LP}/:${LP_ID}/${ANSWER}`,
  RESULT_PHASE1: `/${RESULTS}/${PHASE1}/:${PHASE1_ID}`,
  PHASE1: `/${PHASE1}`,
  PHASE1_CONFIRM: `/${PHASE1}/${CONFIRM}`,
  RESULTS_PHASE1_ANSWER: `/${RESULTS}/${PHASE1}/:${PHASE1_ID}/${ANSWER}`,
  PHASE2_CONFIRM: `/${PHASE2}/${CONFIRM}`,
  RESULT_PHASE2: `/${RESULTS}/${PHASE2}/:${PHASE2_ID}`,
  PHASE2: `/${PHASE2}`,
} as const

export function getResultLPAnswerPath(lpId: number) {
  return generatePath(CLIENT_ROUTE_PATH.RESULT_LP_ANSWER, {
    [LP_ID]: String(lpId),
  })
}

export function getResultLpPath(lpId: number) {
  return generatePath(CLIENT_ROUTE_PATH.RESULT_LP, { [LP_ID]: String(lpId) })
}

export function getResultPhase1Path(phase1Id: number) {
  return generatePath(CLIENT_ROUTE_PATH.RESULT_PHASE1, {
    [PHASE1_ID]: String(phase1Id),
  })
}

export function getResultPhase1AnswerPath(phase1Id: number) {
  return generatePath(CLIENT_ROUTE_PATH.RESULTS_PHASE1_ANSWER, {
    [PHASE1_ID]: String(phase1Id),
  })
}

export function getResultPhase2Path(phase2Id: number) {
  return generatePath(CLIENT_ROUTE_PATH.RESULT_PHASE2, {
    [PHASE2_ID]: String(phase2Id),
  })
}

export const clientRoutes: RouteObject[] = [
  {
    path: "/",
    element: <AppCommonLayout />,
    children: [
      {
        path: CLIENT_ROUTE_PATH.HOME,
        element: <ClientHomePage />,
      },
      {
        path: CLIENT_ROUTE_PATH.SETTINGS_PROFILE,
        element: <ClientSettingsProfilePage />,
      },
      {
        path: CLIENT_ROUTE_PATH.SETTINGS_PASSWORD,
        element: <ClientSettingsPasswordPage />,
      },
      {
        path: CLIENT_ROUTE_PATH.SETTINGS_EMAIL,
        element: <ClientSettingsEmailPage />,
      },
      {
        path: CLIENT_ROUTE_PATH.SETTINGS_EMAIL_VERIFY,
        element: <ClientSettingsEmailCompletedPage />,
      },
      {
        path: CLIENT_ROUTE_PATH.CONTACT,
        element: <ClientContactPage />,
      },
      {
        path: CLIENT_ROUTE_PATH.CONSULT,
        element: <ClientConsultPage />,
      },
      {
        path: CLIENT_ROUTE_PATH.RESULTS,
        element: <ClientResultsPage />,
      },
      {
        path: CLIENT_ROUTE_PATH.RESULT_LP,
        element: <ClientLPResultPage />,
      },
      {
        path: CLIENT_ROUTE_PATH.RESULT_LP_ANSWER,
        element: <ClientLPAnswerPage />,
      },
      {
        path: CLIENT_ROUTE_PATH.RESULT_PHASE1,
        element: <ClientPhase1ResultPage />,
      },
      {
        path: CLIENT_ROUTE_PATH.RESULTS_PHASE1_ANSWER,
        element: <ClientPhase1AnswerPage />,
      },
      {
        path: CLIENT_ROUTE_PATH.RESULT_PHASE2,
        element: <ClientPhase2AnswerPage />,
      },
    ],
  },
  {
    path: "/",
    element: <LPLayout />,
    children: [
      {
        path: CLIENT_ROUTE_PATH.LP,
        element: <ClientLPPage />,
      },
      {
        path: CLIENT_ROUTE_PATH.LP_COMPLETE,
        element: <ClientLPCompletedPage />,
      },
    ],
  },
  {
    path: "/",
    element: <Phase1Layout />,
    children: [
      {
        path: CLIENT_ROUTE_PATH.PHASE1,
        element: <ClientPhase1Page />,
      },
      {
        path: CLIENT_ROUTE_PATH.PHASE1_CONFIRM,
        element: <ClientPhase1ResultConfirmPage />,
      },
    ],
  },
  {
    path: "/",
    element: <Phase2Layout />,
    children: [
      {
        path: CLIENT_ROUTE_PATH.PHASE2,
        element: <ClientPhase2Page />,
      },
      {
        path: CLIENT_ROUTE_PATH.PHASE2_CONFIRM,
        element: <ClientPhase2AnswerConfirmPage />,
      },
    ],
  },
]
