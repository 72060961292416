import { Phase2AnswerF } from "api"
import { Phase2FFormData } from "pages/components/phase2/components/F/schema"
import { DeepPartial } from "utils"
import { selectAndDateDraftTranslator, dateDraftTranslator } from "./utils"

/**
 * 下書きを受けとってformDataの形に変換する
 */
export const phase2DraftFTranslator = (
  answer: Phase2AnswerF,
): DeepPartial<Phase2FFormData> => ({
  // q0: 表示のみ
  q1: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_01),
  q2: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_02),
  q3: dateDraftTranslator(answer.question_group_1?.answers?.answer_03),
  q4: dateDraftTranslator(answer.question_group_1?.answers?.answer_04),
  q5: dateDraftTranslator(answer.question_group_1?.answers?.answer_05),
  q6: dateDraftTranslator(answer.question_group_1?.answers?.answer_06),
  q7: dateDraftTranslator(answer.question_group_1?.answers?.answer_07),
})
