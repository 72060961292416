import { AxiosError } from "axios"
import { useMutation, UseMutationOptions, useQuery } from "react-query"
import { ExpertCreateRequest, ExpertDeleteRequest } from "api/experts/types"
import { OmitMutationConfig, OmitQueryConfig } from "lib/react-query"
import { ExpertsAPI } from "./api"

const PREFIX_ID = "experts"

export const fetchExpertsQuery = {
  queryKey: `${PREFIX_ID}/fetchExperts`,
  queryFn: ExpertsAPI.fetchExperts,
} as const

/**
 * 専門家一覧取得Hooks
 * @param config
 */
export function useExpertsQuery(
  config?: OmitQueryConfig<typeof fetchExpertsQuery>,
) {
  return useQuery({ ...fetchExpertsQuery, ...config })
}

export const createExpertMutation: UseMutationOptions<
  void,
  AxiosError,
  ExpertCreateRequest
> = {
  mutationKey: `${PREFIX_ID}/createExpert`,
  mutationFn: ExpertsAPI.createExpert,
}

/**
 * 専門家作成Hooks
 * @param config
 */
export function useCreateExpertMutation(
  config?: OmitMutationConfig<typeof createExpertMutation>,
) {
  return useMutation({ ...createExpertMutation, ...config })
}

export const deleteExpertMutation: UseMutationOptions<
  void,
  AxiosError,
  ExpertDeleteRequest
> = {
  mutationKey: `${PREFIX_ID}/deleteExpert`,
  mutationFn: ExpertsAPI.deleteExpert,
}

/**
 * 専門家削除Hooks
 * @param config
 */
export function useDeleteExpertMutation(
  config?: OmitMutationConfig<typeof deleteExpertMutation>,
) {
  return useMutation({ ...deleteExpertMutation, ...config })
}
