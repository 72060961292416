import {
  Phase1CreateRequest,
  Phase1CreateResponse,
  Phase1DraftGetResponse,
  Phase1DraftUpdateRequest,
} from "api/phase1/types"
import { axiosClient } from "lib/axios-client"

export const Phase1API = {
  /**
   * Phase1回答作成
   */
  async sendResult(payload: Phase1CreateRequest) {
    const { data } = await axiosClient.request<Phase1CreateResponse>({
      url: "/phase1",
      method: "POST",
      data: payload,
    })
    return data
  },

  /**
   * 下書き解答の取得
   */
  async fetchDraft() {
    const { data } = await axiosClient.request<Phase1DraftGetResponse>({
      url: "/phase1/draft",
      method: "GET",
    })
    return data
  },

  /**
   * 下書き解答の更新
   */
  async updateDraft(payload: Phase1DraftUpdateRequest) {
    await axiosClient.request({
      url: "/phase1/draft",
      method: "PATCH",
      data: payload,
    })
  },

  /**
   * 下書き削除
   */
  async deleteDraft() {
    await axiosClient.request({
      url: "/phase1/draft",
      method: "DELETE",
    })
  },
} as const
