import { ReactNode } from "react"
import styled from "styled-components"
import { Button } from "components/atoms/Button"
import { CloseButton } from "components/atoms/CloseButton"
import { Modal } from "components/atoms/Modal"
import { TextWrap } from "components/atoms/TextWrap"

const MODAL_PADDING_Y = 25
const MODAL_PADDING_X = 38
const TEXT_GAP = 25
const TEXT_BUTTON_GAP = 25
const TEXT_PADDING_BOTTOM = 4

interface Props {
  /**
   * 中央部のメインテキスト
   */
  children: ReactNode
  /**
   * タイトルのテキスト
   */
  titleText: string
  /**
   * closeボタンを押した際のアクション
   * useModalのcloseが渡される想定
   */
  onClickCloseButton: () => void
  /**
   * 「専門家に相談する」ボタンを押した際のアクション
   */
  onClickConsultButton: () => void
}

/**
 * questionに対して解答の役割を持つModal
 */
export const AnswerModal = ({
  children,
  titleText,
  onClickCloseButton,
  onClickConsultButton,
}: Props) => {
  return (
    <StyledModal>
      <TextColseButtonWrapper>
        <StyledTextWrap size="m" weight="bold">
          {titleText}
        </StyledTextWrap>
        <CloseButton onClick={onClickCloseButton} />
      </TextColseButtonWrapper>
      <TextWrap size="m">{children}</TextWrap>
      <StyledButton shape="square" color="green" onClick={onClickConsultButton}>
        専門家に相談する&gt;&gt;
      </StyledButton>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  padding: ${MODAL_PADDING_Y}px ${MODAL_PADDING_X}px;
`

const TextColseButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${TEXT_GAP}px;
`

const StyledTextWrap = styled(TextWrap)`
  padding-bottom: ${TEXT_PADDING_BOTTOM}px;
`

const StyledButton = styled(Button)`
  margin-top: ${TEXT_BUTTON_GAP}px;
`
