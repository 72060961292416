import { useTheme } from "styled-components"
import { SvgCreater } from "utils/SvgCreater"
import { IconProps } from "./interface"

export const OffFlagIcon = ({ size, ...rest }: IconProps) => {
  const theme = useTheme()
  return (
    <SvgCreater
      size={size}
      fill={theme.color.pinkSwan}
      viewboxSize={size}
      {...rest}
    >
      <path d="M18.62 7.11512C15.7101 -0.160965 7.64215 0.516709 7.64215 0.516709L0.0238779 26.5027C-0.0670564 26.8135 0.10976 27.1447 0.417927 27.2364L1.21613 27.4759C1.52429 27.5676 1.85267 27.3893 1.9436 27.0785L5.06568 16.4242C5.13136 16.1949 5.32333 16.0319 5.55572 16.0013C7.20264 15.7975 9.98624 16.0421 14.5936 17.749C21.8633 20.4444 26.2483 15.9962 27.8801 13.8103C28.1782 13.4078 27.8852 12.8371 27.3901 12.8626C25.238 12.9798 20.7822 12.5161 18.62 7.11512Z" />
    </SvgCreater>
  )
}
