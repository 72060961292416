import { AxiosError } from "axios"
import { useMutation, UseMutationOptions, useQuery } from "react-query"
import { MyConsultCreateRequest } from "api/consult/types"
import { OmitMutationConfig, OmitQueryConfig } from "lib/react-query"
import { ConsultAPI } from "./api"

const PREFIX_ID = "consult"

export const fetchMyConsultQuery = {
  queryKey: `${PREFIX_ID}/fetchMyConsult`,
  queryFn: ConsultAPI.fetchMyConsult,
} as const

export const createMyConsultMutation: UseMutationOptions<
  void,
  AxiosError,
  MyConsultCreateRequest
> = {
  mutationKey: `${PREFIX_ID}/createMyConsult`,
  mutationFn: ConsultAPI.createMyConsult,
}

/**
 * ログインユーザの専門家相談を作成するHooks
 * @param config
 */
export function useCreateMyConsultMutation(
  config?: OmitMutationConfig<typeof createMyConsultMutation>,
) {
  return useMutation({ ...createMyConsultMutation, ...config })
}

export const fetchConsultQuery = (userId: string) =>
  ({
    queryKey: `${PREFIX_ID}/consult/${userId}`,
    queryFn: () => ConsultAPI.fetchConsult(userId),
  } as const)

/**
 * 特定のユーザの専門家相談一覧を取得する
 * @param userId
 * @param config
 */
export function useConsultQuery(
  userId: string,
  config?: OmitQueryConfig<ReturnType<typeof fetchConsultQuery>>,
) {
  return useQuery({ ...fetchConsultQuery(userId), ...config })
}
