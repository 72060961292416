import { useTheme } from "styled-components"
import { SvgCreater } from "utils/SvgCreater"
import { IconProps } from "./interface"

export const NavigateLeftIcon = ({ size = 24, ...rest }: IconProps) => {
  const theme = useTheme()
  return (
    <SvgCreater size={size} fill={theme.color.nobel} {...rest}>
      <path d="M15.705 7.41L14.295 6L8.29504 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" />
    </SvgCreater>
  )
}
