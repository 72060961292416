import { Phase2AnswerH } from "api"
import { Phase2HFormData } from "pages/components/phase2/components/H/schema"
import { DeepPartial } from "utils"
import { selectAndDateDraftTranslator } from "./utils"

/**
 * 下書きを受けとってformDataの形に変換する
 */
export const phase2DraftHTranslator = (
  answer: Phase2AnswerH,
): DeepPartial<Phase2HFormData> => ({
  q1: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_01),
  q2: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_02),
  q3: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_03),
  q4: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_04),
  q5: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_05),
  q6: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_06),
  q7: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_07),
  q8: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_08),
  q9: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_09),
  q10: selectAndDateDraftTranslator(
    answer.question_group_1?.answers?.answer_10,
  ),
})
