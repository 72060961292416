import { AxiosError } from "axios"
import { useMutation, UseMutationOptions } from "react-query"
import { OmitMutationConfig } from "lib/react-query"
import { generateParams } from "utils"
import { CompanyAPI } from "./api"
import {
  CompanyCreateRequest,
  CompanyDeleteRequest,
  CompanyEditRequest,
  CompanyEditResponse,
  FetchCompanyUsersQueryArgs,
} from "./types"

const PREFIX_ID = "company"

export const fetchCompaniesQuery = {
  queryKey: `${PREFIX_ID}/fetchCompanies`,
  queryFn: CompanyAPI.fetchCompanies,
} as const

const createCompanyMutation: UseMutationOptions<
  void,
  AxiosError,
  CompanyCreateRequest
> = {
  mutationKey: `${PREFIX_ID}/createCompany`,
  mutationFn: CompanyAPI.createCompany,
}

export const useCreateCompanyMutation = (
  config?: OmitMutationConfig<typeof createCompanyMutation>,
) => {
  return useMutation({ ...createCompanyMutation, ...config })
}

export const fetchCompanyQuery = (companyId: string) =>
  ({
    queryKey: `${PREFIX_ID}/fetchCompany/${companyId}`,
    queryFn: () => CompanyAPI.fetchCompany(companyId),
  } as const)

const editCompanyMutation: UseMutationOptions<
  CompanyEditResponse,
  AxiosError,
  CompanyEditRequest
> = {
  mutationKey: `${PREFIX_ID}/editCompany`,
  mutationFn: CompanyAPI.editCompany,
} as const

export const useEditCompanyMutation = (
  config?: OmitMutationConfig<typeof editCompanyMutation>,
) => {
  return useMutation({ ...editCompanyMutation, ...config })
}

const deleteCompanyMutation: UseMutationOptions<
  void,
  AxiosError,
  CompanyDeleteRequest
> = {
  mutationKey: `${PREFIX_ID}/deleteCompany`,
  mutationFn: CompanyAPI.deleteCompany,
} as const

export const useDeleteCompanyMutation = (
  config?: OmitMutationConfig<typeof deleteCompanyMutation>,
) => {
  return useMutation({ ...deleteCompanyMutation, ...config })
}

export const fetchCompanyUsersQuery = ({
  company_id,
  params,
}: FetchCompanyUsersQueryArgs) => {
  const parsedParams = params && generateParams(params)
  return {
    queryKey: `${PREFIX_ID}/fetchCompanyUsers/${company_id}/${parsedParams}`,
    queryFn: () =>
      CompanyAPI.fetchCompanyUsers({ company_id, params: parsedParams }),
  } as const
}
