import { Phase2AnswerH } from "api"
import { selectAndDate, QAPresentation } from "./utils"

export const phase2ConvertAnswersH: QAPresentation<Phase2AnswerH> = (
  answers: Phase2AnswerH,
) => {
  return [
    {
      QA: [
        // answer_01?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_01?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_01),
        },
        // answer_02?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_02?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_02),
        },
        // answer_03?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_03?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_03),
        },
        // answer_04?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_04?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_04),
        },
        // answer_05?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_05?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_05),
        },
        // answer_06?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_06?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_06),
        },
        // answer_07?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_07?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_07),
        },
        // answer_08?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_08?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_08),
        },
        // answer_09?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_09?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_09),
        },
        // answer_10?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_10?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_10),
        },
      ].filter((item) => item.question && item.answer),
    },
  ].filter((item) => Boolean(item.QA.length))
}
