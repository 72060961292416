import { localStorageFactory } from "./factory"

const USER_PERMISSION_KEY = "sc.user-permission"

type UserPermission = "client" | "expert" | "admin"

/**
 * ユーザーの権限情報を管理するLocalStorage
 * MEMO: 本当はAPIから取得するのが理想
 */
export const userPermissionStorage = localStorageFactory<UserPermission>({
  key: USER_PERMISSION_KEY,
  serializer: (value) => value,
  parser: (value) => value as UserPermission,
})
