import { DateTypeAnswer, Label, QA } from "api"
import { DateFormData } from "pages/utils/types/phase2FormData"
import { DeepPartial } from "utils"

/**
 * 日付入力の設問
 */
export const dateDraftTranslator = (
  answer: DeepPartial<QA<DateTypeAnswer & Label>> | undefined,
): DateFormData | undefined => answer?.answer?.content
