import { BusinessPlan } from "api/types/phase2/utils"
import { BusinessPlanFormData } from "pages/utils/types/phase2FormData"
import { dateToFiscalYear, now } from "utils"

interface Args {
  json: {
    question: string
    label: string
    textbox: {
      label: {
        sales: string
        ordinary_profit: string
      }
      unit: string
    }
  }
  formData?: BusinessPlanFormData
}

/**
 * 事業計画（A Q26, B Q26）
 */
export const businessPlanTranslator = ({
  json,
  formData,
}: Args): BusinessPlan | undefined => {
  if (formData === undefined) return

  return {
    question: json.question,
    answer: {
      type: "array",
      content: formData.map(({ sales, profit } = {}, index) => {
        return {
          label: json.label.replace(
            "${year}",
            String(dateToFiscalYear(now()) + index),
          ),
          sale:
            sales !== undefined
              ? {
                  type: "number",
                  content: sales,
                  label: json.textbox.label.sales,
                  unit: json.textbox.unit,
                }
              : undefined,
          ordinary_profit:
            profit !== undefined
              ? {
                  type: "number",
                  content: profit,
                  label: json.textbox.label.ordinary_profit,
                  unit: json.textbox.unit,
                }
              : undefined,
        }
      }),
    },
  }
}
