import { ActionPlan } from "api/types/phase2/utils"
import { ActionPlanFormData } from "pages/utils/types/phase2FormData"

interface Args {
  json: {
    question: string
    textbox: {
      label: {
        plan: string
        charge: string
        start_date: string
        completed_date: string
      }
    }
  }
  formData: ActionPlanFormData
}

/**
 * 行動計画 あD Q22-24, あG Q22-24Q29-31
 */
export const actionPlanTranslator = ({
  json,
  formData,
}: Args): ActionPlan | undefined => {
  if (formData === undefined) return undefined

  return {
    question: json.question,
    answer: {
      type: "array",
      content: formData.map(
        ({ plan, charge, startDate, completedDate } = {}) => ({
          plan: plan
            ? {
                type: "string",
                content: plan,
                label: json.textbox.label.plan,
              }
            : undefined,
          charge: charge
            ? {
                type: "string",
                content: charge,
                label: json.textbox.label.charge,
              }
            : undefined,
          start_date: startDate
            ? {
                type: "string",
                content: startDate,
                label: json.textbox.label.start_date,
              }
            : undefined,
          completed_date: completedDate
            ? {
                type: "string",
                content: completedDate,
                label: json.textbox.label.completed_date,
              }
            : undefined,
        }),
      ),
    },
  } as const
}
