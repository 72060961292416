import { Phase2AnswerA } from "api"
import { Phase2AFormData } from "pages/components/phase2/components/A/schema"
import { DeepPartial } from "utils"
import {
  nameAndBirthdayDraftTranslator,
  selectAndDateDraftTranslator,
  transferringStockDraftTranslator,
  selectDraftTranslator,
  businessPlanDraftTranslator,
  dateDraftTranslator,
  stringDraftTranslator,
  bankBorrowingAmountAndNetWorthDraftTranslator,
} from "./utils"

/**
 * 下書きを受けとってformDataの形に変換する
 */
export const phase2DraftATranslator = (
  answer: Phase2AnswerA,
): DeepPartial<Phase2AFormData> => ({
  q0: nameAndBirthdayDraftTranslator(
    answer.question_group_1?.answers?.answer_00,
  ),
  q1: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_01),
  q2: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_02),
  q3: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_03),
  q4: selectAndDateDraftTranslator(answer.question_group_1?.answers?.answer_04),
  q5: selectAndDateDraftTranslator(answer.question_group_2?.answers?.answer_05),
  q6: selectDraftTranslator(answer.question_group_2?.answers?.answer_06),
  q7: selectDraftTranslator(answer.question_group_2?.answers?.answer_07),
  q8: selectAndDateDraftTranslator(answer.question_group_3?.answers?.answer_08),
  q9: selectAndDateDraftTranslator(answer.question_group_3?.answers?.answer_09),
  q10: selectAndDateDraftTranslator(
    answer.question_group_3?.answers?.answer_10,
  ),
  q11: selectAndDateDraftTranslator(
    answer.question_group_3?.answers?.answer_11,
  ),
  q12: selectDraftTranslator(answer.question_group_3?.answers?.answer_12),
  q13: selectAndDateDraftTranslator(
    answer.question_group_3?.answers?.answer_13,
  ),
  q14: selectAndDateDraftTranslator(
    answer.question_group_3?.answers?.answer_14,
  ),
  q15: selectDraftTranslator(answer.question_group_3?.answers?.answer_15),
  q16: selectAndDateDraftTranslator(
    answer.question_group_3?.answers?.answer_16,
  ),
  q17: selectDraftTranslator(answer.question_group_3?.answers?.answer_17),
  q18: selectAndDateDraftTranslator(
    answer.question_group_3?.answers?.answer_18,
  ),
  q19: selectAndDateDraftTranslator(
    answer.question_group_3?.answers?.answer_19,
  ),
  q20: selectAndDateDraftTranslator(
    answer.question_group_3?.answers?.answer_20,
  ),
  q21: selectAndDateDraftTranslator(
    answer.question_group_3?.answers?.answer_21,
  ),
  q22: selectDraftTranslator(answer.question_group_4?.answers?.answer_22),
  q23: transferringStockDraftTranslator(
    answer.question_group_4?.answers?.answer_23,
  ),
  q24: selectAndDateDraftTranslator(
    answer.question_group_4?.answers?.answer_24,
  ),
  q25: selectAndDateDraftTranslator(
    answer.question_group_4?.answers?.answer_25,
  ),
  q26: businessPlanDraftTranslator(answer.question_group_4?.answers?.answer_26),
  q27: dateDraftTranslator(answer.question_group_4?.answers?.answer_27),
  q28: selectAndDateDraftTranslator(
    answer.question_group_4?.answers?.answer_28,
  ),
  q29: selectDraftTranslator(answer.question_group_4?.answers?.answer_29),
  q30: stringDraftTranslator(answer.question_group_4?.answers?.answer_30),
  q31: stringDraftTranslator(answer.question_group_4?.answers?.answer_31),
  q32: bankBorrowingAmountAndNetWorthDraftTranslator(
    answer.question_group_4?.answers?.answer_32,
  ),
})
