import { ImgHTMLAttributes } from "react"
import styled from "styled-components"
import PcLogo from "assets/images/shokei_chan_logo_desktop.png"
import MobileLogo from "assets/images/shokei_chan_logo_mobile.png"
import NoIllustLogo from "assets/images/shokei_chan_logo_no_character.svg"
import { unreachable } from "utils"

type Variant = "mobile" | "desktop" | "noCharacter"

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  /**
   * Logoの種類
   */
  variant: Variant
}

/**
 * 承継ちゃんのLogo
 */
export const Logo = ({ variant, ...rest }: Props) => {
  return variant === "mobile" ? (
    <Image {...rest} src={MobileLogo} />
  ) : variant === "desktop" ? (
    <Image {...rest} src={PcLogo} />
  ) : variant === "noCharacter" ? (
    <Image {...rest} src={NoIllustLogo} />
  ) : (
    unreachable()
  )
}

const Image = styled.img`
  pointer-events: none;
  user-select: none;
`
