import { useCallback } from "react"
import constate from "constate"
import { SingleButtonModal } from "components/molecules/Modals"
import { YesNoModal } from "components/organisms/Modals"
import { useClientDownloadPlan } from "hooks/useDownloadPlan"
import { useModal, ModalWrap } from "hooks/useModal"

export const [
  ClientDownloadModalProvider,
  useClientDownloadPlanModals,
  useClientDownloadModal,
] = constate(
  () => {
    const { downloadPlan } = useClientDownloadPlan()

    const {
      ModalPortal: DownloadConfirmModalPortal,
      open: openDownloadConfirmModal,
      close: closeDownloadConfirmModal,
    } = useModal({
      closeOnOverlayClick: true,
      preventScroll: true,
    })
    const {
      ModalPortal: DownloadCompletedModalPortal,
      open: openDownloadCompletedModal,
      close: closeDownloadCompletedModal,
    } = useModal({
      closeOnOverlayClick: true,
      preventScroll: true,
    })

    // DownloadConfirmModalではいを押したときの処理
    const handleClickDownload = useCallback(
      async (phase2Id?: number, createdAt?: Date) => {
        await downloadPlan(phase2Id, createdAt)
        openDownloadCompletedModal()
        closeDownloadConfirmModal()
      },
      [closeDownloadConfirmModal, downloadPlan, openDownloadCompletedModal],
    )

    interface Props {
      phase2Id?: number
      createdAt?: Date
    }

    const ClientDownloadConfirmModal = ({ phase2Id, createdAt }: Props) => {
      return (
        <DownloadConfirmModalPortal>
          <ModalWrap>
            <YesNoModal
              onAgree={() => handleClickDownload(phase2Id, createdAt)}
              onCancel={closeDownloadConfirmModal}
              buttonDirection="col"
            >
              計画表（Excel）を
              <br />
              ダウンロードしますか?
            </YesNoModal>
          </ModalWrap>
        </DownloadConfirmModalPortal>
      )
    }

    const ClientDownloadCompletedModal = () => {
      return (
        <DownloadCompletedModalPortal>
          <ModalWrap>
            <SingleButtonModal
              buttonText="戻る"
              onClickButton={closeDownloadCompletedModal}
            >
              ダウンロードが完了しました。
            </SingleButtonModal>
          </ModalWrap>
        </DownloadCompletedModalPortal>
      )
    }

    return {
      openDownloadConfirmModal,
      ClientDownloadConfirmModal,
      ClientDownloadCompletedModal,
    }
  },
  ({ ClientDownloadConfirmModal, ClientDownloadCompletedModal }) => ({
    ClientDownloadConfirmModal,
    ClientDownloadCompletedModal,
  }),
  ({ openDownloadConfirmModal }) => ({ openDownloadConfirmModal }),
)
