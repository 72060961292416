import axios, { AxiosError } from "axios"
import { API_URL } from "config"

/**
 * アプリ全体で使用するHTTP Client
 */
export const axiosClient = axios.create({
  baseURL: API_URL,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAxiosError(err: any): err is AxiosError {
  return err.isAxiosError === true
}
