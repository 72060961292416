import {
  ExpertsGetResponse,
  ExpertCreateRequest,
  ExpertId,
} from "api/experts/types"
import { axiosClient } from "lib/axios-client"

export const ExpertsAPI = {
  /**
   * サポートユーザ・専門家一覧を取得する
   */
  fetchExperts: async () => {
    const { data } = await axiosClient.request<ExpertsGetResponse>({
      url: "/experts",
      method: "GET",
    })
    return data
  },

  /**
   * 専門家登録
   * @param payload
   */
  createExpert: async (payload: ExpertCreateRequest) => {
    await axiosClient.request({
      url: "/experts",
      method: "POST",
      data: payload,
    })
  },

  /**
   * 専門家削除
   * @param expertId
   */
  deleteExpert: async (expertId: ExpertId) => {
    await axiosClient.request({
      url: `experts/${expertId}`,
      method: "DELETE",
    })
  },
} as const
