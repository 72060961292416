import { Phase2AnswerE } from "api"
import { AnswerWrap } from "pages/components/Phase2Answer"
import { dateFormatter } from "utils"
import { selectAndDate, QAPresentation } from "./utils"

export const phase2ConvertAnswersE: QAPresentation<Phase2AnswerE> = (
  answers: Phase2AnswerE,
) => {
  return [
    {
      QA: [
        // answer_00?: { question?: string } 表示なし
        // answer_01?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_01?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_01),
        },
        // answer_02?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_02?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_02),
        },
        // answer_03?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_03?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_03),
        },
        // answer_04?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_1?.answers?.answer_04?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_1?.answers?.answer_04?.answer?.label}:
              {dateFormatter(
                answers.question_group_1?.answers?.answer_04?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_05?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_1?.answers?.answer_05?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_1?.answers?.answer_05?.answer?.label}:
              {dateFormatter(
                answers.question_group_1?.answers?.answer_05?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_06?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_1?.answers?.answer_06?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_1?.answers?.answer_06?.answer?.label}:
              {dateFormatter(
                answers.question_group_1?.answers?.answer_06?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_07?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_1?.answers?.answer_07?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_1?.answers?.answer_07?.answer?.label}:
              {dateFormatter(
                answers.question_group_1?.answers?.answer_07?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_08?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_1?.answers?.answer_08?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_1?.answers?.answer_08?.answer?.label}:
              {dateFormatter(
                answers.question_group_1?.answers?.answer_08?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_09?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_1?.answers?.answer_09?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_1?.answers?.answer_09?.answer?.label}:
              {dateFormatter(
                answers.question_group_1?.answers?.answer_09?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_10?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_1?.answers?.answer_10?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_1?.answers?.answer_10?.answer?.label}:
              {dateFormatter(
                answers.question_group_1?.answers?.answer_10?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_11?: SelectAndDate
        {
          question: answers.question_group_1?.answers?.answer_11?.question,
          answer: selectAndDate(answers.question_group_1?.answers?.answer_11),
        },
        // answer_12?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_1?.answers?.answer_12?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_1?.answers?.answer_12?.answer?.label}:
              {dateFormatter(
                answers.question_group_1?.answers?.answer_12?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_13?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_1?.answers?.answer_13?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_1?.answers?.answer_13?.answer?.label}:
              {dateFormatter(
                answers.question_group_1?.answers?.answer_13?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_2?.title,
      QA: [
        // answer_14?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_2?.answers?.answer_14?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_2?.answers?.answer_14?.answer?.label}:
              {dateFormatter(
                answers.question_group_2?.answers?.answer_14?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_15?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_2?.answers?.answer_15?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_2?.answers?.answer_15?.answer?.label}:
              {dateFormatter(
                answers.question_group_2?.answers?.answer_15?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_16?: DeepPartial<QA<StringTypeAnswer>>
        {
          question: answers.question_group_2?.answers?.answer_16?.question,
          answer: answers.question_group_2?.answers?.answer_16?.answer?.content,
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_3?.title,
      QA: [
        // answer_17?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_3?.answers?.answer_17?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_3?.answers?.answer_17?.answer?.label}:
              {dateFormatter(
                answers.question_group_3?.answers?.answer_17?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_18?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_3?.answers?.answer_18?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_3?.answers?.answer_18?.answer?.label}:
              {dateFormatter(
                answers.question_group_3?.answers?.answer_18?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_19?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_3?.answers?.answer_19?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_3?.answers?.answer_19?.answer?.label}:
              {dateFormatter(
                answers.question_group_3?.answers?.answer_19?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_20?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_3?.answers?.answer_20?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_3?.answers?.answer_20?.answer?.label}:
              {dateFormatter(
                answers.question_group_3?.answers?.answer_20?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_21?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_3?.answers?.answer_21?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_3?.answers?.answer_21?.answer?.label}:
              {dateFormatter(
                answers.question_group_3?.answers?.answer_21?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_22?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_3?.answers?.answer_22?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_3?.answers?.answer_22?.answer?.label}:
              {dateFormatter(
                answers.question_group_3?.answers?.answer_22?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_23?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_3?.answers?.answer_23?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_3?.answers?.answer_23?.answer?.label}:
              {dateFormatter(
                answers.question_group_3?.answers?.answer_23?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        //answer_24?: SelectAndDate
        {
          question: answers.question_group_3?.answers?.answer_24?.question,
          answer: selectAndDate(answers.question_group_3?.answers?.answer_24),
        },
        //answer_25?: SelectAndDate
        {
          question: answers.question_group_3?.answers?.answer_25?.question,
          answer: selectAndDate(answers.question_group_3?.answers?.answer_25),
        },
      ].filter((item) => item.question && item.answer),
    },
    {
      title: answers.question_group_4?.title,
      QA: [
        // answer_26?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_26?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_26?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_26?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_27?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_27?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_27?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_27?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_28?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_28?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_28?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_28?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_29?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_29?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_29?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_29?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_30?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_30?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_30?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_30?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_31?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_31?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_31?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_31?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_32?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_32?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_32?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_32?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_33?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_33?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_33?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_33?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_34?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_34?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_34?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_34?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_35?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_35?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_35?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_35?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_36?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_36?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_36?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_36?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
        // answer_37?: DeepPartial<QA<DateTypeAnswer&Label>>
        {
          question: answers.question_group_4?.answers?.answer_37?.question,
          answer: (
            <AnswerWrap>
              {answers.question_group_4?.answers?.answer_37?.answer?.label}:
              {dateFormatter(
                answers.question_group_4?.answers?.answer_37?.answer?.content,
              )}
            </AnswerWrap>
          ),
        },
      ].filter((item) => item.question && item.answer),
    },
  ].filter((item) => Boolean(item.QA.length))
}
