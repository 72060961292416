import styled from "styled-components"

export const MOLECULES_LINE_HEIGHT = 1

interface Props {
  type?: "dark" | "light"
}

/**
 * dark, lightの二種類の色を持つLine
 */
export const Line = styled.div<Props>`
  height: ${MOLECULES_LINE_HEIGHT}px;
  background-color: ${(props) =>
    props.type === "dark" || !props.type
      ? props.theme.color.nightRider
      : props.theme.color.pinkSwan};
`
