import {
  Phase2AnswerA,
  Phase2AnswerAB,
  Phase2AnswerB,
  Phase2AnswerC,
  Phase2AnswerD,
  Phase2AnswerE,
  Phase2AnswerF,
  Phase2AnswerG,
  Phase2AnswerH,
  Phase2ResultPattern,
} from "api/types/phase2"
import { Phase2AFormData } from "pages/components/phase2/components/A/schema"
import { Phase2ABFormData } from "pages/components/phase2/components/AB/schema"
import { Phase2BFormData } from "pages/components/phase2/components/B/schema"
import { Phase2CFormData } from "pages/components/phase2/components/C/schema"
import { Phase2DFormData } from "pages/components/phase2/components/D/schema"
import { Phase2EFormData } from "pages/components/phase2/components/E/schema"
import { Phase2FFormData } from "pages/components/phase2/components/F/schema"
import { Phase2GFormData } from "pages/components/phase2/components/G/schema"
import { Phase2HFormData } from "pages/components/phase2/components/H/schema"
import { Phase2FormDataList, Phase2Type } from "pages/components/phase2/utils"
import {
  phase2AFormDataTranslator,
  phase2ABFormDataTranslator,
  phase2BFormDataTranslator,
  phase2CFormDataTranslator,
  phase2DFormDataTranslator,
  phase2EFormDataTranslator,
  phase2FFormDataTranslator,
  phase2GFormDataTranslator,
  phase2HFormDataTranslator,
} from "pages/utils/phase2FormDataTranslator"
import { DeepPartial } from "utils"
import {
  phase2DraftATranslator,
  phase2DraftBTranslator,
  phase2DraftABTranslator,
  phase2DraftCTranslator,
  phase2DraftDTranslator,
  phase2DraftETranslator,
  phase2DraftFTranslator,
  phase2DraftGTranslator,
  phase2DraftHTranslator,
} from "./phase2DraftTranslator"

interface Phase2SendFormDataTranslatorArgs {
  /**
   * フェーズ１診断結果による分類
   */
  diagnosis: Phase2Type
  /**
   * フォームの入力値
   */
  formData: DeepPartial<Phase2FormDataList[Phase2Type]>
}

/**
 * フェーズ2のページの入力値をBEに送るスキーマに変形する
 */
export const phase2SendFormDataTranslator = ({
  diagnosis,
  formData,
}: Phase2SendFormDataTranslatorArgs): Phase2ResultPattern | undefined => {
  if (diagnosis === "AA" || diagnosis === "IA") {
    return phase2AFormDataTranslator({
      diagnosis,
      formData: formData as Phase2AFormData,
    })
  }
  if (diagnosis === "AB" || diagnosis === "IB") {
    return phase2BFormDataTranslator({
      diagnosis,
      formData: formData as Phase2BFormData,
    })
  }
  if (
    diagnosis === "AC" ||
    diagnosis === "IC" ||
    diagnosis === "UC" ||
    diagnosis === "EC"
  ) {
    return phase2CFormDataTranslator({
      diagnosis,
      formData: formData as Phase2CFormData,
    })
  }
  if (
    diagnosis === "AD" ||
    diagnosis === "ID" ||
    diagnosis === "UD" ||
    diagnosis === "ED"
  ) {
    return phase2DFormDataTranslator({
      diagnosis,
      formData: formData as Phase2DFormData,
    })
  }
  if (
    diagnosis === "AE" ||
    diagnosis === "IE" ||
    diagnosis === "UE" ||
    diagnosis === "EE"
  ) {
    return phase2EFormDataTranslator({
      diagnosis,
      formData: formData as Phase2EFormData,
    })
  }
  if (
    diagnosis === "AF" ||
    diagnosis === "IF" ||
    diagnosis === "UF" ||
    diagnosis === "EF"
  ) {
    return phase2FFormDataTranslator({
      diagnosis,
      formData: formData as Phase2FFormData,
    })
  }
  if (
    diagnosis === "AG" ||
    diagnosis === "IG" ||
    diagnosis === "UG" ||
    diagnosis === "EG"
  ) {
    return phase2GFormDataTranslator({
      diagnosis,
      formData: formData as Phase2GFormData,
    })
  }
  if (
    diagnosis === "AH" ||
    diagnosis === "IH" ||
    diagnosis === "UH" ||
    diagnosis === "EH"
  ) {
    return phase2HFormDataTranslator({
      diagnosis,
      formData: formData as Phase2HFormData,
    })
  }
  if (
    diagnosis === "UA" ||
    diagnosis === "UB" ||
    diagnosis === "EA" ||
    diagnosis === "EB"
  ) {
    return phase2ABFormDataTranslator({
      diagnosis,
      formData: formData as Phase2ABFormData,
    })
  }
}

/**
 * フェーズ2のBEのスキーマをformDataの形に変形する
 */
export const phase2DraftTranslator = ({
  diagnosis,
  answer,
}: Phase2ResultPattern):
  | DeepPartial<Phase2FormDataList[Phase2Type]>
  | undefined => {
  if (diagnosis === "AA" || diagnosis === "IA") {
    return phase2DraftATranslator(answer as Phase2AnswerA)
  }
  if (diagnosis === "AB" || diagnosis === "IB") {
    return phase2DraftBTranslator(answer as Phase2AnswerB)
  }
  if (
    diagnosis === "AC" ||
    diagnosis === "IC" ||
    diagnosis === "UC" ||
    diagnosis === "EC"
  ) {
    return phase2DraftCTranslator(answer as Phase2AnswerC)
  }
  if (
    diagnosis === "AD" ||
    diagnosis === "ID" ||
    diagnosis === "UD" ||
    diagnosis === "ED"
  ) {
    return phase2DraftDTranslator(answer as Phase2AnswerD)
  }
  if (
    diagnosis === "AE" ||
    diagnosis === "IE" ||
    diagnosis === "UE" ||
    diagnosis === "EE"
  ) {
    return phase2DraftETranslator(answer as Phase2AnswerE)
  }
  if (
    diagnosis === "AF" ||
    diagnosis === "IF" ||
    diagnosis === "UF" ||
    diagnosis === "EF"
  ) {
    return phase2DraftFTranslator(answer as Phase2AnswerF)
  }
  if (
    diagnosis === "AG" ||
    diagnosis === "IG" ||
    diagnosis === "UG" ||
    diagnosis === "EG"
  ) {
    return phase2DraftGTranslator(answer as Phase2AnswerG)
  }
  if (
    diagnosis === "AH" ||
    diagnosis === "IH" ||
    diagnosis === "UH" ||
    diagnosis === "EH"
  ) {
    return phase2DraftHTranslator(answer as Phase2AnswerH)
  }
  if (
    diagnosis === "UA" ||
    diagnosis === "UB" ||
    diagnosis === "EA" ||
    diagnosis === "EB"
  ) {
    return phase2DraftABTranslator(answer as Phase2AnswerAB)
  }
}
