import { QA, StringTypeAnswer } from "api"
import { StringFormData } from "pages/utils/types/phase2FormData"
import { DeepPartial } from "utils"

/**
 * テキスト入力の設問
 */
export const stringDraftTranslator = (
  answer: DeepPartial<QA<StringTypeAnswer>> | undefined,
): StringFormData | undefined => answer?.answer?.content
