import { ChoiceAnswer, QA, StringTypeAnswer } from "api"
import { SelectFormData } from "pages/utils/types/phase2FormData"
import { DeepPartial } from "utils"

/**
 * 選択肢の設問
 */
export const selectDraftTranslator = (
  answer: DeepPartial<QA<StringTypeAnswer & ChoiceAnswer>> | undefined,
): SelectFormData | undefined => answer?.answer?.choice_number
