import { useTheme } from "styled-components"
import { SvgCreater } from "utils/SvgCreater"
import { IconProps } from "./interface"

export const NavigateNextIcon = ({ size = 24, ...rest }: IconProps) => {
  const theme = useTheme()
  return (
    <SvgCreater size={size} fill={theme.color.nobel} {...rest}>
      <path d="M18 9.70504L16.59 8.29504L12 12.875L7.41 8.29504L6 9.70504L12 15.705L18 9.70504Z" />
    </SvgCreater>
  )
}
