import { lazyImport } from "utils"
import { Phase2QuestionConfig, Phase2Type, PHASE2_TYPE } from "./types"

const { Phase2A } = lazyImport(
  () => import("pages/components/phase2/components/A"),
  "Phase2A",
)

const { Phase2B } = lazyImport(
  () => import("pages/components/phase2/components/B"),
  "Phase2B",
)

const { Phase2C } = lazyImport(
  () => import("pages/components/phase2/components/C"),
  "Phase2C",
)

const { Phase2G } = lazyImport(
  () => import("pages/components/phase2/components/G"),
  "Phase2G",
)

const { Phase2D } = lazyImport(
  () => import("pages/components/phase2/components/D"),
  "Phase2D",
)

const { Phase2E } = lazyImport(
  () => import("pages/components/phase2/components/E"),
  "Phase2E",
)
const { Phase2F } = lazyImport(
  () => import("pages/components/phase2/components/F"),
  "Phase2F",
)

const { Phase2H } = lazyImport(
  () => import("pages/components/phase2/components/H"),
  "Phase2H",
)

const { Phase2AB } = lazyImport(
  () => import("pages/components/phase2/components/AB"),
  "Phase2AB",
)

export const phase2QuestionConfigs: Record<Phase2Type, Phase2QuestionConfig> = {
  [PHASE2_TYPE.AA]: { element: Phase2A },
  [PHASE2_TYPE.AB]: { element: Phase2B },
  [PHASE2_TYPE.AC]: { element: Phase2C },
  [PHASE2_TYPE.AD]: { element: Phase2D },
  [PHASE2_TYPE.AE]: { element: Phase2E },
  [PHASE2_TYPE.AF]: { element: Phase2F },
  [PHASE2_TYPE.AG]: { element: Phase2G },
  [PHASE2_TYPE.AH]: { element: Phase2H },
  [PHASE2_TYPE.IA]: { element: Phase2A },
  [PHASE2_TYPE.IB]: { element: Phase2B },
  [PHASE2_TYPE.IC]: { element: Phase2C },
  [PHASE2_TYPE.ID]: { element: Phase2D },
  [PHASE2_TYPE.IE]: { element: Phase2E },
  [PHASE2_TYPE.IF]: { element: Phase2F },
  [PHASE2_TYPE.IG]: { element: Phase2G },
  [PHASE2_TYPE.IH]: { element: Phase2H },
  [PHASE2_TYPE.UA]: { element: Phase2AB },
  [PHASE2_TYPE.UB]: { element: Phase2AB },
  [PHASE2_TYPE.UC]: { element: Phase2C },
  [PHASE2_TYPE.UD]: { element: Phase2D },
  [PHASE2_TYPE.UE]: { element: Phase2E },
  [PHASE2_TYPE.UF]: { element: Phase2F },
  [PHASE2_TYPE.UG]: { element: Phase2G },
  [PHASE2_TYPE.UH]: { element: Phase2H },
  [PHASE2_TYPE.EA]: { element: Phase2AB },
  [PHASE2_TYPE.EB]: { element: Phase2AB },
  [PHASE2_TYPE.EC]: { element: Phase2C },
  [PHASE2_TYPE.ED]: { element: Phase2D },
  [PHASE2_TYPE.EE]: { element: Phase2E },
  [PHASE2_TYPE.EF]: { element: Phase2F },
  [PHASE2_TYPE.EG]: { element: Phase2G },
  [PHASE2_TYPE.EH]: { element: Phase2H },
}
