import { useEffect } from "react"
import { useInvId } from "context/invitationId"
import { Outlet } from "react-router-dom"
import { usePublicRoute } from "hooks/usePublicRoute"

interface Props {
  shouldVerify?: boolean
}

export const InvitationRequired = ({ shouldVerify = false }: Props) => {
  const { verify, status } = useInvId()
  const { goToInsufficientIdError, goToNotFound } = usePublicRoute()

  useEffect(() => {
    if (shouldVerify) {
      verify()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldVerify])

  useEffect(() => {
    if (status === "insufficient-error") {
      goToInsufficientIdError()
    }
    if (status === "invalid-error") {
      goToNotFound()
    }
  }, [status, goToInsufficientIdError, goToNotFound])

  /**
   * 検証出来ていない間は表示しない
   */
  if (status !== "success") {
    return null
  }

  return <Outlet />
}
