import { AxiosError } from "axios"
import { useMutation, UseMutationOptions } from "react-query"
import { UsersGetParams } from "api/types/users"
import { UsersAPI } from "api/users/api"
import { UserDeleteRequest } from "api/users/types"
import { OmitMutationConfig } from "lib/react-query"
import { generateParams } from "utils"

const PREFIX_ID = "users"

export const fetchUsersQuery = (params?: UsersGetParams) => {
  const parsedParams = params && generateParams(params)

  return {
    queryKey: `${PREFIX_ID}/fetchUsers/${parsedParams}`,
    queryFn: () => UsersAPI.fetchUsers(parsedParams),
  } as const
}

export const deleteUserMutation: UseMutationOptions<
  void,
  AxiosError,
  UserDeleteRequest
> = {
  mutationKey: `${PREFIX_ID}/delete`,
  mutationFn: UsersAPI.deleteUser,
}

/**
 * ユーザを削除するHooks
 * @param config
 */
export function useDeleteUserMutation(
  config?: OmitMutationConfig<typeof deleteUserMutation>,
) {
  return useMutation({ ...deleteUserMutation, ...config })
}

/**
 * 月額会員一覧を取得する
 */
export const fetchPaymentUsersQuery = (params?: UsersGetParams) => {
  const parsedParams = params && generateParams(params)

  return {
    queryKey: `${PREFIX_ID}/fetchPaymentUsers`,
    queryFn: () => UsersAPI.fetchPaymentUsers(parsedParams),
  } as const
}

export const deletePaymentUserMutation: UseMutationOptions<
  void,
  AxiosError,
  UserDeleteRequest
> = {
  mutationKey: `${PREFIX_ID}/delete`,
  mutationFn: UsersAPI.deleteUser,
}

/**
 * 月額会員を削除する
 */
export function useDeletePaymentUserMutation(
  config?: OmitMutationConfig<typeof deletePaymentUserMutation>,
) {
  return useMutation({ ...deletePaymentUserMutation, ...config })
}
