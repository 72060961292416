import { ArrayString } from "api/types/phase2/utils"
import { PartiallyRequiredStringArrayFormData } from "pages/utils/types/phase2FormData"

interface Args {
  json: {
    question: string
    textbox: {
      label: {
        required: string[]
        optional: string[]
      }
    }
  }
  formData: PartiallyRequiredStringArrayFormData
}

/**
 * 数個入力必須で他は任意のテキスト入力の配列の設問 D Q13 Q14 Q15
 */
export const partiallyRequiredStringArrayTranslator = ({
  json,
  formData,
}: Args): ArrayString | undefined => {
  if (formData === undefined) return undefined
  if (formData.optional === undefined || formData.required === undefined)
    return undefined

  const mergedLabels = [
    ...json.textbox.label.required,
    ...json.textbox.label.optional,
  ]

  const mergedFormData = [...formData.required, ...formData.optional]

  return {
    question: json.question,
    answer: {
      type: "array",
      content: mergedLabels.map((label, index) => ({
        type: "string",
        content: mergedFormData[index],
        label: label,
      })),
    },
  } as const
}
