import { useCallback, useMemo, useState } from "react"
import constate from "constate"
import { addMonths, format } from "date-fns"
import { dateToFiscalYear, DeepPartial, now, range, unreachable } from "utils"
import { Phase2FormDataList, Phase2Type } from "../types"

export const [
  Phase2FormDataProvider,
  usePhase2FormData,
  usePhase2FormDataSetter,
  usePhase2Diagnosis,
  usePhase2DiagnosisSetter,
  usePhase2ReconstructionYears,
  usePhase2ReconstructionYearsSetter,
  usePhase2LpDeficit,
  usePhase2LpDeficitSetter,
] = constate(
  () => {
    const [formData, setFormData] = useState<
      DeepPartial<Phase2FormDataList[Phase2Type]>
    >({})

    /**
     * フェーズ1の診断結果（あA等）
     */
    const [diagnosis, setDiagnosis] = useState<Phase2Type>()

    /**
     * フェーズ1#8に基づく再建計画の日数
     */
    const [reconstructionYears, setReconstructionYears] = useState<number>()

    /**
     * lpの診断結果における100歳時点での不足額
     */
    const [lpDeficit, setLpDeficit] = useState<number>()

    /**
     * 現在（実行日の初期値）
     */
    const NOW = useMemo(() => format(now(), "yyyy-MM-dd"), [])

    /**
     * 三か月後（実行予定日の初期値）
     */
    const THREE_MONTHS_LATER = useMemo(
      () => format(addMonths(now(), 3), "yyyy-MM-dd"),
      [],
    )

    /**
     * 後継者の生年月日初期値
     */
    const SUCCESSOR_DEFAULT_BIRTHDATE = "1985-01-01"

    /**
     * 承継期間を受け取って、今年度から承継期間の終わりまでの各年度の配列を返す
     * @param durationOfSuccession 承継期間 ex) 3
     * @return 現在から承継期間の終わりまでの各年度の配列 ex) [2022,2023,2024,2025]
     */
    const getSuccessionPlanYears = useCallback(
      (durationOfSuccession: number): number[] => {
        const period = durationOfSuccession + 1
        return range(period).map((_, index) => dateToFiscalYear(now()) + index)
      },
      [],
    )

    /**
     * 承継期間を受け取って、後継者の着任日の初期値のISO文字列を返す（Aq27等）
     * @param durationOfSuccession 承継期間 ex) 3
     * @return 着任日
     */
    const getDefaultArrivalDate = useCallback(
      (durationOfSuccession: number): string => {
        if (durationOfSuccession === 3) {
          return format(addMonths(now(), 12), "yyyy-MM-dd")
        }
        if (durationOfSuccession === 4) {
          return format(addMonths(now(), 24), "yyyy-MM-dd")
        }
        if (durationOfSuccession === 5) {
          return format(addMonths(now(), 30), "yyyy-MM-dd")
        }
        unreachable("getDefaultArrivalDateの引数が不正です")
        return ""
      },
      [],
    )

    return {
      formData,
      setFormData,
      diagnosis,
      setDiagnosis,
      reconstructionYears,
      setReconstructionYears,
      NOW,
      THREE_MONTHS_LATER,
      SUCCESSOR_DEFAULT_BIRTHDATE,
      getSuccessionPlanYears,
      getDefaultArrivalDate,
      lpDeficit,
      setLpDeficit,
    }
  },
  ({
    formData,
    diagnosis,
    setDiagnosis,
    NOW,
    THREE_MONTHS_LATER,
    SUCCESSOR_DEFAULT_BIRTHDATE,
    getSuccessionPlanYears,
    getDefaultArrivalDate,
  }) => ({
    formData,
    diagnosis,
    setDiagnosis,
    NOW,
    THREE_MONTHS_LATER,
    SUCCESSOR_DEFAULT_BIRTHDATE,
    getSuccessionPlanYears,
    getDefaultArrivalDate,
  }),
  ({ setFormData }) => ({ setFormData }),
  ({ diagnosis }) => ({ diagnosis }),
  ({ setDiagnosis }) => ({ setDiagnosis }),
  ({ reconstructionYears }) => ({ reconstructionYears }),
  ({ setReconstructionYears }) => ({ setReconstructionYears }),
  ({ lpDeficit }) => ({ lpDeficit }),
  ({ setLpDeficit }) => ({ setLpDeficit }),
)
