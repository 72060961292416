import { yupResolver } from "@hookform/resolvers/yup"
import { DefaultValues, useForm, UseFormProps } from "react-hook-form"
import * as yup from "yup"
import { usePhase1FormData } from "pages/components/phase1/utils/hooks/usePhase1FormData"
import { usePhase1Route } from "pages/components/phase1/utils/hooks/usePhase1Route"
import { Phase1FormData } from "pages/components/phase1/utils/types"

/**
 * 各質問項目に対するuseForm
 * @param questionId 質問ID
 * @param options useFormにわたすオプション
 */
export function usePhase1Form<T extends keyof Phase1FormData>(
  questionId: T,
  options?: UseFormProps<Pick<Phase1FormData, T>>,
) {
  const { schema } = usePhase1Route(questionId)
  const { formData } = usePhase1FormData()

  const localSchema =
    schema !== undefined
      ? yup
          .object()
          .shape({
            [questionId]: schema,
          })
          .required()
      : undefined

  const defaultValues = {
    [questionId]: formData[questionId],
  } as DefaultValues<Pick<Phase1FormData, T>>

  return useForm<Pick<Phase1FormData, typeof questionId>>({
    mode: "onChange",
    resolver: yupResolver(localSchema),
    defaultValues,
    ...options,
  })
}
