import { SelectAndDate } from "api/types/phase2/utils"
import { SelectAndDateFormData } from "pages/utils/types/phase2FormData"

/**
 * 選択肢を選ぶと日付の入力欄が出てくる設問
 */
export const selectAndDateDraftTranslator = (
  answer: SelectAndDate | undefined,
): SelectAndDateFormData | undefined => ({
  select: answer?.answer?.content?.select?.choice_number,
  date: answer?.answer?.content?.execution_date?.content,
})
