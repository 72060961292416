import { memo, MutableRefObject } from "react"
import { offset, useFloating } from "@floating-ui/react-dom"
import { dequal } from "dequal"
import { AnimatePresence, motion, Variants } from "framer-motion"
import { createPortal } from "react-dom"
import styled from "styled-components"
import { HeaderPullDown as AtomHeaderPullDown } from "components/atoms/HeaderPullDown"
import { NavigateNextIcon } from "components/atoms/Icons"
import { TextWrap } from "components/atoms/TextWrap"
import { useBoolean } from "hooks/useBoolean"
import { useOutsideClick } from "hooks/useOutsideClick"
import { noop } from "utils"

const variants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}

/**
 * Pulldownの表示位置をリファレンスに対して23pxずらす
 */
const PULLDOWN_OFFSET = 23

interface MenuItem {
  /**
   * メニューラベル
   */
  label: string
  /**
   * メニューがdisabledであるかどうか
   */
  disabled?: boolean
  /**
   * onClick
   */
  onClick: () => void
}

interface Props {
  /**
   * クラス名
   */
  className?: string
  /**
   * ラベル名
   */
  label: string
  /**
   * Pulldownに表示するメニュー
   */
  menus?: MenuItem[]
  /**
   * Pulldownに表示するメニューのクリックハンドラ
   */
  onClickMenu?: (menu: MenuItem) => void
}

/**
 * ヘッダー用Pulldownコンポーネント
 */
export const HeaderPullDown = memo<Props>(
  ({ className, label, menus = [], onClickMenu = noop }) => {
    const { x, y, strategy, reference, floating, refs } = useFloating({
      placement: "bottom",
      middleware: [offset(PULLDOWN_OFFSET)],
    })

    const [isPulldownOpen, setIsPulldownOpen] = useBoolean(false)

    // MEMO: refs.referenceは必ずVirtualElementにはならないためキャストする
    // VirtualElementはポインティング先を実DOMを刺さないで行うためのもの
    useOutsideClick({
      enabled: isPulldownOpen,
      handler: setIsPulldownOpen.off,
      excludes: [
        refs.floating,
        refs.reference as MutableRefObject<Element | null>,
      ],
    })

    return (
      <Container
        ref={reference}
        className={className}
        onClick={setIsPulldownOpen.toggle}
      >
        <Label size="m" weight="bold" ellipsis={1}>
          {label}
        </Label>
        <NavigateNextIcon />
        {createPortal(
          <AnimatePresence initial={false}>
            {isPulldownOpen && (
              <PullDownContainer
                ref={floating}
                variants={variants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                style={{
                  position: strategy,
                  top: y ?? "",
                  left: x ?? "",
                }}
              >
                {menus.map((menu) => (
                  <AtomHeaderPullDown
                    key={menu.label}
                    disabled={menu.disabled}
                    onClick={() => {
                      menu.onClick()
                      onClickMenu(menu)
                    }}
                  >
                    {menu.label}
                  </AtomHeaderPullDown>
                ))}
              </PullDownContainer>
            )}
          </AnimatePresence>,
          document.body,
        )}
      </Container>
    )
  },
  dequal,
)

HeaderPullDown.displayName = "HeaderPullDown"

const Container = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  padding-right: 5px;
  padding-left: 20px;
`

const Label = styled(TextWrap)`
  color: ${({ theme }) => theme.color.nobel};

  :hover {
    color: ${({ theme }) => theme.color.nightRider};
  }
`

const PullDownContainer = styled(motion.div)``
