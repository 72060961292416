import { useCallback } from "react"
import { NavigateOptions, useNavigate } from "react-router-dom"
import {
  ADMIN_ROUTE_PATH,
  getCompanyEditPath,
  getCompanyUserDetailPath,
  getCompanyUserResultLPAnswerPath,
  getCompanyUserResultLPPath,
  getCompanyUserResultPhase1AnswerPath,
  getCompanyUserResultPhase1Path,
  getCompanyUserResultPhase2Path,
  getCompanyUserResultsPath,
  getCompanyUsersPath,
} from "routes"

/**
 * 経営承継専用の画面遷移集約Hooks
 */
export function useAdminRoute() {
  const navigate = useNavigate()

  const goToHome = useCallback(
    (options?: NavigateOptions) => {
      navigate(ADMIN_ROUTE_PATH.HOME, options)
    },
    [navigate],
  )

  const goToCompanyEdit = useCallback(
    (companyId: string, options?: NavigateOptions) => {
      navigate(getCompanyEditPath(companyId), options)
    },
    [navigate],
  )

  const goToCompanyUserDetail = useCallback(
    (companyId: string, userId: string, options?: NavigateOptions) => {
      navigate(getCompanyUserDetailPath(companyId, userId), options)
    },
    [navigate],
  )

  const goToCompanyCreate = useCallback(
    (options?: NavigateOptions) => {
      navigate(ADMIN_ROUTE_PATH.COMPANY_CREATE, options)
    },
    [navigate],
  )

  const goToCompanyCreateComplete = useCallback(
    (options?: NavigateOptions) => {
      navigate(ADMIN_ROUTE_PATH.COMPANY_CREATE_COMPLETE, options)
    },
    [navigate],
  )

  const goToCompanies = useCallback(
    (options?: NavigateOptions) => {
      navigate(ADMIN_ROUTE_PATH.COMPANIES, options)
    },
    [navigate],
  )

  const goToCompanyUsers = useCallback(
    (companyId: string, options?: NavigateOptions) => {
      navigate(getCompanyUsersPath(companyId), options)
    },
    [navigate],
  )

  const goToCompanyUserResults = useCallback(
    (companyId: string, userId: string, options?: NavigateOptions) => {
      navigate(getCompanyUserResultsPath(companyId, userId), options)
    },
    [navigate],
  )

  const goToCompanyUserResultLPAnswer = useCallback(
    (
      companyId: string,
      userId: string,
      lpId: number,
      options?: NavigateOptions,
    ) => {
      navigate(
        getCompanyUserResultLPAnswerPath(companyId, userId, lpId),
        options,
      )
    },
    [navigate],
  )

  const goToCompanyUserResultLP = useCallback(
    (
      companyId: string,
      userId: string,
      lpId: number,
      options?: NavigateOptions,
    ) => {
      navigate(getCompanyUserResultLPPath(companyId, userId, lpId), options)
    },
    [navigate],
  )

  const goToCompanyUserResultPhase1 = useCallback(
    (
      companyId: string,
      userId: string,
      phase1Id: number,
      options?: NavigateOptions,
    ) => {
      navigate(
        getCompanyUserResultPhase1Path(companyId, userId, phase1Id),
        options,
      )
    },
    [navigate],
  )

  const goToCompanyUserResultPhase1Answer = useCallback(
    (
      companyId: string,
      userId: string,
      phase1Id: number,
      options?: NavigateOptions,
    ) => {
      navigate(
        getCompanyUserResultPhase1AnswerPath(companyId, userId, phase1Id),
        options,
      )
    },
    [navigate],
  )

  const goToCompanyUserResultPhase2 = useCallback(
    (
      companyId: string,
      userId: string,
      phase2Id: number,
      options?: NavigateOptions,
    ) => {
      navigate(
        getCompanyUserResultPhase2Path(companyId, userId, phase2Id),
        options,
      )
    },
    [navigate],
  )

  const goToSettingsEmail = useCallback(
    (options?: NavigateOptions) => {
      navigate(ADMIN_ROUTE_PATH.SETTINGS_EMAIL, options)
    },
    [navigate],
  )

  const goToSettingPassword = useCallback(
    (options?: NavigateOptions) => {
      navigate(ADMIN_ROUTE_PATH.SETTINGS_PASSWORD, options)
    },
    [navigate],
  )

  const goToSettingsEmailPending = useCallback(
    (options?: NavigateOptions) => {
      navigate(ADMIN_ROUTE_PATH.SETTINGS_EMAIL_PENDING, options)
    },
    [navigate],
  )

  const goToSettingPasswordComplete = useCallback(
    (options?: NavigateOptions) => {
      navigate(ADMIN_ROUTE_PATH.SETTINGS_PASSWORD_COMPLETE, options)
    },
    [navigate],
  )

  return {
    goToHome,
    goToCompanyEdit,
    goToCompanies,
    goToCompanyUsers,
    goToCompanyUserDetail,
    goToCompanyUserResults,
    goToCompanyUserResultLP,
    goToCompanyUserResultPhase1,
    goToCompanyUserResultPhase2,
    goToCompanyCreate,
    goToCompanyCreateComplete,
    goToSettingPassword,
    goToSettingPasswordComplete,
    goToCompanyUserResultPhase1Answer,
    goToCompanyUserResultLPAnswer,
    goToSettingsEmail,
    goToSettingsEmailPending,
  } as const
}
