import { TransferringStock } from "api/types/phase2/utils"
import { TransferringStockFormData } from "pages/utils/types/phase2FormData"
import { dateToFiscalYear, now } from "utils/"

interface Args {
  json: {
    question: string
    options: {
      id: number
      label: string
      date_label?: string
    }[]
    plan: {
      label: string
      textbox: {
        label: {
          president: string
          successor: string
        }
      }
      unit: string
    }
  }
  formData?: TransferringStockFormData
}

/**
 * 株式の移転計画（A Q23）
 */
export const transferringStockTranslator = ({
  json,
  formData,
}: Args): TransferringStock | undefined => {
  if (formData === undefined) return undefined

  return {
    question: json.question,
    answer: {
      type: "object",
      content: {
        select: formData.select
          ? {
              type: "string",
              content: json.options[formData.select - 1].label,
              choice_number: formData.select,
            }
          : undefined,
        transferring_stock: formData.stocks && {
          type: "array",
          content: formData.stocks?.map(({ president, successor }, index) => {
            if (formData.select === undefined) return

            return {
              label: json.plan.label.replace(
                "${year}",
                String(dateToFiscalYear(now()) + index),
              ),
              president:
                president !== undefined
                  ? {
                      type: "number",
                      content: president,
                      label: json.plan.textbox.label.president,
                      unit: json.plan.unit,
                    }
                  : undefined,
              successor:
                successor !== undefined
                  ? {
                      type: "number",
                      content: successor,
                      label: json.plan.textbox.label.successor,
                      unit: json.plan.unit,
                    }
                  : undefined,
            }
          }),
        },
        execution_date:
          formData.date && formData.select
            ? {
                type: "string",
                content: formData.date,
                label: json.options[formData.select - 1]?.date_label,
              }
            : undefined,
      },
    },
  }
}
