import { Phase2AnswerC } from "api/types/phase2/c"
import phase2C from "assets/json/phase2/c.json"
import phase2E from "assets/json/phase2/e.json"
import { Phase2CFormData } from "pages/components/phase2/components/C/schema"
import { Phase2Type } from "pages/components/phase2/utils"
import { DeepPartial } from "utils"
import {
  selectAndDateTranslator,
  dateTranslator,
  stringTranslator,
  selectTranslator,
} from "./utils"

type Diagnosis = Extract<Phase2Type, "AC" | "IC" | "UC" | "EC">

interface Args {
  diagnosis: Diagnosis
  formData: DeepPartial<Phase2CFormData>
}

// TODO: phase2ResultAnswerがマージされたらそちらを使う
interface Return {
  diagnosis: Diagnosis
  answer: Phase2AnswerC
}

export const phase2CFormDataTranslator = ({
  diagnosis,
  formData,
}: Args): Return => ({
  diagnosis: diagnosis,
  answer: {
    question_group_1: {
      answers: {
        answer_01: selectTranslator({
          json: phase2C.q1,
          formData: formData.q1,
        }),
        answer_02: selectTranslator({
          json: phase2C.q2,
          formData: formData.q2,
        }),
        answer_03: selectTranslator({
          json: phase2C.q3,
          formData: formData.q3,
        }),
        answer_04: dateTranslator({
          json: phase2C.q4,
          formData: formData.q4,
        }),
        answer_05: selectAndDateTranslator({
          json: phase2C.q5,
          formData: formData.q5,
        }),
        answer_06: selectAndDateTranslator({
          json: phase2C.q6,
          formData: formData.q6,
        }),
        answer_07: selectAndDateTranslator({
          json: phase2C.q7,
          formData: formData.q7,
        }),
      },
    },
    question_group_2: {
      title: phase2C.title2,
      answers: {
        answer_08: selectAndDateTranslator({
          json: phase2C.q8,
          formData: formData.q8,
        }),
        answer_09: selectAndDateTranslator({
          json: phase2C.q9,
          formData: formData.q9,
        }),
        answer_10: selectTranslator({
          json: phase2C.q10,
          formData: formData.q10,
        }),
        answer_11: selectAndDateTranslator({
          json: phase2C.q11,
          formData: formData.q11,
        }),
        answer_12: selectTranslator({
          json: phase2C.q12,
          formData: formData.q12,
        }),
      },
    },
    question_group_3: {
      title: phase2C.title3,
      answers: {
        answer_13: selectTranslator({
          json: phase2C.q13,
          formData: formData.q13,
        }),
      },
    },
    question_group_4: {
      answers: {
        // answer_14 表示のみ
        answer_15: selectAndDateTranslator({
          json: phase2E.q1,
          formData: formData.e?.q1,
        }),
        answer_16: selectAndDateTranslator({
          json: phase2E.q2,
          formData: formData.e?.q2,
        }),
        answer_17: selectAndDateTranslator({
          json: phase2E.q3,
          formData: formData.e?.q3,
        }),
        answer_18: dateTranslator({
          json: phase2E.q4,
          formData: formData.e?.q4,
        }),
        answer_19: dateTranslator({
          json: phase2E.q5,
          formData: formData.e?.q5,
        }),
        answer_20: dateTranslator({
          json: phase2E.q6,
          formData: formData.e?.q6,
        }),
        answer_21: dateTranslator({
          json: phase2E.q7,
          formData: formData.e?.q7,
        }),
        answer_22: dateTranslator({
          json: phase2E.q8,
          formData: formData.e?.q8,
        }),
        answer_23: dateTranslator({
          json: phase2E.q9,
          formData: formData.e?.q9,
        }),
        answer_24: dateTranslator({
          json: phase2E.q10,
          formData: formData.e?.q10,
        }),
        answer_25: selectAndDateTranslator({
          json: phase2E.q11,
          formData: formData.e?.q11,
        }),
        answer_26: dateTranslator({
          json: phase2E.q12,
          formData: formData.e?.q12,
        }),
        answer_27: dateTranslator({
          json: phase2E.q13,
          formData: formData.e?.q13,
        }),
      },
    },
    question_group_5: {
      title: phase2E.title2,
      answers: {
        answer_28: dateTranslator({
          json: phase2E.q14,
          formData: formData.e?.q14,
        }),
        answer_29: dateTranslator({
          json: phase2E.q15,
          formData: formData.e?.q15,
        }),
        answer_30: stringTranslator({
          json: phase2E.q16,
          formData: formData.e?.q16,
        }),
      },
    },
    question_group_6: {
      title: phase2E.title3,
      answers: {
        answer_31: dateTranslator({
          json: phase2E.q17,
          formData: formData.e?.q17,
        }),
        answer_32: dateTranslator({
          json: phase2E.q18,
          formData: formData.e?.q18,
        }),
        answer_33: dateTranslator({
          json: phase2E.q19,
          formData: formData.e?.q19,
        }),
        answer_34: dateTranslator({
          json: phase2E.q20,
          formData: formData.e?.q20,
        }),
        answer_35: dateTranslator({
          json: phase2E.q21,
          formData: formData.e?.q21,
        }),
        answer_36: dateTranslator({
          json: phase2E.q22,
          formData: formData.e?.q22,
        }),
        answer_37: dateTranslator({
          json: phase2E.q23,
          formData: formData.e?.q23,
        }),
        answer_38: selectAndDateTranslator({
          json: phase2E.q24,
          formData: formData.e?.q24,
        }),
        answer_39: selectAndDateTranslator({
          json: phase2E.q25,
          formData: formData.e?.q25,
        }),
      },
    },
    question_group_7: {
      title: phase2E.title4,
      answers: {
        answer_40: dateTranslator({
          json: phase2E.q26,
          formData: formData.e?.q26,
        }),
        answer_41: dateTranslator({
          json: phase2E.q27,
          formData: formData.e?.q27,
        }),
        answer_42: dateTranslator({
          json: phase2E.q28,
          formData: formData.e?.q28,
        }),
        answer_43: dateTranslator({
          json: phase2E.q29,
          formData: formData.e?.q29,
        }),
        answer_44: dateTranslator({
          json: phase2E.q30,
          formData: formData.e?.q30,
        }),
        answer_45: dateTranslator({
          json: phase2E.q31,
          formData: formData.e?.q31,
        }),
        answer_46: dateTranslator({
          json: phase2E.q32,
          formData: formData.e?.q32,
        }),
        answer_47: dateTranslator({
          json: phase2E.q33,
          formData: formData.e?.q33,
        }),
        answer_48: dateTranslator({
          json: phase2E.q34,
          formData: formData.e?.q34,
        }),
        answer_49: dateTranslator({
          json: phase2E.q35,
          formData: formData.e?.q35,
        }),
        answer_50: dateTranslator({
          json: phase2E.q36,
          formData: formData.e?.q36,
        }),
        answer_51: dateTranslator({
          json: phase2E.q37,
          formData: formData.e?.q37,
        }),
      },
    },
  },
})
