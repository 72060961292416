import { useSearchParams } from "react-router-dom"
import { LPQuestionId } from ".."

const isQuestionId = (
  questionId: string | null,
): questionId is LPQuestionId => {
  if (questionId === null) {
    return false
  }
  return Object.values<string>(LPQuestionId).includes(questionId)
}

/**
 * paramsからquestionIdを取得する
 * paramsの値が想定外のものだった場合はundefined
 */
export const useLPQuestionId = (): LPQuestionId | undefined => {
  const [searchParam] = useSearchParams()
  const questionId = searchParam.get("question")

  if (!isQuestionId(questionId)) {
    return
  }

  return questionId
}
