import { AxiosError } from "axios"
import { useMutation, UseMutationOptions } from "react-query"
import { OmitMutationConfig } from "lib/react-query"
import { InvitationAPI } from "./api"
import {
  InvitationGetResponse,
  InvitationCreateResponse,
  InvitationId,
} from "./types"

const PREFIX_ID = "invitation"

export const verifyInvitationMutation: UseMutationOptions<
  InvitationGetResponse,
  AxiosError,
  InvitationId
> = {
  mutationKey: `${PREFIX_ID}/verifyInvitation/`,
  mutationFn: InvitationAPI.verifyInvitation,
}

/**
 * 招待IDの有効期限を検証するHook
 * @param config
 */
export function useVerifyInvitationMutation(
  config?: OmitMutationConfig<typeof verifyInvitationMutation>,
) {
  return useMutation({ ...verifyInvitationMutation, ...config })
}

export const createInvitationMutation: UseMutationOptions<
  InvitationCreateResponse,
  AxiosError,
  void
> = {
  mutationKey: `${PREFIX_ID}/createInvitation`,
  mutationFn: InvitationAPI.createInvitation,
}

/**
 * 専門家招待URLを発行するHook
 * @param config
 */
export function useCreateInvitationMutation(
  config?: OmitMutationConfig<typeof createInvitationMutation>,
) {
  return useMutation({ ...createInvitationMutation, ...config })
}
