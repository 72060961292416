import { ReactNode } from "react"
import styled from "styled-components"
import { Button } from "components/atoms/Button"
import { Modal } from "components/atoms/Modal"
import { TextWrap } from "components/atoms/TextWrap"
import { useMediaLessThan } from "hooks/useMedia"

type ButtonDirection = "col" | "row"

const MODAL_PADDING_X = 38
const MODAL_PADDING_Y = 25
const TEXT_BUTTON_GAP = 25
const BUTTON_GAP = 20

interface Props {
  /**
   * textの内容
   */
  children: ReactNode
  /**
   * cancelボタンを押した際のアクション
   */
  onCancel: () => void
  /**
   * Yesボタンを押した際のアクション
   */
  onAgree: () => void
  /**
   * ボタンの方向
   * 指定がなければ自動
   */
  buttonDirection?: ButtonDirection
}

/**
 * 「はい」か「キャンセル」で答えるModal
 */
export const YesNoModal = ({
  children,
  onAgree,
  onCancel,
  buttonDirection,
}: Props) => {
  const isMobile = useMediaLessThan("tablet")
  const direction = buttonDirection ?? isMobile ? "col" : "row"

  return (
    <StyledModal dir={direction}>
      <TextWrap size="m" weight="bold">
        {children}
      </TextWrap>
      <ButtonWrapper dir={direction}>
        <Button onClick={onAgree}>はい</Button>
        <Button color="gray" onClick={onCancel}>
          キャンセル
        </Button>
      </ButtonWrapper>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)<{ dir: ButtonDirection }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ dir }) => (dir === "col" ? "center" : "stretch")};
  padding: ${MODAL_PADDING_Y}px ${MODAL_PADDING_X}px;
  gap: ${TEXT_BUTTON_GAP}px;
`

const ButtonWrapper = styled.div<{ dir: ButtonDirection }>`
  display: flex;
  width: 100%;
  flex-direction: ${({ dir }) => (dir === "col" ? "column" : "row")};
  gap: ${BUTTON_GAP}px;
`
